import { MetricSuggestion, configuationFromMetricSuggestion } from 'Slices/editMetricCard';
import { ADMIN_INDEX_SOURCE_TYPES, SOURCE_TYPES } from 'Utils/enums/SourceModelTypes';
import { store } from 'src/core/store';

const priortisedCombinations = [
  [ADMIN_INDEX_SOURCE_TYPES.volume, SOURCE_TYPES.SUPPORT_TYPE],
  [ADMIN_INDEX_SOURCE_TYPES.sentiment, SOURCE_TYPES.NPS_TYPE],
  [ADMIN_INDEX_SOURCE_TYPES.sentiment, SOURCE_TYPES.CSAT_TYPE],
  [ADMIN_INDEX_SOURCE_TYPES.sentiment, SOURCE_TYPES.SURVEY_TYPE],
  [ADMIN_INDEX_SOURCE_TYPES.nps, SOURCE_TYPES.NPS_TYPE],
  [ADMIN_INDEX_SOURCE_TYPES.csat, SOURCE_TYPES.SURVEY_TYPE],
] as const;

const getMetricCombination = (sourceType: string, valueType: string) => {
  return priortisedCombinations.find(([source, value]) => source === sourceType && value === valueType);
};

const getPriortisedMetricSuggestions = () => {
  const suggestedMetricCards = store.getState().editMetricCard.suggestedMetricCards;
  const sources = store.getState().auth.user.clients[0].es_aliases;
  const selectedCombinations = new Set<string>();
  sources.forEach((source) => {
    const combinations = source.metrics.map((metric) => getMetricCombination(source.type, metric.name)).filter(Boolean);
    if (!combinations.length) return;
    const selectedSuggestion = suggestedMetricCards.find((suggestion: MetricSuggestion) => `${combinations[0][1]}-${source.name}` === suggestion.id);
    if (combinations.length && selectedSuggestion && selectedSuggestion?.data.value !== 0)
      selectedCombinations.add(`${combinations[0][1]}-${source.name}`);
    else if (source.metrics.length) {
      const suggestionId = suggestedMetricCards.find(
        (suggestion: MetricSuggestion) => suggestion.data.value !== 0 && suggestion.id.includes(source.name)
      )?.id;
      if (suggestionId) selectedCombinations.add(suggestionId);
    }
  });
  if (selectedCombinations.size >= 4) return [...selectedCombinations].slice(0, 4);
  sources.forEach((source) => {
    source.metrics.forEach((metric) => {
      if (suggestedMetricCards.find((suggestion: MetricSuggestion) => `${metric.name}-${source.name}` === suggestion.id))
        selectedCombinations.add(`${metric.name}-${source.name}`);
    });
  });
  return [...selectedCombinations].slice(0, 4);
};

export const getMetricSuggestions = () => {
  const suggestedMetricCards = store.getState().editMetricCard.suggestedMetricCards;
  if (!suggestedMetricCards?.length) return;
  const priortisedMetricSuggestions = getPriortisedMetricSuggestions();
  return suggestedMetricCards.filter((suggestion: MetricSuggestion) => priortisedMetricSuggestions.includes(suggestion.id));
};
