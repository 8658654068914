import { Box } from 'grommet';
import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import Fuse from 'fuse.js';
import DropdownItem from '../DropdownItem';
import { IOption } from '../types';
import { TextInputGray } from 'src/components/Input';
export interface IDropdownMenuBase<T> {
  options: IOption<T>[];
  onChange: (option: T) => void;
  enableSearch?: boolean;
  searchPlaceholder?: string;
  selected?: IOption<T>;
  extra?: React.ReactNode;
}

export interface IDropdownMenuProps<T> extends IDropdownMenuBase<T> {
  collapseMenu?: () => void;
}

const DropdownMenu = <T,>({ options, enableSearch, onChange, collapseMenu, searchPlaceholder, selected, extra }: IDropdownMenuProps<T>) => {
  const [searchString, setSearchString] = useState<string>('');
  const [matchedOptions, setMatchedOptions] = useState<IOption<T>[]>(options);
  const fuse = useMemo(() => new Fuse(options, { keys: ['label'] }), [options]);
  const searchOptions = () => {
    if (!searchString) return options;
    return fuse.search(searchString).map((result) => result.item);
  };
  const selectOption = useCallback(
    (option: IOption<T>) => {
      if (!option.disabled) onChange(option.value);
      collapseMenu?.();
    },
    [onChange]
  );
  useEffect(() => {
    setMatchedOptions(searchOptions());
  }, [options, searchString]);
  return (
    <Box pad={{ vertical: '5px' }} overflow="hidden" width="280px">
      {enableSearch && (
        <Box
          flex="grow"
          pad={{
            vertical: '5px',
            horizontal: 'small',
          }}
        >
          <TextInputGray placeholder={searchPlaceholder} value={searchString} onChange={(e) => setSearchString(e.target.value)} autoFocus />
        </Box>
      )}{' '}
      <Box flex="grow" height={{ max: '320px' }} overflow="auto">
        {matchedOptions.map((option) => (
          <DropdownItem
            key={option.label}
            label={option.label}
            onClick={() => selectOption(option)}
            isDisabled={option.disabled}
            capitalize={true}
            optionValue={option.value}
            selected={option.value === selected?.value}
          />
        ))}
      </Box>
      {extra}
    </Box>
  );
};

export default DropdownMenu;
