import { PayloadAction, createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit';
import { IDateFilterData, IGetDateRangeBoundsResponse } from './types/filter';
import { RootState } from '../store';
import getDateRangeIntervals, { setDateRangeOnDateFieldChange } from 'Utils/constants/DateRangeIntervals';
import { getPreviousDefaultDateRange, setPreveiousDateRangeOnDateRangeChange } from 'Utils/constants/previousDateRangeIntervals';
import { setBreakDownOnDateRangeChange } from 'Utils/constants/BreakDownOptions';
import { getDateRangeBounds, getFiltersData } from 'src/api/Filters';
import { CommonFilterState } from './types/common';
import { INTERVAL_BREAKDOWN_TYPES } from 'Utils/enums/chartIntervalBreakdownTypes';
import { IChartComparator } from 'src/types/Filter';
import { getSummaryOverviewGraphData } from 'src/api/Summary';
import { IApiCallStatus } from 'src/types/Globals';
import { MetricsType, OverTimeData } from 'src/api/apiTypes/Summary';
import { SENTISUM_TAG_DIMENSION_OBJECT } from 'Utils/enums/chartDimensionsType';
import { createReport, editReport, getSelectedClientSourceData } from 'src/api/dashboard/Report';
import { IEsAlias, ISelectedClientSourceData, IUser } from 'src/types/User';
import { MetricReportConfigration } from 'src/api/apiTypes/Report';
import { CHART_COMPARATOR_TYPES } from 'Utils/enums/ChartComparatorTypes';
import { IDataSourceFilters, IFilterRelation } from 'src/types/Bookmark';
import { getMetricCardsReportData, hasInValidFilters, transformFilters, transformViewToFilters } from 'src/api/Tranformers';
import { cloneDeep, isEqual } from 'lodash';
import filterColors from 'Constants/filterColors.json';
import { getReportConfigurationThunk } from './editReport';
import { SourceModelTypesEnum } from 'Utils/enums/SourceModelTypes';
import { LANG_TYPES, TEXT_TYPES } from 'Utils/constants/languages';
import { metricCardBgColors } from 'Pages/Dashboard/DashboardContent/MetricReportCard/MetricReport/MetricCardGrid';

export const createMetricCardThunk = createAsyncThunk<unknown, number, { state: RootState }>(
  'metricCard/createMetricCardThunk',
  (dashboardId, { getState }) => createReport(getMetricCardsReportData(dashboardId, getState().editMetricCard.metricCards))
);
export const editMetricCardThunk = createAsyncThunk<unknown, number, { state: RootState }>(
  'metricCard/editMetricCardThunk',
  (dashboardId, { getState }) => editReport(getState().editReport.id, getMetricCardsReportData(dashboardId, getState().editMetricCard.metricCards))
);

const setDefaultDateRange = (state: EditMetricCardState) => {
  const dateRangeBounds = state.dateRangeBounds;
  const dateRanges = getDateRangeIntervals(dateRangeBounds, dateRangeBounds[0].date_field, []);
  const dateRangeKey = 'last7days';
  const selectedDateRange = dateRanges.find(({ key }) => key === dateRangeKey);
  state.dateRangeFilter = state.dateRangeFilter || {};
  state.dateRangeFilter.selectedDateField = dateRangeBounds[0].date_field;
  state.dateRangeFilter.dateRange = { start: selectedDateRange?.startDate, end: selectedDateRange?.endDate };
  state.dateRangeFilter.interval = selectedDateRange?.key;
  state.dateRangeFilter.previousDateRange = getPreviousDefaultDateRange(state.dateRangeFilter.dateRange);
  state.dateRangeFilter.previous_interval = 'default';
  state.chartIntervalBreakdown = setBreakDownOnDateRangeChange(state.dateRangeFilter.dateRange);
};

const getSourceData = async (alias: IEsAlias, metricCardState: EditMetricCardState, user: IUser) => {
  const { dateRangeFilter, chartIntervalBreakdown } = metricCardState;
  const metricsData = await getSummaryOverviewGraphData(
    { allMetrics: alias.metrics },
    { filters: { source: alias.name, ...dateRangeFilter }, chartIntervalBreakdown, chartDimensionObject: SENTISUM_TAG_DIMENSION_OBJECT }
  );
  return { metricsData, source: getSelectedClientSourceData(alias.name, user) };
};

export const availableSourceDateRangeBounds = createAsyncThunk<IGetDateRangeBoundsResponse, void, { state: RootState }>(
  'availableSourceDateRangeBounds',
  (_, { getState }) => getDateRangeBounds(getState().auth.user.clients[0].es_aliases.map((alias) => alias.name))
);

export const fetchMetricsData = createAsyncThunk('metricCard/fetchMetricsData', async (_, { getState }) => {
  const state = getState() as RootState;
  const client = state.auth.user.clients[0];
  const results = await Promise.allSettled(client.es_aliases.map((alias) => getSourceData(alias, state.editMetricCard, state.auth.user)));

  const fulfilledResults = results.filter((result) => result.status === 'fulfilled') as PromiseFulfilledResult<any>[];
  const rejectedResults = results.filter((result) => result.status === 'rejected') as PromiseRejectedResult[];
  rejectedResults.forEach((result) => {
    console.error('API call failed:', result.reason);
  });
  return fulfilledResults.map((result) => result.value);
});

export interface MetricSuggestion {
  id: string;
  metricName: string;
  source: ISelectedClientSourceData;
  data: MetricsType;
}

export const configuationFromMetricSuggestion = (suggestion: MetricSuggestion, bgColor?: string) => {
  return {
    id: nanoid(),
    source: suggestion.source.es_alias.name,
    name: SourceModelTypesEnum?.[suggestion.metricName]?.[LANG_TYPES.EN]?.[TEXT_TYPES.ALIAS],
    bgColor: bgColor,
    conditions: {},
    metrics: [suggestion.source.es_alias.metrics.find((metric) => metric.name === suggestion.metricName)],
    dimension: SENTISUM_TAG_DIMENSION_OBJECT,
    comparisons: [CHART_COMPARATOR_TYPES.ABSOLUTE_TYPE],
  };
};

export type EditMetricCardState = {
  dateRangeFilter?: Pick<CommonFilterState['filters'], 'dateRange' | 'interval' | 'selectedDateField' | 'previousDateRange' | 'previous_interval'>;
  suggestedMetricCards: MetricSuggestion[];
  fetchMetricsStatus: IApiCallStatus;
  fetchMetricsBySourceStatus: IApiCallStatus;
  metricCards: (MetricReportConfigration & { id: string })[];
  selecedMetricCard?: Pick<
    CommonFilterState,
    'filterdata' | 'bookmarkFilters' | 'selectedSourceClientData' | 'filters' | 'chartComparator' | 'chartMetricObject' | 'chartDimensionObject'
  > & { name: string; bgColor: string };
  selectedMetricCardIndex?: number;
  showSuggestedMetricInfo?: boolean;
} & Pick<
  CommonFilterState,
  'fetchAvailbleDateRangeBoundsStatus' | 'fetchAvailbleDateRangeBoundsError' | 'dateRangeBounds' | 'chartIntervalBreakdown'
>;

export const getMetricCardConfigFromFilterData = (filterData: EditMetricCardState['selecedMetricCard'], id: string) => {
  const { filters, name } = filterData;
  return {
    id,
    source: filters.source,
    name,
    conditions: {
      dataSourceFilters: filters.metadata,
      restrict_dimensions: filters.isRestrictDimension,
    },
    bgColor: filterData.bgColor,
    metrics: [filterData.chartMetricObject],
    dimension: filterData.chartDimensionObject,
    comparisons: [filterData.chartComparator],
  };
};

const initialState: EditMetricCardState = {
  fetchAvailbleDateRangeBoundsStatus: 'idle',
  fetchAvailbleDateRangeBoundsError: '',
  chartIntervalBreakdown: 'day',
  suggestedMetricCards: [],
  fetchMetricsStatus: 'idle',
  fetchMetricsBySourceStatus: 'idle',
  metricCards: [],
  selectedMetricCardIndex: null,
  selecedMetricCard: null,
  showSuggestedMetricInfo: true,
};
const EditMetricCardSlice = createSlice({
  name: 'editMetricCard',
  initialState: { ...initialState },
  reducers: {
    resetMetricState: (state) => Object.assign(state, initialState),
    updateFilterDateRange: (state, action: PayloadAction<IDateFilterData>) => {
      if (!state.dateRangeFilter) return;
      state.dateRangeFilter.dateRange = { start: action.payload.startDate, end: action.payload.endDate };
      state.dateRangeFilter.interval = action.payload.interval;
      state.dateRangeFilter.previousDateRange = setPreveiousDateRangeOnDateRangeChange(state.dateRangeFilter);
      state.chartIntervalBreakdown = setBreakDownOnDateRangeChange(state.dateRangeFilter.dateRange, state.chartIntervalBreakdown);
      if (state.selecedMetricCard?.filters) state.selecedMetricCard.filters = { ...state.selecedMetricCard.filters, ...state.dateRangeFilter };
    },
    updateChartPreviousInterval: (state, action: PayloadAction<IDateFilterData>) => {
      const { startDate, endDate, interval } = action.payload;
      if (!state.dateRangeFilter) return;
      state.dateRangeFilter.previousDateRange = { start: startDate, end: endDate };
      state.dateRangeFilter.previous_interval = interval;
      if (state.selecedMetricCard?.filters) state.selecedMetricCard.filters = { ...state.selecedMetricCard.filters, ...state.dateRangeFilter };
    },
    updateSelectedDateField: (state, action: PayloadAction<string>) => {
      state.dateRangeFilter.selectedDateField = action.payload;
      state.dateRangeFilter.dateRange = setDateRangeOnDateFieldChange(state.dateRangeBounds, state.dateRangeFilter);
      state.dateRangeFilter.previousDateRange = setPreveiousDateRangeOnDateRangeChange(state.dateRangeFilter);
      if (state.selecedMetricCard?.filters) state.selecedMetricCard.filters = { ...state.selecedMetricCard.filters, ...state.dateRangeFilter };
    },
    updateChartIntervalBreakdown: (state, action: PayloadAction<INTERVAL_BREAKDOWN_TYPES>) => {
      state.chartIntervalBreakdown = action.payload;
    },
    updateChartComparator: (state, action: PayloadAction<IChartComparator>) => {
      if (state.selecedMetricCard) state.selecedMetricCard.chartComparator = action.payload;
    },
    updateMetricCards: (state, action: PayloadAction<EditMetricCardState['metricCards']>) => {
      state.metricCards = action.payload;
    },
    replaceMetricCard: (state, action: PayloadAction<{ newMetricCard: MetricReportConfigration; targetIndex: number }>) => {
      const { newMetricCard, targetIndex } = action.payload;
      if (targetIndex >= 0 && targetIndex < state.metricCards.length) {
        state.metricCards.splice(targetIndex, 1, newMetricCard);
      }
    },
    updateDataSourceFilters: (state, action: PayloadAction<IDataSourceFilters>) => {
      state.selecedMetricCard.bookmarkFilters.datasourceFilters = action.payload;
      if (state.selecedMetricCard.filters.metadata.length === 0) state.selecedMetricCard.filters.isRestrictDimension = false;
      const newMedataFilters = transformFilters(cloneDeep(state.selecedMetricCard.bookmarkFilters));
      if (!hasInValidFilters(state.selecedMetricCard.bookmarkFilters) && !isEqual(state.selecedMetricCard.filters.metadata, newMedataFilters))
        state.selecedMetricCard.filters.metadata = newMedataFilters;
    },
    updatePreviousFilterRelation: (state, action: PayloadAction<IFilterRelation>) => {
      state.selecedMetricCard.bookmarkFilters.previousFilterRelation = action.payload;
      const newMedataFilters = transformFilters(cloneDeep(state.selecedMetricCard.bookmarkFilters));
      if (!isEqual(state.selecedMetricCard.filters.metadata, newMedataFilters)) state.selecedMetricCard.filters.metadata = newMedataFilters;
    },
    selectMetricCard: (state, action: PayloadAction<{ index: number; user: IUser }>) => {
      const { index, user } = action.payload;
      state.selectedMetricCardIndex = index;
      const { source, comparisons, conditions, metrics, name, bgColor } = state.metricCards[index];
      state.selecedMetricCard = {
        name: name === metrics[0].name ? SourceModelTypesEnum?.[metrics[0].name]?.[LANG_TYPES.EN]?.[TEXT_TYPES.ALIAS] : name,
        selectedSourceClientData: getSelectedClientSourceData(source, user),
        filters: {
          source,
          ...state.dateRangeFilter,
          metadata: conditions.dataSourceFilters ?? [],
          isRestrictDimension: conditions.restrict_dimensions,
        },
        bgColor,
        bookmarkFilters: transformViewToFilters({ dataSourceFilters: conditions.dataSourceFilters ?? [], search: '' }),
        filterdata: { filters: [], topics: [], themes: [] },
        chartMetricObject: metrics[0],
        chartDimensionObject: SENTISUM_TAG_DIMENSION_OBJECT,
        chartComparator: comparisons[0] as IChartComparator,
      };
    },
    updateCardFilterData: (state, action: PayloadAction<Awaited<ReturnType<typeof getFiltersData>>>) => {
      const { filters, topics, themes } = action.payload;
      state.selecedMetricCard.filterdata.filters = filters.map((filter, index) => ({ ...filter, theme: filterColors[index % filterColors.length] }));
      state.selecedMetricCard.filterdata.topics = topics.map((topic) => ({ value: topic, count: 0 }));
      state.selecedMetricCard.filterdata.themes = themes;
    },
    updateCardName: (state, action: PayloadAction<string>) => {
      state.selecedMetricCard.name = action.payload;
    },
    saveMetricCardConfig: (state) => {
      if (state.selectedMetricCardIndex !== null) {
        state.metricCards[state.selectedMetricCardIndex] = getMetricCardConfigFromFilterData(
          state.selecedMetricCard,
          state.metricCards[state.selectedMetricCardIndex].id
        );
      }
      state.selecedMetricCard = null;
      state.selectedMetricCardIndex = null;
    },
    discardMetricCardConfig: (state) => {
      state.selecedMetricCard = null;
      state.selectedMetricCardIndex = null;
    },
    updateShowSuggestedMetricInfo: (state, action: PayloadAction<boolean>) => {
      state.showSuggestedMetricInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(availableSourceDateRangeBounds.pending, (state) => {
        state.fetchAvailbleDateRangeBoundsStatus = 'pending';
      })
      .addCase(availableSourceDateRangeBounds.fulfilled, (state, action) => {
        state.fetchAvailbleDateRangeBoundsStatus = 'fulfilled';
        const dateRangeData = action.payload.filterdata;
        if (dateRangeData.length) {
          state.dateRangeBounds = dateRangeData.map(({ dateRange, dateField }) => ({ ...dateField, ...dateRange }));
          setDefaultDateRange(state);
        }
      })
      .addCase(availableSourceDateRangeBounds.rejected, (state, action) => {
        state.fetchAvailbleDateRangeBoundsStatus = 'rejected';
        state.fetchAvailbleDateRangeBoundsError = action.error.message;
      })
      .addCase(fetchMetricsData.pending, (state, action) => {
        state.fetchMetricsStatus = 'pending';
      })
      .addCase(fetchMetricsData.fulfilled, (state, action) => {
        state.suggestedMetricCards = action.payload
          .map(({ metricsData, source }) =>
            Object.entries(metricsData?.data).map(([metricName, data], index) => {
              return {
                id: `${metricName}-${source.es_alias.name}`,
                data: (data as OverTimeData).current_interval.metrics.find((metric) => metric.metric_type === metricName),
                source,
                metricName,
              };
            })
          )
          .flat();
        state.fetchMetricsStatus = 'fulfilled';
      })
      .addCase(fetchMetricsData.rejected, (state, action) => {
        state.fetchMetricsStatus = 'rejected';
      })
      .addCase(getReportConfigurationThunk.fulfilled, (state, action) => {
        Object.assign(state, initialState);
        const report_type = action.payload.configuration.report_type;
        if (report_type !== 'MC') return;
        state.metricCards = action.payload.configuration.metric_cards.map((card, index) => {
          const bgColor = metricCardBgColors[index % metricCardBgColors.length];
          return {
            id: nanoid(),
            ...card,
            bgColor,
          };
        });
      });
  },
});

export const {
  updateFilterDateRange,
  updateChartPreviousInterval,
  updateSelectedDateField,
  updateChartIntervalBreakdown,
  updateChartComparator,
  updateMetricCards,
  replaceMetricCard,
  updateCardName,
  updateDataSourceFilters,
  updatePreviousFilterRelation,
  selectMetricCard,
  updateShowSuggestedMetricInfo,
  saveMetricCardConfig,
  updateCardFilterData,
  resetMetricState,
  discardMetricCardConfig,
} = EditMetricCardSlice.actions;

export default EditMetricCardSlice.reducer;
