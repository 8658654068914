import { cn } from 'Utils/TailwindUtils';
import React, { FC, createElement, useMemo } from 'react';
import { DriversGraph } from './DriversGraph';
import { SOURCE_TYPES } from 'Utils/enums/SourceModelTypes';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { Topic } from 'src/api/apiTypes/Summary';
import { getDriverData } from 'Utils';
import * as HoverCard from '@radix-ui/react-hover-card';
import { TableBodyProps } from './TableBody';
import { DownRed, UpGreen } from 'Static/images';

interface TableDataProps extends Omit<TableBodyProps, 'drivers'> {
  driver: Topic;
}

export const TableBodyData: FC<TableDataProps> = ({ driver, ...props }) => {
  const { chartMetricObject, chartComparator, chartDimensionObject, openLinkInDiscoverPage, lazyLoadDisabled, animationDisabled } = props;
  const { valueText, changeText, color, change, noOfResponses } = useMemo(
    () => getDriverData(driver, chartComparator, chartMetricObject.name, true),
    [chartMetricObject.name, chartComparator, driver]
  );
  return (
    <tr
      className={cn('border-b border-gray-150 duration-100', {
        'hover:shadow-[2px_2px_18px_8px_rgba(163,177,198,0.2)]': openLinkInDiscoverPage,
      })}
      role={openLinkInDiscoverPage ? 'button' : undefined}
      onClick={openLinkInDiscoverPage ? () => openLinkInDiscoverPage(driver.name) : undefined}
    >
      <td scope="row" className="truncate py-4 pl-6 text-left font-medium capitalize">
        <HoverCard.Root openDelay={10} closeDelay={0}>
          <HoverCard.Trigger>
            <span className={cn({ 'hover:underline': openLinkInDiscoverPage })}>{driver?.name}</span>
          </HoverCard.Trigger>
          <HoverCard.Portal>
            <HoverCard.Content sideOffset={12} side="top">
              <span className="rounded-lg bg-white px-3 py-1.5 text-sm capitalize text-blue-1100 shadow-xl">{driver?.name}</span>
            </HoverCard.Content>
          </HoverCard.Portal>
        </HoverCard.Root>
      </td>
      <td className="px-6 py-4">{valueText}</td>
      <td className="flex grow items-center justify-center gap-2 px-6 py-1">
        <div style={{ color }} className="flex items-center gap-1 py-3">
          {createElement(change < 0 ? DownRed : UpGreen, { stroke: color, fill: color })}
          {changeText}
        </div>
        <DriversGraph
          selectedMetricType={chartMetricObject.name}
          driver={driver}
          chartComparator={chartComparator}
          chartDimension={chartDimensionObject.name}
          diasableAnimation={animationDisabled}
          lazyLoadDisabled={lazyLoadDisabled}
        />
      </td>
      {SOURCE_TYPES.SURVEY_TYPE === chartMetricObject.name && <td className="px-6 py-4">{noOfResponses}</td>}
    </tr>
  );
};
