import React from 'react';
import { HiOutlineGlobeAlt } from 'react-icons/hi2';
import { CopyLink } from './CopyLink';
import { UpdateLink } from './UpdateLink';

export const MenuContent = (targetAnchor: HTMLElement) => {
  const link = targetAnchor?.getAttribute('href');
  if (!link) return null;
  return (
    <div className="flex items-center gap-1.5 rounded-xl border border-grey-200 bg-white px-3.5 py-1 text-base text-grey-500 shadow-md">
      <HiOutlineGlobeAlt />
      <span className="max-w-56 truncate text-xs">{link}</span>
      <CopyLink link={link} />
      <UpdateLink targetElement={targetAnchor} />
    </div>
  );
};
