import React from 'react';
import styled from 'styled-components';
import { Box, Text, TextExtendedProps } from 'grommet';
const StyledText = styled(Text)`
  text-transform: capitalize;
  color: #000000;
`;
interface IToolTipText {
  text: string;
  textProps?: TextExtendedProps;
}
const TooltipText: React.FC<IToolTipText> = ({ text, textProps }) => {
  return (
    <Box>
      <StyledText size="xsmall" {...textProps}>
        {text}
      </StyledText>
    </Box>
  );
};

export default TooltipText;
