import { FormatFiltersV4Params, FormatFiltersV4State, formatFiltersV4 } from 'src/api/Tranformers';
import { getDataApiClientInstance } from 'src/api/utils/clientAdapters';
import { FetchConversationsProps } from './conversation';
import moment from 'moment';
import { EXPORT_DATA_TYPE } from 'Components/DataExportComponents/DataExportEnums';
import { Topic } from './apiTypes/Summary';
import { saveAs } from 'file-saver';
import { Dimension, ISelectedClientSourceData, Metric } from 'src/types/User';

interface DownloadDataExport extends FetchConversationsProps {
  exportProps: any;
}

interface IDataExportClientParams {
  selectedSourceClientData?: ISelectedClientSourceData;
  chartDimensionObject?: Dimension;
  chartMetricObject?: Metric;
}

export const fetchDimensionData = async ({ search, theme, metric, diy_topic, dimension }: FormatFiltersV4Params, state?: FormatFiltersV4State) => {
  try {
    const key = 'v4/data/insights/dimension';
    const response = await getDataApiClientInstance(key).post<{ data: Topic[] }>(
      key,
      formatFiltersV4({ search, theme, metric, diy_topic, dimension }, state)
    );
    return { topics: response.data.data };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const fetchAspect = async ({ search, dimension, topic, filterState }: FormatFiltersV4Params & { filterState?: FormatFiltersV4State }) => {
  try {
    const key = 'v4/data/insights/aspects';
    const response = await getDataApiClientInstance(key).post<{ aspects: Topic[] }>(key, formatFiltersV4({ search, dimension, topic }, filterState));
    return { aspects: response.data.aspects };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const startToDownloadDataExport = async (
  { exportProps, page, ...rest }: DownloadDataExport,
  { selectedSourceClientData, chartDimensionObject, chartMetricObject }: IDataExportClientParams
) => {
  try {
    const key = 'v4/data/async/download';
    const response = await getDataApiClientInstance(key).post(key, { ...formatFiltersV4({ ...rest, addTypeMetric: true }), export: exportProps });
    const datetime = moment();
    const dimensionName = chartDimensionObject?.display_name ? chartDimensionObject?.display_name : '';
    const metricName = chartMetricObject?.display_name ? chartMetricObject?.display_name : '';
    let fileName = `${dimensionName}_export_${metricName}_${datetime.format('YYYY_M_D_H_m_s')}.csv`;
    if (selectedSourceClientData?.display_name) {
      switch (exportProps.type) {
        case EXPORT_DATA_TYPE.DISCOVER_SENTISUM_TAG_ANALYTICS:
          fileName = `${selectedSourceClientData?.display_name}_${dimensionName}_export_${metricName}_${datetime.format('YYYY_M_D_H_m_s')}.csv`;
          break;
        case EXPORT_DATA_TYPE.CONVERSATION_DATA:
          fileName = `${selectedSourceClientData?.display_name}_${dimensionName}_conversations_export_${datetime.format('YYYY_M_D_H_m_s')}.csv`;
          break;
        default:
          fileName = `${selectedSourceClientData?.display_name}_${dimensionName}_export_${datetime.format('YYYY_M_D_H_m_s')}.csv`;
      }
    }
    saveAs(new Blob([response.data]), fileName);
    return { data: true };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
