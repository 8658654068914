import { REMOVE_UNDERSCORE_REGEX, CAMEL_CASE_IDENTIFIER } from 'Constants/regexPatterns';

const REMOVE_UNDERSCORE_REGEX_PATTERN = new RegExp(REMOVE_UNDERSCORE_REGEX, 'gim');

const ADD_SPACE_FOR_CAMEL_CASE_PATTERN = new RegExp(CAMEL_CASE_IDENTIFIER, 'g');

export const removeUnderscore = (word, replaceText = ' ') => {
  return word.replace(REMOVE_UNDERSCORE_REGEX_PATTERN, replaceText);
};

export const addSpaceAtCamelCasePosition = (word) => {
  if (!!word && typeof word === 'string') {
    return word
      .split(/\s+/)
      .map((value) => {
        return value.toString().replace(ADD_SPACE_FOR_CAMEL_CASE_PATTERN, ' $&');
      })
      .join(' ');
  }
  return word;
};
