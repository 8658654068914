import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { IAnalyticsState } from './types/analytics';
import { digInSummaryViewed, getDigInUsage } from 'src/api/DigIn';
import { fetchDimensionData } from 'src/api/Analytics';

export const fetchDimensions = createAsyncThunk<any, any>('analytics/fetchDimensions', ({ theme, search, metric, dimension, diy_topic, state }) =>
  fetchDimensionData({ theme, search, metric, dimension, diy_topic }, state)
);

export const digInUsageThunk = createAsyncThunk('analytics/getDigInUsage', getDigInUsage);
export const digInSummaryViewedThunk = createAsyncThunk('analytics/digInSummaryViewed', digInSummaryViewed);

const initialStateAPIStatus = {
  fetchTopicsStatus: 'idle',
  fetchTicketSummaryStatus: 'idle',
  fetchDigInUsageStatus: 'idle',
} as const;
const initialState: IAnalyticsState = {
  ...initialStateAPIStatus,
  isDIYTopicsSelected: false,
  topics: null,
  diyTopicKeywords: [],
};

const AnalyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetAnalyticsReduxState: (state) => {
      Object.assign(state, {
        ...initialState,
      });
    },
    resetAnalyticsReduxStateAPIStatus: (state) => {
      Object.assign(state, {
        ...initialStateAPIStatus,
      });
    },
    updateDIYTopicKeywords: (state, action: PayloadAction<string[]>) => {
      if (action.payload && Array.isArray(action.payload)) {
        state.diyTopicKeywords = action.payload;
      } else {
        state.diyTopicKeywords = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDimensions.pending, (state) => {
        state.fetchTopicsStatus = 'pending';
        state.topics = null;
      })
      .addCase(fetchDimensions.fulfilled, (state, action) => {
        state.fetchTopicsStatus = 'fulfilled';
        state.topics = action.payload.topics;
      })
      .addCase(fetchDimensions.rejected, (state) => {
        state.fetchTopicsStatus = 'rejected';
      })
      .addCase(digInUsageThunk.pending, (state) => {
        state.fetchDigInUsageStatus = 'pending';
      })
      .addCase(digInUsageThunk.fulfilled, (state, action) => {
        state.digInUsage = action.payload;
        state.fetchDigInUsageStatus = 'fulfilled';
      })
      .addCase(digInUsageThunk.rejected, (state) => {
        state.fetchDigInUsageStatus = 'rejected';
      })
      .addCase(digInSummaryViewedThunk.pending, (state) => {
        state.digInUsage.summaryUsage += 1;
      });
  },
});

export const { resetAnalyticsReduxState, resetAnalyticsReduxStateAPIStatus, updateDIYTopicKeywords } = AnalyticsSlice.actions;

const persistConfig = {
  key: 'analytics',
  storage,
  whitelist: [],
};

export default persistReducer(persistConfig, AnalyticsSlice.reducer);
