import { useCallback, useMemo } from 'react';
import { IOption } from '../types';

export const useSelectAll = <T,>(options: IOption<T>[], selected: T[], setSelected: (value: T[]) => void) => {
  const optionValues = useMemo(() => options.map((option) => option.value), [options]);
  const allSelected = useMemo(() => options.length > 0 && optionValues.every((value) => selected.includes(value)), [optionValues, selected]);
  const someSelected = useMemo(() => !allSelected && optionValues.some((value) => selected.includes(value)), [optionValues, selected]);
  const handleSelectAll = useCallback(() => setSelected(allSelected ? [] : optionValues), [allSelected, optionValues, setSelected]);
  return { allSelected, someSelected, handleSelectAll } as const;
};
