import { Area } from 'recharts';
import { AnomalyDot, getDataSize } from '../DrawMajorLine';
import { CustomAreaProps } from '../types';
import { getCustomLines } from './LineConstant';

export const CustomArea = ({ valueOverTime, lines: linesObject = {}, lineKey }: CustomAreaProps) => {
  const line = getCustomLines(linesObject)[lineKey];
  const props = line.props;
  const [size, prevSize] = getDataSize(valueOverTime);
  if (size === prevSize && lineKey === 'previousData') props.fillOpacity = 0;
  props.dot = <AnomalyDot />;
  return <Area {...props} key={lineKey} className="mix-blend-multiply" />;
};
