import { createElement, useCallback, useMemo, useState } from 'react';
import { updateFiltersFromView } from 'Slices/filter';
import { bookmarkView, deleteView, unbookmarkView } from 'Slices/filterbookmarks';
import { asyncErrorConfirm } from 'src/components/Dialog';
import { updateSelectedView } from 'Slices/filterbookmarks';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { IFilterView } from 'src/types/Views';
import { TabbedMenu } from 'src/components/Dropdown';
import { clearView, trackView } from './commonFunctions';
import { BiFilterAlt } from 'react-icons/bi';
import { cn } from 'Utils/TailwindUtils';
import { FiChevronDown, FiTrash2 } from 'react-icons/fi';
import { FaRegBookmark, FaBookmark } from 'react-icons/fa';
import { useMutation } from '@tanstack/react-query';
import { deleteUserView } from 'src/api/Views';
import toast from 'react-hot-toast';
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';

export const ViewDropDown = () => {
  const dispatch = useAppDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { bookmarks, selectedView, isViewValidated } = useAppSelector((state) => state.filterbookmarks);
  const selectedViewOption = useMemo(() => (selectedView ? { label: selectedView.name, value: selectedView } : null), [selectedView]);
  const deleteViewMutation = useMutation({
    mutationKey: ['filterViews', 'delete'],
    mutationFn: deleteUserView,
    onSuccess: (data) => {
      toast.success(`View "${bookmarks?.all.find(({ id }) => id === data).name}" deleted successfully.`);
      dispatch(deleteView(data));
      if (selectedView?.id === data) clearView();
    },
    onError: (error: Error, viewid) => {
      const viewName = bookmarks?.all.find(({ id }) => id === viewid)?.name;
      if (error.message === 'Saved Filter does not exist.') toast.error(`"${viewName}" ${error.message} Please refresh the page to sync data.`);
      else toast.error(`Error deleting saved filter "${error.message}".`);
    },
  });

  const selectViewCallback = useCallback((view: IFilterView) => {
    dispatch(updateSelectedView(view));
    dispatch(updateFiltersFromView(view));
    trackView(view, 'selected');
    setDropdownOpen(false);
  }, []);

  const confirmDeleteViewCallback = useCallback(async (view: IFilterView) => {
    if (
      await asyncErrorConfirm(`Are you sure you want to delete saved filter - "${view?.name}"?`, {
        title: 'Delete Saved Filter',
        icon: BiFilterAlt,
        okText: 'Delete',
        okExtra: { variant: 'danger' },
      })
    ) {
      trackView(view, 'deleted');
      deleteViewMutation.mutate(view.id);
    }
  }, []);

  const toggleBookmarkView = useCallback((view: IFilterView) => {
    dispatch(view.is_bookmarked ? unbookmarkView(view) : bookmarkView(view));
    trackView(view, view.is_bookmarked ? 'unbookmark' : 'bookmark');
  }, []);

  const getViewOption = (view: IFilterView) => ({
    label: view.name,
    value: view,
    extra: [
      {
        icon: view.is_bookmarked ? FaBookmark : FaRegBookmark,
        onClick: toggleBookmarkView,
        title: view.is_bookmarked ? 'Unbookmark' : 'Bookmark',
        iconProps: { className: 'text-indigo-600 w-4 h-4' },
      },
      {
        icon: FiTrash2,
        onClick: confirmDeleteViewCallback,
        title: 'Delete',
        iconProps: { className: 'text-red-900 h-4 w-4' },
      },
    ],
  });

  const dropdownTabs = useMemo(() => {
    return [
      {
        label: 'All',
        options: bookmarks?.all.map((bookmark) => getViewOption(bookmark)),
      },
      {
        label: 'Bookmarked',
        options: bookmarks?.all.filter((bookmark) => bookmark.is_bookmarked).map((bookmark) => getViewOption(bookmark)),
      },
    ];
  }, [bookmarks, toggleBookmarkView]);

  if (isViewValidated)
    return (
      <DropdownPrimitive.Root open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownPrimitive.Trigger>
          <span
            className={cn('flex items-center gap-1 rounded-md p-2 text-xs hover:bg-gray-650', {
              'bg-blue-750/10 text-blue-750 hover:bg-blue-750/30': selectedViewOption,
            })}
            data-testid="saved-filter-dropdown"
          >
            <p className="font-bold">Saved filter: {selectedViewOption?.label ?? 'None Selected'}</p>
            <FiChevronDown />
          </span>
        </DropdownPrimitive.Trigger>
        <DropdownPrimitive.Content
          align="start"
          sideOffset={5}
          side="bottom"
          className="z-50 rounded-md border border-gray-200 bg-white drop-shadow-xl"
        >
          <TabbedMenu
            enableSearch
            noDataMessage="No Data"
            searchPlaceholder="Search Saved Filter"
            options={dropdownTabs}
            selected={selectedViewOption}
            onChange={selectViewCallback}
          />
        </DropdownPrimitive.Content>
      </DropdownPrimitive.Root>
    );
  return <></>;
};
