import React, { FC, Fragment } from 'react';
import { BreadCrumbsProps } from './types';
import { cn } from 'Utils/TailwindUtils';
import { BreadCrumb } from './BreadCrumb';
import { HiOutlineChevronRight } from 'react-icons/hi2';

export const Breadcrumbs: FC<BreadCrumbsProps> = ({ crumbs, className, seprator, iconSize, crumbClassName, ...rest }) => {
  return (
    <div className={cn('flex items-center gap-3', className)} {...rest}>
      {crumbs.map((crumb, index) => (
        <Fragment key={index}>
          {index > 0 && (seprator ?? <HiOutlineChevronRight size={12} className="mx-1 shrink-0 text-grey-400" />)}
          <BreadCrumb {...crumb} className={crumbClassName} iconSize={iconSize} data-state={index === crumbs.length - 1 ? 'active' : 'inactive'} />
        </Fragment>
      ))}
    </div>
  );
};
