import { Box, Image, Text } from 'grommet';
import { Update } from 'grommet-icons';
import { showReportDialog } from '@sentry/browser';
import { Button } from 'src/components/Button';

export const ErrorFalback = () => {
  return (
    <Box width="100%" height="100%" justify="center" align="center" data-testid="error-fallback">
      <Box border={{ color: '#DEE1EC' }} round="small" pad="large" gap="medium" justify="center" align="center">
        <Image src="/images/error/error-image-dog.png" width="178px" height="143px" />
        <Box gap="small" align="center">
          <Text size="medium" weight="bold">
            Oops, something went wrong
          </Text>
          <Text size="small" color="text-field-light">
            It's not you, it's us. Kindly refresh or report this issue
          </Text>
        </Box>
        <Box gap="small" direction="row" justify="center">
          <Button variant="secondary" label="Report error" onClick={() => showReportDialog()} />
          <Button variant="secondary" label="Refresh" onClick={() => window.location.reload()} icon={<Update size="small" />} />
        </Box>
      </Box>
    </Box>
  );
};
