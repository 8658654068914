import { LANG_TYPES, TEXT_TYPES } from 'Constants/languages';
const CHART_COMPARATOR_TYPES = {
  ABSOLUTE_TYPE: 'absolute',
  PERCENT_TYPE: 'percent',
};
const CHART_COMPARATOR_TYPES_ENUM = Object.freeze({
  [CHART_COMPARATOR_TYPES.ABSOLUTE_TYPE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.SHORT]: '',
      [TEXT_TYPES.MID]: '',
      [TEXT_TYPES.LONG]: 'Absolute Change',
      [TEXT_TYPES.ALIAS]: 'Absolute',
    },
  },
  [CHART_COMPARATOR_TYPES.PERCENT_TYPE]: {
    [LANG_TYPES.EN]: {
      [TEXT_TYPES.SHORT]: '',
      [TEXT_TYPES.MID]: '',
      [TEXT_TYPES.LONG]: '% Change',
      [TEXT_TYPES.ALIAS]: 'Percentage',
    },
  },
});

export { CHART_COMPARATOR_TYPES, CHART_COMPARATOR_TYPES_ENUM };
