import { SpinnerIcon } from 'Static/images';
import React from 'react';
import { cn } from 'Utils/TailwindUtils';
interface ISpinnner {
  size?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | (string & {});
  className?: string;
  containerClassName?: string;
}
export const Spinner: React.FC<ISpinnner> = ({ className, size, containerClassName }) => {
  let _size = size ?? '20px';

  // make this uniform with grommet styling
  if (size === 'xxsmall') _size = '12px';
  if (size === 'xsmall') _size = '18px';
  if (size === 'small') _size = '24px';
  if (size === 'medium') _size = '36px';
  if (size === 'large') _size = '48px';

  return (
    <div className={cn('flex', containerClassName)}>
      <SpinnerIcon width={_size} height={_size} fill="currentColor" className={cn('text-indigo-600', className)} />
    </div>
  );
};
