import * as Select from '@radix-ui/react-select';
import { ForwardedRef, forwardRef } from 'react';
import { cn } from 'Utils/TailwindUtils';
import { GrCheckmark } from 'react-icons/gr';

interface RadixDescriptionSelectMenuProps extends Select.SelectContentProps {
  options: { value: string; label: string; description: string }[];
  optionClassName?: string;
}

const RadixDescriptionSelectMenuComponent = (
  { options, className, optionClassName, ...rest }: RadixDescriptionSelectMenuProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  return (
    <Select.Content ref={ref} className={cn('radix-dropdown-content flex max-w-[18rem] flex-col p-2', className)} {...rest}>
      {options.map((option) => (
        <Select.Item key={option.value} value={option.value} asChild>
          <button className={cn('group flex items-center justify-between gap-2 rounded-md py-2 px-4 hover:bg-sky-1000', optionClassName)}>
            <div className="flex flex-col gap-1 text-left text-xs">
              <span className="group-radix-state-checked:font-bold">{option.label}</span>
              <span className="text-gray-500">{option.description}</span>
            </div>
            <GrCheckmark className="opacity-0 group-radix-state-checked:opacity-100" />
          </button>
        </Select.Item>
      ))}
    </Select.Content>
  );
};

export const RadixDescriptionSelectMenu = forwardRef(RadixDescriptionSelectMenuComponent);
