import React, { PropsWithChildren } from 'react';
import { HTMLDivProps } from 'src/types/Globals';
import { cn } from 'Utils/TailwindUtils';

interface ImageDescriptionCardProps extends HTMLDivProps {
  imagePath: string;
  imageDimensions: { width: number; height: number };
  imageAlt: string;
  title: string;
  description: React.ReactNode;
}

export const ImageDescriptionCard: React.FC<PropsWithChildren<ImageDescriptionCardProps>> = (props) => {
  const { children, className, imageAlt, imagePath, imageDimensions, title, description, ...rest } = props;
  return (
    <div className={cn('flex flex-col items-center justify-center gap-6 rounded-xl bg-white text-center', className)} {...rest}>
      <img src={imagePath} alt={imageAlt} width={imageDimensions.width} height={imageDimensions.height} />
      <div className="flex flex-col items-center justify-center gap-6">
        <h3 className="max-w-[50%] text-2xl font-bold">{title}</h3>
        <p className="max-w-[70%] text-sm text-gray-500">{description}</p>
      </div>
      {children}
    </div>
  );
};
