import React from 'react';
import Skeleton, { SkeletonProps, SkeletonTheme } from 'react-loading-skeleton';

interface IBarSkeletonProps extends SkeletonProps {
  width?: string;
  containerClassName?: string;
}

const BarSkeleton: React.FC<IBarSkeletonProps> = ({ width, count = 1, containerClassName, ...rest }) => {
  return (
    <SkeletonTheme borderRadius="1.875rem">
      {[...Array(count)].map((_, i) => (
        <div style={{ width }} key={i} className={containerClassName}>
          <Skeleton {...rest} />
        </div>
      ))}
    </SkeletonTheme>
  );
};

export default BarSkeleton;
