import { GrClose } from 'react-icons/gr';
import { cn } from 'Utils/TailwindUtils';
import { RenderTagProps } from 'react-tagsinput';

export const renderTag = (props: RenderTagProps<string>) => {
  const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, className, ...other } = props;
  return (
    <span className="flex items-baseline" key={key}>
      <span className={cn('flex items-center gap-2 rounded-md bg-gray-650 px-2 py-1', className)} {...other}>
        <span>{getTagDisplayValue(tag)}</span>
        <button type="button" className={cn(' text-gray-850', classNameRemove)} onClick={() => onRemove(key)}>
          <GrClose size={8} />
        </button>
      </span>
      <span className="px-1">,</span>
    </span>
  );
};
