import React, { FC } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { ISetState } from 'src/types/Globals';
import { useEditorContext } from '../../EditorContext';
import { NodeLabel } from './NodeLabel';
interface NodeSelectorProps {
  open: boolean;
  setOpen: ISetState<boolean>;
}

export const NodeSelector: FC<NodeSelectorProps> = ({ open, setOpen }) => {
  const { editor } = useEditorContext();
  const items = [
    { label: 'Heading', isActive: editor.isActive('heading', { level: 4 }), command: () => editor.chain().focus().toggleHeading({ level: 4 }).run() },
    { label: 'Paragraph', isActive: editor.isActive('paragraph'), command: () => editor.chain().focus().toggleNode('paragraph', 'paragraph').run() },
  ];
  const activeItem = items.find((item) => item.isActive)?.label;

  return (
    <div className="relative">
      <button
        className="flex items-center gap-1 rounded-md p-2 hover:bg-grey-100 radix-state-open:bg-grey-100"
        onClick={() => setOpen(!open)}
        data-state={open ? 'open' : 'closed'}
      >
        <span className="text-sm font-semibold text-grey-800">{activeItem}</span>
        <FiChevronDown className="text-xl text-grey-500" />
      </button>
      {open && (
        <section className="radix-dropdown-content fixed top-full flex flex-col gap-2 p-2.5 text-sm">
          {items.map((item) => (
            <NodeLabel key={item.label} {...item} setOpen={setOpen} />
          ))}
        </section>
      )}
    </div>
  );
};
