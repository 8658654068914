import { cn } from 'Utils/TailwindUtils';
import React from 'react';
import { IconType } from 'react-icons';
import { useEditorContext } from '../../EditorContext';

interface MenuOptionProps {
  icon: IconType;
  command: () => void;
  name: string;
}

export const MenuOption: React.FC<MenuOptionProps> = ({ icon: Icon, command, name }) => {
  const { editor } = useEditorContext();
  const isActive = editor?.isActive(name);
  return (
    <button className="flex items-center justify-center rounded-md p-2 hover:bg-grey-100" onClick={command}>
      <Icon className={cn('text-xl text-grey-500', { 'text-grey-900': isActive })} />
    </button>
  );
};
