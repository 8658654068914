import React, { forwardRef } from 'react';
import { Button } from 'src/components/Button';
import { FormTextInput } from 'src/components/Input/FormTextInput';
import { useEditorContext } from '../../EditorContext';
import { HTMLDivProps, ISetState } from 'src/types/Globals';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import validator from 'validator';

const LinkFormSchema = z.object({
  link: z
    .string()
    .nonempty({ message: 'Link is required' })
    .refine((value) => validator.isURL(value, { require_protocol: false }), { message: 'Invalid URL' }),
});

type LinkForm = z.infer<typeof LinkFormSchema>;

interface LinkDialogProps extends HTMLDivProps {
  setOpen: ISetState<boolean>;
}

export const LinkDialog = forwardRef<HTMLDivElement, LinkDialogProps>(({ setOpen, ...props }, ref) => {
  const { editor } = useEditorContext();
  const methods = useForm<LinkForm>({ resolver: zodResolver(LinkFormSchema), mode: 'onSubmit' });
  const { register, handleSubmit } = methods;
  const addLink = (data: LinkForm) => {
    const href = data.link.startsWith('http') || data.link.startsWith('/') ? data.link : `https://${data.link}`;
    editor.chain().focus().setLink({ href }).run();
    setOpen(false);
  };
  const removeLink = () => {
    editor.chain().focus().unsetLink().run();
    setOpen(false);
  };
  const link = editor.getAttributes('link').href || '';
  return (
    <div className="z-20 min-w-[22rem] rounded-xl border border-grey-200 bg-white p-5 shadow-md" ref={ref} {...props}>
      <FormProvider {...methods}>
        <form className="flex flex-col gap-2.5 text-sm" onSubmit={handleSubmit(addLink)}>
          <FormTextInput label="Enter a link" type="text" className="border-grey-300 shadow-sm" defaultValue={link} {...register('link')} autoFocus />
          <div className="flex items-center gap-3">
            {link && (
              <button className="text-indigo-600 hover:underline" onClick={removeLink} type="button">
                Remove link
              </button>
            )}
            <Button label={link ? 'Update link' : 'Add link'} variant="primary" type="submit" className="ml-auto rounded-full" />
          </div>
        </form>
      </FormProvider>
    </div>
  );
});
