import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { ITooltip, Tooltip } from 'react-tooltip';
import { cn } from 'Utils/TailwindUtils';

export interface ExtendedTooltipProps extends ITooltip {
  plain?: boolean;
}

export const ReactTooltipContainer: FC<ExtendedTooltipProps> = ({ children, className, plain, ...rest }) =>
  createPortal(
    <Tooltip place="top" className={cn({ tooltip: !plain }, className)} opacity={1} {...rest}>
      {children}
    </Tooltip>,
    document.body
  );
