import { CommonFilterState } from 'Slices/types/common';
import { IFilterData } from 'src/types/Bookmark';
import { IFilterOption } from 'src/types/Filter';
import { Metric } from 'src/types/User';
import { TYPE_API_METRIC_MAPPING } from 'Utils/enums/SourceModelTypes';
import { IFilterDataRequest, IFilterDataResponse, ITopicDataResponse } from './apiTypes/Filters';
import { getFilterRequestData } from './Tranformers';
import { getDataApiClientInstance, getAdminClientInstance, previousDependsOn } from './utils/clientAdapters';
import { getThemes } from './Theme';

export const getSentisumTags = async (source: string) => {
  try {
    const filterKey = '/v4/filters/topics/all';
    const response = await getDataApiClientInstance(filterKey).post<ITopicDataResponse>(filterKey, { source });
    return response.data.topics ?? [];
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getFiltersData = async (filters: IFilterDataRequest, metric: string) => {
  try {
    const metaDataFilterKey = '/v4/filters';
    const metricsKey = 'metric';
    const metaDataFilterResponse = await getDataApiClientInstance(metaDataFilterKey).post<IFilterDataResponse>(metaDataFilterKey, filters);
    const topicFilterResponse = await getSentisumTags(filters.source);
    const themeFilterResponse = await getThemes(filters.source);
    const metricResponse = await getAdminClientInstance(metricsKey).get<{ data: { metric: Metric } }>(metricsKey, {
      params: { metric: TYPE_API_METRIC_MAPPING[metric] ?? metric },
    });
    return {
      themes: themeFilterResponse,
      filters: metaDataFilterResponse.data[0]?.filters,
      topics: topicFilterResponse ?? [],
      typeMetric: metricResponse.data.data.metric,
    };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getDateRangeBounds = async (source: string | string[]) => {
  try {
    const dateFilterKey = '/v4/filters?range=true';
    const previousSource = previousDependsOn['getDateRangeBounds']?.[0];
    const response = await getDataApiClientInstance('getDateRangeBounds', source?.toString()).post<IFilterDataResponse>(dateFilterKey, {
      sources: typeof source === 'string' ? [source] : source,
    });
    return {
      filterdata: response.data.map((data) => ({
        ...data,
        dateRange: { start: `${data.dateRange.start}Z`, end: `${data.dateRange.end}Z` },
      })),
      isSourceChanged: previousSource !== source,
    };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getFilterApiSearchResult = async (searchString: string, filter: IFilterData, filters: Pick<CommonFilterState, 'filters'>) => {
  const newFilters = filters.filters;
  const { type, fieldName } = filter;
  try {
    const filterKey = '/v4/filters/search';
    const response = await getDataApiClientInstance(filterKey).post<{ options: IFilterOption[] }>(filterKey, {
      source: newFilters?.source ?? '',
      conditions: {
        filterSearch: {
          searchQuery: searchString,
          filter: {
            parent_field: type,
            search_field: fieldName.replace('.keyword', ''),
            is_keyword: fieldName.indexOf('.keyword') > -1,
          },
        },
        interval: {
          dateField: {
            date_field: newFilters?.selectedDateField ?? '',
          },
          range: {
            start: newFilters?.dateRange.start,
            end: newFilters?.dateRange.end,
          },
        },
      },
    });
    return response.data.options;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getMetaDataFilterOptions = async (
  filter: IFilterData,
  dataSource: string,
  filterState: Pick<CommonFilterState, 'filters' | 'selectedSourceClientData'>
) => {
  const requestData = getFilterRequestData(filterState).filterdata;
  const { type, fieldName } = filter;
  try {
    const filterKey = 'v4/filters/options';
    const response = await getDataApiClientInstance(filterKey).post<{ options: IFilterOption[] }>(filterKey, {
      source: requestData.source,
      conditions: {
        ...requestData.conditions,
        filterSearch: {
          filter: {
            parent_field: type,
            search_field: fieldName.replace('.keyword', ''),
            is_keyword: fieldName.indexOf('.keyword') > -1,
          },
          dataSource,
        },
      },
    });
    return response.data.options;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
