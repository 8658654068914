import { cloneDeep } from 'lodash';
import { AreaProps } from 'recharts';
import { CustomLineData } from '../types';

const NEUTRAL_CHART_COLOR = '#BDBDBD';

export interface Lines {
  previousData: {
    props: Omit<AreaProps, 'ref'>;
  };
  data: {
    color: string;
    props: Omit<AreaProps, 'ref'>;
    anomalyIndices?: number[];
  };
}

const defaultLines: Lines = {
  previousData: {
    props: {
      dataKey: 'previousData',
      type: 'monotone',
      fillOpacity: 0.05,
      fill: '#828282',
      stroke: NEUTRAL_CHART_COLOR,
      strokeWidth: 1.5,
      strokeDasharray: '8 4',
      activeDot: { r: 5 },
    },
  },
  data: {
    color: 'var(--tw-indigo-600)',
    props: {
      type: 'monotone',
      dataKey: 'data',
      fillOpacity: 1,
      fill: 'url(#colorData)',
      stroke: 'var(--tw-indigo-600)',
      strokeWidth: 1.5,
      activeDot: { r: 5 },
    },
  },
};

export const getCustomLines = (lineProps: CustomLineData) => {
  const { previousDataStrokeWidth, previousDataIsAnimationActive, previousStokeDashArray, dataStrokeWidth, dataIsAnimationActive } = lineProps;
  const newLines = cloneDeep(defaultLines);
  newLines.previousData.props.strokeWidth = previousDataStrokeWidth ?? newLines.previousData.props.strokeWidth;
  newLines.previousData.props.isAnimationActive = previousDataIsAnimationActive;
  newLines.previousData.props.strokeDasharray = previousStokeDashArray ?? '8 4';
  newLines.data.props.strokeWidth = dataStrokeWidth ?? newLines.data.props.strokeWidth;
  newLines.data.props.isAnimationActive = dataIsAnimationActive;
  return newLines;
};
