import React from 'react';
import { DropdownLabel } from 'src/components/Dropdown';

interface SourceLabelProps {
  label?: string;
}

export const SourceLabel: React.FC<SourceLabelProps> = ({ label }) => {
  return (
    <div className="flex flex-col items-start">
      <div className="text-xs text-blue-950">Source</div>
      <DropdownLabel label={label} className="font-bold" />
    </div>
  );
};
