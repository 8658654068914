import { SOURCE_TYPES } from './SourceModelTypes';

export const SENTIMENT_METRIC_OBJECT = Object.freeze({
  name: SOURCE_TYPES.SURVEY_TYPE,
  display_name: 'Sentiment',
  path: 'annotations.sentiment',
  is_order_reverse: true,
});

export const VOLUME_METRIC_OBJECT = Object.freeze({
  name: SOURCE_TYPES.SUPPORT_TYPE,
  display_name: 'Volume',
  path: 'NO_PATH_VOLUME',
  is_order_reverse: false,
});

export const PERCENT_TICKET_METRIC_OBJECT = Object.freeze({
  name: SOURCE_TYPES.PERCENT_TICKET_TYPE,
  display_name: 'Percent of Tickets',
  path: 'NO_PATH_PERCENT_OF_TICKETS',
  is_order_reverse: false,
});

export const CSAT_METRIC_OBJECT = Object.freeze({
  name: SOURCE_TYPES.CSAT_TYPE,
  display_name: 'CSAT',
  path: 'metadata.satisfactionRating.keyword',
  is_order_reverse: false,
});
