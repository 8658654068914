import { getAvailableFilters, updateFilterContainerHeight } from 'src/core/slices/filter';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { DataExport } from 'Components/DataExportComponents';
import { cn } from 'Utils/TailwindUtils';
import { FilterButton } from './FilterButton';
import { FilterTopBar } from './FilterTopBar';
import { SearchFilter } from './SearchFilter';
import { PrevoiusDateRange } from './PrevoiusDateRange';
import { DateFilter } from './DateFilter';
import { useMeasure } from 'react-use';
import { VisualizationFilters } from '../VisualizationFilters';
export interface FilterContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  enableSearch?: boolean;
  yScroll?: number;
}

export const FilterContainer: React.FC<FilterContainerProps> = ({ enableSearch, className, yScroll, ...rest }) => {
  const dispatch = useAppDispatch();
  const { filters, fetchAvailableFiltersStatus, dateRangeBounds, filterdata } = useAppSelector((state) => state.filter);

  const { fetchAvailableBookmarkViewsStatus } = useAppSelector((state) => state.filterbookmarks);
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const source = filters?.source;
  const dateRange = filters?.dateRange;

  useEffect(() => {
    dispatch(updateFilterContainerHeight(height));
  }, [height]);

  useEffect(() => {
    if (source && dateRange) dispatch(getAvailableFilters());
  }, [source, dateRange]);

  return (
    <section className={cn('flex w-full flex-col gap-2 bg-white py-4', className)} {...rest} ref={ref}>
      <div className="flex items-start justify-between gap-2 px-4">
        <div className="flex flex-wrap gap-2">
          <DateFilter />
          {[fetchAvailableFiltersStatus, fetchAvailableBookmarkViewsStatus].every((status) => status === 'fulfilled') && dateRangeBounds ? (
            <>
              <PrevoiusDateRange />
              {filterdata?.filters?.length > 0 && yScroll > 0 && <FilterButton containerHeight={height} />}
              <VisualizationFilters />
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="flex grow justify-end gap-2">
          {enableSearch && <SearchFilter />}
          <DataExport />
        </div>
      </div>
      <FilterTopBar yScroll={yScroll} />
    </section>
  );
};
