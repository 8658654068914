import React, { FC } from 'react';
import ReactDOM from 'react-dom';

export const createConfirmation = <T,>(component: FC<ConfirmableProps<T>>, unmountDelay = 1000, mountingNode?: HTMLElement) => {
  return (props: FilteredBaseConfirmProps<T>) => {
    const wrapper = (mountingNode || document.body).appendChild(document.createElement('div'));

    const promise = new Promise<boolean>((resolve, reject) => {
      try {
        ReactDOM.render(
          React.createElement(component, {
            reject,
            resolve,
            dispose,
            ...props,
          }),
          wrapper
        );
      } catch (e) {
        console.error(e);
        throw e;
      }
    });

    function dispose() {
      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(wrapper);
        setTimeout(() => {
          if (document.body.contains(wrapper)) {
            document.body.removeChild(wrapper);
          }
        });
      }, unmountDelay);
    }

    return promise.then(
      (result) => {
        dispose();
        return result;
      },
      (result) => {
        dispose();
        return Promise.reject(result);
      }
    );
  };
};
