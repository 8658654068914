export const DATA_EXPORT_STEPS_TYPE = {
  INITIALIZE: 'init',
  CONFIGURE: 'configure',
  CONFIRM: 'confirm',
  DOWNLOAD: 'download',
};

export const EXPORT_DATA_TYPE = {
  // "conversation" or "topic"
  DISCOVER_SENTISUM_TAG_ANALYTICS: 'topic',
  CONVERSATION_DATA: 'conversation',
  DISCOVER_SENTISUM_TAG_BREAKDOWN_DATA: 'topic_breakdown',
  SUMMARY_DATA: 'summary',
};

export const EXPORT_STEP_LIST = {
  [EXPORT_DATA_TYPE.DISCOVER_SENTISUM_TAG_ANALYTICS]: {
    STEPS: [
      DATA_EXPORT_STEPS_TYPE.INITIALIZE,
      DATA_EXPORT_STEPS_TYPE.CONFIRM,
      DATA_EXPORT_STEPS_TYPE.DOWNLOAD,
    ],
  },
  [EXPORT_DATA_TYPE.CONVERSATION_DATA]: {
    STEPS: [
      DATA_EXPORT_STEPS_TYPE.INITIALIZE,
      DATA_EXPORT_STEPS_TYPE.CONFIGURE,
      DATA_EXPORT_STEPS_TYPE.CONFIRM,
      DATA_EXPORT_STEPS_TYPE.DOWNLOAD,
    ],
  },
};
