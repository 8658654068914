export const FBEnvVariables = () => {
  if (process.env.REACT_APP_FB_INTEGRATION) {
    const settings = process.env.REACT_APP_FB_INTEGRATION;
    return JSON.parse(settings);
  }
  return {
    appId: '',
    apiVersion: 'v12.0',
    scope: 'pages_manage_metadata,pages_manage_posts,pages_show_list',
  };
};
