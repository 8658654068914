import { Box, Button, ButtonExtendedProps, Text } from 'grommet';
import { Checkmark } from 'grommet-icons';
import React, { createElement, useCallback } from 'react';
import styled from 'styled-components';
import { IOptionExtra } from './types';
import { cn } from 'Utils/TailwindUtils';

const DropItemButton = styled(Button)({
  padding: '8px 20px',
  '&:focus-visible': {
    backgroundColor: 'rgba(221, 221, 221, 0.3)',
  },
});

interface IDropdownItemProps<T> extends ButtonExtendedProps {
  label: string;
  icon?: JSX.Element;
  isDisabled?: boolean;
  capitalize?: boolean;
  extra?: IOptionExtra<T>[];
  optionValue: T;
  selected?: boolean;
  className?: string;
}

const DropItem = <T,>({ label, icon, isDisabled, capitalize, extra, optionValue, selected, ...rest }: IDropdownItemProps<T>) => {
  const selectOption = useCallback(
    (option: IOptionExtra<T>, event: React.MouseEvent) => {
      event.stopPropagation();
      option.onClick?.(optionValue);
    },
    [extra]
  );

  const labelWithoutExtra = (
    <Box justify="between" direction="row" width="100%" align="baseline" gap="small">
      <Text
        size="small"
        color={selected ? 'neutral-dark' : isDisabled ? '#BDBDBD' : 'inherit'}
        style={{ textTransform: capitalize ? 'capitalize' : 'none' }}
        weight={selected ? 'bold' : 'normal'}
      >
        {label}
      </Text>
      {selected && <Checkmark size="12px" />}
    </Box>
  );

  const labelWithExtra = (
    <Box direction={'row'} align={'center'} style={{ justifyContent: 'space-between' }}>
      <Box flex={true} overflow={'hidden'} title={label}>
        <p className={cn('text-sm', { 'font-semibold text-indigo-600': selected })}>{label}</p>
      </Box>
      <Box direction={'row'} gap={'small'}>
        {extra?.map((item, index) => (
          <span data-tooltip-content={item.title} data-tooltip-id="tooltip" key={index}>
            {createElement(item.icon, {
              onClick: (event) => selectOption(item, event),
              ...item.iconProps,
            })}
          </span>
        ))}
      </Box>
    </Box>
  );

  return (
    <DropItemButton
      plain
      hoverIndicator="light-gray-2"
      focusIndicator={false}
      label={extra ? labelWithExtra : labelWithoutExtra}
      icon={icon}
      disabled={isDisabled}
      {...rest}
      className={cn({ '!cursor-default': !rest.onClick })}
    />
  );
};

export default DropItem;
