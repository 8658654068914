import { BubbleMenu, BubbleMenuProps } from '@tiptap/react';
import React, { useState } from 'react';
import { ColorSelector } from './ColorSelector';
import { ToggleOptions } from './ToggleOptions';
import { NodeSelector } from './NodeSelector';
import { AddLink } from './AddLink';
import { Instance as TippyInstance } from 'tippy.js';
import { useClickAway } from 'react-use';
import { useEditorContext } from '../EditorContext';

export const EditorBubbleMenu = () => {
  const { editor } = useEditorContext();
  const [openDropdown, setOpenDropdown] = useState<'colour' | 'node' | 'link' | null>();
  const [tippyInstance, setTippyInstance] = useState<TippyInstance>();
  const menuRef = React.useRef<HTMLDivElement>();
  useClickAway(menuRef, (e) => tippyInstance?.hideWithInteractivity(e as MouseEvent));
  if (!editor) return null;
  const bubbleMenuProps: Omit<BubbleMenuProps, 'children'> = {
    editor,
    tippyOptions: {
      onShow: (instance) => {
        setTippyInstance(instance);
        document.body.classList.add('pointer-events-none');
      },
      onHidden: () => {
        setOpenDropdown(null);
        document.body.classList.remove('pointer-events-none');
      },
    },
  };

  return (
    <BubbleMenu {...bubbleMenuProps}>
      <div className="pointer-events-auto flex w-fit gap-1 rounded-xl bg-white px-4 py-1 shadow-xl" ref={menuRef}>
        <ColorSelector open={openDropdown === 'colour'} setOpen={(open) => setOpenDropdown(open ? 'colour' : null)} />
        <NodeSelector open={openDropdown === 'node'} setOpen={(open) => setOpenDropdown(open ? 'node' : null)} />
        <ToggleOptions />
        <AddLink open={openDropdown === 'link'} setOpen={(open) => setOpenDropdown(open ? 'link' : null)} />
      </div>
    </BubbleMenu>
  );
};
