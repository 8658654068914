import { Box } from 'grommet';
import { DateRangePicker, DateRangePickerProps } from 'react-date-range';
import { FC, useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { rightArrowCalender, leftArrowCalender } from 'src/static/images';
import { useState, createElement } from 'react';
import { renderStaticRangeLabel, StaticRanges } from './CustomStaticRangeLabelContent';
import ReactDOMServer from 'react-dom/server';
import { IDateRangeInterval } from './types';
import { IDateFilterData } from 'Slices/types/filter';
import './styles.css';
import moment from 'moment';
import { DateRange } from 'src/types/Globals';
import { getNoOfDaysFromRange } from 'Utils/dateOperations';
import { Button } from '../Button';

export interface IDateRangePickerProps {
  /**
   * Used to set the selected date range in the date range picker
   *
   * Note: The date range picker will only show the first date range in the array
   *
   * Note: Only use memoized values for this prop
   */
  dateRange: DateRange;
  dateRangeBounds: DateRange;
  toggleDropdown: () => void;
  setDateRange: (dateRange: IDateFilterData) => void;
  dateIntervals: IDateRangeInterval[];
  interval: string;
  defaultIntervalKey: string;
  extraRangeLabel?: JSX.Element;
  dayContentRenderer?: DateRangePickerProps['dayContentRenderer'];
}

export const CustomDateRangePicker: FC<IDateRangePickerProps> = ({
  dateRange,
  dateRangeBounds,
  toggleDropdown,
  setDateRange,
  dateIntervals,
  interval,
  defaultIntervalKey,
  extraRangeLabel,
  dayContentRenderer,
}) => {
  const currentDateRangeNoOfDays = useMemo(() => getNoOfDaysFromRange(dateRange.start, dateRange.end), [dateRange]);
  const defaultInterval = useMemo(() => dateIntervals.find(({ key }) => key === defaultIntervalKey), [defaultIntervalKey, dateIntervals]);
  const setDateRangeValue = useCallback(
    () => [
      {
        key: 'selection',
        days: currentDateRangeNoOfDays,
        startDate: dateRange.start,
        endDate: dateRange.end,
        definedRange: true,
      },
    ],
    [dateRange]
  );

  const [state, setState] = useState(() => setDateRangeValue());
  const [selectionState, setSelectionState] = useState(interval);

  useEffect(() => setState(setDateRangeValue()), [dateRange]);

  const Selection = (givenSelection: string) => {
    if (givenSelection && selectionState !== givenSelection) {
      setSelectionState(givenSelection);
    }
    return selectionState;
  };

  useLayoutEffect(() => {
    const NextButton = ReactDOMServer.renderToString(createElement('img', { src: rightArrowCalender }, null));
    window.document.querySelector('.rdrNextButton').innerHTML = NextButton;
    const PrevButton = ReactDOMServer.renderToString(createElement('img', { src: leftArrowCalender }, null));
    window.document.querySelector('.rdrPprevButton').innerHTML = PrevButton;
  });

  return (
    <Box direction="row" gap="small" pad="small" className="font-normal">
      <Box gap="xsmall">
        <DateRangePicker
          onChange={(item) => {
            setState([
              {
                key: item.selection['key'],
                days: currentDateRangeNoOfDays,
                startDate: item.selection.startDate,
                endDate: item.selection.endDate,
                definedRange: false,
              },
            ]);
            if (!item.selection['definedRange']) setSelectionState('custom');
          }}
          months={2}
          ranges={state}
          direction="horizontal"
          rangeColors={['#e1f0ea']}
          color="#2e403f"
          showMonthAndYearPickers={false}
          editableDateInputs
          fixedHeight
          preventSnapRefocus
          renderStaticRangeLabel={renderStaticRangeLabel}
          staticRanges={StaticRanges(dateIntervals, Selection, dateRangeBounds.start, extraRangeLabel)}
          minDate={dateRangeBounds.start}
          maxDate={dateRangeBounds.end}
          dayContentRenderer={dayContentRenderer}
        />
        <Box justify="between" direction="row">
          <Box align="left" direction="row">
            <Button
              onClick={() => {
                setState([
                  {
                    key: 'selection',
                    startDate: defaultInterval.startDate,
                    endDate: defaultInterval.endDate,
                    days: moment(defaultInterval.endDate).diff(defaultInterval.startDate, 'days'),
                    definedRange: true,
                  },
                ]);
                setSelectionState(defaultIntervalKey);
              }}
              className="!border-0 underline"
              label="Reset"
            />
          </Box>
          <div className="flex items-start justify-end gap-2 text-sm">
            <Button variant="secondary" onClick={toggleDropdown} label="Cancel" />
            <Button
              variant="primary"
              onClick={() => {
                toggleDropdown();
                setDateRange({ startDate: state[0].startDate, endDate: state[0].endDate, interval: selectionState });
              }}
              label="Apply"
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};
export default CustomDateRangePicker;
