import { ReactNode } from 'react';

export const renderTagsLayout = (tagElements: ReactNode, inputElement: ReactNode) => {
  return (
    <span className="flex flex-wrap gap-y-1 p-[11px]">
      {tagElements}
      {inputElement}
    </span>
  );
};
