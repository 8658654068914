import { cva } from 'class-variance-authority';
import { InputHTMLAttributes, forwardRef } from 'react';
import { cn } from 'Utils/TailwindUtils';

export const buttonCVA = cva('w-full rounded-md border-transparent bg-gray-150 text-blue-1100 placeholder-gray-750 focus:outline-none', {
  variants: {
    variant: {
      small: 'p-2.5 text-xs',
      large: 'px-4 py-3 text-sm',
    },
  },
  defaultVariants: {
    variant: 'small',
  },
});

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & { variant?: 'small' | 'large' };

export const TextInputGray = forwardRef<HTMLInputElement, TextInputProps>(({ className, variant, ...props }, ref) => {
  return <input className={cn(buttonCVA({ variant }), className)} {...props} ref={ref} />;
});
