import { Box } from 'grommet';
import React from 'react';
import { Text } from 'src/components/Text';
import styled from 'styled-components';
import { ITabOption } from '../types';

const TabItem = styled(Box)`
  cursor: pointer;
  border-bottom: 3px solid transparent;
  padding: 10px 20px;
  &:focus-visible {
    background-color: rgba(221, 221, 221, 0.3);
    border-bottom: 3px solid var(--tw-indigo-600);
  }
  &:hover {
    border-bottom: 3px solid var(--tw-indigo-600);
    background: transparent;
  }
  &.active {
    border-bottom: 3px solid var(--tw-indigo-600);
  }
`;

export interface IDropdwonTabsProps<T> {
  tabs: ITabOption<T>[];
  activeTabIndex: number;
  setActiveTabIndex: (index: number) => void;
}

const DropdwonTabs = <T,>({ tabs, activeTabIndex, setActiveTabIndex }: IDropdwonTabsProps<T>) => {
  return (
    <Box flex="grow" justify="center" align="center" overflow="hidden" gap="xsmall" direction="row" className="mb-2 border-b">
      {tabs.map((tab, index) => (
        <TabItem className={activeTabIndex === index ? 'active' : ''} key={index} onClick={() => setActiveTabIndex(index)} pad="8px">
          <Text text={tab.label} />
        </TabItem>
      ))}
    </Box>
  );
};

export default DropdwonTabs;
