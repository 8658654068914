import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { trackPage, mixpanel } from '../trackEvent';

export const useTrackPageTme = () => {
  useLocation();
  const pageTitle = document.title.split(' | ')[0];
  useEffect(() => {
    mixpanel.time_event('Page View');
    trackPage(pageTitle);
    return () => mixpanel.track('Page View', { title: pageTitle });
  }, [pageTitle]);
};
