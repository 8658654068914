import { Box, Text } from 'grommet';
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import Fuse from 'fuse.js';
import DropdownItem from '../DropdownItem';
import { IOption, ITabOption } from '../types';
import DropdwonTabs from '../DropdownTabs';
import styled from 'styled-components';
import { TextInputGray } from 'src/components/Input';
const StyledNoDataText = styled(Text)({ fontSize: '14px', lineHeight: '20px' });

export interface ITabbedMenuBase<T> {
  options: ITabOption<T>[];
  onChange?: (option: T) => void;
  enableSearch?: boolean;
  enableToolTip?: boolean;
  middleContent?: React.ReactNode;
  searchPlaceholder?: string;
  noDataMessage?: string;
  noSearchResultMessage?: string;
  selected?: IOption<T>;
  type?: string;
}

export interface ITabbedMenuProps<T> extends ITabbedMenuBase<T> {
  collapseMenu?: () => void;
}

const DropdownMenu = <T,>({
  options,
  enableSearch,
  onChange,
  middleContent,
  searchPlaceholder = 'Search ...',
  noDataMessage = 'No data found',
  noSearchResultMessage = 'No search result found',
  selected,
  collapseMenu,
}: ITabbedMenuProps<T>) => {
  const [searchString, setSearchString] = useState<string>('');
  const [selctedTab, setSelectedTab] = useState<number>(0);
  const [matchedOptions, setMatchedOptions] = useState<IOption<T>[]>(options[selctedTab].options);
  const fuse = useMemo(() => new Fuse(options[selctedTab].options, { keys: ['label'] }), [options, selctedTab]);
  const searchOptions = () => {
    if (!searchString) return options[selctedTab].options;
    return fuse.search(searchString).map((result) => result.item);
  };
  const selectOption = useCallback(
    (option: IOption<T>) => {
      if (option.disabled) return;
      onChange(option.value);
      collapseMenu?.();
    },
    [onChange]
  );
  useEffect(() => {
    setMatchedOptions(searchOptions());
  }, [options, searchString, selctedTab]);

  return (
    <Box pad={{ vertical: '5px' }} overflow="hidden" width={{ min: '18rem' }}>
      {options.length > 1 && <DropdwonTabs tabs={options} setActiveTabIndex={setSelectedTab} activeTabIndex={selctedTab} />}
      {enableSearch && (
        <Box
          flex="grow"
          pad={{
            vertical: '5px',
            horizontal: 'small',
          }}
        >
          <TextInputGray placeholder={searchPlaceholder} value={searchString} onChange={(e) => setSearchString(e.target.value)} autoFocus />
        </Box>
      )}{' '}
      {middleContent}
      <Box flex="grow" height={{ max: '320px' }} overflow="auto">
        {!matchedOptions.length ? (
          <Box flex={true} align="center" pad={{ vertical: '24px' }}>
            <StyledNoDataText>{searchString ? noSearchResultMessage : noDataMessage}</StyledNoDataText>
          </Box>
        ) : (
          matchedOptions.map((option) => (
            <DropdownItem
              key={option.label}
              label={option.label}
              onClick={onChange ? () => selectOption(option) : null}
              isDisabled={option.disabled}
              optionValue={option.value}
              extra={option.extra}
              selected={selected?.value === option.value}
            />
          ))
        )}
      </Box>
    </Box>
  );
};

export default DropdownMenu;
