enum TEXT_TYPES {
  SHORT = 'short',
  MID = 'mid',
  LONG = 'long',
  ALIAS = 'alias',
  ALIAS_1 = 'alias1',
  ALIAS_2 = 'alias2',
  ALIAS_3 = 'alias3',
  ALIAS_4 = 'alias4',
  ALIAS_5 = 'alias5',
  ALIAS_6 = 'alias6',
  ALIAS_7 = 'alias7',
  ALIAS_8 = 'alias8',
  ALIAS_9 = 'alias9',
}

export default TEXT_TYPES;
