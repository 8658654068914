import { createElement, useMemo } from 'react';
import { DownRed, UpGreen } from 'Static/images';
import { checkConditions, getColorOverDataChange } from 'Utils';
import { METRICS_VALUE_META, PERCENT_CHANGE_COMPARATOR_META } from 'Utils/enums/ChartMetadata';

export interface ReportHeaderTabProps {
  chartMetric: string;
  overAllMetricObject: { change: number; value: number };
  chartComparator: string;
}

export const ReportHeaderTab = ({ chartMetric, overAllMetricObject, chartComparator }: ReportHeaderTabProps) => {
  const { change, value } = overAllMetricObject;
  const color = getColorOverDataChange(chartMetric, change);

  const percentChangeMetadata = useMemo(() => PERCENT_CHANGE_COMPARATOR_META[chartComparator][chartMetric], [chartMetric, chartComparator]);

  const getPercentChangedText = () => {
    let { value, postfixText } = checkConditions.getChartValueDisplayProperty(change, percentChangeMetadata, true);
    return value + postfixText;
  };
  const metricMetadata = useMemo(() => METRICS_VALUE_META[chartMetric], [chartMetric]);

  let getCurrentValue = (isSymbolNeeded = false) => {
    let { prefixText, value: valueData, postfixText } = checkConditions.getChartValueDisplayProperty(value, metricMetadata);
    if (!isSymbolNeeded) {
      postfixText = '';
    }
    const text = prefixText + valueData + postfixText;
    return text;
  };
  const getSymbolPercentText = () => {
    if (metricMetadata?.IS_PERCENT_SYMBOL_NEED) return <p className="mt-2 text-xs font-normal">%</p>;
  };
  return (
    <div className="flex items-center gap-2 pl-3">
      <span className="flex gap-1 font-sherika text-3xl font-semibold">
        {getCurrentValue()} {getSymbolPercentText()}
      </span>
      <span className="flex items-center gap-1 text-sm" style={{ color }}>
        {createElement(change < 0 ? DownRed : UpGreen, { stroke: color, fill: color })}
        {getPercentChangedText()}
      </span>
    </div>
  );
};
