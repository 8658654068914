import axios from 'axios';
import { store } from '../store';
import { setSentryContext, sendHttpSentryError, getAccessToken } from 'src/api/utils/clientAdapters';

export const getUser = () => {
  const state = store.getState();
  return state.auth.user;
};
const addInterceptors = (client) => {
  let sentryContext;
  client.interceptors.request.use(
    async (config) => {
      const accessToken = await getAccessToken();
      sentryContext = setSentryContext(config);
      if (accessToken) config.headers.set('Authorization', `Bearer ${accessToken}`);
      return config;
    },
    (error) => {
      sendHttpSentryError(sentryContext, error);
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      sendHttpSentryError(sentryContext, error);
      return Promise.reject(error);
    }
  );
  return client;
};

export const authClient = addInterceptors(
  axios.create({
    baseURL: process.env.REACT_APP_AUTH_API_ENDPOINT,
    timeout: 30000,
    responseType: 'json',
  })
);

export const apiClient = addInterceptors(
  axios.create({
    baseURL: process.env.REACT_APP_CORE_API_ENDPOINT,
    timeout: 30000,
    responseType: 'json',
  })
);

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
