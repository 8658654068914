import { IViewFilterData } from 'src/types/Views';
import { IFetchViewsResponse, IUserViewResponse } from './apiTypes/Views';
import { getAdminClientInstance } from './utils/clientAdapters';

export const fetchSavedViews = async (source: string) => {
  const key = 'filterview/list';
  try {
    const response = await getAdminClientInstance(key).post<IFetchViewsResponse>(key, { es_alias: source });
    return response.data.data.filter_views;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const saveUserView = async (name: string, source: string, filters: IViewFilterData) => {
  const key = 'filterview/create';
  try {
    const response = await getAdminClientInstance(key).post<IUserViewResponse>(key, { name: name.trim(), es_alias: source, filters: filters });
    return response.data.data.filterview;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const updateUserView = async (id: number, name: string, filters: IViewFilterData) => {
  const key = 'filterview/update';
  try {
    const response = await getAdminClientInstance(key).post<IUserViewResponse>(key, { name: name.trim(), id: id, filters: filters });
    return response.data.data.filterview;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
export const deleteUserView = async (id: number) => {
  const key = 'filterview/delete';
  try {
    await getAdminClientInstance(key).post(key, { id: id });
    return id;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const bookmarkUserView = async (id: number) => {
  const key = 'filterview/bookmark/create';

  try {
    await getAdminClientInstance(key).post(key, { id: id });
    return id;
  } catch (error) {
    if (error?.response?.data?.message.toLowerCase() === 'Filter View Bookmark already exists.'.toLocaleLowerCase()) {
      return id;
    }
    throw new Error(error?.response?.data?.message);
  }
};

export const unbookmarkUserView = async (id: number) => {
  const key = 'filterview/bookmark/delete';
  try {
    await getAdminClientInstance(key).post('filterview/bookmark/delete', { id: id });
    return id;
  } catch (error) {
    if (error?.response?.data?.message.toLowerCase() === 'Filter View Bookmark does not exist.'.toLocaleLowerCase()) {
      return id;
    }
    throw new Error(error?.response?.data?.message);
  }
};
