import { Outlet as RouterOutlet } from 'react-router';
import { SentryErrorContext } from 'src/context/SentryError';

export const Outlet = () => {
  return (
    <SentryErrorContext>
      <RouterOutlet />
    </SentryErrorContext>
  );
};
