import { ReactTooltipContainer } from '../../../../components/Tooltip/ReactToolipContainer';

export const SidebarTooltip: React.FC = () => {
  return (
    <ReactTooltipContainer
      id="navigation-sidebar-tooltip"
      place="right"
      render={(target) => (
        <>
          <div className="flex gap-2 text-sm">
            <span className="font-bold">{target?.content}</span>
            {target?.activeAnchor?.getAttribute('data-tooltip-is-beta') && <span className="rounded bg-blue-1100 px-2 text-xxs uppercase">Beta</span>}
          </div>
          {target?.activeAnchor?.getAttribute('data-tooltip-description') && (
            <div className="text-xs">{target?.activeAnchor?.getAttribute('data-tooltip-description')}</div>
          )}
        </>
      )}
    />
  );
};
