import React, { FC } from 'react';
import { confirmable, createConfirmation } from './BaseAyncConfirm';
import { IconConfirm, IconConfirmProps } from './IconConfirm';

interface ConfirmProps {
  show: boolean;
  proceed: (proceed: boolean) => void;
  confirmation: IconConfirmProps['text'];
  options: Omit<IconConfirmProps, 'visible' | 'setVisible' | 'text' | 'isProcessing'>;
}

const Confirm: FC<ConfirmProps> = ({ show, proceed, confirmation, options }) => {
  return <IconConfirm visible={show} setVisible={() => proceed(false)} text={confirmation} onOk={() => proceed(true)} {...options} />;
};

export function asyncIconConfirm(confirmation: IconConfirmProps['text'], options: ConfirmProps['options']) {
  return createConfirmation(confirmable(Confirm))({ confirmation, options });
}
