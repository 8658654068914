import { Box, Text } from 'grommet';
import { createElement, FC, useMemo } from 'react';
import { CommonFilterState } from 'Slices/types/common';
import { useAppSelector } from 'src/core/store';
import TEXT_TYPES from 'src/core/utils/constants/textTypes';
import { METRICS_VALUE_META, PERCENT_CHANGE_COMPARATOR_META } from 'src/core/utils/enums/ChartMetadata';
import { SourceModelTypesEnum } from 'src/core/utils/enums/SourceModelTypes';
import { DownRed, UpGreen } from 'src/static/images';
import { IChartComparator } from 'src/types/Filter';
import { GraphScreenShotBase, IGraphScreenShotBaseProps } from 'src/ui/containers/ScreenShotBases';
import { checkConditions } from 'Utils';
import { LANG_TYPES } from 'Utils/constants/languages';
import { cn } from 'Utils/TailwindUtils';

interface ISummaryGraphScreenShotProps extends Omit<IGraphScreenShotBaseProps, 'changeValue'> {
  overAllMetricObject: { change: number; value: number };
  graphColor: any;
  chartComparator: IChartComparator;
  chartIntervalBreakdown: CommonFilterState['chartIntervalBreakdown'];
  filters: Pick<CommonFilterState['filters'], 'dateRange' | 'previousDateRange'>;
  reportName?: string;
}

export const SummaryGraphScreenShot: FC<ISummaryGraphScreenShotProps> = (props) => {
  const { overAllMetricObject, selectedMetricType, chartComparator, chartIntervalBreakdown, filters, reportName, ...rest } = props;
  const changeIcon = createElement(overAllMetricObject.change < 0 ? DownRed : UpGreen, { stroke: rest.graphColor, fill: rest.graphColor });
  const LANG = LANG_TYPES.EN;
  const { change, value: valueData } = overAllMetricObject;

  const valueText = useMemo(() => {
    const { prefixText, value, postfixText } = checkConditions.getChartValueDisplayProperty(valueData, METRICS_VALUE_META[selectedMetricType]);
    return prefixText + value + postfixText;
  }, [selectedMetricType, overAllMetricObject]);

  const changedText = useMemo(() => {
    const { value, postfixText } = checkConditions.getChartValueDisplayProperty(
      change,
      PERCENT_CHANGE_COMPARATOR_META[chartComparator][selectedMetricType]
    );
    return Math.abs(value) + postfixText;
  }, [selectedMetricType, overAllMetricObject, chartComparator]);

  return (
    <GraphScreenShotBase selectedMetricType={selectedMetricType} changeValue={change} chartComparator={chartComparator} filters={filters} {...rest}>
      <div className={cn('text-base font-semibold', { uppercase: !reportName })}>
        {reportName ? reportName : SourceModelTypesEnum?.[selectedMetricType]?.[LANG]?.[TEXT_TYPES.ALIAS]}
      </div>
      <Box direction="row" gap="small" justify="start">
        <Text size="large" weight="bolder" color="black">
          {valueText}
        </Text>
        <Box direction="row" gap="xsmall" align="center">
          {changeIcon}
          <Text size="small" weight="bold" color={rest.graphColor}>
            {changedText}
          </Text>
        </Box>
      </Box>
    </GraphScreenShotBase>
  );
};
