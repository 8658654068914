import React, { FC } from 'react';
import type { Topic } from 'src/api/apiTypes/Summary';
import { InlineGraph } from 'src/components/Charts/LineCharts';
import type { SummaryGraphProps } from 'src/components/Charts/LineCharts/types';
import { IChartComparator } from 'src/types/Filter';
import { formChartValueOverTime } from 'Utils/chartsV2/graphFunctions';
import * as HoverCard from '@radix-ui/react-hover-card';
import { CustomDriverGraphTooltip } from './CustomDriverGraphTooltip';
import { useIntersection } from 'react-use';
import { useAppSelector } from 'src/core/store';

const getLines = (diasableAnimation: boolean) => {
  return {
    previousDataStrokeWidth: 0.8,
    previousStokeDashArray: '4 3',
    dataStrokeWidth: 0.8,
    previousDataIsAnimationActive: !diasableAnimation,
    dataIsAnimationActive: !diasableAnimation,
  };
};

const chartProps = { margin: { top: 4, right: 4, left: -50, bottom: 4 } };

interface DriversGraphProps extends Pick<SummaryGraphProps, 'selectedMetricType'> {
  driver: Topic;
  chartComparator: IChartComparator;
  chartDimension: string;
  diasableAnimation?: boolean;
  lazyLoadDisabled?: boolean;
}

export const DriversGraph: FC<DriversGraphProps> = ({ chartDimension, lazyLoadDisabled, diasableAnimation, ...props }) => {
  const { driver, chartComparator, selectedMetricType } = props;
  const intersectionRef = React.useRef<HTMLDivElement>(null);
  const intersection = useIntersection(intersectionRef, { root: null, rootMargin: '0px' });
  const valueOverTime = formChartValueOverTime(driver, selectedMetricType, chartComparator);
  if (valueOverTime.isDataNull) return null;
  return (
    <div className="h-11" ref={lazyLoadDisabled ? null : intersectionRef}>
      {intersection?.isIntersecting || lazyLoadDisabled ? (
        <HoverCard.Root openDelay={10} closeDelay={0}>
          <HoverCard.Trigger>
            <InlineGraph height={44} width={120} chartProps={chartProps} lines={getLines(diasableAnimation)} valueOverTime={valueOverTime} />
          </HoverCard.Trigger>
          <HoverCard.Portal>
            <HoverCard.Content sideOffset={10} align="center" side="top" className="z-50 -mb-2" onClick={(e) => e.stopPropagation()}>
              <CustomDriverGraphTooltip {...props} />
            </HoverCard.Content>
          </HoverCard.Portal>
        </HoverCard.Root>
      ) : null}
    </div>
  );
};
