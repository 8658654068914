const CongnitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${window.location.origin}/confirm/`,
    redirectSignOut: `${window.location.origin}/login/`,
    responseType: 'code',
  },
};
export default CongnitoConfig;
