import { Anomaly } from 'src/types/anomaly';
import { VOLUME_METRIC_OBJECT } from 'Utils/enums/ChartMetricObjects';
import { SENTISUM_TAG_DIMENSION_OBJECT } from 'Utils/enums/chartDimensionsType';
import { getUserTimezone } from 'Utils/dateOperations';

export const getDefaultAnamolyObject = (topics: string[], source: string): Anomaly => ({
  anomalyId: undefined,
  name: undefined,
  isEnabled: true,
  source,
  anomalyAlgo: 'inHouse',
  anomaly_dimensions: [{ dimension: SENTISUM_TAG_DIMENSION_OBJECT, dimensionValues: [] }],
  anomaly_metrics: [{ metric: VOLUME_METRIC_OBJECT }],
  frequency: 'day',
  isDetectorConfig: true,
  timezone: getUserTimezone(),
  conditions: [],
  alerts: [],
  modelIdentifier: undefined,
  z_score: 3.5,
  volume_threshold: undefined,
});
