import { InputHTMLAttributes, forwardRef } from 'react';
import { cn } from 'Utils/TailwindUtils';
export const TextInputWhite = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(({ className, ...props }, ref) => {
  return (
    <input
      className={cn(
        'w-full rounded-xl border bg-white p-3 text-blue-1100 placeholder-gray-850 focus:outline-none disabled:text-opacity-50',
        className
      )}
      {...props}
      ref={ref}
    />
  );
});
