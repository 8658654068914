import { useCallback, useRef, useState, createContext, useContext, useMemo, useEffect, ForwardedRef, forwardRef, useLayoutEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { BlockButtonSmall } from 'src/components/Button';
import { cn } from 'Utils/TailwindUtils';
import { TagsInput } from 'src/components/Input';
import { Tooltip } from 'react-tooltip';
import { renderTagsLayout } from './TagsLayout';
import { CreateDiyTagButton } from './CreateDiyTagButton';
import { ISetState } from 'src/types/Globals';
import { useAppSelector } from 'src/core/store';
import { cancelApiRequest } from 'src/api/utils/clientAdapters';
import * as Dialog from 'src/components/Dialog/NestedDialog';
import UseKey from 'react-use/lib/component/UseKey';

const DiyTagModalStateContext = createContext<{ showDiyTagModal: boolean; setShowDiyTagModal: ISetState<boolean> } | null>(null);

interface SearchDialogProps {
  updateSearchString: (searchString: string) => void;
  searchString: string;
  open?: boolean;
}

export const SearchDialog = forwardRef(({ updateSearchString, searchString, open }: SearchDialogProps, ref: ForwardedRef<HTMLDivElement>) => {
  const dimensions = useAppSelector((state) => state.filter.selectedSourceClientData?.es_alias?.dimensions);
  const hasDiyTagDimension = useMemo(() => dimensions?.some((dimension) => dimension.name === 'diy_topic'), [dimensions]);
  const [searchTags, setSearchTags] = useState(searchString ? searchString.split(',') : []);
  const [inputText, setInputText] = useState('');
  const tagsInputRef = useRef<HTMLInputElement>(null);
  const [showIntialTooltip, setShowIntialTooltip] = useState(true);
  const [showDiyTagModal, setShowDiyTagModal] = useState(false);
  const applySearch = useCallback(() => {
    const currentSearchTags = [...searchTags];
    if (inputText.trim()) currentSearchTags.push(inputText.trim());
    updateSearchString(currentSearchTags.join(','));
  }, [searchTags, inputText]);

  const clearInput = useCallback(() => {
    setInputText('');
    setSearchTags([]);
    tagsInputRef.current?.focus();
  }, []);

  useEffect(() => () => cancelApiRequest('conversationscount'), []);

  const handleSearchInputChange = useCallback((value: string) => {
    setShowIntialTooltip(false);
    setInputText(value);
  }, []);
  useEffect(() => {
    if (open) {
      setSearchTags(searchString ? searchString.split(',') : []);
      setInputText('');
      setShowIntialTooltip(true);
    }
  }, [open]);
  useLayoutEffect(() => tagsInputRef.current?.focus(), [open]);
  return (
    <Dialog.NestedDialogContent
      isOpen={open}
      className="min-w-[20rem] -translate-x-full rounded-xl border border-blue-1100 bg-white text-blue-1100"
      addPortal={false}
      ref={ref}
      onInteractOutside={applySearch}
    >
      <DiyTagModalStateContext.Provider value={{ showDiyTagModal, setShowDiyTagModal }}>
        <div className="flex flex-col gap-4 px-4 py-3">
          <div className="flex items-center gap-3 text-sm">
            <FiSearch size={16} />
            <TagsInput
              value={searchTags}
              onChange={setSearchTags}
              className="grow"
              inputProps={{
                placeholder: searchTags.length ? '' : 'Search keywords in conversations',
                'data-tooltip-id': 'search-dialog',
                autoFocus: !!searchTags.length,
              }}
              inputValue={inputText}
              onChangeInput={handleSearchInputChange}
              ref={tagsInputRef}
              renderLayout={renderTagsLayout}
            />
            {showIntialTooltip && searchTags.length === 0 && (
              <Tooltip
                id="search-dialog"
                place="bottom-start"
                classNameArrow="-ml-24"
                className="tooltip !left-0 !mt-5 !w-72 after:!left-6"
                opacity={1}
                openEvents={{ focus: true }}
                closeEvents={{ blur: true }}
              >
                <div className="flex flex-col gap-2 py-1">
                  <div className="px-2">You can now search multiple keywords using comma</div>
                  <img src="/search-demo.gif" alt="search-demo" />
                </div>
              </Tooltip>
            )}
            <BlockButtonSmall icon={IoCloseCircleOutline} className={cn('!p-0', { hidden: !searchTags.length && !inputText })} onClick={clearInput} />
          </div>
          {(searchTags.length > 0 || inputText.length > 0) && <span className="text-xs text-gray-850">Press ↩️ Enter to apply search</span>}
        </div>
        {searchTags.length > 0 && hasDiyTagDimension && <CreateDiyTagButton searchTags={searchTags} />}
        <UseKey filter="Enter" fn={applySearch} deps={[searchTags, inputText, showDiyTagModal]} />
      </DiyTagModalStateContext.Provider>
    </Dialog.NestedDialogContent>
  );
});

export const useDiyTagModalStateContext = () => useContext(DiyTagModalStateContext);
