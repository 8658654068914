import { SentisumLogo } from 'Static/images';
import { useWhiteLabel } from 'src/context/WhiteLabelContext';

export const Logo = () => {
  const whiteLabelContext = useWhiteLabel();
  if (!whiteLabelContext?.isWhiteLabel)
    return (
      <div className="flex items-center gap-2 text-xs text-grey-700">
        Powered by <SentisumLogo width={56} height={40} />
      </div>
    );
  const { cdnFolder, pngLogoSize } = whiteLabelContext;
  return (
    <div className="flex min-w-max items-center">
      <img src={cdnFolder.concat('logo.png')} alt="logo" width={pngLogoSize.width} height={pngLogoSize.height} />
      <div className="mx-2 h-8 border-l-[0.6px] border-grey-500"></div>
      <SentisumLogo width={40} height={28} />
    </div>
  );
};
