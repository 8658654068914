import React, { PropsWithChildren } from 'react';
import { Logo } from './Logo';

export interface ScreenShotBaseProps {
  dashboardName?: string;
}

export const ScreenShotBase: React.FC<PropsWithChildren<ScreenShotBaseProps>> = ({ children, dashboardName }) => {
  return (
    <div className="pointer-events-none p-5">
      {children}
      <div className="flex flex-row-reverse items-center justify-between gap-5 px-4 py-1.5">
        <Logo />
        {dashboardName && (
          <div className="shrink-0 grow space-x-3 rounded-xl bg-indigo-50 px-4 py-2.5 text-xs">
            <span className="font-semibold uppercase text-indigo-600">Dashboard:</span>
            <span className="font-semibold text-grey-900">{dashboardName}</span>
          </div>
        )}
      </div>
    </div>
  );
};
