import { DropButton } from 'grommet';
import { createElement, useCallback, useState } from 'react';
import styled from 'styled-components';
import { DropdownLabel } from '../DropdownLabel';
import { IDropdownMenuBase, DropdownMenu } from '../DropdownMenu';
import { HTMLButtonProps } from 'src/types/Globals';

const StyledDropButton = styled(DropButton)({
  borderRadius: '5px',
  border: '0px solid',
  padding: '4px',
  '&:hover': { boxShadow: 'none !important' },
});

interface IIconDropdownProps<T> extends IDropdownMenuBase<T>, Omit<HTMLButtonProps, 'onChange'> {
  icon?: JSX.Element;
  label?: typeof DropdownLabel;
}

const IconDropdown = <T,>(props: IIconDropdownProps<T>) => {
  const { icon, label = DropdownLabel, selected, options, onChange, searchPlaceholder, ...rest } = props;
  const [open, setOpen] = useState(false);
  const onOpen = useCallback(() => setOpen(true), [setOpen]);
  const onClose = useCallback(() => setOpen(false), [setOpen]);
  return (
    <StyledDropButton
      dropAlign={{ top: 'bottom', left: 'left' }}
      icon={icon}
      size="small"
      gap="xsmall"
      dropContent={
        <DropdownMenu
          collapseMenu={onClose}
          selected={selected}
          options={options}
          onChange={onChange}
          enableSearch
          searchPlaceholder={searchPlaceholder}
        />
      }
      label={createElement(label, { label: selected?.label })}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      {...rest}
    />
  );
};

export default IconDropdown;
