const HTTP_SUB_DOMAIN = '^[a-zA-Z0-9][a-zA-Z0-9-_]*$';
const REMOVE_UNDERSCORE_REGEX = '[_]+';
const CAMEL_CASE_IDENTIFIER = '\\B([A-Z]+)';
const EMAIL_PATTERN = '\\b[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z.-]+\\b';

export {
  HTTP_SUB_DOMAIN,
  REMOVE_UNDERSCORE_REGEX,
  CAMEL_CASE_IDENTIFIER,
  EMAIL_PATTERN,
};
