import { useRef, useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { api } from '../api';

export const useGetDecodedQueryParams: () => Record<string, string> = () => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  return [...params.entries()].reduce((acc, [key, value]) => ({ ...acc, [key]: value ? decodeURIComponent(value) : null }), {});
};

export const usePrevious = <T,>(value: T): T => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useApi = (endpoint: string, callback?: any) => {
  const initialState = {
    initiated: false,
    succeeded: false,
    data: null,
    failed: false,
    failureMessage: null,
  };
  const [stats, setStats] = useState(initialState);

  const succeeded = (data) => {
    setStats({ ...stats, initiated: false, succeeded: true, data });
    callback && callback(data);
  };

  const failed = (failureMessage) => setStats({ ...stats, initiated: false, failed: true, failureMessage });

  const initiate = (...args: any[]) => {
    setStats({ ...initialState, initiated: true });
    api[endpoint](...args)
      .then((response) => {
        if (response) {
          let datakey = null;
          Object.keys(response).forEach((key) => {
            if (key !== 'success') datakey = key;
          });
          succeeded(datakey && response[datakey]);
        } else succeeded(null);
      })
      .catch((error) => failed(error.message));
  };
  return [stats, initiate] as const;
};

export * from './useTrackPageTme';
export * from './useRefreshNotification';
export * from './useSsoProvider';
