import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IEsAlias, ISelectedClientSourceData } from 'src/types/User';
import { CommonFilterState } from './types/common';
import { AnomalyWithUpdatedFlag } from 'src/types/anomaly';
import { setDefaultDateRange } from './filter';
import { getDateRangeBounds, getFiltersData } from 'src/api/Filters';
import filterColors from 'Constants/filterColors.json';
import { cloneDeep, isEqual } from 'lodash';
import { transformFilters, hasInValidFilters, transformViewToFilters } from 'src/api/Tranformers';
import { IDataSourceFilters, IFilterRelation } from 'src/types/Bookmark';
import { IFilterView } from 'src/types/Views';
import { getDefaultAnamolyObject } from 'Pages/Alerts/AlertSetup/SetupAlertDialog/utils';

type AlertState = {
  esAlias?: IEsAlias;
  selectedSourceClientData?: ISelectedClientSourceData;
  selectedAnomaly?: AnomalyWithUpdatedFlag;
} & Pick<
  CommonFilterState,
  | 'fetchAvailbleDateRangeBoundsStatus'
  | 'fetchAvailbleDateRangeBoundsError'
  | 'dateRangeBounds'
  | 'filterdata'
  | 'bookmarkFilters'
  | 'selectedSourceClientData'
  | 'filters'
  | 'chartIntervalBreakdown'
>;

const initialState: AlertState = {
  chartIntervalBreakdown: 'day',
  fetchAvailbleDateRangeBoundsStatus: 'idle',
  fetchAvailbleDateRangeBoundsError: '',
  filterdata: { filters: [], topics: [], themes: [] },
  bookmarkFilters: {
    previousFilterRelation: 'and',
    datasourceFilters: {},
  },
};

const AlertSlice = createSlice({
  name: 'alert',
  initialState: initialState,
  reducers: {
    resetAnomaly(state) {
      state.selectedAnomaly = { ...getDefaultAnamolyObject([], state.selectedSourceClientData.es_alias.name), updatedFields: [] };
    },
    setEsAlias(state, action: PayloadAction<ISelectedClientSourceData>) {
      state.esAlias = action.payload.es_alias;
      state.selectedSourceClientData = action.payload;
      state.filters = { source: action.payload.es_alias.name, selectedDateField: 'datetime', metadata: [] };
    },
    setSelectedAnomaly(state, action: PayloadAction<Partial<AnomalyWithUpdatedFlag>>) {
      state.selectedAnomaly = { ...state.selectedAnomaly, ...action.payload };
      if (!state.dateRangeBounds?.length) return;
      setDefaultDateRange(state, state.selectedAnomaly?.frequency === 'day' ? 'last7days' : 'today');
    },
    setDateRangeBounds(state, action: PayloadAction<Awaited<ReturnType<typeof getDateRangeBounds>>>) {
      const dateRangeData = action.payload.filterdata;
      if (dateRangeData.length) {
        state.dateRangeBounds = dateRangeData.map(({ dateRange, dateField }) => ({ ...dateField, ...dateRange }));
        setDefaultDateRange(state, state.selectedAnomaly?.frequency === 'day' ? 'last7days' : 'today');
      }
    },
    updateFilterData: (state, action: PayloadAction<Awaited<ReturnType<typeof getFiltersData>>>) => {
      const { filters, topics, themes } = action.payload;
      state.filterdata.filters = filters.map((filter, index) => ({ ...filter, theme: filterColors[index] }));
      state.filterdata.topics = topics.map((topic) => ({ value: topic, count: 0 }));
      state.filterdata.themes = themes;
    },
    updateDataSourceFilters: (state, action: PayloadAction<IDataSourceFilters>) => {
      state.bookmarkFilters.datasourceFilters = action.payload;
      if (state.filters.metadata.length === 0) state.filters.isRestrictDimension = false;
      const newMedataFilters = transformFilters(cloneDeep(state.bookmarkFilters));
      if (!hasInValidFilters(state.bookmarkFilters) && !isEqual(state.filters.metadata, newMedataFilters)) state.filters.metadata = newMedataFilters;
    },
    updatePreviousFilterRelation: (state, action: PayloadAction<IFilterRelation>) => {
      state.bookmarkFilters.previousFilterRelation = action.payload;
      const newMedataFilters = transformFilters(cloneDeep(state.bookmarkFilters));
      if (!isEqual(state.filters.metadata, newMedataFilters)) state.filters.metadata = newMedataFilters;
    },
    updateFiltersFromView: (state, action: PayloadAction<Pick<IFilterView, 'filters'>>) => {
      state.filters.metadata = action.payload.filters.dataSourceFilters;
      state.bookmarkFilters = transformViewToFilters(action.payload.filters);
    },
  },
});

export const {
  setEsAlias,
  setDateRangeBounds,
  setSelectedAnomaly,
  updateFilterData,
  updateDataSourceFilters,
  updatePreviousFilterRelation,
  updateFiltersFromView,
  resetAnomaly,
} = AlertSlice.actions;
export default AlertSlice.reducer;
