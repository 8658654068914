import { useAppSelector } from 'src/core/store';
import { NavigateWithNextPageParam } from './NavigateWithNextPageParam';
import { Helmet } from 'react-helmet-async';
import { createElement } from 'react';
import { BaseRouteProps } from './types';

export const ProtectedLayout = <P,>({ component, title, props, responsiveView = true }: BaseRouteProps<P>) => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  if (isAuthenticated) {
    return (
      <>
        <Helmet titleTemplate="%s | SentiSum" title={title} defaultTitle="SentiSum">
          {responsiveView && <meta name="viewport" content="width=device-width, initial-scale=1" />}
        </Helmet>
        {createElement(component, props)}
      </>
    );
  } else {
    return <NavigateWithNextPageParam pathname="/login" />;
  }
};
