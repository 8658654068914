import { Box, Drop, Text } from 'grommet';
import { Download } from 'grommet-icons';
import { useRef, useState } from 'react';
import { DataExportPopupContainer } from './DataExportPopupContainer';
import { resetDataExportReduxState } from 'Slices/dataExport';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { toggleShowExportPopup } from 'Slices/dataExport';
import { StartToDownloadDataExportComponent } from './StartToDownloadDataExportComponent';
import { SummaryDataExport } from './SummaryDataExport/SummaryDataExport';
import { Spinner } from 'src/components/Spinner';
import { useLocation } from 'react-router';
export const DataExport = () => {
  const activePage = useLocation().pathname.split('/')[1];
  const [doSummaryExport, setDoSummaryExport] = useState(undefined);
  const dispatch = useAppDispatch();
  const exportButtonRef = useRef();
  const { showExportPopup, dataExportDownloadingStatus } = useAppSelector((state) => state.dataexport);
  const { fetchSummaryGraphDataV2Status, fetchSummaryDriversDataV2Status } = useAppSelector((state) => state.summary);
  const isDiscoverPage = activePage === 'discover';
  const updateSummaryExportStatus = (status) => {
    if (!doSummaryExport && status) {
      setDoSummaryExport(new String('start'));
    } else {
      setDoSummaryExport(undefined);
    }
  };

  const toggleExportPopup = (event) => {
    if (event && event?.preventDefault) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (isDiscoverPage) {
      if (dataExportDownloadingStatus !== 'pending') {
        if (!showExportPopup) {
          dispatch(resetDataExportReduxState());
        }
        dispatch(toggleShowExportPopup(!showExportPopup));
      }
    } else {
      updateSummaryExportStatus(true);
    }
  };

  const showExportConfig = () => {
    switch (activePage) {
      case 'discover':
        return (
          <>
            <StartToDownloadDataExportComponent />
            {exportButtonRef.current && showExportPopup && (
              <Drop
                plain={true}
                pad={{ top: '2px', bottom: 'small', horizontal: 'xsmall' }}
                align={{ top: 'top', right: 'left' }}
                target={exportButtonRef.current}
                onClickOutside={toggleExportPopup}
                onEsc={toggleExportPopup}
              >
                <Box background={'#ffffff'} pad={'small'} round={'xsmall'} elevation={'small'}>
                  <DataExportPopupContainer />
                </Box>
              </Drop>
            )}
          </>
        );
      default:
        return <SummaryDataExport doSummaryExport={doSummaryExport} updateSummaryExportStatus={updateSummaryExportStatus} />;
    }
  };

  if (fetchSummaryGraphDataV2Status !== 'fulfilled' || (fetchSummaryDriversDataV2Status !== 'fulfilled' && !isDiscoverPage)) return null;

  return (
    <>
      <button className="filter-button" ref={exportButtonRef} onClick={toggleExportPopup} data-state={showExportPopup ? 'open' : 'close'}>
        {dataExportDownloadingStatus === 'pending' ? <Spinner /> : <Download size={'16px'} color="text-field" />}
        <Text weight={'bold'} size="12px">
          {dataExportDownloadingStatus === 'pending' ? 'Preparing Export' : 'Export'}
        </Text>
      </button>
      {showExportConfig()}
    </>
  );
};
