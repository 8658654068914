import React, { createElement, PropsWithChildren } from 'react';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import { DownRed, UpGreen, DottedLineLegend, SolidLineLegend } from 'src/static/images';
import { capitalize } from 'lodash';
import { cn } from 'Utils/TailwindUtils';
import { SOURCE_TYPES } from 'Utils/enums/SourceModelTypes';
import { Topic } from 'src/api/apiTypes/Summary';

const TooltipContainer = styled(Box)({
  border: '1.05px solid #e0e0e0',
  borderRadius: '12px',
  boxShadow: '0px 4.2px 10.5px rgba(0, 0, 0, 0.12)',
  minHeight: 'auto',
});

const NoSpaceSpan = styled.span({
  whiteSpace: 'nowrap',
});

export interface IGraphTooltipProps {
  title: string;
  change?: number;
  changeText?: string;
  graphCurrentColor: string;
  getCurrentDateLabel: () => string;
  getPreviousDateLabel: () => string;
  previousValue?: string;
  currentValue?: string;
  driver?: Topic;
  noOfResponses?: number;
  selectedMetricType?: string;
}

const GraphTooltip: React.FC<PropsWithChildren<IGraphTooltipProps>> = (props) => {
  const {
    title,
    change,
    graphCurrentColor,
    driver,
    getCurrentDateLabel,
    getPreviousDateLabel,
    previousValue,
    currentValue,
    changeText,
    noOfResponses,
    selectedMetricType,
    children,
  } = props;
  const changeIcon = createElement(change < 0 ? DownRed : UpGreen, { stroke: graphCurrentColor, fill: graphCurrentColor });
  const changeLegend = <SolidLineLegend className="text-indigo-600" />;

  return (
    <TooltipContainer round="xsmall" pad="small" gap="xsmall" elevation="small" background="white">
      {driver && <p className="text-sm font-bold text-gray-800">{capitalize(driver.name)}</p>}
      <span className="flex items-center gap-1.5">
        <p className={cn('text-xs font-bold', { 'text-gray-500': driver })}>{title}</p>
        {driver && <span className="flex h-0.5 grow bg-gray-200"></span>}
      </span>
      {currentValue && (
        <>
          {previousValue ? (
            <Box direction="column">
              <Box direction="row" align="center">
                {changeLegend}&ensp;
                <Text size="xsmall" className="capitalize" color="gray-1">
                  {getCurrentDateLabel()}
                </Text>
              </Box>
              <Box direction="row" align="center">
                &emsp;&emsp;
                <Text size="small" color="gray-1">
                  <b>{currentValue}</b>
                </Text>
                &ensp;
                {changeIcon}
                &ensp;
                <Text size="14px" color={graphCurrentColor}>
                  {changeText}
                </Text>
                &ensp;
                <Text size="xsmall" color="#828282">
                  from previous period
                </Text>
              </Box>
            </Box>
          ) : (
            <Box direction="column" gap="xsmall" align="start">
              <Box direction="row" align="center">
                {changeLegend}&ensp;
                <Text size="xsmall" className="capitalize" color="gray-1">
                  {getCurrentDateLabel()}
                </Text>
              </Box>
              <NoSpaceSpan>
                &emsp;&emsp;
                <Text size="small">
                  <b>{currentValue}</b>
                </Text>
              </NoSpaceSpan>
            </Box>
          )}
          {SOURCE_TYPES.SURVEY_TYPE === selectedMetricType && driver && (
            <p className="mx-7 w-fit rounded-full bg-indigo-50 px-3 py-0.5 text-xxs">
              <span className="font-bold">{noOfResponses}</span>
              &nbsp;No. of Responses
            </p>
          )}
        </>
      )}
      {previousValue ? (
        <Box direction="column" align="start">
          <Box direction="row" align="center">
            <DottedLineLegend />
            &ensp;
            <Text size="xsmall" className="capitalize" color="gray-1">
              {getPreviousDateLabel()}
            </Text>
          </Box>

          <Text size="small" color="gray-1">
            &emsp; &emsp;
            <b>{previousValue}</b>
          </Text>
        </Box>
      ) : (
        ''
      )}
      {children}
    </TooltipContainer>
  );
};

export default GraphTooltip;
