import React from 'react';
import { ChangeTypeToggle } from './ChangeTypeToggle';
import { DimensionDropdown } from './DimensionDropdown';
import { MetricsDropdown } from './MetricsDropdown';
import { useAppSelector } from 'src/core/store';
import { SUPPORT_TYPES } from 'Utils/enums/SourceModelTypes';
import { IntervalDropdown } from './IntervalDropdown';

export const VisualizationFilters = () => {
  const selectedSourceClientData = useAppSelector((state) => state.filter?.selectedSourceClientData);
  return (
    <>
      <DimensionDropdown />
      {SUPPORT_TYPES.includes(selectedSourceClientData?.es_alias?.type) && <MetricsDropdown />}
      <ChangeTypeToggle />
      <IntervalDropdown />
    </>
  );
};
