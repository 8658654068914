import { ForwardedRef, forwardRef, useState, useEffect } from 'react';
import * as Dialog from 'src/components/Dialog/NestedDialog';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { DiyTagData } from 'src/types/Filter';
import { CloseButton } from 'Static/images';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { DiyTagsForm } from './DiyTagsForm';
import { DiyTagActionTypes } from './types';
import { useMutation } from '@tanstack/react-query';
import { createDiyTag, updateDiyTag } from 'src/api/DiyTag';
import { fetchDimensions, updateDIYTopicKeywords } from 'Slices/analytics';
import { toast } from 'react-hot-toast';
import { addOrUpdateDiyTopic } from 'Slices/filter';
import { addOrUpdateDiyTagInClient } from 'Slices/auth';

const defaultIntialValue = { includeKeywords: [], excludeKeywords: [], name: '' };

interface DiyTagsModalProps extends Dialog.NestedContentProps {
  type: DiyTagActionTypes;
  intialValue?: DiyTagData;
  setOpen: (value: boolean) => void;
}

export const DiyTagsModalComponent = (
  { isOpen, type, intialValue = defaultIntialValue, setOpen, ...rest }: DiyTagsModalProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const dispatch = useAppDispatch();
  const source = useAppSelector((state) => state.filter?.filters?.source);
  const topic = useAppSelector((state) => state.filter?.selectedTopic);
  const { themes } = useAppSelector((state) => state.filter.filterdata);
  const { selectedTheme, searchString } = useAppSelector((state) => state.filter);
  const [value, setValue] = useState(intialValue);
  const dimensionMapping = themes?.find((theme) => theme.name === selectedTheme)?.dimension_mapping;
  const diyTagsMutation = useMutation({
    mutationKey: ['diyTagsForm', type],
    mutationFn: type === 'create' ? createDiyTag : updateDiyTag,
    onSettled: () => setOpen(false),
    onSuccess: (data) => {
      intialValue?.name === topic && dispatch(updateDIYTopicKeywords(value.includeKeywords));
      toast.success(`"${value.name}" DIY tag ${type === 'create' ? 'created' : 'updated'} successfully`);
      dispatch(addOrUpdateDiyTopic(data));
      dispatch(addOrUpdateDiyTagInClient(data));
      trackFilterEvent(TRACK_EVENT.DIY_TAG_OPERATIONS, {
        DIY_TAG_Name: value.name,
        Action_Type: type,
        Include_Keywords: value.includeKeywords,
        Exclude_Keywords: value.excludeKeywords,
      });
      dispatch(fetchDimensions({ theme: !!dimensionMapping ? Object.values(dimensionMapping)[0] : [], search: searchString }));
    },
    onError: (error: Error) => toast.error(error.message ?? `Unable to ${type === 'create' ? 'create' : 'update'} DIY tag`),
  });
  useEffect(() => setValue(intialValue), [open, intialValue]);
  const submit = async (value: DiyTagData) => {
    const { id } = intialValue;
    const apiValue = { keywords: value.includeKeywords, exclude: value.excludeKeywords, name: value.name.trim(), es_alias: source, id };
    diyTagsMutation.mutate(apiValue);
  };
  return (
    <Dialog.NestedDialogContent isOpen={isOpen} ref={ref} className="w-full max-w-lg rounded-xl p-5" {...rest}>
      <div className="flex flex-col gap-4">
        <div className="flex items-start justify-between gap-2">
          <div className="flex flex-col gap-1">
            <Dialog.Title className="text-xl font-bold text-blue-1050">{type === 'create' ? 'Create DIY Tag' : 'Update DIY Tag'}</Dialog.Title>
            <Dialog.Description className="text-sm text-gray-450">
              {type === 'create' ? 'This will add to your DIY tags collection' : 'This will update the existing DIY tag'}
            </Dialog.Description>
          </div>
          <Dialog.Close>
            <CloseButton />
          </Dialog.Close>
        </div>
        <hr />
      </div>
      <DiyTagsForm value={value} setValue={setValue} type={type} submitCallback={submit} />
    </Dialog.NestedDialogContent>
  );
};

export const DiyTagsModal = forwardRef(DiyTagsModalComponent);
