import { cloneDeep } from 'lodash';
import { store } from 'src/core/store';
import { IFilterData } from 'src/types/Bookmark';
import { FILTER_ACTION_TYPES } from 'src/types/enums';
import { FILTER_VIEW_METADATA_GROUP_OPTIONS } from 'Utils/constants/common';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { useFilterContext } from '../DataSourceFilters';

export const getFilterOptions = (dataSource: string, filterGroupIndex: number, filterContext: ReturnType<typeof useFilterContext>) => {
  const dispatch = store.dispatch;
  const { updateDataSourceFilters, filterdata, filterState: filters, bookmarkFilters } = filterContext;
  const source = filters?.filters?.source;
  const defaultOptionValues: FILTER_VIEW_METADATA_GROUP_OPTIONS[] = Object.values(FILTER_VIEW_METADATA_GROUP_OPTIONS);
  const defaultOption = defaultOptionValues.map((option) => ({ value: option, label: option }));
  const metaDataOptions =
    filterdata.filters
      ?.find((filter) => filter.dataSource === dataSource)
      ?.metadataFilters?.map((metadataFilter) => ({
        value: metadataFilter,
        label: metadataFilter.displayName,
      })) ?? [];

  const options = [...defaultOption, ...metaDataOptions];

  const addFilterToGroup = (filterData: IFilterData) => {
    const datasourceFilters = cloneDeep(bookmarkFilters.datasourceFilters);
    datasourceFilters[dataSource][filterGroupIndex].conditionalFilters.push(filterData);
    trackFilterEvent(TRACK_EVENT.FILTER_ACTION, {
      Data_Source: dataSource,
      Type: filterData.type,
      Action_Type: FILTER_ACTION_TYPES.ADD,
      Source: source,
    });
    dispatch(updateDataSourceFilters(datasourceFilters));
  };

  const onOptionSelect = (metadataField: (typeof options)[0]['value']) => {
    switch (metadataField) {
      case FILTER_VIEW_METADATA_GROUP_OPTIONS.SENTISUM_TAG:
        addFilterToGroup({
          type: 'topic',
          fieldName: 'annotations.topic',
          valuesRelation: 'or',
          values: [],
        });
        break;
      case FILTER_VIEW_METADATA_GROUP_OPTIONS.DIY_TAG:
        addFilterToGroup({
          type: 'diyTopic',
          fieldName: 'segments.text',
          valuesRelation: 'or',
          values: [],
        });
        break;
      case FILTER_VIEW_METADATA_GROUP_OPTIONS.Theme:
        addFilterToGroup({
          type: 'themes',
          fieldName: 'NO_PATH',
          valuesRelation: 'or',
          values: [],
        });
        break;
      default:
        addFilterToGroup({
          type: metadataField.parentField,
          fieldName: metadataField.searchField.concat(metadataField.keyword ? '.keyword' : ''),
          valuesRelation: 'or',
          values: [],
        });
    }
  };

  return { options, onOptionSelect };
};
