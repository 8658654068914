import * as Dialog from 'src/components/Dialog/NestedDialog';
import { CloseButton } from 'Static/images';
import { TagsInput } from 'src/components/Input';
import { PropsWithChildren, useCallback, useState } from 'react';
import { Button } from 'src/components/Button';
import { useAppSelector } from 'src/core/store';
import { FormError } from 'src/components/Text';
import { useQuery, useIsMutating } from '@tanstack/react-query';
import { getTeamInviteDomains, getTeamMembers } from 'src/api/Team';
import { z } from 'zod';

interface InviteFormProps {
  onInvite: (emails: string[]) => void;
}

export const InviteForm: React.FC<PropsWithChildren<InviteFormProps>> = ({ onInvite }) => {
  const [emails, setEmails] = useState<string[]>([]);
  const userEmail = useAppSelector((state) => state.auth.user?.email);
  const [inputText, setInputText] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [warning, setWarning] = useState<string | null>(null);
  const teamsMembersQuery = useQuery({ queryKey: ['teamsMembers'], queryFn: getTeamMembers });
  const openInviteQuery = useQuery({ queryKey: ['openInvite'], queryFn: getTeamInviteDomains });
  const isInviteMutating = useIsMutating({ mutationKey: ['userInvite'] });

  const handleTagsChange = (emails: string[]) => {
    setEmails(emails);
    const currentTeamMembers = teamsMembersQuery.data?.map((member) => member.email) ?? [];
    const zodSchema = z
      .string()
      .email({ message: 'Please enter a valid email address' })
      .refine(
        (email) => !currentTeamMembers.includes(email),
        'Looks like some of the emails entered already has the access to the dashboard. Kindly double check the emails.'
      )
      .refine(() => new Set(emails).size === emails.length, 'Looks like you have entered the same email twice')
      .array();
    const result = zodSchema.safeParse(emails);
    const validDomains = new Set([...(openInviteQuery.data?.map((domain) => domain.domain) ?? []), userEmail?.split('@')[1]]);
    if (result.success === false) setError(result.error.issues[0].message);
    else if (result.data.some((email) => !validDomains.has(email.split('@')[1])))
      setWarning("Looks like you're trying to add a person outside your organisation. Please check the email domain name");
    else setWarning(null);
    if (result.success) setError(null);
    return result.success;
  };

  const handleSubmit = useCallback(() => {
    if (inputText.trim())
      if (z.string().email().safeParse(inputText).success) {
        if (handleTagsChange([...emails, inputText.trim()]) === null) {
          setInputText('');
          onInvite([...emails, inputText.trim()]);
        }
      } else setError('Please enter a valid email address');
    else onInvite(emails);
  }, [emails, handleTagsChange, inputText, onInvite]);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex">
        <p className="text-xl font-bold not-italic text-gray-900">Invite People</p>
        <Dialog.Close className="ml-auto">
          <CloseButton />
        </Dialog.Close>
      </div>
      <p className="text-sm font-normal text-gray-500">
        Enter the emails of your teammates you would like to invite to your SentiSum account. They will then receive an email with an invitation link.
      </p>
      <TagsInput
        className="min-h-32 rounded-lg border border-grey-300 p-1 text-indigo-600"
        value={emails}
        onChange={handleTagsChange}
        inputProps={{
          placeholder: `name@${userEmail?.split('@')[1]}`,
          autoFocus: true,
          type: 'email',
          class: 'text-grey-800',
        }}
        inputValue={inputText}
        onChangeInput={setInputText}
        addKeys={['Enter', 'Tab', ',', ';', ' ']}
      />
      <FormError size="small">{error}</FormError>
      <FormError type="warning" size="small">
        {warning}
      </FormError>
      <Button
        variant="primary"
        label="Send Invite"
        className="ml-auto"
        disabled={!emails.length || !!error}
        onClick={handleSubmit}
        inProgress={!!isInviteMutating}
      />
    </div>
  );
};
