import { FiDatabase } from 'react-icons/fi';
import { cn } from 'Utils/TailwindUtils';

export const SourceNameLabel = ({ sourceName, className }: { sourceName: string; className?: string }) => {
  return (
    <div
      className={cn(
        'ml-2.5 flex w-fit items-center gap-1.5 rounded-b-xl border border-grey-200 bg-white px-3 py-1 !text-xxs text-gray-850',
        className
      )}
    >
      <FiDatabase className="h-3 w-3" />
      <span className="capitalize">{sourceName?.length > 20 ? `${sourceName?.slice(0, 20)}...` : sourceName}</span>
    </div>
  );
};
