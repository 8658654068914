import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { cn } from 'Utils/TailwindUtils';

interface FormFieldProps {
  label: ReactNode;
  name: string;
  className?: string;
  help?: string;
}

export const FormField: FC<PropsWithChildren<FormFieldProps>> = ({ label, name, className, children, help }) => {
  const { formState } = useFormContext();
  return (
    <div className={cn('flex flex-col gap-1 text-sm', className)}>
      <label htmlFor={name}>{label}</label>
      {children}
      {formState.errors[name] && (
        <span role="alert" className="pl-1 text-red-1000">
          {formState.errors[name].message ?? formState.errors[name][0]?.message}
        </span>
      )}
      {help && <div className="text-xs text-grey-500">{help}</div>}
    </div>
  );
};
