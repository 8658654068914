import moment from 'moment';
import { CommonFilterState } from 'Slices/types/common';

const getDateRangeIntervals = (
  dateRangeBounds: CommonFilterState['dateRangeBounds'],
  selectedDateField: string,
  ignoreKeys: string[] = ['last30days', 'last3months', 'last3days']
) => {
  const { start, end } = dateRangeBounds?.find(({ date_field }) => date_field === selectedDateField) ?? {};
  const dateRangeBoundsStart = moment(start).startOf('day').utc().toDate();
  const dateRangeBoundsEnd = moment(end).endOf('day').utc().toDate();
  return dateRangeBounds
    ? [
        {
          label: 'Today',
          startDate: dateRangeBoundsEnd,
          endDate: dateRangeBoundsEnd,
          key: 'today',
        },
        {
          label: 'Yesterday',
          startDate: moment(dateRangeBoundsEnd).subtract(1, 'days').toDate(),
          endDate: moment(dateRangeBoundsEnd).subtract(1, 'days').toDate(),
          key: 'yesterday',
        },
        {
          label: 'This Month',
          startDate: moment(dateRangeBoundsEnd).startOf('month').toDate(),
          endDate: dateRangeBoundsEnd,
          key: 'thismonth',
        },
        {
          label: 'Last Month',
          startDate: moment(dateRangeBoundsEnd).subtract(1, 'months').startOf('month').toDate(),
          endDate: moment(dateRangeBoundsEnd).subtract(1, 'months').endOf('month').toDate(),
          key: 'lastmonth',
        },
        {
          label: 'Last 3 Days',
          startDate: moment(dateRangeBoundsEnd).subtract(3, 'days').toDate(),
          endDate: dateRangeBoundsEnd,
          key: 'last3days',
        },
        {
          label: 'Last 7 Days',
          startDate: moment(dateRangeBoundsEnd).subtract(7, 'days').toDate(),
          endDate: dateRangeBoundsEnd,
          key: 'last7days',
        },
        {
          label: 'Last 28 Days',
          startDate: moment(dateRangeBoundsEnd).subtract(28, 'days').toDate(),
          endDate: dateRangeBoundsEnd,
          key: 'last28days',
        },
        {
          label: 'Last 30 Days',
          startDate: moment(dateRangeBoundsEnd).subtract(30, 'days').toDate(),
          endDate: dateRangeBoundsEnd,
          key: 'last30days',
        },
        {
          label: 'Last 90 Days',
          startDate: moment(dateRangeBoundsEnd).subtract(90, 'days').toDate(),
          endDate: dateRangeBoundsEnd,
          key: 'last90days',
        },
        {
          label: 'Last 3 Months',
          startDate: moment(dateRangeBoundsEnd).subtract(3, 'months').toDate(),
          endDate: dateRangeBoundsEnd,
          key: 'last3months',
        },
        {
          label: 'Last 6 Months',
          startDate: moment(dateRangeBoundsEnd).subtract(6, 'months').toDate(),
          endDate: dateRangeBoundsEnd,
          key: 'last6months',
        },
        {
          label: 'Last 1 Year',
          startDate: moment(dateRangeBoundsEnd).subtract(1, 'years').toDate(),
          endDate: dateRangeBoundsEnd,
          key: 'last1year',
        },
        {
          label: 'All Time',
          startDate: dateRangeBoundsStart,
          endDate: dateRangeBoundsEnd,
          key: 'alltime',
        },
      ].filter(({ key }) => !ignoreKeys.includes(key))
    : [];
};
export default getDateRangeIntervals;

export const setDateRangeOnDateFieldChange = (
  dateRangeBounds: CommonFilterState['dateRangeBounds'],
  data: Pick<CommonFilterState['filters'], 'interval' | 'selectedDateField' | 'dateRange'>,
  ignoreKeys?: string[]
) => {
  const { interval, selectedDateField, dateRange } = data;
  const dateRangeIntervals = getDateRangeIntervals(dateRangeBounds, selectedDateField, ignoreKeys);
  const dateRangeInterval = dateRangeIntervals.find(({ key }) => key === interval);
  if (dateRangeInterval) return { start: dateRangeInterval.startDate, end: dateRangeInterval.endDate };
  return dateRange;
};
