import { BsLink45Deg } from 'react-icons/bs';
import { cn } from 'Utils/TailwindUtils';
import React, { FC } from 'react';
import { useFloating, offset, autoPlacement, autoUpdate } from '@floating-ui/react-dom';
import { useEditorContext } from '../../EditorContext';
import { LinkDialog } from './LinkDialog';

interface AddLinkProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddLink: FC<AddLinkProps> = ({ open, setOpen }) => {
  const { editor } = useEditorContext();
  const { refs, floatingStyles } = useFloating({
    middleware: [offset(10), autoPlacement({ allowedPlacements: ['bottom-end', 'top-end'] })],
    whileElementsMounted: autoUpdate,
  });
  return (
    <div>
      <button
        className="flex items-center justify-center gap-0.5 rounded-md p-2 hover:bg-grey-100 radix-state-open:bg-grey-100"
        onClick={() => setOpen(!open)}
        ref={refs.setReference}
        data-state={open ? 'open' : 'closed'}
      >
        <BsLink45Deg className={cn('text-xl text-grey-500', { 'text-grey-900': editor.isActive('link') })} />
      </button>
      {open && <LinkDialog style={floatingStyles} ref={refs.setFloating} setOpen={setOpen} />}
    </div>
  );
};
