import moment from 'moment';

export const getNoOfDaysFromRange = (start: Date, end: Date) => moment(end).startOf('day').diff(moment(start).startOf('day'), 'days', true) + 1;

export const getUserTimezone = () => moment().format('Z');

export const getDateRangeLabel = (start: Date, end: Date) => {
  const momentStartDate = moment(start);
  const momentEndDate = moment(end);
  const startDateString =
    momentStartDate.year() === momentEndDate.year()
      ? momentStartDate.month() === momentEndDate.month()
        ? momentStartDate.format('DD')
        : momentStartDate.format('DD MMM')
      : momentStartDate.format('DD MMM, YYYY');
  const endDateString = momentEndDate.format('DD MMM, YYYY');
  if (momentStartDate.format('DD MMM, YYYY') === momentEndDate.format('DD MMM, YYYY')) return endDateString;
  return `${startDateString} - ${endDateString}`;
};
