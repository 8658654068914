import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IGetSummaryGraphDataRequest } from 'src/api/apiTypes/Summary';
import { getSummaryDrivers, getSummaryOverviewGraphData } from 'src/api/Summary';
import { IGetSummaryGraphDataResponse, IFetchSummaryDriversResponse, ISummaryState } from './types/summary';

export const fetchSummaryOverviewV3 = createAsyncThunk<IGetSummaryGraphDataResponse, IGetSummaryGraphDataRequest>(
  'fetchSummaryOverviewV3',
  ({ search, theme, diy_topic }) => {
    return getSummaryOverviewGraphData({ theme, search, diy_topic });
  }
);

export const fetchSummaryDriversDataV3 = createAsyncThunk<IFetchSummaryDriversResponse>('fetchSummaryDriversDataV3', () => {
  return getSummaryDrivers();
});

const requestObjectInfo = {
  fetchSummaryOverviewV2: { requestId: '' },
  fetchSummaryDriversDataV2: { requestId: '' },
};

const initialState: ISummaryState = {
  fetchSummaryGraphDataV2Status: 'idle',
  fetchSummaryDriversDataV2Status: 'idle',
};
const SummarySlice = createSlice({
  name: 'summary',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetSummaryReduxState: (state) => {
      Object.assign(state, { ...initialState });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSummaryOverviewV3.pending, (state, action) => {
        state.fetchSummaryGraphDataV2Status = 'pending';
        requestObjectInfo.fetchSummaryOverviewV2.requestId = action.meta.requestId;
      })
      .addCase(fetchSummaryOverviewV3.fulfilled, (state, action) => {
        state.summaryGraphDataV2 = action.payload.data;
        state.fetchSummaryGraphDataV2Status = 'fulfilled';
      })
      .addCase(fetchSummaryOverviewV3.rejected, (state, action) => {
        if (requestObjectInfo.fetchSummaryOverviewV2.requestId === action.meta.requestId) {
          state.summaryGraphDataV2 = null;
          state.fetchSummaryGraphDataV2Status = 'rejected';
        }
      })
      .addCase(fetchSummaryDriversDataV3.pending, (state, action) => {
        state.summaryDriverGraphDataV2 = null;
        state.fetchSummaryDriversDataV2Status = 'pending';
        requestObjectInfo.fetchSummaryDriversDataV2.requestId = action.meta.requestId;
      })
      .addCase(fetchSummaryDriversDataV3.fulfilled, (state, action) => {
        state.summaryDriverGraphDataV2 = action.payload.data;
        state.fetchSummaryDriversDataV2Status = 'fulfilled';
      })
      .addCase(fetchSummaryDriversDataV3.rejected, (state, action) => {
        if (requestObjectInfo.fetchSummaryDriversDataV2.requestId === action.meta.requestId) {
          state.summaryDriverGraphDataV2 = null;
          state.fetchSummaryDriversDataV2Status = 'rejected';
        }
      });
  },
});

export const { resetSummaryReduxState } = SummarySlice.actions;
export default SummarySlice.reducer;
