import React, { forwardRef, useCallback } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { cn } from 'Utils/TailwindUtils';
import { HTMLButtonProps } from 'src/types/Globals';

export const TransparentClose = forwardRef<HTMLButtonElement, HTMLButtonProps>(({ className, onClick, ...rest }, ref) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onClick && onClick(e);
    },
    [onClick]
  );
  return (
    <div className={cn('absolute right-0 flex h-full items-center justify-end pl-1.5 pr-1 fade-transparent', className)}>
      <button onClick={handleClick} {...rest} ref={ref}>
        <IoCloseCircleOutline size={16} className="text-red-900" />
      </button>
    </div>
  );
});
