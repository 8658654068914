import { CreateDashbaoardResponse } from '../apiTypes/Dashboard';
import { getAdminClientInstance } from '../utils/clientAdapters';

export const renameFolder = async (data: { id: number; name: string }) => {
  try {
    const key = 'folders/';
    return (await getAdminClientInstance('renameFolder').put(key + data.id, data)).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const createFolder = async (data: { name: string }) => {
  try {
    const key = 'folders';
    return (await getAdminClientInstance('createFolder').post<CreateDashbaoardResponse>(key, data)).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const deleteFolder = async (id: number) => {
  try {
    const key = 'folders/';
    return (await getAdminClientInstance('deleteFolder').delete(key + id)).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const updateFolderOrder = async (data: number[]) => {
  try {
    const key = 'folders/order';
    return await getAdminClientInstance('folderOrder').post(key, data);
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const updateDashboardOrder = async (data: Record<number, number[]>) => {
  try {
    const key = 'folders/dashboards/order';
    return await getAdminClientInstance('updateDashboardOrder').post(key, data);
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
