import React from 'react';
import { Bar, XAxis, YAxis } from 'recharts';
import { customBarProps, customXAxisProps, customYAxisProps } from './types';

export const currentBar = ({ currentCustomLabel, onClick, barData }: customBarProps) => {
  const handleClick = (e) => onClick?.(e.name);
  return (
    <Bar fill="#444CE7" isAnimationActive={false} dataKey="data" onClick={handleClick} role={onClick ? 'button' : undefined} {...barData}>
      {currentCustomLabel}
    </Bar>
  );
};

export const previousBar = ({ onClick, barData }: customBarProps) => {
  const handleClick = (e) => onClick?.(e.name);
  return (
    <Bar fill="#E0EAFF" isAnimationActive={false} dataKey="previousData" onClick={handleClick} role={onClick ? 'button' : undefined} {...barData} />
  );
};

export const customYAxis = ({ yAxisData, yAxisLabel }: customYAxisProps) => (
  <YAxis axisLine={false} tickLine={false} className="text-xs" {...yAxisData}>
    {yAxisLabel}
  </YAxis>
);

export const customXAxis = ({ xAxisData, xAxisLabel }: customXAxisProps) => (
  <XAxis dataKey={xAxisLabel ? undefined : 'name'} axisLine={false} tickLine={false} className="text-xs" {...xAxisData}>
    {xAxisLabel}
  </XAxis>
);
