import * as TabsPrimitive from '@radix-ui/react-tabs';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { cn } from 'Utils/TailwindUtils';
import { ForwardedRef, forwardRef, useState } from 'react';
import { TextInputGray } from 'src/components/Input';
import styled from 'styled-components';
import { DropdownTab } from '../../types';
import { RadixDropdownOption } from './RadixDropDownOption';
import { useSearchText } from '../../Hooks';

const StyledTabsPrimitiveTrigger = styled(TabsPrimitive.Trigger)`
  &[data-state='active'] {
    color: ${(props) => props['data-text-color']};
    background: ${(props) => props['data-bg-color']};
  }
`;

interface RadixTabbedDropdownProps<T> extends DropdownMenuPrimitive.DropdownMenuContentProps {
  tabs: DropdownTab<T>[];
  className?: string;
  searchPlaceholder?: string;
  menuOpen: boolean;
  optionClassName?: string;
}

const RadixTabbedDropdownFunction = <T,>(
  { tabs, className, searchPlaceholder, menuOpen, optionClassName, ...rest }: RadixTabbedDropdownProps<T>,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const [searchText, setSearchText] = useSearchText(menuOpen);
  const [activeTab, setActiveTab] = useState<string>();

  return (
    <DropdownMenuPrimitive.Content align="start" ref={ref} className={cn('radix-dropdown-content min-w-60')} {...rest}>
      <TabsPrimitive.Root value={activeTab} onValueChange={setActiveTab} defaultValue={tabs[0]?.value}>
        <TabsPrimitive.List className="flex gap-5 p-2">
          {tabs.map(({ label, value, theme }) => (
            <StyledTabsPrimitiveTrigger
              key={`tab-trigger-${value}`}
              value={value}
              className={cn(
                'flex items-center rounded-md border border-transparent px-3 py-1',
                'radix-state-inactive:bg-white radix-state-inactive:text-blue-950',
                'text-center hover:radix-state-inactive:bg-gray-550',
                'only:flex-grow'
              )}
              data-text-color={theme.text}
              data-bg-color={theme.background}
            >
              <span className={cn('grow text-center text-xs font-medium uppercase')}>{label}</span>
            </StyledTabsPrimitiveTrigger>
          ))}
        </TabsPrimitive.List>
        <DropdownMenuPrimitive.Separator className="border border-r-gray-1100" />
        {tabs.map((tab) => (
          <TabsPrimitive.Content key={`tab-content-${tab.value}`} value={tab.value}>
            <div className="flex flex-col gap-2 py-2">
              <div className="px-4" onKeyDown={(e) => e.stopPropagation()}>
                <TextInputGray value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder={searchPlaceholder} autoFocus />
              </div>
              <RadixDropdownOption
                options={tab.options}
                onItemClick={tab.onTabItemClick}
                searchText={searchText}
                className={className}
                optionClassName={optionClassName}
              />
            </div>
          </TabsPrimitive.Content>
        ))}
      </TabsPrimitive.Root>
    </DropdownMenuPrimitive.Content>
  );
};

export const RadixTabbedDropdownMenu = forwardRef(RadixTabbedDropdownFunction);
