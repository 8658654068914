import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import React, { createContext, FC, useContext } from 'react';
import { CommonFilterState, IFilterData } from 'Slices/types/common';
import { useAppSelector } from 'src/core/store';
import { IBookmarkFilter, IDataSourceFilters, IFilterRelation } from 'src/types/Bookmark';
import { IThemedFilter } from 'src/types/Filter';
import { ISelectedClientSourceData } from 'src/types/User';
import { DataSourceFilterContainer } from './DataSourceFilterContainer';

export type FilterState = Pick<CommonFilterState, 'filterdata' | 'bookmarkFilters' | 'selectedSourceClientData' | 'filters'>;

export interface FilterContext {
  datasourceFilters: IDataSourceFilters;
  availableFilters: IThemedFilter[];
  previousFilterRelation: IFilterRelation;
  filterState: FilterState;
  selectedSourceClientData: ISelectedClientSourceData;
  updateDataSourceFilters: ActionCreatorWithOptionalPayload<IDataSourceFilters>;
  updatePreviousFilterRelation: ActionCreatorWithOptionalPayload<IFilterRelation>;
  filterdata: IFilterData;
  bookmarkFilters: IBookmarkFilter;
}

export const FilterContext = createContext<FilterContext | null>(null);

type DataSourceFiltersProps = Pick<FilterContext, 'filterState' | 'updateDataSourceFilters' | 'updatePreviousFilterRelation'>;

export const DataSourceFilters: FC<DataSourceFiltersProps> = ({ filterState, updateDataSourceFilters, updatePreviousFilterRelation }) => {
  const { filterdata, bookmarkFilters, selectedSourceClientData } = filterState;
  const { datasourceFilters, previousFilterRelation } = bookmarkFilters;
  const availableFilters = filterState.filterdata?.filters;
  return (
    <div className="relative flex flex-col gap-3">
      {Object.entries(datasourceFilters).map(([dataSource, filterGroups], index) => (
        <FilterContext.Provider
          value={{
            datasourceFilters,
            availableFilters,
            previousFilterRelation,
            filterState,
            selectedSourceClientData,
            updateDataSourceFilters,
            updatePreviousFilterRelation,
            filterdata,
            bookmarkFilters,
          }}
          key={index}
        >
          <DataSourceFilterContainer dataSource={dataSource} filterGroups={filterGroups} key={dataSource} />
        </FilterContext.Provider>
      ))}
    </div>
  );
};

export const useFilterContext = () => {
  const context = useContext(FilterContext);
  return context;
};
