import { FiPlus } from 'react-icons/fi';
import { BlockButtonSmall } from 'src/components/Button';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { RadixTabbedDropdownMenu } from 'src/components/Dropdown/DropdownMenu';
import { useMemo, useState } from 'react';
import { useAppSelector } from 'src/core/store';
import { getFilterTabs } from './AddFilter';

export const AddFiltersContiner = () => {
  const { filterdata, filters, bookmarkFilters, selectedSourceClientData } = useAppSelector((state) => state.filter);
  const filterTabs = useMemo(getFilterTabs, [filterdata, filters, bookmarkFilters, selectedSourceClientData]);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <DropdownMenuPrimitive.Root open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuPrimitive.Trigger asChild>
        <BlockButtonSmall
          data-testid="add-filter-summary"
          className="rounded-xl text-indigo-600 hover:bg-indigo-50"
          label="Add Filter"
          icon={FiPlus}
        />
      </DropdownMenuPrimitive.Trigger>
      <RadixTabbedDropdownMenu tabs={filterTabs} className="max-h-60" searchPlaceholder="Search Filter" sideOffset={10} menuOpen={menuOpen} />
    </DropdownMenuPrimitive.Root>
  );
};
