import React, { forwardRef, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormField } from '../Form';
import { DropDownArrowIcon } from 'Static/images';
import { RadixSingleSelect, RadixSingleSelectMenuProps } from 'src/components/Select';
import { cn } from 'Utils/TailwindUtils';

interface IFormSelectProps {
  name: string;
  label?: string;
  placeholder?: string;
  enableSearch?: boolean;
  className?: string;
  options: RadixSingleSelectMenuProps['options'];
}

export const FormSelect = forwardRef<HTMLDivElement, IFormSelectProps>(({ name, label, className, enableSearch, placeholder, options }, ref) => {
  const { control } = useFormContext();
  const { field } = useController({ control, name });
  const onChange = useCallback((value: string) => value && field.onChange(value), [field.onChange]);
  return (
    <FormField label={label} name={name}>
      <RadixSingleSelect
        value={field.value}
        onValueChange={onChange}
        options={options}
        className={cn('min-w-[21rem]', className)}
        enableSearch={enableSearch}
        searchPlaceholder="Search Dashboard"
      >
        <button className="flex h-full w-full items-center justify-between rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-700">
          <span>
            {field?.value ? options.find((option) => option.value === field.value)?.label : <span className="text-gray-850">{placeholder}</span>}
          </span>
          <DropDownArrowIcon />
        </button>
      </RadixSingleSelect>
    </FormField>
  );
});
