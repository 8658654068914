import React, { PropsWithChildren } from 'react';
import { SummaryGraph } from 'src/components/Charts/LineCharts';
import { Box } from 'grommet';
import { DottedLineMedium, SolidLineMedium } from 'src/static/images';
import { DateRangeLabel } from 'src/components/Label';
import { IValueOverTime } from 'src/components/Charts/LineCharts/types';
import { IChartComparator } from 'src/types/Filter';
import { CommonFilterState } from 'Slices/types/common';

export interface IGraphScreenShotBaseProps {
  selectedMetricType?: string;
  valueOverTime: IValueOverTime;
  changeValue: number;
  isDecimal?: boolean;
  chartComparator?: IChartComparator;
  filters: Pick<CommonFilterState['filters'], 'dateRange' | 'previousDateRange'>;
}

const lines = { previousDataIsAnimationActive: false, dataIsAnimationActive: false };

export const GraphScreenShotBase: React.FC<PropsWithChildren<IGraphScreenShotBaseProps>> = (props) => {
  const { selectedMetricType, valueOverTime, changeValue, isDecimal, chartComparator, filters, children } = props;
  const { dateRange, previousDateRange } = filters;
  const YAxisMeta = { show: true, label: '', isDecimal };
  return (
    <Box gap="medium" width={{ min: '45rem' }}>
      <Box direction="row" align="center" justify="between" pad={{ horizontal: 'large' }}>
        <Box gap="small">{children}</Box>
        <Box gap="small" align="start">
          <Box direction="row" align="center" pad={{ top: 'large' }}>
            <SolidLineMedium className="text-indigo-600" />
            &ensp;
            <DateRangeLabel start={dateRange.start} end={dateRange.end} />
          </Box>
          <Box direction="row" align="center">
            <DottedLineMedium />
            &ensp;
            <DateRangeLabel {...previousDateRange} />
          </Box>
        </Box>
      </Box>
      <SummaryGraph
        height={280}
        selectedMetricType={selectedMetricType}
        valueOverTime={valueOverTime}
        changeValue={changeValue}
        YAxisMeta={YAxisMeta}
        lines={lines}
        chartComparator={chartComparator}
      />
    </Box>
  );
};
