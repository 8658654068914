import React, { useMemo } from 'react';
import { IFilterGroup } from 'src/types/Bookmark';
import { PreviousFilterRelationSelect } from './PreviousFilterRelationSelect';
import { DataSourceFilterLabel } from './DataSourceFilterLabel';
import styled from 'styled-components';
import getThemeColor from 'Utils/grommet/GetThemeColor';
import { FilterGroup } from './FilterGroup';
import { useFilterContext } from './DataSourceFilters';

const StyledFilterContainer = styled.div`
  &:has(.previous-relation:hover) .previous-relation {
    background-color: var(--tw-indigo-50);
    color: var(--tw-indigo-600);
  }
`;

interface DataSourceFilterContainerProps {
  dataSource: string;
  filterGroups: IFilterGroup[];
}

interface FilterGroupContext {
  filterGroup: IFilterGroup;
  filterGroupIndex: number;
  dataSource: string;
}

const FilterGroupContext = React.createContext<FilterGroupContext | null>(null);

export const DataSourceFilterContainer: React.FC<DataSourceFilterContainerProps> = ({ dataSource, filterGroups }) => {
  const { availableFilters, previousFilterRelation } = useFilterContext();
  const filterTheme = useMemo(() => availableFilters?.find((filter) => filter.dataSource === dataSource)?.theme, [availableFilters, dataSource]);
  return (
    <section className="flex gap-2">
      <DataSourceFilterLabel dataSource={dataSource} theme={filterTheme} />
      <StyledFilterContainer className="flex flex-wrap items-center gap-x-2 gap-y-3">
        {filterGroups.map((filterGroup, filterGroupIndex) => (
          <FilterGroupContext.Provider value={{ filterGroup, filterGroupIndex, dataSource }} key={filterGroupIndex}>
            {filterGroupIndex > 0 && (
              <PreviousFilterRelationSelect className="previous-relation rounded-md">{previousFilterRelation}</PreviousFilterRelationSelect>
            )}
            <FilterGroup />
          </FilterGroupContext.Provider>
        ))}
      </StyledFilterContainer>
    </section>
  );
};

export const useFilterGroupContext = () => {
  const context = React.useContext(FilterGroupContext);
  return context;
};
