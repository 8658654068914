import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { MetricReportConfigration } from 'src/api/apiTypes/Report';
import { ConfigMetricCard } from './ConfigMetricCard';

export const SortableMetricCard = ({
  metric,
  idx,
  isOver,
}: {
  metric: MetricReportConfigration;
  idx: number;
  draggedItemId?: UniqueIdentifier;
  isOver?: boolean;
}) => {
  const { attributes, listeners, setNodeRef, isDragging, over } = useSortable({ id: metric.id });
  if (isDragging) return <div className="h-28 w-full"></div>;
  return (
    <div ref={setNodeRef} {...attributes} {...listeners}>
      <ConfigMetricCard metric={metric} idx={idx} isOver={isOver} isInternalOver={metric?.id === over?.id} />
    </div>
  );
};
