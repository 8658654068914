import React, { SVGAttributes } from 'react';
import { cn } from 'Utils/TailwindUtils';

export const SpokeSpinner: React.FC<SVGAttributes<SVGElement>> = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      className={cn('animate-spin text-base', className)}
      {...props}
    >
      <g clip-path="url(#clip0_1445_8135)">
        <path d="M8 1.33301V3.99967" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M8 12V14.6667" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.28662 3.28613L5.17329 5.1728" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10.8267 10.8271L12.7133 12.7138" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1.3335 8H4.00016" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12 8H14.6667" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.28662 12.7138L5.17329 10.8271" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10.8267 5.1728L12.7133 3.28613" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1445_8135">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
