import { PayloadAction, createAsyncThunk, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getReportConfiguration } from 'src/api/dashboard/Report';
import { IApiCallStatus } from 'src/types/Globals';
import { createGraphReportThunk, editGraphReportThunk } from './editGraphReport';
import { createMetricCardThunk } from './editMetricCard';

export const getReportConfigurationThunk = createAsyncThunk('getReportConfiguration', getReportConfiguration);

export interface EditReportState {
  id?: number;
  name: string;
  description?: string;
  fetchReportConfigrationStatus: IApiCallStatus;
  modalType: 'report' | 'metricCard';
  saveReportStatus: IApiCallStatus;
}

const initialState: EditReportState = {
  name: 'Untitled',
  fetchReportConfigrationStatus: 'idle',
  modalType: 'report',
  saveReportStatus: 'idle',
};

const EditReportSlice = createSlice({
  name: 'editReport',
  initialState: { ...initialState },
  reducers: {
    updateReportName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    updateModalType: (state, action: PayloadAction<EditReportState['modalType']>) => {
      state.modalType = action.payload;
    },
    setReportId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    resetEditReportState: (state) => Object.assign(state, initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReportConfigurationThunk.pending, (state, action) => {
        state.id = action.meta.arg;
        state.fetchReportConfigrationStatus = 'pending';
      })
      .addCase(getReportConfigurationThunk.fulfilled, (state, action) => {
        state.fetchReportConfigrationStatus = 'fulfilled';
        state.modalType = action.payload.configuration.report_type === 'MC' ? 'metricCard' : 'report';
        state.name = action.payload.name;
      })
      .addCase(getReportConfigurationThunk.rejected, (state, action) => {
        state.fetchReportConfigrationStatus = 'rejected';
      })
      .addMatcher(isPending(createGraphReportThunk, editGraphReportThunk, createMetricCardThunk, editGraphReportThunk), (state) => {
        state.saveReportStatus = 'pending';
      })
      .addMatcher(isFulfilled(createGraphReportThunk, editGraphReportThunk, createMetricCardThunk, editGraphReportThunk), (state) => {
        state.saveReportStatus = 'fulfilled';
      })
      .addMatcher(isRejected(createGraphReportThunk, editGraphReportThunk, createMetricCardThunk, editGraphReportThunk), (state) => {
        state.saveReportStatus = 'rejected';
      });
  },
});

export const { updateReportName, updateModalType, setReportId, resetEditReportState } = EditReportSlice.actions;

export default EditReportSlice.reducer;
