import { UniqueIdentifier } from '@dnd-kit/core';
import { cn } from 'Utils/TailwindUtils';
import { SortableMetricCard } from './SortableMetricCard';
import { MetricReportConfigration } from 'src/api/apiTypes/Report';

export const MetricCardGridData = ({
  metricCards,
  overItemId,
  draggedItemId,
  setNodeRef,
  activeId,
}: {
  metricCards: MetricReportConfigration[];
  overItemId: UniqueIdentifier;
  draggedItemId?: UniqueIdentifier;
  setNodeRef?: (element: HTMLElement | null) => void;
  activeId?: UniqueIdentifier;
}) => {
  return (
    <div
      className={cn('my-2 grid grid-cols-2 gap-4', { 'h-28': metricCards.length === 0, 'h-56': metricCards.length === 2 })}
      ref={setNodeRef}
      onDragOver={(e) => e.preventDefault()}
    >
      {metricCards?.map((metric, idx) => (
        <div
          key={metric.id}
          data-tooltip-html="<span className='text-center'>Click to customize, add filters <br> and drag the card to reorder</span>"
          data-tooltip-id="tooltip"
          data-tooltip-hidden={!!draggedItemId || !!activeId}
        >
          <SortableMetricCard metric={metric} idx={idx} key={idx} isOver={overItemId === metric.id} />
        </div>
      ))}
      {overItemId === 'edit-metric-card-container' && metricCards.length < 4 && (
        <div className="h-28 rounded-xl border border-dashed border-indigo-600"></div>
      )}
    </div>
  );
};
