import { getAdminClientInstance } from './utils/clientAdapters';

export const verifyEmailApi = async (email: string, code: string) => {
  try {
    const key = 'user/confirm';
    const { data } = await getAdminClientInstance(key).post(key, { email, code });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
