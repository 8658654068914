import { getDataApiClientInstance } from './utils/clientAdapters';
import { FormatFiltersV4State, formatFiltersV4 } from './Tranformers';
import { IConvData, IConversationsMetadataFieldList } from 'Slices/types/timeseries';
import { IChartComparator } from 'src/types/Filter';
import { AxiosError } from 'axios';

export interface FetchConversationsProps {
  theme?: string[];
  topic?: string;
  diy_topic?: string;
  aspect?: string;
  page?: number;
  search?: string;
  unread?: boolean;
  starred?: boolean;
  dimension_values?: any;
  sentiment?: number;
  chartComparator?: IChartComparator;
  state?: FormatFiltersV4State;
}

export const fetchConversations = async ({
  theme,
  topic,
  diy_topic,
  aspect,
  page,
  search,
  unread,
  starred,
  dimension_values,
  sentiment = 0,
  state,
}: FetchConversationsProps) => {
  const params: any = { page };
  if (unread) params.read = false;
  if (starred) params.starred = true;

  try {
    const key = 'v4/data/conversations';
    const response = await getDataApiClientInstance(key).post<IConvData>(
      key,
      formatFiltersV4({ theme, topic, diy_topic, aspect, search, dimension_values, sentiment }, state),
      { params }
    );
    return { convdata: response.data, page };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const fetchConversationsMetadataField = async ({ source }) => {
  try {
    const key = 'v2/data/insights/all_fields';
    const response = await getDataApiClientInstance(key).post<{ data: IConversationsMetadataFieldList[] }>(key, { source });
    return { data: response.data, source: source };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const fetchConversationRecordingUrl = async (file: string) => {
  try {
    const key = 'v4/data/conversations/recording';
    const response = await getDataApiClientInstance(key).get<string>(key, { params: { file } });
    return response.data;
  } catch (error) {
    if ((error as AxiosError).response?.status === 404) {
      throw new Error('No call recording available');
    }
    throw new Error(error?.response?.data?.message);
  }
};
