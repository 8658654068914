import React, { lazy, Suspense } from 'react';
import { useAppSelector } from 'src/core/store';
import { ViewDropDown } from './ViewDropDown';
const ViewActions = lazy(() => import('./ViewActions'));

export const ViewDropDownContainer = () => {
  const metadataFilters = useAppSelector((state) => state.filter.filters.metadata);
  const selectedView = useAppSelector((state) => state.filterbookmarks.selectedView);
  return (
    <div className="flex gap-3">
      <ViewDropDown />
      {(metadataFilters?.length > 0 || selectedView?.id) && (
        <Suspense fallback={<></>}>
          <ViewActions />
        </Suspense>
      )}
    </div>
  );
};
