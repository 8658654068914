import { cn } from 'Utils/TailwindUtils';
import React, { FC } from 'react';
import { HiOutlineCheck } from 'react-icons/hi';

interface NodeLabelProps {
  label: string;
  isActive: boolean;
  command: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NodeLabel: FC<NodeLabelProps> = ({ label, isActive, command, setOpen }) => {
  const onClick = () => {
    command();
    setOpen(false);
  };
  return (
    <button className="flex items-center justify-between gap-3 rounded-md p-2 text-blue-1100 hover:bg-sky-1000" onClick={onClick}>
      <div>{label}</div>
      <HiOutlineCheck className={cn('text-xl opacity-0', { 'opacity-100': isActive })} />
    </button>
  );
};
