import React, { createElement, forwardRef } from 'react';
import type { IconType } from 'react-icons/lib';
import { cn } from 'Utils/TailwindUtils';
import { NavLink, NavLinkProps } from 'src/components/NavLink';
interface NavigationSidebarProps extends NavLinkProps {
  icon: IconType;
  title: string;
  description?: string;
  className?: string;
  isBeta?: boolean;
  asChild?: boolean;
  activeClassName?: string;
}

export const SidebarNavLink = forwardRef<HTMLAnchorElement, NavigationSidebarProps>(
  ({ icon, title, description, className, activeClassName, isBeta, asChild, ...props }, ref) => {
    return (
      <NavLink
        {...props}
        className={cn('flex rounded-full p-2 hover:bg-gray-650', className)}
        activeClassName={cn('bg-indigo-600 text-white hover:!bg-indigo-600', activeClassName)}
        data-tooltip-id="navigation-sidebar-tooltip"
        data-tooltip-description={description}
        data-tooltip-is-beta={isBeta}
        data-tooltip-content={title}
        ref={ref}
      >
        {createElement(icon, { strokeWidth: 2 })}
      </NavLink>
    );
  }
);
