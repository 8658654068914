import React, { useState } from 'react';
import { getTipTapExtensions } from './extensions';
import { EditorOptions, useEditor as useBaseEditor } from '@tiptap/react';
import { v4 as uuid } from 'uuid';

export const useEditor = (props?: Partial<EditorOptions>) => {
  const [editorId] = useState(uuid());
  const editor = useBaseEditor({
    editorProps: { attributes: { class: 'text-grey-900 prose-sm prose-headings:font-semibold prose-h4:text-2xl' } },
    extensions: getTipTapExtensions(editorId),
    ...props,
  });
  return { editor, editorId };
};
