import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { cn } from 'Utils/TailwindUtils';

export const MetricsListLoader = ({ count, className, height }: { count?: number; className?: string; height?: number }) => {
  return (
    <div className={cn('mt-3 flex flex-col gap-4 overflow-hidden rounded-xl', className)}>
      {Array.from({ length: count ?? 4 }).map((_, i) => (
        <Skeleton key={i} height={height ?? 126} />
      ))}
    </div>
  );
};
