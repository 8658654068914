export enum FilterOperations {
  is = 'is',
  isNot = 'is not',
  contains = 'contains',
  doesNotContains = 'does not contain',
}

export enum FilterRelations {
  and = 'and',
  or = 'or',
}

export enum FILTER_ACTION_TYPES {
  ADD = 'added',
  REMOVE = 'removed',
  UPDATE = 'updated',
  ADD_GROUP = 'added group',
  UPDATE_GROUP = 'updated group',
  OVERALL_CONDITION = 'overall condition',
  GROUP_CONDITION = 'group condition',
}

export enum FILTER_TYPES {
  DATA_SOURCE = 'data_source',
  METADATA = 'metadata',
  SENTISUM_TAG = 'sentisum_tag',
  DIY_TAG = 'diy_tag',
}
