import { cva, VariantProps } from 'class-variance-authority';
import React, { createElement, forwardRef } from 'react';
import type { IconType } from 'react-icons/lib';
import { HTMLButtonProps } from 'src/types/Globals';
import { cn } from 'Utils/TailwindUtils';

export const iconButtonCVA = cva(
  ['transition duration-500 ease-in disabled:cursor-not-allowed disabled:opacity-50 px-2 py-1 text-base grid place-tems-center'],
  {
    variants: {
      variant: {
        default: 'hover:bg-grey-100',
        danger: 'text-error-700 bg-error-50 hover:bg-error-100',
        primary: 'text-white bg-indigo-600 hover:bg-indigo-700',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

export interface IconButtonProps extends HTMLButtonProps, VariantProps<typeof iconButtonCVA> {
  icon: IconType;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({ icon, className, variant, ...rest }, ref) => {
  return (
    <button ref={ref} className={cn(iconButtonCVA({ variant }), className)} {...rest}>
      {createElement(icon)}
    </button>
  );
});
