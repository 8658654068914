import { useState, FocusEvent, useEffect, FC, PropsWithChildren, KeyboardEvent } from 'react';
import { cn } from 'Utils/TailwindUtils';
import { MdErrorOutline } from 'react-icons/md';
import { z } from 'zod';

interface EditableTextInputProps {
  value?: string;
  onChange: (value: string) => void;
  className?: string;
  schema?: z.ZodSchema<string>;
}

export const EditableTextInput: FC<PropsWithChildren<EditableTextInputProps>> = ({ value, onChange, className, children, schema = z.string() }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [editing, setEditing] = useState(false);
  const handleFocus = (e: FocusEvent<HTMLInputElement>) => e.target.select();
  const [valitationError, setValidationError] = useState('');
  const onBlur = () => {
    const zodResult = schema?.safeParse(currentValue);
    setValidationError(zodResult.success === false ? zodResult.error?.issues[0].message : '');
    if (!zodResult.success) return;
    else onChange(currentValue);
    setEditing(false);
  };

  useEffect(() => setCurrentValue(value), [value, editing]);

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    const zodResult = schema?.safeParse(e.currentTarget.value);
    setValidationError(zodResult.success === false ? zodResult.error?.issues[0].message : '');
    if (!zodResult.success) return;
    onChange(e.currentTarget.value);
    setEditing(false);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);
    const zodResult = schema?.safeParse(e.target.value);
    if (valitationError) setValidationError(zodResult.success === false ? zodResult.error?.issues[0].message : '');
  };
  return editing ? (
    <div>
      <div className="relative">
        <input
          onFocus={handleFocus}
          value={currentValue}
          onChange={handleValueChange}
          onBlur={onBlur}
          data-state="active"
          className={cn('w-64 rounded-md border-none border-blue-1100 bg-slate-170 p-2', className, { 'w-72 pr-8': valitationError })}
          autoFocus
          onKeyUp={onKeyUp}
        />
        {valitationError && <MdErrorOutline className="absolute right-3 top-3 h-4 w-4 text-red-500" />}
      </div>
      {valitationError && <p className="px-1 text-sm font-normal text-red-500">{valitationError}</p>}
    </div>
  ) : (
    <button onClick={() => setEditing(true)} data-state="inactive" className={cn('text-left', className)}>
      {children}
    </button>
  );
};
