import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getMetricCardConfigFromFilterData } from 'Slices/editMetricCard';
import { getMetricCardDataFromConfig } from 'src/api/dashboard/Report';
import { useAppSelector } from 'src/core/store';
import { OverviewLineGraph, SummaryGraphCardLoader } from '../../OverviewLineGraph';

export const EditMetricCardOverviewLineGraph = () => {
  const { selecedMetricCard, selectedMetricCardIndex, metricCards, chartIntervalBreakdown } = useAppSelector((state) => state.editMetricCard);
  const dateData = useAppSelector((state) => state.editMetricCard.dateRangeFilter);
  const config = getMetricCardConfigFromFilterData(selecedMetricCard, metricCards[selectedMetricCardIndex].id);
  const reportDataQuery = useQuery({
    queryKey: [
      'loadReportDatafromConfig',
      selecedMetricCard.chartComparator,
      selecedMetricCard.filters,
      selectedMetricCardIndex,
      chartIntervalBreakdown,
    ],
    queryFn: () => getMetricCardDataFromConfig(config, dateData, chartIntervalBreakdown),
  });

  if (reportDataQuery.isLoading)
    return (
      <div className="pl-2.5 pr-4">
        <SummaryGraphCardLoader className="pl-3" />
      </div>
    );
  return (
    <div className="h-[19rem] w-[36rem]">
      <OverviewLineGraph
        selectedSummaryGraphData={reportDataQuery?.data?.data}
        selectedMetricType={selecedMetricCard.chartMetricObject.name}
        fetchDataStatus={'fulfilled'}
        chartComparator={selecedMetricCard.chartComparator}
        className="mx-4 !mr-4"
      />
    </div>
  );
};
