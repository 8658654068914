export enum DIMENSION_TYPES {
  SENTISUM_TAG = 'topic',
  DIY_TAG = 'diy_topic',
  THEMES = 'themes',
}

export const SENTISUM_TAG_DIMENSION_OBJECT = {
  name: DIMENSION_TYPES.SENTISUM_TAG,
  display_name: 'Sentisum Tag',
  path: 'annotations.topic',
} as const;

export const DIY_TAG_DIMENSION_OBJECT = {
  name: DIMENSION_TYPES.DIY_TAG,
  display_name: 'DIY Tag',
  path: 'NO_PATH_DIY_TAG',
} as const;

export const THEME_DIMENSION_OBJECT = {
  name: DIMENSION_TYPES.THEMES,
  display_name: 'Themes',
  path: 'NO_PATH',
} as const;

export const DIMENSION_MAPPING = {
  [DIMENSION_TYPES.SENTISUM_TAG]: SENTISUM_TAG_DIMENSION_OBJECT,
  [DIMENSION_TYPES.DIY_TAG]: DIY_TAG_DIMENSION_OBJECT,
  [DIMENSION_TYPES.THEMES]: THEME_DIMENSION_OBJECT,
} as Record<string, { name: DIMENSION_TYPES; display_name: string; path: string }>;
