import { BarSkeleton } from 'src/components/SkeletonPlaceHolder';

export const SummaryCardLoader = () => {
  return (
    <div className="pt-2">
      <div className="ml-3 py-2">
        <BarSkeleton width="30%" />
      </div>
      <div className="my-6 grid grid-cols-[2fr,repeat(3,1fr)] gap-y-6 px-3">
        <BarSkeleton height={25} width="38%" />
        <BarSkeleton height={25} width="75%" count={7} />
        <BarSkeleton height={25} width="38%" />
        <BarSkeleton height={25} width="75%" count={3} />
        <BarSkeleton height={25} width="50%" /> <BarSkeleton height={25} width="75%" count={3} />
        <BarSkeleton height={25} width="50%" /> <BarSkeleton height={25} width="75%" count={3} />
      </div>
    </div>
  );
};
