import Fuse from 'fuse.js';
import { useEffect, useMemo, useState } from 'react';
import { IOption } from '../types';

export const useMatchedOptions = <T,>(
  options: IOption<T>[],
  searchString: string,
  intialSelected: T[] = [],
  useFuzzySearch = false,
  disableSorting = false
) => {
  const [matchedOptions, setMatchedOptions] = useState<IOption<T>[]>(options);
  const fuse = useMemo(() => new Fuse(options, { keys: ['label'], findAllMatches: true }), [options]);
  const searchOptions = () => {
    let value: IOption<T>[] = [];
    if (!searchString) value = disableSorting ? options : options.sort((a, b) => a.label.localeCompare(b.label));
    else {
      value = useFuzzySearch
        ? fuse.search(searchString).map((result) => result.item)
        : disableSorting
        ? options.filter((option) => option.label.toLowerCase().includes(searchString.toLowerCase()))
        : options.sort((a, b) => a.label.localeCompare(b.label)).filter((option) => option.label.toLowerCase().includes(searchString.toLowerCase()));
    }
    return value.sort((a, b) => {
      if (intialSelected.includes(a.value)) return -1;
      if (intialSelected.includes(b.value)) return 1;
      return 0;
    });
  };
  useEffect(() => setMatchedOptions(searchOptions), [options, searchString]);
  return matchedOptions;
};
