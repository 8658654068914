import { getFilterEventCommonProps, getDashboardEventCommonProps, getReportEventCommonProps } from './commonProps';
import { INTEGRATION_TYPES } from 'Enums/IntegrationSources';
import { trackEvent } from './trackers';
import { TRACK_EVENT } from './events';

export const trackFilterEvent = (eventName: TRACK_EVENT, properties: Record<string, any> = {}) =>
  trackEvent(eventName, { ...getFilterEventCommonProps(), ...properties });

export const trackDashboardEvent = (eventName: TRACK_EVENT, properties: Record<string, any> = {}) =>
  trackEvent(eventName, { ...getDashboardEventCommonProps(), ...properties });

export const trackReportEvent = (eventName: TRACK_EVENT, properties: Record<string, any> = {}) =>
  trackEvent(eventName, { ...getDashboardEventCommonProps(), ...getReportEventCommonProps(), ...properties });

export const trackIntegrationsEvent = (
  integration: INTEGRATION_TYPES,
  actionType: 'add' | 'remove' | 'update',
  status: 'request_success' | 'request_failure' | 'requested',
  isGeneric: boolean = false
) => trackEvent(TRACK_EVENT.APP_INTEGRATION, { Name: integration, Action_Type: actionType, Status: status, Is_Generic: isGeneric });
