import { Blank, IconProps } from 'grommet-icons';
import React from 'react';
import { CalendarImage } from '../images';

const CalendarIcon: React.FC<IconProps & JSX.IntrinsicElements['svg']> = (props) => {
  return (
    <Blank {...props}>
      <CalendarImage />
    </Blank>
  );
};

export default CalendarIcon;
