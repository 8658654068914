import { useCallback, useEffect, useState } from 'react';
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import { FilterValuesLabel } from './FilterValuesLabel';
import { RadixTextWithApply } from 'src/components/Dropdown';
import { useIndivisualFilterContext } from '../FilterGroup';
import { useFilterGroupContext } from '../../DataSourceFilterContainer';
import { useAppDispatch } from 'src/core/store';
import { cloneDeep } from 'lodash';
import { FilterOperations } from 'src/types/enums/Filter';
import { TransparentClose } from 'src/components/Button';
import { ValuesMuliselectDropdown } from './ValuesMuliselectDropdown';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { useFilterFieldContext } from '../IndivisualFilter';
import { useFilterContext } from '../../DataSourceFilters';

interface ValuesDropdownProps {
  removeFilter: () => void;
}

export const ValuesDropdown: React.FC<ValuesDropdownProps> = ({ removeFilter }) => {
  const dispatch = useAppDispatch();
  const { filter, filterIndex } = useIndivisualFilterContext();
  const { filterGroupIndex, dataSource } = useFilterGroupContext();
  const { displayName } = useFilterFieldContext();
  const { datasourceFilters, updateDataSourceFilters } = useFilterContext();
  const [menuOpen, setMenuOpen] = useState(true);
  useEffect(() => setMenuOpen(!filter.values.length), [filter.values]);

  const valuesCheck = useCallback(() => (filter.values.length && filter.values[0].length) || removeFilter(), [filter.values, removeFilter]);

  const applyFilter = useCallback(
    (values: string[]) => {
      const newDatasourceFilters = cloneDeep(datasourceFilters);
      newDatasourceFilters[dataSource][filterGroupIndex].conditionalFilters[filterIndex].values = values;
      dispatch(updateDataSourceFilters(newDatasourceFilters));
      setMenuOpen(false);
      trackFilterEvent(TRACK_EVENT.FILTER_ACTION, { Data_Source: dataSource, Action_Type: 'updated', Value: displayName, Options: values });
    },
    [datasourceFilters, dataSource, filterGroupIndex, filterIndex, dispatch]
  );
  const applySingleValueFilter = useCallback((value: string) => applyFilter([value]), [applyFilter]);

  const validateSingleValueFilter = useCallback((value: string) => {
    if (!value.trim().length) return 'Please enter a value';
    if (value.includes(' ')) return <span className="text-indigo-600">Spaces aren't allowed at the moment</span>;
    return undefined;
  }, []);

  return (
    <div className="relative">
      <DropdownPrimitive.Root open={menuOpen} onOpenChange={setMenuOpen}>
        <DropdownPrimitive.Trigger className="min-w-[1.5rem] p-2 text-left hover:bg-indigo-50 hover:text-indigo-600">
          <FilterValuesLabel values={filter.values} />
        </DropdownPrimitive.Trigger>
        <DropdownPrimitive.Portal>
          {[FilterOperations.contains, FilterOperations.doesNotContains].includes(filter.operation) ? (
            <RadixTextWithApply
              value={filter.values[0] ?? ''}
              onApply={applySingleValueFilter}
              menuOpen={menuOpen}
              onPointerDownOutside={valuesCheck}
              placeholder="Enter Value..."
              validate={validateSingleValueFilter}
            />
          ) : (
            <ValuesMuliselectDropdown applyFilter={applyFilter} valuesCheck={valuesCheck} menuOpen={menuOpen} />
          )}
        </DropdownPrimitive.Portal>
      </DropdownPrimitive.Root>
      <TransparentClose className="top-0 from-gray-650 to-gray-650/0 opacity-0 group-hover/filter:opacity-100" onClick={removeFilter} />
    </div>
  );
};
