import { cn } from 'Utils/TailwindUtils';
import React, { forwardRef } from 'react';
import { NavLink as RouterNavLink, NavLinkProps as RouterNavLinkProps } from 'react-router-dom';

export interface NavLinkProps extends RouterNavLinkProps {
  activeClassName?: string;
  className?: string;
}

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(({ children, className, activeClassName, ...rest }, ref) => {
  return (
    <RouterNavLink className={({ isActive }) => (isActive ? cn(activeClassName, className) : className)} {...rest} ref={ref}>
      {children}
    </RouterNavLink>
  );
});
