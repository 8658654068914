import { ButtonHTMLAttributes, useCallback, useMemo, useEffect } from 'react';
import { useAppSelector } from 'src/core/store';
import { cn } from 'Utils/TailwindUtils';
import { BiFilterAlt } from 'react-icons/bi';
import * as Dialog from 'src/components/Dialog/NestedDialog';
import { useKey, useToggle } from 'react-use';
import { FilterDialog } from './FilterDialog';
import { IDataSourceFilters } from 'src/types/Bookmark';

const getFilterCount = (dataSourceFilters?: IDataSourceFilters) => {
  if (!dataSourceFilters) return 0;
  return Object.values(dataSourceFilters).reduce((acc, filterGroup) => {
    return acc + filterGroup.reduce((acc, filter) => acc + filter.conditionalFilters.length, 0);
  }, 0);
};

interface FilterButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  containerHeight: number;
}

export const FilterButton: React.FC<FilterButtonProps> = ({ className, containerHeight, ...rest }) => {
  const { selectedView } = useAppSelector((state) => state.filterbookmarks);
  const dataSourceFilters = useAppSelector((state) => state.filter.bookmarkFilters?.datasourceFilters);
  const filterCount = useMemo(() => getFilterCount(dataSourceFilters), [dataSourceFilters]);
  const [open, setOpen] = useToggle(false);
  const closeModal = useCallback(() => setOpen(false), [setOpen]);
  useKey('Escape', closeModal);
  return (
    <Dialog.Root open={open} modal={false}>
      <Dialog.Trigger asChild>
        <button
          className={cn('filter-button', className)}
          {...rest}
          onClick={setOpen}
          data-tip={selectedView ? `Saved Filters: ${selectedView.name}` : null}
          data-tour-element="apply-filter-button"
        >
          <BiFilterAlt size={16} />
          <div className="max-w-[5.2rem] truncate">
            {selectedView ? (
              <span>Saved Filters: {selectedView?.name}</span>
            ) : filterCount > 0 ? (
              <span>Filters & Saved Filters</span>
            ) : (
              <span>Apply Filters</span>
            )}
          </div>
          {filterCount > 0 && <div className="flex h-5 w-5 items-center justify-center rounded-full bg-blue-1100 text-white">{filterCount}</div>}
        </button>
      </Dialog.Trigger>
      <Dialog.Content className="fixed z-50">
        <div className="fixed inset-0 left-16 bg-black opacity-50" onClick={closeModal} style={{ top: containerHeight + 85 }} />
        <FilterDialog toggleDialog={closeModal} containerHeight={containerHeight} />
      </Dialog.Content>
    </Dialog.Root>
  );
};
