import TEXT_TYPES from 'src/core/utils/constants/textTypes';

export enum LANG_TYPES {
  EN = 'EN',
}

const LANG = {
  [LANG_TYPES.EN]: {
    [TEXT_TYPES.SHORT]: 'EN',
    [TEXT_TYPES.MID]: 'ENG',
    [TEXT_TYPES.LONG]: 'English',
    [TEXT_TYPES.ALIAS]: 'British English',
  },
};

export { TEXT_TYPES, LANG };
