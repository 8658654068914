import React, { forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FormField } from 'src/components/Form';
import FormOtpInput, { IFormOtpInputProps } from './FormOtpInput';

interface IFormOtpInputFieldProps extends Omit<IFormOtpInputProps, 'value' | 'onChange'> {
  label: string;
  name: string;
}

const FormOtpInputField = forwardRef<HTMLDivElement, IFormOtpInputFieldProps>(({ label, name, ...rest }, ref) => {
  const context = useFormContext();
  const controller = useController({ name, control: context.control });
  return (
    <FormField label={label} name={name}>
      <FormOtpInput
        {...rest}
        value={controller.field.value}
        onChange={controller.field.onChange}
        className={controller.fieldState.invalid ? 'invalid' : ''}
      />
    </FormField>
  );
});

export default FormOtpInputField;
