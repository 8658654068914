import { SOURCE_TYPES } from 'Enums/SourceModelTypes';

export const SUMMARY_DRIVER_TYPES = {
  TOP_REASONS: 'top_reasons',
  TOP_DECREASES: 'top_decreases',
  TOP_INCREASES: 'top_increases',
  DRIVERS: 'drivers',
} as const;

export const SURVEY_DRIVERS_HEADER_NAME = {
  [SOURCE_TYPES.SURVEY_TYPE]: 'Sentiment (% positive mentions)',
  [SOURCE_TYPES.SUPPORT_TYPE]: 'No. Of Tickets',
  [SOURCE_TYPES.PERCENT_TICKET_TYPE]: '% Of Tickets',
} as const;

export const DRIVERS_TYPE_TEXTS = {
  [SUMMARY_DRIVER_TYPES.TOP_REASONS]: {
    [SOURCE_TYPES.SURVEY_TYPE]: {
      captionName: 'Biggest Changes In Sentiment',
    },
    [SOURCE_TYPES.SUPPORT_TYPE]: {
      captionName: 'Top Reasons For Contact',
    },
    [SOURCE_TYPES.CPO_TYPE]: {
      captionName: 'Top Reasons For Contact',
    },
    [SOURCE_TYPES.PERCENT_TICKET_TYPE]: {
      captionName: 'Top Reasons For Contact',
    },
    captionName: 'Top Drivers',
  },
  [SUMMARY_DRIVER_TYPES.TOP_DECREASES]: {
    [SOURCE_TYPES.SURVEY_TYPE]: {
      captionName: 'Top Positive Drivers',
    },
    [SOURCE_TYPES.SUPPORT_TYPE]: {
      captionName: 'Top Decreases',
    },
    [SOURCE_TYPES.CSAT_TYPE]: {
      captionName: 'Top Positive Drivers',
    },
    [SOURCE_TYPES.CPO_TYPE]: {
      captionName: 'Top Decreases',
    },
    [SOURCE_TYPES.PERCENT_TICKET_TYPE]: {
      captionName: 'Top Decreases',
    },
    captionName: 'Top Decreases',
  },
  [SUMMARY_DRIVER_TYPES.TOP_INCREASES]: {
    [SOURCE_TYPES.SURVEY_TYPE]: {
      captionName: 'Top Negative Drivers',
    },
    [SOURCE_TYPES.SUPPORT_TYPE]: {
      captionName: 'Top Increases',
    },
    [SOURCE_TYPES.CSAT_TYPE]: {
      captionName: 'Top Negative Drivers',
    },
    [SOURCE_TYPES.CPO_TYPE]: {
      captionName: 'Top Increases',
    },
    [SOURCE_TYPES.PERCENT_TICKET_TYPE]: {
      captionName: 'Top Increases',
    },
    captionName: 'Top Increases',
  },
  [SUMMARY_DRIVER_TYPES.DRIVERS]: {
    [SOURCE_TYPES.SURVEY_TYPE]: {
      captionName: 'Drivers',
    },
    [SOURCE_TYPES.SUPPORT_TYPE]: {
      captionName: 'Drivers',
    },
    [SOURCE_TYPES.CSAT_TYPE]: {
      captionName: 'Drivers',
    },
    [SOURCE_TYPES.CPO_TYPE]: {
      captionName: 'Drivers',
    },
    [SOURCE_TYPES.PERCENT_TICKET_TYPE]: {
      captionName: 'Drivers',
    },
    captionName: 'Drivers',
  },
} as const;
