import moment from 'moment';
import { IChartIntervalBreakDown } from 'src/types/Filter';
import { DateRange } from 'src/types/Globals';
import { getNoOfDaysFromRange } from 'Utils/dateOperations';
import { INTERVAL_BREAKDOWN_TYPES } from 'Utils/enums/chartIntervalBreakdownTypes';

export const getBreakDownIntevals = (dateRange: DateRange) => {
  if (!dateRange?.start || !dateRange?.end) return [];
  const start = moment(dateRange.start);
  const end = moment(dateRange.end);
  const noOfDays = getNoOfDaysFromRange(dateRange.start, dateRange.end);
  return [
    {
      label: 'Hourly',
      value: INTERVAL_BREAKDOWN_TYPES.HOUR,
      disabled: noOfDays > 1,
    },
    {
      label: 'Daily',
      value: INTERVAL_BREAKDOWN_TYPES.DAY,
      disabled: noOfDays < 2 || noOfDays >= 90,
    },
    {
      label: 'Weekly',
      value: INTERVAL_BREAKDOWN_TYPES.WEEK,
      disabled: noOfDays <= 7,
    },
    {
      label: 'Monthly',
      value: INTERVAL_BREAKDOWN_TYPES.MONTH,
      disabled: !(noOfDays > 28 && (Math.abs(end.month() - start.month()) > 0 || end.year() - start.year() !== 0)),
    },
  ];
};

const getDefaultBreakDown = (dateRange: DateRange) => {
  const noOfDays = getNoOfDaysFromRange(dateRange.start, dateRange.end);
  if (noOfDays <= 1) return INTERVAL_BREAKDOWN_TYPES.HOUR;
  if (noOfDays >= 2 && noOfDays <= 8) return INTERVAL_BREAKDOWN_TYPES.DAY;
  if (noOfDays > 8 && noOfDays <= 32) return INTERVAL_BREAKDOWN_TYPES.WEEK;
  return INTERVAL_BREAKDOWN_TYPES.MONTH;
};

export const setBreakDownOnDateRangeChange = (dateRange: DateRange, currentBreakDown?: IChartIntervalBreakDown) => {
  const breakDownIntervals = getBreakDownIntevals(dateRange);
  if (!currentBreakDown) return getDefaultBreakDown(dateRange);
  const currentBreakDownInterval = breakDownIntervals.find((interval) => interval.value === currentBreakDown);
  if (currentBreakDownInterval?.disabled) return getDefaultBreakDown(dateRange);
  return currentBreakDown;
};

export const getBreakDownLabel = (breakDown: IChartIntervalBreakDown) => {
  switch (breakDown) {
    case INTERVAL_BREAKDOWN_TYPES.HOUR:
      return 'Hourly';
    case INTERVAL_BREAKDOWN_TYPES.DAY:
      return 'Daily';
    case INTERVAL_BREAKDOWN_TYPES.WEEK:
      return 'Weekly';
    case INTERVAL_BREAKDOWN_TYPES.MONTH:
      return 'Monthly';
    default:
      return '';
  }
};
