import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { useScroll } from 'react-use';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { SourcesDropdown } from './SourcesDropdown';
import { FilterContainer, FilterContainerProps } from './FilterContainer';
import { NoSourceCard } from './NoSourceCard';
import { BarSkeleton } from 'src/components/SkeletonPlaceHolder';
import { useLDFlags } from 'Hooks/useLaunchDakly';
import { setDefaultFilter } from 'Slices/filter';

export const DashboardFiltersContainer: FC<PropsWithChildren<FilterContainerProps>> = ({ children, ...rest }) => {
  const dispatch = useAppDispatch();
  const { defaultSavedFilters } = useLDFlags();
  const { showSavedFilters } = useAppSelector((state) => state.filter);
  const srollContainerRef = useRef<HTMLDivElement>(null);
  const { y } = useScroll(srollContainerRef);
  const { fetchAvailbleDateRangeBoundsError, filters } = useAppSelector((state) => state.filter);

  useEffect(() => {
    if (defaultSavedFilters && showSavedFilters) {
      dispatch(setDefaultFilter(JSON.parse(defaultSavedFilters)));
    }
  }, [defaultSavedFilters]);

  return (
    <>
      {filters?.source ? <SourcesDropdown /> : <BarSkeleton width="8rem" />}
      {!fetchAvailbleDateRangeBoundsError ? (
        <>
          <FilterContainer {...rest} yScroll={y} />
          <div className="w-full grow overflow-auto border-t pb-16" ref={srollContainerRef}>
            <main className="flex min-h-max flex-col gap-1 px-3 pt-3" data-testid="discover-page">
              {children}
            </main>
          </div>
        </>
      ) : (
        <NoSourceCard />
      )}
    </>
  );
};
