import React, { useCallback, useMemo, useState } from 'react';
import * as Select from 'src/components/Select';
import { cn } from 'Utils/TailwindUtils';
import { SelectProps } from '@radix-ui/react-select';

interface RelationDropdownProps extends SelectProps {
  className?: string;
  options: string[];
  removeFilter: () => void;
  placeholder?: string;
}

export const RelationDropdown: React.FC<RelationDropdownProps> = ({ className, options, removeFilter, placeholder, ...rest }) => {
  const { value } = rest;
  const [open, setOpen] = useState(!value);
  const relationOptions = useMemo(() => options.map((option) => ({ value: option, label: option })), [options]);
  const valueCheck = useCallback(() => {
    if (!value) removeFilter();
  }, [removeFilter, value]);
  return (
    <div className="relative">
      <Select.Root open={open} onOpenChange={setOpen} {...rest}>
        <Select.Trigger className={cn('hover:bg-indigo-50 hover:text-indigo-600', { 'text-indigo-600': !value }, className)}>
          {value ?? placeholder}
        </Select.Trigger>
        <Select.RadixSingleSelectMenu options={relationOptions} onPointerDownOutside={valueCheck} className="min-w-20" />
      </Select.Root>
    </div>
  );
};
