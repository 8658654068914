import React from 'react';
import { cn } from 'Utils/TailwindUtils';
import { ReactTooltipContainer, ExtendedTooltipProps } from './ReactToolipContainer';

export const WhiteTextTooltip: React.FC<ExtendedTooltipProps> = ({ className, ...rest }) => (
  <ReactTooltipContainer
    plain
    className={cn('z-50 !rounded-lg !bg-white !px-3 !py-1 !text-blue-1100 !shadow-md before:!content-none after:!content-none', className)}
    {...rest}
  />
);
