const HTTP_RESPONSE_STATUS = {
  SUCCESS: {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NON_AUTHORITATIVE: 203,
    NO_CONTENT: 204,
    RESET_CONTENT: 205,
  },
  REDIRECT: {
    MULTI_CHOICE: 300,
    MOVED_PERMANENTLY: 301,
    FOUND: 302,
    SEE_OTHER: 303,
    NOT_MODIFIED: 304,
    USE_PROXY: 305,
  },
  CLIENT_ERROR: {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    REQUEST_TIMEOUT: 408,
    TOO_MANY_REQUESTs: 429,
  },
  SERVER_ERROR: {
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
  },
};
const HTTP_ERROR_MESSAGES = {
  NETWORK_ERROR: 'Network Error',
  ERR_CANCELED: 'canceled',
};

enum FILTER_VIEW_METADATA_DEFAULT_OPTIONS {
  ALL = 'All',
  SENTISUM_TAG = 'SentiSum Tags',
  DIY_TAG = 'DIY Tags',
  Theme = 'Theme',
}

enum FILTER_VIEW_METADATA_GROUP_OPTIONS {
  SENTISUM_TAG = 'SentiSum Tags',
  DIY_TAG = 'DIY Tags',
  Theme = 'Theme',
}

enum FILTER_VIEW_METADATA_SURVEY_GROUP_OPTIONS {
  SENTISUM_TAG = 'SentiSum Tags',
}

enum BOOKMARK_DATASOURCE_ATTRIBUTES {
  FILTERS = 'filters',
  TOPICS = 'topics',
  DIY_TOPICS = 'diy_topics',
}

enum BOOKMARKED_VIEWS_KEY {
  BOOKMARK = 'bookmark',
  ALL = 'all',
  Your_Views = 'Your Views',
  Others_Views = 'Other Views',
}

const BOOKMARK_VIEWS_TYPE = {
  [BOOKMARKED_VIEWS_KEY.Your_Views]: {
    label: 'Your Views',
    key: BOOKMARKED_VIEWS_KEY.Your_Views,
  },
  [BOOKMARKED_VIEWS_KEY.Others_Views]: {
    label: 'Other Views',
    key: BOOKMARKED_VIEWS_KEY.Others_Views,
  },
  [BOOKMARKED_VIEWS_KEY.BOOKMARK]: {
    label: 'Bookmarked Views',
    key: BOOKMARKED_VIEWS_KEY.BOOKMARK,
  },
  [BOOKMARKED_VIEWS_KEY.ALL]: {
    label: 'All Views',
    key: BOOKMARKED_VIEWS_KEY.ALL,
  },
};

enum SENTISUM_TAG_TYPES {
  SENTISUM_TAG = 'topic',
  DIY_TAG = 'diy_topic',
  THEME = 'themes',
}

const NETWORK_ERROR = 'Something went wrong. Please try again.';
const SOMETHING_WRONG = 'Something went wrong. Please try again.';

export enum FILTER_TOPIC_MATCH_TYPES {
  ALL = 'all',
  EXACT = 'exact',
}

export const FILTER_TOPIC_MATCH_TYPE_OPTIONS = [
  {
    value: FILTER_TOPIC_MATCH_TYPES.ALL,
    label: 'Co-ocurrence',
  },
  {
    value: FILTER_TOPIC_MATCH_TYPES.EXACT,
    label: 'Exact',
  },
];

export {
  HTTP_RESPONSE_STATUS,
  HTTP_ERROR_MESSAGES,
  FILTER_VIEW_METADATA_DEFAULT_OPTIONS,
  BOOKMARK_DATASOURCE_ATTRIBUTES,
  BOOKMARK_VIEWS_TYPE,
  SENTISUM_TAG_TYPES,
  NETWORK_ERROR,
  SOMETHING_WRONG,
  BOOKMARKED_VIEWS_KEY,
  FILTER_VIEW_METADATA_GROUP_OPTIONS,
  FILTER_VIEW_METADATA_SURVEY_GROUP_OPTIONS,
};
