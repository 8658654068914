import { CustomText } from 'Static/icons';
import { FC } from 'react';
import { IChartData } from '../BarGraphCards/HorizontalBarGraph';

export interface CustomLabelProps {
  x?: number | string;
  y?: number | string;
  value?: number | string;
  data: IChartData[];
  name?: string;
  dataKey: string;
  width?: number | string;
  height?: number | string;
}

export const VerticalBarCustomLabel: FC<CustomLabelProps> = ({ x, y, data, name }) => {
  const selectedBar = data.find((d) => d.name === name);
  const adjustedY = selectedBar.data < 0 ? +y + 17 : +y - 10;
  return <CustomText x={+x} y={adjustedY} value={selectedBar.dataLabel} className="text-xs" />;
};

export const HorizontalBarCustomLabel: FC<CustomLabelProps> = ({ x, y, width, data, name }) => {
  const selectedBar = data.find((d) => d.name === name);
  const adjustedX = selectedBar.data < 0 ? +x + +width - 35 : +x + +width + 15;
  return <CustomText x={adjustedX} y={+y + 12} value={selectedBar.dataLabel} textAnchor="start" />;
};
