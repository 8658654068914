import React, { PropsWithChildren } from 'react';
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import { useToggle } from 'react-use';
import DateRangePicker, { IDateRangePickerProps } from './DateRangePicker';
import { cn } from 'Utils/TailwindUtils';
import { FiChevronDown } from 'react-icons/fi';

interface DateRangePickerRootProps extends Omit<IDateRangePickerProps, 'toggleDropdown'> {
  className?: string;
}

export const DateRangePickerRoot: React.FC<PropsWithChildren<DateRangePickerRootProps>> = ({ children, className, ...rest }) => {
  const [open, toggleOpen] = useToggle(false);
  return (
    <DropdownPrimitive.Root open={open} onOpenChange={toggleOpen}>
      <DropdownPrimitive.Trigger className={cn('flex items-center justify-center gap-1', className)}>
        {children}
        <FiChevronDown />
      </DropdownPrimitive.Trigger>
      <DropdownPrimitive.Portal>
        <DropdownPrimitive.Content className="radix-dropdown-content min-w-[43rem]" side="bottom" align="start" forceMount>
          <DateRangePicker toggleDropdown={toggleOpen} {...rest} />
        </DropdownPrimitive.Content>
      </DropdownPrimitive.Portal>
    </DropdownPrimitive.Root>
  );
};
