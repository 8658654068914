import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { bookmarkUserView, fetchSavedViews, saveUserView, unbookmarkUserView, updateUserView } from 'src/api/Views';
import { IFilterView, ISaveViewData, IUpdateViewData } from 'src/types/Views';
import { IApiCallStatus } from 'src/types/Globals';
import { IBookmark } from 'src/types/Bookmark';

export interface IViewError {
  message: string;
  type: VIEW_ERROR_TYPE;
  action: VIEW_ACTION_TYPE;
}

interface IFilterBookmarksState {
  fetchAvailableBookmarkViewsStatus: IApiCallStatus;
  saveViewStatus: IApiCallStatus;
  updateViewStatus: IApiCallStatus;
  bookmarks: IBookmark;
  selectedView?: IFilterView;
  isViewValidated: boolean;
  isViewFilterChanged: boolean;
  isSelectedViewNameChangedForUpdate: boolean;
  isViewSearchStringChangedForUpdate: boolean;
  isViewChanged: boolean;
  responseErrorInfo?: IViewError;
}

export enum VIEW_ERROR_TYPE {
  VIEW_NOT_EXIST = 'VIEW_NOT_EXIST',
  VIEW_NAME_ALREADY_EXIST = 'VIEW_NAME_ALREADY_EXIST',
}

export enum VIEW_ACTION_TYPE {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

const VIEW_ERRORS = {
  [VIEW_ERROR_TYPE.VIEW_NOT_EXIST]: {
    message: 'Saved filter does not exist.',
    type: VIEW_ERROR_TYPE.VIEW_NOT_EXIST,
  },
  [VIEW_ERROR_TYPE.VIEW_NAME_ALREADY_EXIST]: {
    message: 'Saved filter with same name exists.',
    type: VIEW_ERROR_TYPE.VIEW_NAME_ALREADY_EXIST,
  },
};
export const fetchSavedBookmarks = createAsyncThunk<IFilterView[], string>('bookmark/fetch', (source) => fetchSavedViews(source));

export const saveView = createAsyncThunk<IFilterView, ISaveViewData>('bookmark/create', ({ name, source, filters }) =>
  saveUserView(name, source, filters)
);
export const updateView = createAsyncThunk<IFilterView, IUpdateViewData>('bookmark/update', ({ id, name, filters }) =>
  updateUserView(id, name, filters)
);

export const bookmarkView = createAsyncThunk<number, { id: number }>('bookmark/userbookmarks', ({ id }) => bookmarkUserView(id));
export const unbookmarkView = createAsyncThunk<number, { id: number }>('bookmark/userunbookmarks', ({ id }) => unbookmarkUserView(id));

const toggleBookmarkOfView = (state: IFilterBookmarksState, id: number, status: boolean) => {
  if (state.bookmarks.all)
    state.bookmarks.all = state.bookmarks.all.map((view) => ({ ...view, is_bookmarked: view.id === id ? status : view.is_bookmarked }));
};

const initialStateAPIStatus = {
  fetchAvailableBookmarkViewsStatus: 'idle',
  saveViewStatus: 'idle',
  updateViewStatus: 'idle',
};
const initialState: IFilterBookmarksState = {
  fetchAvailableBookmarkViewsStatus: 'idle',
  saveViewStatus: 'idle',
  updateViewStatus: 'idle',
  bookmarks: {},
  selectedView: null,
  isViewValidated: false,
  isViewFilterChanged: false,
  isSelectedViewNameChangedForUpdate: false,
  isViewSearchStringChangedForUpdate: false,
  isViewChanged: false,
  responseErrorInfo: null,
};

const ServicesFilterBookmarks = createSlice({
  name: 'filterbookmarks',
  initialState: { ...initialState },
  reducers: {
    resetFilterBookmarksReduxState: (state) => Object.assign(state, { ...initialState }),
    resetFilterBookmarksReduxStateAPIStatus: (state) => Object.assign(state, { ...initialStateAPIStatus }),
    updateSelectedView: (state, action?: PayloadAction<IFilterView>) => {
      state.isViewChanged = true;
      state.isViewFilterChanged = false;
      state.isSelectedViewNameChangedForUpdate = false;
      state.isViewSearchStringChangedForUpdate = false;
      state.selectedView = action.payload;
    },
    viewChanged: (state) => {
      state.isViewChanged = false;
    },
    viewFilterChanged: (state, action: PayloadAction<boolean>) => {
      state.isViewFilterChanged = action.payload;
    },
    selectedViewNameChanged: (state, action: PayloadAction<boolean>) => {
      state.isSelectedViewNameChangedForUpdate = action.payload;
    },
    selectedViewSearchStringChanged: (state, action: PayloadAction<boolean>) => {
      state.isViewSearchStringChangedForUpdate = action.payload;
    },
    resetStatus: (state) => {
      state.saveViewStatus = 'idle';
      state.updateViewStatus = 'idle';
    },
    viewValidationStatus: (state, action: PayloadAction<boolean>) => {
      state.isViewValidated = action.payload;
    },
    deleteView: (state, action: PayloadAction<number>) => {
      if (state.bookmarks.all) state.bookmarks.all = state.bookmarks.all.filter((view) => view.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSavedBookmarks.pending, (state) => {
        state.bookmarks = {};
        state.isViewValidated = false;
        state.fetchAvailableBookmarkViewsStatus = 'pending';
      })
      .addCase(fetchSavedBookmarks.fulfilled, (state, action) => {
        const views = { all: action.payload };
        state.bookmarks = views;
        state.fetchAvailableBookmarkViewsStatus = 'fulfilled';
      })
      .addCase(fetchSavedBookmarks.rejected, (state, action) => {
        state.fetchAvailableBookmarkViewsStatus = 'rejected';
        state.isViewValidated = true;
      })
      .addCase(saveView.pending, (state) => {
        state.saveViewStatus = 'pending';
      })
      .addCase(saveView.fulfilled, (state, action) => {
        state.bookmarks['all'] = [...state.bookmarks['all'], action.payload];
        state.isViewFilterChanged = false;
        state.isSelectedViewNameChangedForUpdate = false;
        state.isViewSearchStringChangedForUpdate = false;
        state.selectedView = { ...action.payload };
        state.saveViewStatus = 'fulfilled';
      })
      .addCase(saveView.rejected, (state, action) => {
        if (action?.error?.message === VIEW_ERRORS[VIEW_ERROR_TYPE.VIEW_NAME_ALREADY_EXIST].message)
          state['responseErrorInfo'] = {
            ...VIEW_ERRORS[VIEW_ERROR_TYPE.VIEW_NAME_ALREADY_EXIST],
            action: VIEW_ACTION_TYPE.CREATE,
          };
        else state.responseErrorInfo = null;
        state.saveViewStatus = 'rejected';
      })
      .addCase(updateView.pending, (state) => {
        state.updateViewStatus = 'pending';
      })
      .addCase(updateView.fulfilled, (state, action) => {
        let views: IFilterView[] = [];
        let viewIndex = -1;
        let view: IFilterView;
        if (state.bookmarks['all'] && state.bookmarks?.all?.length > 0) {
          views = [...state.bookmarks.all];
          viewIndex = -1;
          view = views.find((view, index) => {
            if (action.payload.id === view.id) {
              viewIndex = index;
              return true;
            }
          });
          if (view && viewIndex > -1 && viewIndex < views.length) {
            views.splice(viewIndex, 1, action.payload);
            state.bookmarks['all'] = views;
          }
        }
        if (state.selectedView['id'] === action.payload.id) {
          state.selectedView = action.payload;
          state.isViewFilterChanged = false;
          state.isSelectedViewNameChangedForUpdate = false;
          state.isViewSearchStringChangedForUpdate = false;
        }
        state.updateViewStatus = 'fulfilled';
      })
      .addCase(updateView.rejected, (state, action) => {
        if (action?.error?.message === VIEW_ERRORS[VIEW_ERROR_TYPE.VIEW_NOT_EXIST].message)
          state.responseErrorInfo = { ...VIEW_ERRORS[VIEW_ERROR_TYPE.VIEW_NOT_EXIST], action: VIEW_ACTION_TYPE.UPDATE };
        else if (action?.error?.message === VIEW_ERRORS[VIEW_ERROR_TYPE.VIEW_NAME_ALREADY_EXIST].message)
          state.responseErrorInfo = {
            ...VIEW_ERRORS[VIEW_ERROR_TYPE.VIEW_NAME_ALREADY_EXIST],
            action: VIEW_ACTION_TYPE.UPDATE,
          };
        else state.responseErrorInfo = null;
        state.updateViewStatus = 'rejected';
      })
      .addCase(bookmarkView.fulfilled, (state, action) => toggleBookmarkOfView(state, action.payload, true))
      .addCase(unbookmarkView.fulfilled, (state, action) => toggleBookmarkOfView(state, action.payload, false));
  },
});

const persistConfig = {
  key: 'filterbookmarks',
  storage,
  whitelist: ['selectedView', 'bookmarks'],
};
export const {
  resetFilterBookmarksReduxState,
  resetFilterBookmarksReduxStateAPIStatus,
  updateSelectedView,
  resetStatus,
  viewChanged,
  viewFilterChanged,
  selectedViewNameChanged,
  selectedViewSearchStringChanged,
  viewValidationStatus,
  deleteView,
} = ServicesFilterBookmarks.actions;
export default persistReducer(persistConfig, ServicesFilterBookmarks.reducer);
