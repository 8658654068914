import { CustomSummayGraphTooltip } from './CustomSummayGraphTooltip';
import { FC, useMemo } from 'react';
import { XAxis, YAxis, Tooltip } from 'recharts';
import { SummaryGraphProps } from '../types';
import { getColorOverDataChange } from 'Utils';
import { BaseLineGraph } from '../BaseLineGraph';
import './styles.css';
import { FiAlertTriangle } from 'react-icons/fi';
import { getBreakDownLabel } from 'Utils/constants/BreakDownOptions';

const defaultChartProps = { margin: { top: 10, right: 60, left: 20, bottom: 0 } };
const color = '#BCB7B3';

export const SummaryGraph: FC<SummaryGraphProps> = (props) => {
  const { chartProps, selectedMetricType, changeValue, YAxisMeta, chartComparator, tooltipProps, valueOverTime, ...rest } = props;
  const chartColor = useMemo(
    () => (!Number.isNaN(changeValue) ? getColorOverDataChange(selectedMetricType, changeValue) : color),
    [changeValue, selectedMetricType]
  );
  const renderTooltip = (props) => {
    const newProps = { ...props, yAxisLabel: YAxisMeta.label, chartColor, selectedMetricType, chartComparator };
    if (props.active && props.payload && props.payload.length > 0) return <CustomSummayGraphTooltip {...newProps} />;
    else return null;
  };

  const compartorLabel = getBreakDownLabel(valueOverTime.data[0]?.dataMeta.interval).toLocaleLowerCase();
  return (
    <div className={valueOverTime.isDataNull ? 'relative h-full w-full overflow-hidden' : 'contents'}>
      {valueOverTime.isDataNull && (
        <div
          className="absolute inset-0 z-50 flex w-full flex-col items-center justify-center gap-4 bg-white/80 text-grey-500 backdrop-blur-[2px]"
          style={{ height: rest.height }}
        >
          <FiAlertTriangle className="text-2xl" />
          <div className="text-xs ">No {compartorLabel} data available</div>
        </div>
      )}
      <BaseLineGraph size="large" chartProps={chartProps ?? defaultChartProps} valueOverTime={valueOverTime} {...rest}>
        <XAxis dataKey="date" interval="preserveStartEnd" axisLine tickLine={false} tick={{ fontSize: 12 }} allowDecimals={false} />
        <Tooltip
          wrapperStyle={{ zIndex: '9999', paddingLeft: '5rem' }}
          allowEscapeViewBox={{ x: true, y: true }}
          content={renderTooltip}
          offset={-70}
          {...tooltipProps}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) => value.toLocaleString()}
          tick={{ fontSize: 12 }}
          domain={['auto', 'auto']}
          allowDecimals={YAxisMeta.isDecimal}
          padding={{ top: 10 }}
        />
      </BaseLineGraph>
    </div>
  );
};
