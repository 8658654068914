import { Box, Text } from 'grommet';

const SummaryError = () => {
  return (
    <Box pad="medium" justify="center" height="100%">
      <Text textAlign="center" size="small" style={{ fontStyle: 'italic' }}>
        Could not load data, please refresh...
      </Text>
    </Box>
  );
};

export default SummaryError;
