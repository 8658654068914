import React, { ForwardedRef, useCallback, useMemo } from 'react';
import { useIndivisualFilterContext } from '../FilterGroup';
import { RadixApiMultiSelectWithApply, RadixMultipleSelectWithApply } from 'src/components/Dropdown';
import { getFilterApiSearchResult } from 'src/api/Filters';
import { useFilterFieldContext } from '../IndivisualFilter';
import { useFilterContext } from '../../DataSourceFilters';

interface ValuesMuliselectDropdownProps {
  applyFilter: (values: string[]) => void;
  valuesCheck: () => void;
  menuOpen: boolean;
}

const ValuesMuliselectDropdownComponent = (
  { applyFilter, valuesCheck, menuOpen }: ValuesMuliselectDropdownProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const { filter } = useIndivisualFilterContext();
  const { values } = filter;
  const { filterState: filters } = useFilterContext();
  const { displayName, options, all } = useFilterFieldContext();
  const currentOptions = useMemo(() => options.map((option) => ({ label: option.value, value: option.value })), [options]);
  const apiSearch = useCallback(
    async (searchString: string) => {
      const optionValues = await getFilterApiSearchResult(searchString, filter, filters);
      return optionValues.map((option) => ({ label: option.value, value: option.value }));
    },
    [currentOptions]
  );

  return (
    <>
      {all ? (
        <RadixMultipleSelectWithApply
          searchPlaceholder={`Search ${displayName}...`}
          options={currentOptions}
          selectedOptions={values}
          menuOpen={menuOpen}
          onApply={applyFilter}
          ref={ref}
          onPointerDownOutside={valuesCheck}
        />
      ) : (
        <RadixApiMultiSelectWithApply
          searchPlaceholder={`Search ${displayName}...`}
          options={currentOptions}
          selectedOptions={values}
          menuOpen={menuOpen}
          onApply={applyFilter}
          apiSearch={apiSearch}
          ref={ref}
          onPointerDownOutside={valuesCheck}
        />
      )}
    </>
  );
};

export const ValuesMuliselectDropdown = React.forwardRef(ValuesMuliselectDropdownComponent);
