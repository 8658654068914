import React, { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { cn } from 'Utils/TailwindUtils';
import { FormField } from '../Form';

interface FormTextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label: React.ReactNode;
  children?: React.ReactNode;
}

export const FormTextArea = forwardRef<HTMLTextAreaElement, FormTextAreaProps>(({ label, name, className, children, ...props }, ref) => {
  const formContext = useFormContext();
  const error = formContext.formState.errors[name ?? ''];
  return (
    <FormField label={label} name={name ?? ''}>
      <div className={cn('flex justify-between rounded-lg border', { 'border-red-900': error }, className)}>
        <textarea
          ref={ref}
          name={name}
          className="h-32 w-full resize-none overflow-auto rounded-lg bg-white p-3 text-blue-1100 placeholder-gray-850 focus:outline-none disabled:text-opacity-50"
          aria-invalid={!!error}
          {...props}
        />
        {children}
      </div>
    </FormField>
  );
});
