import { casex } from 'casex';
import React, { FC } from 'react';

interface TickValuesProps {
  x: number;
  y: number;
  value: string;
  isHorizontalBarGraph?: boolean;
  onClick?: (name) => void;
}

export const TickValues: FC<TickValuesProps> = ({ x, y, value, isHorizontalBarGraph, onClick }) => {
  const words = value.split(' ');
  const lines = [];
  let currentLine = '';

  for (const word of words) {
    if ((currentLine + ' ' + word).trim().length > (!isHorizontalBarGraph ? 18 : 20)) {
      if (word.length >= 25) lines.push(`${word.slice(0, 19)}..`);
      else {
        lines.push(currentLine.trim());
        currentLine = word;
      }
    } else {
      if (currentLine !== '') currentLine += ' ';
      currentLine += word;
    }
  }
  if (currentLine !== '') lines.push(currentLine.trim());
  const displayLines = lines.length > 2 ? lines.slice(0, 2) : lines;
  const ellipsis = lines.length > 2 ? '..' : '';
  if (isHorizontalBarGraph) {
    return (
      <text
        x={x}
        y={y}
        textAnchor="start"
        fill="#666"
        className="cursor-pointer text-xs"
        data-tooltip-id={ellipsis === '..' ? 'tooltip' : ''}
        data-tooltip-content={value}
        data-tooltip-class-name="capitalize"
        onClick={() => onClick(value)}
      >
        {displayLines.map((line, index) => (
          <tspan key={index} x={0} dy={index * 16}>
            {casex({ text: line, pattern: 'Ca Se' })}
          </tspan>
        ))}
        {ellipsis}
      </text>
    );
  } else {
    return (
      <g transform={`translate(${x},${y})`} onClick={() => onClick(value)}>
        <text x={x} y={4} fill="#666" textAnchor="middle" className="cursor-pointer text-xxs capitalize">
          {displayLines.map((line, index) => (
            <tspan key={index} x={0} dy={index * 16}>
              {casex({ text: line, pattern: 'Ca Se' })}
            </tspan>
          ))}
          {ellipsis}
        </text>
      </g>
    );
  }
};
