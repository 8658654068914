import * as Dialog from 'src/components/Dialog/NestedDialog';
import { PropsWithChildren, useCallback, useState } from 'react';
import { InviteForm } from './InviteForm';
import { cn } from 'Utils/TailwindUtils';
import { InviteStatus } from './InviteStatus';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { inviteTeamMembers } from 'src/api/Team';
import { TRACK_EVENT, trackEvent } from 'src/core/trackEvent';

export const UserInvite: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [isInviteSettled, setIsInviteSettled] = useState(false);
  const queryClient = useQueryClient();
  const userInviteMutation = useMutation({
    mutationKey: ['userInvite'],
    mutationFn: inviteTeamMembers,
    onSettled: () => setIsInviteSettled(true),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['teamsMembers'] }),
    onMutate: (data) => trackEvent(TRACK_EVENT.USER_INVITE, { inviteCount: data.length }),
  });

  const handleOpenChange = useCallback((isOpen: boolean) => {
    setShowDialog(isOpen);
    setIsInviteSettled(false);
    userInviteMutation.reset();
  }, []);

  return (
    <Dialog.Root open={showDialog} onOpenChange={handleOpenChange}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.NestedDialogContent isOpen={showDialog} className={cn('rounded-xl p-6', isInviteSettled ? 'max-w-[25rem]' : 'max-w-[36rem]')}>
        {isInviteSettled ? <InviteStatus status={userInviteMutation.status} /> : <InviteForm onInvite={userInviteMutation.mutate} />}
      </Dialog.NestedDialogContent>
    </Dialog.Root>
  );
};
