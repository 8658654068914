import { Editor } from '@tiptap/react';
import { PropsWithChildren, createContext, useContext, useState } from 'react';
import './styles.css';

type EditorContextType = { editor: Editor; editorId: string };

const EditorContext = createContext<EditorContextType | null>(null);

export const EditorContextProvider: React.FC<PropsWithChildren<EditorContextType>> = ({ children, ...value }) => (
  <EditorContext.Provider value={value}>{children}</EditorContext.Provider>
);

export const useEditorContext = () => {
  const context = useContext(EditorContext);
  if (!context) throw new Error('useEdidorContext must be used within a EditorContext');
  return context;
};
