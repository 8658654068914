import { SentisumLogoFill } from 'Static/images';
import React from 'react';
import { NavLink } from 'src/components/NavLink';
import { ReactTooltipContainer } from 'src/components/Tooltip';
import { useWhiteLabel } from 'src/context/WhiteLabelContext';

export const SidebarLogo = () => {
  const whiteLabel = useWhiteLabel();
  if (!whiteLabel.isWhiteLabel)
    return (
      <NavLink to="/" className="font-sherika text-lg font-semibold not-italic leading-3 text-gray-900">
        <h6>senti</h6>
        <h6 className="tracking-wide">sum.</h6>
      </NavLink>
    );
  return (
    <NavLink to="/">
      <img src={whiteLabel.cdnFolder.concat('dashboard-logo.png')} alt="logo" width={54} height={54} data-tooltip-id="sidebar-logo" />
      <ReactTooltipContainer id="sidebar-logo" place="right" className="!ml-1 !rounded-lg !bg-white !px-3 !py-1 !shadow-md after:!bg-white" plain>
        <div className="flex items-center gap-2 text-xs font-semibold text-grey-700">
          Powered by <SentisumLogoFill />
        </div>
      </ReactTooltipContainer>
    </NavLink>
  );
};
