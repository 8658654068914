import Lottie from 'lottie-react';
import React, { useCallback, useState } from 'react';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi2';
import { useCopyToClipboard } from 'react-use';
import greenCheckAnimation from 'Static/animations/green-check.json';

interface CopyLinkProps {
  link?: string;
}

export const CopyLink: React.FC<CopyLinkProps> = ({ link }) => {
  const [_, copyToClipboard] = useCopyToClipboard();
  const [showCopiedAnimation, setShowCopiedAnimation] = useState(false);
  const copySummary = useCallback(() => {
    copyToClipboard(link);
    setShowCopiedAnimation(true);
  }, []);

  return (
    <div className="shrink-0">
      {showCopiedAnimation ? (
        <div className="p-1.5">
          <Lottie animationData={greenCheckAnimation} loop={false} className="h-6 w-6" onComplete={() => setShowCopiedAnimation(false)} />
        </div>
      ) : (
        <button className="flex items-center justify-center rounded-md p-2 text-xl hover:bg-grey-100 hover:text-grey-900" onClick={copySummary}>
          <HiOutlineDocumentDuplicate />
        </button>
      )}
    </div>
  );
};
