import React, { FC } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { ColorCheckbox } from './ColorCheckbox';
import { useEditorContext } from '../../EditorContext';

const colourOptions = ['#101828', '#475467', '#444CE7', '#D92D20', '#039855'];

interface ColorSelectorProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ColorSelector: FC<ColorSelectorProps> = ({ open, setOpen }) => {
  const { editor } = useEditorContext();
  const activeColor = colourOptions.find((color) => editor.isActive('textStyle', { color })) ?? colourOptions[0];
  return (
    <div className="relative">
      <button
        className="flex items-center gap-0.5 rounded-md p-2 hover:bg-grey-100 radix-state-open:bg-grey-100"
        onClick={() => setOpen(!open)}
        data-state={open ? 'open' : 'closed'}
      >
        <div className="px-2 drop-shadow-xl">
          <div className="h-5 w-5 rounded-full ring-2 ring-white" style={{ backgroundColor: activeColor }} />
        </div>
        <FiChevronDown className="text-xl text-grey-500" />
      </button>
      {open && (
        <section className="radix-dropdown-content fixed top-full flex items-center p-2.5">
          {colourOptions.map((colour) => (
            <ColorCheckbox key={colour} colour={colour} checked={activeColor === colour} setOpen={setOpen} />
          ))}
        </section>
      )}
    </div>
  );
};
