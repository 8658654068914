import React, { createElement, useCallback, useMemo } from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { HiOutlineLifebuoy } from 'react-icons/hi2';
import * as HoverCard from '@radix-ui/react-hover-card';
import { TRACK_EVENT, trackEvent } from 'src/core/trackEvent';

export const HelpContainer = () => {
  const HelpCenterClick = useCallback(() => {
    trackEvent(TRACK_EVENT.OPEN_HELP_CENTER);
    window.open('https://help.sentisum.com', '_blank');
  }, []);

  const options = useMemo(
    () => [
      {
        label: 'Help Guide',
        icon: HiOutlineLifebuoy,
        onClick: HelpCenterClick,
      },
    ],
    []
  );

  return (
    <HoverCard.Root openDelay={300}>
      <HoverCard.Trigger asChild>
        <button className="flex rounded-full p-2 hover:bg-gray-650">
          <FiHelpCircle />
        </button>
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content side="right" align="center" sideOffset={15} className="radix-dropdown-content flex min-w-[10rem] flex-col p-2">
          {options.map(({ label, icon, onClick }, i) => (
            <button key={i} onClick={onClick} className="flex items-center gap-3 rounded-md p-2 text-left text-sm text-blue-1100 hover:bg-sky-1000">
              {createElement(icon, { size: 18 })}
              <span>{label}</span>
            </button>
          ))}
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
};
