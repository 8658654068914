import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { ForwardedRef, forwardRef } from 'react';
import { TextInputGray } from 'src/components/Input';
import { cn } from 'Utils/TailwindUtils';
import { useSearchText } from '../../Hooks';
import { IOption } from '../../types';
import { RadixDropdownOption } from './RadixDropDownOption';

interface RadixDropdownProps<T> extends DropdownMenuPrimitive.DropdownMenuContentProps {
  className?: string;
  searchPlaceholder?: string;
  options: IOption<T>[];
  onItemClick: (value: T) => void;
  menuOpen: boolean;
  enableSearch?: boolean;
  optionClassName?: string;
}

const RadixDropdownFunction = <T,>(
  { className, searchPlaceholder, options, onItemClick, menuOpen, enableSearch = true, optionClassName, ...rest }: RadixDropdownProps<T>,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const [searchText, setSearchText] = useSearchText(menuOpen);
  return (
    <DropdownMenuPrimitive.Content align="start" ref={ref} className="radix-dropdown-content" {...rest}>
      <div className="flex flex-col gap-2 py-2">
        {enableSearch && (
          <div className="px-4">
            <TextInputGray value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder={searchPlaceholder} autoFocus />
          </div>
        )}
        <RadixDropdownOption
          options={options}
          onItemClick={onItemClick}
          searchText={searchText}
          className={cn('min-w-60', className)}
          optionClassName={optionClassName}
        />
      </div>
    </DropdownMenuPrimitive.Content>
  );
};

export const RadixDropdownMenu = forwardRef(RadixDropdownFunction);
