import React, { ForwardedRef, useState, useEffect } from 'react';
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import { cn } from 'Utils/TailwindUtils';
import { CheckBox, TextInputGray } from 'src/components/Input';
import { IOption } from 'src/components/Dropdown/types';
import { useApiMatchedOptions, useSelectAll, useSearchText } from 'src/components/Dropdown/Hooks';
import { Spinner } from 'src/components/Spinner';
import { Button } from 'src/components/Button';

interface RadixApiMultiSelectWithApplyProps<T> extends DropdownPrimitive.DropdownMenuContentProps {
  options: IOption<T>[];
  searchPlaceholder?: string;
  selectedOptions: T[];
  menuOpen: boolean;
  onApply?: (values: T[]) => void;
  apiSearch: (searchString: string) => Promise<IOption<T>[]>;
}

const ApiDropDownFunction = <T,>(
  { options, searchPlaceholder, selectedOptions, menuOpen, onApply, apiSearch, ...rest }: RadixApiMultiSelectWithApplyProps<T>,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const [searchString, setSearchString] = useSearchText(menuOpen);
  const { matchedOptions, loading } = useApiMatchedOptions(options, searchString, apiSearch, selectedOptions);
  const [selectedValues, setSelectedValues] = useState(selectedOptions);
  const { allSelected, someSelected, handleSelectAll } = useSelectAll(matchedOptions, selectedValues, setSelectedValues);
  const handleSelect = (value: T) =>
    selectedValues.includes(value)
      ? setSelectedValues(selectedValues.filter((selectedValue) => selectedValue !== value))
      : setSelectedValues([...selectedValues, value]);
  const handleApply = () => onApply?.(selectedValues);
  useEffect(() => menuOpen && setSelectedValues(selectedOptions), [menuOpen]);
  return (
    <DropdownPrimitive.Content ref={ref} className={cn('radix-dropdown-content flex min-w-60 flex-col p-2')} align="start" {...rest}>
      <TextInputGray value={searchString} onChange={(e) => setSearchString(e.target.value)} placeholder={searchPlaceholder ?? 'Search'} autoFocus />
      {loading ? (
        <div className="flex items-center justify-start gap-2 p-4">
          <Spinner size="xsmall" />
          <span className="text-sm text-gray-700">Loading...</span>
        </div>
      ) : (
        <div className="flex max-h-72 flex-col gap-2 overflow-y-auto">
          <div className="hover:bg-sky-1000">
            <CheckBox
              label={<span className="text-sm">Select All</span>}
              checked={allSelected}
              indeterminate={someSelected}
              onChange={handleSelectAll}
              disabled={matchedOptions.length === 0}
              name="Select All"
              className="my-3 ml-3"
            />
          </div>
          {matchedOptions.map((option) => (
            <div className="hover:bg-sky-1000" key={option.label}>
              <CheckBox
                label={<span className="text-sm capitalize">{option.label}</span>}
                key={option.label}
                checked={selectedValues.includes(option.value)}
                onChange={() => handleSelect(option.value)}
                name={option.label}
                className="my-3 ml-3"
              />
            </div>
          ))}
        </div>
      )}
      <Button variant="primary" label="Apply" onClick={handleApply} disabled={matchedOptions.length === 0} />
    </DropdownPrimitive.Content>
  );
};

export const RadixApiMultiSelectWithApply = React.forwardRef(ApiDropDownFunction);
