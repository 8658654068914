import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { CalendarIcon } from 'src/static/icons';
import { DateRangeLabel } from 'src/components/Label';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { DateRangePickerRoot } from 'src/components/DateRangePicker';
import getDateRangeIntervals from 'src/core/utils/constants/DateRangeIntervals';
import { BarSkeleton } from 'src/components/SkeletonPlaceHolder';
import { updateFilterDateRange } from 'Slices/filter';
import { IDateFilterData } from 'Slices/types/filter';
import { trackFilterEvent, TRACK_EVENT } from 'src/core/trackEvent';
import { DateFieldSelect } from './DateFieldSelect';

export const DateRangeFilterButton = () => {
  const dispatch = useAppDispatch();
  const { dateRangeBounds, filters } = useAppSelector((state) => state.filter);
  const { dateRange, interval, selectedDateField } = filters;
  const { start, end } = dateRangeBounds?.find(({ date_field }) => date_field === selectedDateField) ?? {};
  const dateRangeBound = useMemo(() => ({ start: moment(start).startOf('day').toDate(), end: moment(end).endOf('day').toDate() }), [start, end]);
  const dateIntervals = useMemo(() => getDateRangeIntervals(dateRangeBounds, selectedDateField), [dateRangeBounds, selectedDateField]);
  const dateFieldOptions = useMemo(
    () => dateRangeBounds?.map(({ date_field, name, description }) => ({ label: name, value: date_field, description })),
    [dateRangeBounds]
  );

  const changeFilterDateRange = useCallback((dateRange: IDateFilterData) => {
    trackFilterEvent(TRACK_EVENT.FILTER_DATE_RANGE, { Date_Range_Start: dateRange.startDate, Date_Range_End: dateRange.endDate });
    dispatch(updateFilterDateRange(dateRange));
  }, []);

  if (dateRange?.start && dateRange?.end) {
    return (
      <DateRangePickerRoot
        dateRange={dateRange}
        dateRangeBounds={dateRangeBound}
        setDateRange={changeFilterDateRange}
        dateIntervals={dateIntervals}
        interval={interval}
        defaultIntervalKey="last7days"
        extraRangeLabel={dateFieldOptions.length > 1 && <DateFieldSelect dateFieldOptions={dateFieldOptions} />}
        className="filter-button font-medium"
      >
        <CalendarIcon size="17px" color="black" />
        <DateRangeLabel size="xsmall" {...dateRange} />
      </DateRangePickerRoot>
    );
  } else return <BarSkeleton width="8rem" />;
};
