import React, { useMemo } from 'react';
import { FiPlus } from 'react-icons/fi';
import * as Select from 'src/components/Dropdown/DropdownMenu/RadixDropdownMenu';
import { useFilterGroupContext } from '../DataSourceFilterContainer';
import { getFilterOptions } from './AddGroupFilter';
import { ReactTooltipContainer } from 'src/components/Tooltip';
import { useFilterContext } from '../DataSourceFilters';

export const AddFilterToGroup = () => {
  const { filterGroup, dataSource, filterGroupIndex } = useFilterGroupContext();
  const [open, setOpen] = React.useState(false);
  const context = useFilterContext();
  const { options, onOptionSelect } = useMemo(() => getFilterOptions(dataSource, filterGroupIndex, context), [dataSource, filterGroupIndex, context]);
  const id = `${dataSource}-${filterGroupIndex}-add-filter`;
  return (
    <>
      <Select.Root open={open} onOpenChange={setOpen}>
        <Select.Trigger className="ml-0.5 rounded-r-md bg-gray-650 p-2 text-xs hover:bg-indigo-50 hover:text-indigo-600" data-tooltip-id={id}>
          <FiPlus />
        </Select.Trigger>
        <Select.RadixDropdownMenu
          options={options}
          onItemClick={onOptionSelect}
          className="max-h-60"
          searchPlaceholder="Search Filter"
          menuOpen={open}
        />
      </Select.Root>
      <ReactTooltipContainer id={id}>
        <div className="flex gap-1">
          <span>Add filter to create group with</span>
          <span className="rounded bg-blue-1100 px-2 text-white">{filterGroup.conditionalFilterRelation}</span>
          <span>condition</span>
        </div>
      </ReactTooltipContainer>
    </>
  );
};
