import { createElement, FC, useCallback } from 'react';
import { cn } from 'Utils/TailwindUtils';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { IconToggleSingleGroupProps } from './types';

type SingleToggleGroupProps = FC<IconToggleSingleGroupProps<string>>;

export const SingleToggleGroup: SingleToggleGroupProps = ({ optionClassName, options, onValueChange, className, allowNull, ...rest }) => {
  const changeHandler = useCallback((value: string) => ((!value && allowNull) || value) && onValueChange?.(value), [allowNull, onValueChange]);
  return (
    <ToggleGroup.Root type="single" className={cn('flex', className)} onValueChange={changeHandler} {...rest}>
      {options?.map(({ label, icon, iconProps, ...rest }, index) => (
        <ToggleGroup.Item
          className={cn(
            'flex items-center justify-center gap-3 bg-gray-650 px-4 py-2',
            'first:rounded-l-xl last:rounded-r-xl hover:bg-gray-1000',
            optionClassName
          )}
          key={index}
          {...rest}
        >
          {icon && createElement(icon, { size: 18, ...iconProps })}
          <span>{label}</span>
        </ToggleGroup.Item>
      ))}
    </ToggleGroup.Root>
  );
};
