import { Box } from 'grommet';
import React from 'react';
import OtpInput, { OtpInputProps } from 'react-otp-input';
import styled from 'styled-components';

const InputContainer = styled(Box)({
  '&>div': {
    '&>div:first-child>input': {
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
    '&>div:last-child>input': {
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
});

const StyledOtpInput = styled(OtpInput)({
  justifyContent: 'space-between',
  '&>input': {
    fontSize: '14px',
    padding: '11px',
    flex: 1,
    borderRadius: '0px',
    fontWeight: 'bolder',
    borderWidth: '1px',
    '&:hover,&:focus': {
      borderColor: 'var(--tw-indigo-600) !important',
    },
    '&.invaild': {
      borderColor: '#B53131',
    },
  },
  flex: 1,
});

export interface IFormOtpInputProps extends Omit<OtpInputProps, 'onChange'> {
  onChange: (value: Event) => void;
}

const FormOtpInput: React.FC<IFormOtpInputProps> = (props) => {
  return (
    <InputContainer>
      <StyledOtpInput isInputNum {...props} />
    </InputContainer>
  );
};

export default FormOtpInput;
