import { Theme } from 'src/types/theme';
import { getAdminClientInstance } from './utils/clientAdapters';
import { AdminApiWrapper } from './apiTypes/Common';

export const createTheme = async (themeData: Omit<Theme, 'id'>) => {
  try {
    const key = 'themes';
    return (await getAdminClientInstance(key).post(key, themeData)).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const updateTheme = async (themeData: Theme) => {
  try {
    const key = `themes/${themeData.id}`;
    return (await getAdminClientInstance(key).put(key, themeData)).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const deleteTheme = async (themeId: string) => {
  try {
    const key = `themes/${themeId}`;
    return (await getAdminClientInstance(key).delete(key)).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getThemes = async (es_alias: string, dimension?: string) => {
  try {
    const key = 'themes';
    return (await getAdminClientInstance(key).get<AdminApiWrapper<Theme[]>>(key, { params: { es_alias, dimension } })).data.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getThemeByName = async (name: string) => {
  try {
    const key = `themes?name=${encodeURIComponent(name)}`;
    return (await getAdminClientInstance(key).get(key)).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
