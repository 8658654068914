import { lazy } from 'react';

const ZendeskSupportOauthVerifierPage = lazy(() => import('./ZendeskSupportOauthVerifierPage'));
const ZendeskChatOauthVerifierPage = lazy(() => import('./ZendeskChatOauthVerifierPage'));
const SurveyMonkeyOauthVerifierPage = lazy(() => import('./SurveyMonkeyOauthVerifierPage'));
const GorgiasOauthVerifierPage = lazy(() => import('./GorgiasOauthVerifierPage'));
const IntercomOauthVerifierPage = lazy(() => import('./IntercomOauthVerifierPage'));
const HelpScoutOAuthVerifierPage = lazy(() => import('./HelpScoutOAuthVerifierPage'));
const GorgiasAppInstall = lazy(() => import('./GorgiasAppInstall'));
const TypeformOauthVerifierPage = lazy(() => import('./TypeformOauthVerifierPage'));

export {
  ZendeskSupportOauthVerifierPage,
  ZendeskChatOauthVerifierPage,
  SurveyMonkeyOauthVerifierPage,
  GorgiasOauthVerifierPage,
  IntercomOauthVerifierPage,
  HelpScoutOAuthVerifierPage,
  GorgiasAppInstall,
  TypeformOauthVerifierPage,
};
