import React from 'react';
import { HiCheck } from 'react-icons/hi';
import { SourceNameLabel } from '../SourceNameLabel';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { discardMetricCardConfig, saveMetricCardConfig } from 'Slices/editMetricCard';
import { EditMetricCardName } from './EditMetricCardName';
import { getSelectedClientSourceData } from 'src/api/dashboard/Report';
import { EditMetricCardOverviewLineGraph } from './EditMetricCardOverviewLineGraph';

export const EditMetricCard = () => {
  const dispatch = useAppDispatch();
  const selecedMetricCard = useAppSelector((state) => state.editMetricCard.selecedMetricCard);
  const souceDisplayName = getSelectedClientSourceData(selecedMetricCard.selectedSourceClientData.es_alias.name).es_alias.display_name;
  return (
    <div className="relative mt-4">
      <div className="absolute -top-4 right-6 z-10 flex items-center justify-center gap-2">
        <button
          className="rounded-full border bg-white px-3 py-2 text-sm text-gray-500"
          onClick={() => {
            dispatch(discardMetricCardConfig());
          }}
        >
          Cancel
        </button>
        <button
          className="flex items-center gap-2 rounded-full bg-green-700 px-3 py-2 text-sm text-white"
          onClick={() => {
            dispatch(saveMetricCardConfig());
          }}
        >
          <HiCheck /> Done
        </button>
      </div>
      <div className="relative min-h-96 rounded-xl border border-indigo-600 text-center">
        <SourceNameLabel sourceName={souceDisplayName} className="!ml-4 border-t-0" />
        <EditMetricCardName />
        <EditMetricCardOverviewLineGraph />
      </div>
    </div>
  );
};
