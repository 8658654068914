import React from 'react';
import { ViewAndFilterContainer } from './ViewAndFilterContainer';
import { FiChevronUp } from 'react-icons/fi';
interface FilterDialogProps {
  toggleDialog: () => void;
  containerHeight: number;
}

export const FilterDialog: React.FC<FilterDialogProps> = ({ toggleDialog, containerHeight }) => {
  return (
    <div className="fixed left-16 right-0 border-b" style={{ top: containerHeight + 85 }}>
      <div className="relative">
        <div className="flex flex-col gap-3 bg-white px-4 pb-4">
          <hr />
          <ViewAndFilterContainer />
        </div>
        <button className="absolute -bottom-6 right-16 rounded-full border bg-white px-4 py-3 hover:bg-indigo-50" onClick={toggleDialog}>
          <FiChevronUp />
        </button>
      </div>
    </div>
  );
};
