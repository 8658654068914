import { cloneDeep } from 'lodash';
import { updateDataSourceFilters } from 'Slices/filter';
import { DropdownTab } from 'src/components/Dropdown/types';
import { store } from 'src/core/store';
import { IFilterData, IDataSourceFilters } from 'src/types/Bookmark';
import { FILTER_ACTION_TYPES, FILTER_TYPES } from 'src/types/enums';
import { IMetadataFilter } from 'src/types/Filter';
import { FILTER_VIEW_METADATA_DEFAULT_OPTIONS } from 'Utils/constants/common';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';

export const getFilterTabs = () => {
  const dispatch = store.dispatch;
  const { filterdata, filters, bookmarkFilters } = store.getState().filter;

  const source = filters?.source;

  const createFilterGroup = (dataSource: string, filterData: IFilterData, datasourceFilters: IDataSourceFilters) => {
    if (dataSource in datasourceFilters) datasourceFilters[dataSource].push({ conditionalFilterRelation: 'or', conditionalFilters: [filterData] });
    else datasourceFilters[dataSource] = [{ conditionalFilterRelation: 'or', conditionalFilters: [filterData] }];
    trackFilterEvent(TRACK_EVENT.FILTER_ACTION, {
      Data_Source: dataSource,
      Type: FILTER_TYPES.DATA_SOURCE,
      Action_Type: dataSource in datasourceFilters ? FILTER_ACTION_TYPES.ADD : FILTER_ACTION_TYPES.UPDATE,
      Source: source,
    });
  };

  const onMetadataSelect = (dataSource: string, metadataField: FILTER_VIEW_METADATA_DEFAULT_OPTIONS | IMetadataFilter) => {
    const datasourceFilters = { ...cloneDeep(bookmarkFilters.datasourceFilters) };
    switch (metadataField) {
      case FILTER_VIEW_METADATA_DEFAULT_OPTIONS.ALL:
        if (dataSource in datasourceFilters) delete datasourceFilters[dataSource];
        else datasourceFilters[dataSource] = [];
        break;
      case FILTER_VIEW_METADATA_DEFAULT_OPTIONS.SENTISUM_TAG:
        createFilterGroup(
          dataSource,
          {
            type: 'topic',
            fieldName: 'annotations.topic',
            valuesRelation: 'or',
            values: [],
          },
          datasourceFilters
        );
        break;
      case FILTER_VIEW_METADATA_DEFAULT_OPTIONS.DIY_TAG:
        createFilterGroup(
          dataSource,
          {
            type: 'diyTopic',
            fieldName: 'segments.text',
            valuesRelation: 'or',
            values: [],
          },
          datasourceFilters
        );
        break;
      case FILTER_VIEW_METADATA_DEFAULT_OPTIONS.Theme:
        createFilterGroup(
          dataSource,
          {
            type: 'themes',
            fieldName: 'NO_PATH',
            valuesRelation: 'or',
            values: [],
          },
          datasourceFilters
        );
        break;
      default:
        createFilterGroup(
          dataSource,
          {
            type: metadataField.parentField,
            fieldName: metadataField.searchField.concat(metadataField.keyword ? '.keyword' : ''),
            valuesRelation: 'or',
            values: [],
          },
          datasourceFilters
        );
    }
    dispatch(updateDataSourceFilters(datasourceFilters));
  };

  const filterTabs: DropdownTab<FILTER_VIEW_METADATA_DEFAULT_OPTIONS | IMetadataFilter>[] =
    filterdata.filters?.map((filter) => {
      const defaultOptionValues: FILTER_VIEW_METADATA_DEFAULT_OPTIONS[] = Object.values(FILTER_VIEW_METADATA_DEFAULT_OPTIONS);
      const defaultOption = defaultOptionValues.map((option) => ({ value: option, label: option }));
      const options =
        filter.metadataFilters?.map((metadataFilter) => ({
          value: metadataFilter,
          label: metadataFilter.displayName,
        })) ?? [];
      return {
        label: filter.dataSource,
        value: filter.dataSource,
        theme: filter.theme,
        onTabItemClick: (value: FILTER_VIEW_METADATA_DEFAULT_OPTIONS | IMetadataFilter) => onMetadataSelect(filter.dataSource, value),
        options: [...defaultOption, ...options],
      };
    }) ?? [];
  return filterTabs;
};
