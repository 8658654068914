import { persistor } from '../store';
import { FBEnvVariables } from 'Utils/getEnvVariables';
import { Amplify } from '@aws-amplify/core';
import CongnitoConfig from '../config/CognitoConfig';
import { PREVIOUS_DEPENDS_ON_KEY } from 'src/api/utils/clientAdapters';

import * as Sentry from '@sentry/react';
import 'Utils/polyfills';

import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router';
const chmln = require('@chamaeleonidae/chmln');

const addHubspotService = () => {
  window.hsConversationsSettings = {
    loadImmediately: false,
  };
  const hubspotService = document.createElement('script');
  const idAttribute = document.createAttribute('id');
  idAttribute.value = 'hubspot-service';
  hubspotService.setAttributeNode(idAttribute);
  hubspotService.src = 'https://js-na1.hs-scripts.com/2894767.js';
  document.body.appendChild(hubspotService);
};

const addFacebookService = () => {
  const intercomService = document.createElement('script');
  const idAttribute = document.createAttribute('id');
  idAttribute.value = 'fb-service';
  intercomService.setAttributeNode(idAttribute);
  const fb = FBEnvVariables();
  intercomService.innerText = `(function(){window.fbAsyncInit = function() {
    FB.init({
      appId            : '${fb.appId}',
      autoLogAppEvents : true,
      xfbml            : true,
      version          : '${fb.apiVersion}'
    });
  };})();`;
  document.body.appendChild(intercomService);
};

const addHotjar = () => {
  const hotjar = document.createElement('script');
  const idAttribute = document.createAttribute('id');
  idAttribute.value = 'hotjar';
  hotjar.setAttributeNode(idAttribute);
  hotjar.innerText =
    "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:3238386,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";
  document.head.appendChild(hotjar);
};

const validateResetCodeToResetPersistedStore = (callback) => {
  const PERSIST_VALIDATION_CODE = 'RESET_PERSIST_VALIDATION_CODE';
  const STORED_RESET_PERSIST_VALIDATION_CODE = localStorage.getItem(PERSIST_VALIDATION_CODE) || '';
  const RESET_PERSIST_VALIDATION_CODE = process.env.REACT_APP_RESET_PERSIST_DATA_VALIDATION_CODE || '';

  if (
    (RESET_PERSIST_VALIDATION_CODE && RESET_PERSIST_VALIDATION_CODE !== STORED_RESET_PERSIST_VALIDATION_CODE) ||
    !STORED_RESET_PERSIST_VALIDATION_CODE
  ) {
    localStorage.setItem(PERSIST_VALIDATION_CODE, RESET_PERSIST_VALIDATION_CODE || Math.random().toString());
    persistor.purge();
    localStorage.removeItem(PREVIOUS_DEPENDS_ON_KEY);
    if (window && window?.location) {
      window.location.reload();
    }
  } else {
    callback();
  }
};

const scriptsToExecute = () => {
  addFacebookService();
  addHotjar();
  addHubspotService();
};

const addSentryService = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_VERCEL_ENV,
    replaysOnErrorSampleRate: Number(process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
    replaysSessionSampleRate: Number(process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        stickySession: true,
        networkCaptureBodies: true,
        networkDetailAllowUrls: [/sentisum.com/, 'https://cognito-idp.eu-west-1.amazonaws.com/'],
        networkDetailDenyUrls: [/v4\/data\/conversations/],
      }),
    ],
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    tunnel: process.env.REACT_APP_SENTRY_TUNNEL,
  });
};

const initScripts = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (
    (urlSearchParams.has('code') || urlSearchParams.has('error') || urlSearchParams.has('token')) &&
    !window.location.pathname.includes('confirm')
  ) {
    const newUrlSearchParams = new URLSearchParams(window.location.search);
    newUrlSearchParams.set('xcode', urlSearchParams.get('code'));
    newUrlSearchParams.set('xerror', urlSearchParams.get('error'));
    newUrlSearchParams.set('xtoken', urlSearchParams.get('token'));
    newUrlSearchParams.delete('code');
    newUrlSearchParams.delete('error');
    newUrlSearchParams.delete('token');
    window.location.href = `${window.location.origin}${window.location.pathname}?${newUrlSearchParams.toString()}`;
  }
  chmln.init(process.env.REACT_APP_CHAMELEON_ID, { fastUrl: 'https://fast.chameleon.io/' });
  Amplify.configure({ Auth: CongnitoConfig });
  addSentryService();
  validateResetCodeToResetPersistedStore(scriptsToExecute);
};

export default initScripts;
