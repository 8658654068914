import { FC, SVGProps } from 'react';

export const DownRedSVG: FC<SVGProps<SVGSVGElement>> = ({ color, x, y }) => {
  return (
    <svg x={x} y={y} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.1" r="7" transform="matrix(1 0 0 -1 7 6.99976)" fill={color} />
      <path d="M9.60974 7.00006L6.9992 9.6106L4.38867 7.00006" stroke={color} strokeWidth="1.17971" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.99902 4.38953V9.6106" stroke={color} strokeWidth="1.17971" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
