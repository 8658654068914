import { useEffect, useState } from 'react';
import { IOption } from '../types';

export const useApiMatchedOptions = <T,>(
  options: IOption<T>[],
  searchString: string,
  fetchApiOptions: (searchString: string) => Promise<IOption<T>[]>,
  intialSelected: T[] = []
) => {
  const [matchedOptions, setMatchedOptions] = useState<IOption<T>[]>(options);
  const [loading, setLoading] = useState(false);
  const searchOptions = async () => {
    let value: IOption<T>[] = [];
    if (!searchString) value = options;
    else {
      const previousSearchString = searchString;
      setLoading(true);
      value = await fetchApiOptions(searchString);
      if (previousSearchString !== searchString) return;
      setLoading(false);
    }
    setMatchedOptions(
      value.sort((a, b) => {
        if (intialSelected.includes(a.value)) return -1;
        if (intialSelected.includes(b.value)) return 1;
        return 0;
      })
    );
  };
  useEffect(() => {
    searchOptions();
  }, [options, searchString]);
  return { matchedOptions, loading } as const;
};
