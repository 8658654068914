import { forwardRef } from 'react';
import { cn } from 'Utils/TailwindUtils';
import { buttonCVA, CommonButtonProps } from './Button';
import { NavLink, NavLinkProps } from 'src/components/NavLink';

type ButtonNavLinkProps = CommonButtonProps & NavLinkProps;

export const ButtonNavLink = forwardRef(
  ({ variant, className, icon, size, label, ...rest }: ButtonNavLinkProps, ref: React.RefObject<HTMLAnchorElement>) => {
    return (
      <NavLink className={cn(buttonCVA({ variant, size }), { 'flex items-center justify-center': icon }, className)} {...rest} ref={ref}>
        {icon}
        {label}
      </NavLink>
    );
  }
);
