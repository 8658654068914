import React, { ForwardedRef, useEffect, useState, useCallback, ReactNode } from 'react';
import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu';
import { cn } from 'Utils/TailwindUtils';
import { TextInputWhite } from 'src/components/Input';
import { Button } from 'src/components/Button';

interface RadixTextWithApplyProps extends DropdownPrimitive.DropdownMenuContentProps {
  value: string;
  onApply?: (value: string) => void;
  placeholder?: string;
  menuOpen: boolean;
  validate?: (value: string) => ReactNode | undefined;
}

const RadixTextWithApplyComponent = (
  { value, onApply, placeholder, menuOpen, validate, ...props }: RadixTextWithApplyProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const [valueString, setValueString] = useState(value);
  const [error, setError] = useState<ReactNode | undefined>(undefined);
  const handleApply = useCallback(() => {
    const error = validate?.(valueString);
    setError(error);
    if (!error) onApply?.(valueString);
  }, [onApply, valueString]);
  const onValueChange = useCallback(
    (e) => {
      setValueString(e.target.value);
      if (error) setError(validate?.(e.target.value));
    },
    [error]
  );
  useEffect(() => {
    setValueString(value);
    setError(undefined);
  }, [menuOpen]);

  return (
    <DropdownPrimitive.Content ref={ref} className={cn('radix-dropdown-content flex min-w-60 flex-col gap-2 p-2')} align="start" {...props}>
      <TextInputWhite className="text-xs" value={valueString} onChange={onValueChange} placeholder={placeholder} autoFocus />
      {error && <div className="px-1 text-xs text-red-1000">{error}</div>}
      <Button variant="primary" label="Apply" onClick={handleApply} />
    </DropdownPrimitive.Content>
  );
};

export const RadixTextWithApply = React.forwardRef(RadixTextWithApplyComponent);
