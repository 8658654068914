import React, { FC, createElement, useCallback } from 'react';
import * as Dialog from 'src/components/Dialog/NestedDialog';
import { createConfirmation, confirmable } from './BaseAyncConfirm';
import { IconConfirmProps } from './IconConfirm';
import { cn } from 'Utils/TailwindUtils';
import { Button } from '../Button';

interface ErrorConfirmProps {
  show: boolean;
  proceed: (proceed: boolean) => void;
  confirmation: string;
  options: Omit<IconConfirmProps, 'visible' | 'setVisible' | 'text' | 'isProcessing' | 'variant'>;
}

const ErrorConfirm: FC<ErrorConfirmProps> = ({ show, proceed, confirmation, options }) => {
  const { okText, cancelText, title, icon, iconClassName, okExtra, cancelExtra } = options;
  const hideDialogCallback = useCallback(() => proceed(false), []);
  return (
    <Dialog.Root open={show} onOpenChange={hideDialogCallback}>
      <Dialog.NestedDialogContent isOpen={show} className="w-96 rounded-xl p-2">
        <div className="flex flex-col gap-1 p-3">
          <div className={cn('flex h-14 w-14 items-center justify-center rounded-full border-8', iconClassName)}>
            {createElement(icon, { width: 18, height: 18 })}
          </div>
          <p className="mt-2 break-words text-lg font-semibold text-grey-800">{title}</p>
          <p className="text-sm font-normal text-grey-500">{confirmation}</p>
          <div className="mt-5 flex gap-2">
            <Button variant="secondary" label={cancelText ?? 'Cancel'} className="flex-1" onClick={hideDialogCallback} {...cancelExtra} />
            <Button variant="danger" label={okText ?? 'ok'} className="flex-1" onClick={() => proceed(true)} {...okExtra} />
          </div>
        </div>
      </Dialog.NestedDialogContent>
    </Dialog.Root>
  );
};

export const asyncErrorConfirm = (confirmation: string, options: ErrorConfirmProps['options']) => {
  const newOptions = { iconClassName: 'border-red-110 bg-red-250 text-red-900', ...options };
  return createConfirmation(confirmable(ErrorConfirm))({ confirmation, options: newOptions });
};
