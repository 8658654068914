import { FC } from 'react';
import { CartesianGrid, YAxis } from 'recharts';
import { InlineGraphProps } from './types';
import { BaseLineGraph } from './BaseLineGraph';

export const InlineGraph: FC<InlineGraphProps> = (props) => {
  return (
    <BaseLineGraph {...props} isChartTypeInline>
      <CartesianGrid vertical={false} horizontal={false} />
      <YAxis axisLine={false} tick={false} domain={['auto', 'auto']} />
    </BaseLineGraph>
  );
};
