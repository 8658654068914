import { useEffect, useCallback, useState } from 'react';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { FiSearch } from 'react-icons/fi';
import { BlockButtonSmall, TransparentClose } from 'src/components/Button';
import * as Dialog from 'src/components/Dialog/NestedDialog';
import tinykeys from 'tinykeys';
import { osName } from 'react-device-detect';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { updateSearchString } from 'Slices/filter';
import { SearchDialog } from './SearchDialog';
import { cn } from 'Utils/TailwindUtils';
import { ReactTooltipContainer } from 'src/components/Tooltip';

const isMacOS = osName === 'Mac OS';
const openSearchShortcut = isMacOS ? 'Meta+K' : 'Control+K';

export const SearchFilter = () => {
  const dispatch = useAppDispatch();
  const searchString = useAppSelector((state) => state.filter.searchString);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  useEffect(() => tinykeys(window, { [openSearchShortcut]: () => setSearchModalOpen(true) }), []);

  const doSearch = useCallback((searchText: string) => {
    setSearchModalOpen(false);
    dispatch(updateSearchString(searchText));
    if (searchText.trim()) trackFilterEvent(TRACK_EVENT.FILTER_SEARCH_TEXT_IN_DISCOVER_PAGE, { Value: searchText, Search_String: searchText });
  }, []);

  return (
    <>
      <Dialog.Root open={searchModalOpen} onOpenChange={setSearchModalOpen}>
        <Dialog.Trigger asChild>
          <BlockButtonSmall
            icon={FiSearch}
            className={cn('relative rounded-xl px-3 py-2 hover:bg-white', {
              'group/close max-w-[10rem] grow border-blue-1100 xl:max-w-[10rem]': !!searchString,
            })}
            variant="primary"
            data-tooltip-id="search-button"
          >
            {searchString ? (
              <>
                <span className="truncate">{searchString}</span>
                <TransparentClose
                  onClick={() => doSearch('')}
                  className="rounded-xl from-white to-transparent opacity-0 group-hover/close:opacity-100"
                />
              </>
            ) : (
              <span className="text-gray-850">Search...</span>
            )}
          </BlockButtonSmall>
        </Dialog.Trigger>
        <SearchDialog updateSearchString={doSearch} searchString={searchString} open={searchModalOpen} />
      </Dialog.Root>
      <ReactTooltipContainer id="search-button" openEvents={{ mouseenter: true }} closeEvents={{ mouseleave: true }}>
        <div className="flex gap-3">
          <span>Search</span>
          <span className="rounded bg-blue-1100 px-2 text-white">{isMacOS ? '⌘ + K' : '^ + K'}</span>
        </div>
      </ReactTooltipContainer>
    </>
  );
};
