import { ReactNode } from 'react';

export const renderTagsLayout = (tagElements: ReactNode, inputElement: ReactNode) => {
  return (
    <span className="flex w-max min-w-[stretch] max-w-3xl flex-wrap">
      {tagElements}
      {inputElement}
    </span>
  );
};
