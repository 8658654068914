import { useAppSelector } from 'src/core/store';
import { AddFiltersContiner } from './AddFilterDropdown/AddFiltersContiner';
import { updateDataSourceFilters, updatePreviousFilterRelation } from 'Slices/filter';
import { DataSourceFilters } from 'src/ui/containers/DataSourceFilters';
import { ViewDropDownContainer } from './ViewDropDownContainer';

export const ViewAndFilterContainer = () => {
  const datasourceFilters = useAppSelector((state) => state.filter.bookmarkFilters.datasourceFilters);
  const filterState = useAppSelector((state) => state.filter);
  return (
    <div className="flex flex-col gap-3 px-5">
      <ViewDropDownContainer />
      {datasourceFilters && Object.keys(datasourceFilters).length > 0 && (
        <DataSourceFilters
          filterState={filterState}
          updateDataSourceFilters={updateDataSourceFilters}
          updatePreviousFilterRelation={updatePreviousFilterRelation}
        />
      )}
      <div className="flex items-center gap-3">
        <AddFiltersContiner />
      </div>
    </div>
  );
};

