import { getDriverData } from 'Utils';
import React, { FC } from 'react';
import { Label, LabelList } from 'recharts';
import { VerticalBarGraphCard } from 'src/components/Charts/BarCharts/BarGraphCards/VerticalBarGraph/VerticalBarGraphCard';
import { IndividualVBGProps } from './HighestLowestVBG';
import { CustomText, DownRedSVG, UpGreenSVG } from 'Static/icons';
import { getBarGraphData } from 'Utils/chartsV2/barGraphFunctions';
import { currentBar, customYAxis } from 'src/components/Charts/BarCharts/GraphComponents';

const CustomLabel = (props) => {
  const { x, y, name, data } = props;
  const selectedBar = data.find((d) => d.name === name);
  return (
    <g>
      {selectedBar.change < 0 ? (
        <DownRedSVG x={x - 8} y={y - 21} color={selectedBar.color} />
      ) : (
        <UpGreenSVG x={x - 8} y={y - 21} color={selectedBar.color} />
      )}
      <CustomText x={x + 12} y={y - 8} value={selectedBar.dataLabel} color={selectedBar.color} />
    </g>
  );
};

export const TopIncreaseDecreaseVBG: FC<IndividualVBGProps> = (props) => {
  const { drivers, chartMetricObject, hideLabels, label, onClick, chartComparator, ...rest } = props;
  const tranformDriverDataProps = (data: ReturnType<typeof getDriverData>) => ({
    data: Math.abs(data.change),
    dataLabel: data.changeText,
    color: data.color,
    change: data.change,
  });
  const { data, minDomain, maxDomain } = getBarGraphData(drivers, chartComparator, chartMetricObject, tranformDriverDataProps);
  const currentCustomLabel = !hideLabels && <LabelList content={<CustomLabel data={data} />} />;
  const yAxisLabel = <Label value={label} angle={-90} position="insideLeft" dy={40} />;
  const barData = {
    barSize: 28,
    radius: [8, 8, 0, 0],
  };
  const yAxisData = {
    domain: [minDomain ?? 0, maxDomain],
    tickFormatter: (value) => (chartComparator === 'percent' ? `${value}%` : value),
  };
  return (
    <VerticalBarGraphCard data={data} selectedMetricType={chartMetricObject.name} chartComparator={chartComparator} onClick={onClick}>
      {customYAxis({ yAxisData, yAxisLabel })}
      {currentBar({ currentCustomLabel, onClick, barData })}
    </VerticalBarGraphCard>
  );
};
