import { base, ThemeType } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';

const custom: ThemeType = {
  formField: {
    label: {
      color: '#344054',
    },
    border: {
      side: 'all',
      error: {
        color: '#B53131',
      },
    },
  },
  global: {
    colors: {
      brand: '#2d403e',
      'brand-lighter-1': '#35504e',
      'brand-lighter-2': '#367a70',
      'border-color': '#D0D5DD',
      zendesk: '#03363D',
      tomato: '#F76951',
      seashell: '#FFF5ED',
      teagreen: '#E2E2E2',
      'status-ok': '#00C781',
      'status-critical': '#FF4040',
      card: '#FFF5ED',
      'card-darker': '#FFD2AD',
      navbar: '#FFF5ED',
      gold: '#FFD700',
      'neutral-3': '#CCE7FA',
      'neutral-4': '#CCE7FA',
      'sentisum-neutral': '#BCB7B3',
      positive: '#039855',
      'positive-transparent': '#45CB8599',
      'positive-dark': '#00A36A',
      'positive-dark-opacity': '#00A36A25',
      negative: '#EE544F',
      'negative-transparent': '#EE544F99',
      'negative-dark': '#FF4040',
      'negative-dark-opacity': '#FF404025',
      neutral: '#BCB7B3',
      'neutral-transparent': '#BCB7B399',
      'neutral-dark': '#4F4F4F',
      'neutral-dark-opacity': '#4F4F4F25',
      'sentiment-dark': '#2095E9',
      'sentiment-dark-opacity': '#2095E925',
      'neutral-lighter': '#BCB7B399',
      unselected: '#F8DF89',
      focus: 'transparent',
      'cyan-blue': '#006BCD',
      'cyan-blue-light': '#006BCD1A',
      'navy-blue': '#0A2540',
      'navy-blue-light': '#3A5072',
      sentiblue: '#CCE7FA',
      'gray-1': '#333333',
      'gray-2': '#FCFCFC',
      'review-black': '#4C5470',
      'light-gray': '#F9F9FA',
      'light-gray-1': '#E6E6E6',
      'light-gray-2': '#F5F8FA',
      'light-gray-3': '#F7F6F6',
      'light-gray-4': '#EEEEEE',
      'light-gray-5': '#F6F6F6',
      'light-gray-6': '#797979',
      'light-gray-7': '#F5F7FB',
      'light-gray-8': '#F2F4F6',
      'border-secondary': '#DBDEE2',
      'border-gray': '#E4E4E4',
      'border-gray-1': '#EBEEF5',
      'danger-red': '#F1E4E4',
      'danger-red-1': '#FEE2E2',
      'dark-gray': '#6B7795',
      'dark-gray-1': '#F1F3F5',
      'text-field': '#344054',
      'heading-text': '#231B1B',
      'text-field-dark': '#2D3958',
      'text-field-light': '#667085',
      'text-field-light-2': '#667C8C',
      'text-field-dark-1': '#828282',
      'text-danger': '#B53131',
      'text-danger-light': '#b531311a',
      'shadow-grey': '#0000001f',
      'text-field-dark-2': '#303030',
      'text-field-dark-3': '#777777',
      'text-field-dark-4': '#303440',
      'text-field-light-1': '#8D9093',
    },
  },
};

export default deepMerge(base, custom);
