import React, { FC } from 'react';
import { Box, Grid, Text } from 'grommet';
import { DateRangeLabel } from 'src/components/Label';
import type { IChartComparator } from 'src/types/Filter';
import type { CommonFilterState } from 'Slices/types/common';
import type { Topic } from 'src/api/apiTypes/Summary';
import type { Dimension, Metric } from 'src/types/User';
import { ReportType } from 'src/types/Report';
import { VerticalBarGraphContainer } from '../BarGraph/VerticalBarGraphContainer';

interface IVerticalBarGraphScreenShotProps {
  label: string;
  rowCount?: number;
  filters: Pick<CommonFilterState['filters'], 'dateRange' | 'previousDateRange'>;
  chartMetricData: Topic[];
  chartMetricObject: Metric;
  chartComparator: IChartComparator;
  chartDimensionObject: Dimension;
  report_type?: ReportType;
}

export const VerticalBarGraphScreenShot: FC<IVerticalBarGraphScreenShotProps> = (props) => {
  const { label, filters, chartMetricData, report_type, chartMetricObject, chartComparator, rowCount } = props;
  const { dateRange, previousDateRange } = filters;

  return (
    <div className="w-[40rem]">
      <div className="flex justify-between">
        <Box gap="small" align="center" flex="shrink">
          <span className="text-base font-semibold capitalize">{label}</span>
        </Box>
        <div className="max-w-max">
          <div className="flex gap-6">
            <Text size="xsmall" color="text-field-dark-2">
              Current:
            </Text>
            <span className="flex items-center justify-center gap-1.5">
              {(report_type === 'H' || report_type === 'L') && <span className="size-2 rounded-full bg-indigo-600"></span>}
              <DateRangeLabel start={dateRange.start} end={dateRange.end} color="text-field-dark-2" />
            </span>
          </div>
          <div className="flex gap-6">
            <Text size="xsmall" color="text-field-dark-2">
              Comparing to:
            </Text>
            <span className="flex items-center justify-center gap-1.5">
              {(report_type === 'H' || report_type === 'L') && <span className="size-2 rounded-full bg-indigo-100"></span>}
              <DateRangeLabel {...previousDateRange} color="text-field-dark-2" />
            </span>
          </div>
        </div>
      </div>
      <div className="h-[20rem]">
        <VerticalBarGraphContainer
          drivers={chartMetricData.slice(0, rowCount)}
          chartMetricObject={chartMetricObject}
          chartComparator={chartComparator}
          tableVisualization={report_type}
        />
      </div>
    </div>
  );
};
