import React, { FC, useCallback, useState } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { HTMLDivProps } from 'src/types/Globals';
import { cn } from 'Utils/TailwindUtils';
import { TextInputWhite } from './TextInputWhite';

interface ArrayTextInputProps extends Omit<HTMLDivProps, 'onChange' | 'ref'> {
  value: string[];
  onChange: (value: string[]) => void;
  maxLimit?: number;
  placeholder?: string;
  textInputClassName?: string;
}

export const ArrayTextInput: FC<ArrayTextInputProps> = ({ value, onChange, maxLimit, placeholder, className, textInputClassName, ...rest }) => {
  const handleValueChange = useCallback(
    (index: number, newValue: string) => {
      const newValueArray = [...value];
      newValueArray[index] = newValue;
      onChange(newValueArray);
    },
    [value, onChange]
  );
  const handleAddAnother = useCallback(() => {
    if (maxLimit && value.length >= maxLimit) return;
    onChange([...value, '']);
  }, [value, onChange, maxLimit]);
  const handleRemove = useCallback(
    (index: number) => {
      const newValueArray = [...value];
      newValueArray.splice(index, 1);
      onChange(newValueArray);
    },
    [value, onChange]
  );
  return (
    <div className={cn('flex flex-col gap-3', className)} {...rest}>
      {value.map((item, index) => (
        <div className="flex gap-3">
          <TextInputWhite
            key={index}
            value={item}
            onChange={(e) => handleValueChange(index, e.target.value)}
            placeholder={placeholder}
            className={textInputClassName}
            autoFocus
          />
          <button className="text-gray-400 hover:text-gray-750" onClick={() => handleRemove(index)}>
            <FiXCircle />
          </button>
        </div>
      ))}
      <button type="button" className="w-fit text-blue-975 hover:text-blue-1150" onClick={handleAddAnother}>
        + Add another
      </button>
    </div>
  );
};
