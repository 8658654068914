import { CustomTopicWithSource, DiyTagResponse } from './apiTypes/DiyTag';
import { getAdminClientInstance } from './utils/clientAdapters';

export const createDiyTag = async (data: Omit<CustomTopicWithSource, 'id'>) => {
  const key = 'customtopic/create';
  try {
    const response = await getAdminClientInstance(key).post<DiyTagResponse>(key, data);
    return { ...response.data.data.custom_topic, es_alias: data.es_alias };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const updateDiyTag = async (data: CustomTopicWithSource) => {
  const key = 'customtopic/update';
  try {
    const response = await getAdminClientInstance(key).post<DiyTagResponse>(key, data);
    return { ...response.data.data.custom_topic, es_alias: data.es_alias };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const deleteDiyTag = async (data: Pick<CustomTopicWithSource, 'id' | 'es_alias'>) => {
  const key = 'customtopic/delete';
  try {
    await getAdminClientInstance(key).post(key, { id: data.id });
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
