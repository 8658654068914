import React, { forwardRef } from 'react';
import { Button } from 'src/components/Button';
import { FormTextInput } from 'src/components/Input/FormTextInput';
import { useEditorContext } from '../../EditorContext';
import { HTMLDivProps, ISetState } from 'src/types/Globals';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import validator from 'validator';
import { Editor, getMarkRange } from '@tiptap/react';

const LinkFormSchema = z.object({
  link: z
    .string()
    .nonempty({ message: 'Link is required' })
    .refine((value) => validator.isURL(value, { require_protocol: false }), { message: 'Invalid URL' }),
});

type LinkForm = z.infer<typeof LinkFormSchema>;

interface LinkDialogProps extends HTMLDivProps {
  setOpen: ISetState<boolean>;
  targetElement: HTMLElement;
}

const getMarkRangeForLink = (editor: Editor, element: HTMLElement) => {
  const pos = editor.view.posAtDOM(element, 1);
  return getMarkRange(editor.view.state.doc.resolve(pos), editor.view.state.schema.marks.link, { href: element.getAttribute('href') });
};

export const LinkDialog = forwardRef<HTMLDivElement, LinkDialogProps>(({ setOpen, targetElement, ...props }, ref) => {
  const { editor } = useEditorContext();
  const methods = useForm<LinkForm>({ resolver: zodResolver(LinkFormSchema), mode: 'onSubmit' });
  const { register, handleSubmit } = methods;
  const updateLink = (data: LinkForm) => {
    const href = data.link;
    const markRange = getMarkRangeForLink(editor, targetElement);
    if (!markRange) return;
    const { from, to } = markRange;
    const transaction = editor.view.state.tr
      .removeMark(from, to, editor.view.state.schema.marks.link)
      .addMark(from, to, editor.view.state.schema.marks.link.create({ href }));
    editor.view.dispatch(transaction);
    setOpen(false);
  };
  const removeLink = () => {
    const markRange = getMarkRangeForLink(editor, targetElement);
    if (!markRange) return;
    const { from, to } = markRange;
    const transaction = editor.view.state.tr.removeMark(from, to, editor.view.state.schema.marks.link);
    editor.view.dispatch(transaction);
    setOpen(false);
  };
  const link = targetElement.getAttribute('href');
  return (
    <div className="pointer-events-auto z-20 min-w-[22rem] rounded-xl border border-grey-200 bg-white p-5 shadow-md" ref={ref} {...props}>
      <FormProvider {...methods}>
        <form className="flex flex-col gap-2.5 text-sm" onSubmit={handleSubmit(updateLink)}>
          <FormTextInput label="Enter a link" type="text" className="border-grey-300 shadow-sm" defaultValue={link} {...register('link')} autoFocus />
          <div className="flex items-center gap-3">
            {link && (
              <button className="text-indigo-600 hover:underline" onClick={removeLink} type="button">
                Remove link
              </button>
            )}
            <Button label="Update link" variant="primary" type="submit" className="ml-auto rounded-full" />
          </div>
        </form>
      </FormProvider>
    </div>
  );
});
