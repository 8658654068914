import mixpanel from 'mixpanel-browser';
import { Analytics, AnalyticsBrowser } from '@june-so/analytics-next';
import { store } from '../store';
import { TRACK_EVENT } from './events';
const chmln = require('@chamaeleonidae/chmln');

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { cross_site_cookie: true });

type TrackQueue = ({ type: 'event'; eventName: TRACK_EVENT; properties: Record<string, any> } | { type: 'page'; title: string })[];

const getTrackQueue = () => (localStorage.getItem('trackQueue') ? JSON.parse(localStorage.getItem('trackQueue')!) : []) as TrackQueue;
const setTrackQueue = (trackQueue: TrackQueue) => localStorage.setItem('trackQueue', JSON.stringify(trackQueue));
export const pushToTrackQueue = (item: TrackQueue[number]) => setTrackQueue([...getTrackQueue(), item]);

let JuneAnalytics: Analytics;
export let trackGroupId: string;
export const getIsTrackingIntialised = () => store.getState().auth?.user;

export const trackEvent = (eventName: TRACK_EVENT, properties: Record<string, any> = {}) => {
  if (!getIsTrackingIntialised()) return pushToTrackQueue({ type: 'event', eventName, properties });
  mixpanel.track(eventName, properties);
  chmln.track(eventName, properties);
  getJuneInstance().then((juneInstance) => juneInstance.track(eventName, properties, { context: { groupId: trackGroupId } }));
};

export const trackPage = (pageTitle: string) => {
  if (!getIsTrackingIntialised()) return pushToTrackQueue({ type: 'page', title: pageTitle });
  getJuneInstance().then((juneInstance) => juneInstance.page('Page View', pageTitle, { context: { groupId: trackGroupId } }));
};

export const getJuneInstance = async () => {
  if (JuneAnalytics) return JuneAnalytics;
  JuneAnalytics = (await AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_JUNE_ANALYTICS_KEY }))[0];
  return JuneAnalytics;
};

export const intializeTrackers = async () => {
  const user = store.getState().auth?.user;
  if (!user) return;
  const juneInstance = await getJuneInstance();
  const { uuid, email, on_trial, name } = user;
  mixpanel.identify(user.uuid);
  mixpanel.people.set({ $email: email, $name: name, id: uuid });
  juneInstance.identify(uuid, { email, on_trial, name });
  if (user.clients.length > 0) {
    const isInternalUser = process.env.REACT_APP_INTERNAL_USER_DOMAINS?.split(',').includes(user.email.split('@')[1]);
    trackGroupId = isInternalUser ? 'sentisum' : user.clients[0].name;
    mixpanel.set_group('company', trackGroupId);
    juneInstance.group(trackGroupId, { name: isInternalUser ? 'Sentisum' : user.clients[0].display_name });
  } else trackGroupId = 'No Company';
  window.hj?.('identify', uuid, { email, name, on_trial });
  const trackQueue = getTrackQueue();
  trackQueue.forEach((item) => (item.type === 'event' ? trackEvent(item.eventName, item.properties) : trackPage(item.title)));
  setTrackQueue([]);
};

export const resetTrackers = () => {
  trackGroupId = '';
  mixpanel.reset();
  getJuneInstance().then((juneInstance) => juneInstance.reset());
};

export { mixpanel };
