import Placeholder from '@tiptap/extension-placeholder';
import StarterKit from '@tiptap/starter-kit';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import Link from '@tiptap/extension-link';

const getHoverLink = (tooltipId: string) =>
  tooltipId
    ? Link.extend({
        addAttributes() {
          return {
            ...this.parent?.(),
            href: {
              default: null,
              renderHTML: (attributes) => {
                return {
                  ...attributes,
                  'data-tooltip-id': tooltipId,
                };
              },
            },
            target: {
              default: '_self',
              renderHTML: () => ({
                target: '_self',
              }),
            },
          };
        },
      })
    : Link;

export const getTipTapExtensions = (id?: string) => [
  StarterKit.configure({
    bulletList: { HTMLAttributes: { class: 'list-disc list-outside leading-3 ml-3' } },
    orderedList: { HTMLAttributes: { class: 'list-decimal list-outside leading-3 ml-3' } },
    listItem: { HTMLAttributes: { class: 'leading-normal' } },
  }),
  Placeholder.configure({
    placeholder: 'Start typing...',
    showOnlyWhenEditable: false,
    emptyEditorClass: 'is-editor-empty text-grey-500',
  }),
  TextStyle,
  Color,
  getHoverLink(id).configure({ HTMLAttributes: { class: 'underline underline-offset-[3px]' }, autolink: false }),
];
