import { authClient, apiClient } from '../client';
import { validateErrorResponse } from 'Utils/apiRequestResponseValidator';

import { HTTP_RESPONSE_STATUS } from 'Constants/common';
// admin api

const register = async (name, email, password, onTrial) => {
  try {
    await authClient.post('user/register', { name, email, password, onTrial });
  } catch (error) {
    validateErrorResponse(error);
  }
};

const sendResetCode = async (email) => {
  try {
    await authClient.post('auth/forgot/initiate', { email });
  } catch (error) {
    if (error.response?.status === 401) throw new Error('Email Not Confirmed');
    throw new Error(error?.response?.data?.message);
  }
};

const resetPassword = async (email, password, code) => {
  try {
    await authClient.post('auth/forgot/reset', { email, password, code });
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

const resendVerificationEmail = async (email) => {
  try {
    await authClient.post('user/resend_code', { email });
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
// data api

const updateTopics = async (source, conversationId, updatedTopics) => {
  try {
    await apiClient.post('data/conversations/feedback', { source, conversationId, updatedTopics });
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

const syncConversation = async (source, conversationId, status) => {
  try {
    const response = await apiClient.post('data/conversations/sync', { source, conversationId, status });
    return { data: response.data };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

const proceedToZendeskAccessToken = async (subdomain, code, csat) => {
  try {
    await authClient.post(`integration/zendesk${csat ? '?type=csat' : ''}`, { auth_code: code, subdomain: subdomain });
    return { status: 'success' };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

const proceedToZendeskChatAccessToken = async (subdomain, code) => {
  try {
    await authClient.post('integration/zendesk-chat', { auth_code: code, subdomain: subdomain });
    return { status: 'success' };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

const proceedToSurveyMonkeyAccessToken = async (code) => {
  try {
    await authClient.post('integration/survey-monkey', { auth_code: code });
    return { status: 'success' };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

const proceedToGorgiasAccessToken = async (subdomain, code) => {
  try {
    await authClient.post('integration/gorgias', { subdomain: subdomain, auth_code: code });
    return { status: 'success' };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

const proceedToIntercomAccessToken = async (code) => {
  try {
    await authClient.post('integration/intercom', { auth_code: code });
    return { status: 'success' };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

const proceedToDixaAccessToken = async (code) => {
  try {
    await authClient.post('integration/dixa', { auth_code: code });
    return { status: 'success' };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

const proceedToFaceebokAccessToken = async ({ accessToken, userId, apiVersion }) => {
  try {
    await authClient.post('integration/facebook', {
      auth_code: accessToken,
      fb_user_id: userId,
      api_version: apiVersion,
    });
    return { status: 'success' };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

const getIntegrationServiceStatusList = async () => {
  try {
    const response = await authClient.get('integration/all');
    return { data: response.data.data };
  } catch (error) {
    const message = error?.response?.data?.message;
    const status = error?.response?.status;
    const error_message = 'client not found.';
    if (!!message && !!status && status === HTTP_RESPONSE_STATUS.CLIENT_ERROR.BAD_REQUEST && message.toLowerCase() === error_message) {
      return { data: {} };
    }
    throw new Error(error?.response?.data?.message);
  }
};

const addNewGenericIntegration = async (data) => {
  try {
    const response = await authClient.post('integration/generic/add', data);
    return { data: response.data.data };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
const removeGenericIntegration = async (data) => {
  try {
    const response = await authClient.post('integration/generic/remove', data);
    return { data: response.data.data };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
const updateGenericIntegration = async (data) => {
  try {
    const response = await authClient.post('integration/generic/update', data);
    return { data: response.data.data };
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export default {
  register,
  sendResetCode,
  resetPassword,
  resendVerificationEmail,
  updateTopics,
  syncConversation,
  proceedToZendeskAccessToken,
  proceedToZendeskChatAccessToken,
  getIntegrationServiceStatusList,
  proceedToSurveyMonkeyAccessToken,
  proceedToGorgiasAccessToken,
  proceedToDixaAccessToken,
  addNewGenericIntegration,
  removeGenericIntegration,
  updateGenericIntegration,
  proceedToFaceebokAccessToken,
  proceedToIntercomAccessToken,
};
