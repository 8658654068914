import { cn } from 'Utils/TailwindUtils';
import React, { useCallback, useState } from 'react';
import LinesEllipsis, { CommonReactLinesEllipsisProps } from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { HTMLDivProps } from 'src/types/Globals';
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

interface ClampledLinesWithExpandProps extends HTMLDivProps {
  textClassName?: string;
  children: string;
  maxLine?: number;
}

export const ClampledLinesWithExpand: React.FC<ClampledLinesWithExpandProps> = ({ className, textClassName, children, maxLine, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpand, setShowExpand] = useState(false);
  const reflowCallback = useCallback<CommonReactLinesEllipsisProps['onReflow']>(({ clamped }) => setShowExpand(clamped), []);

  return (
    <div className={cn('flex flex-col gap-1', className)} {...props}>
      {isExpanded ? (
        <p className={textClassName}>{children}</p>
      ) : (
        <ResponsiveEllipsis text={children} maxLine={maxLine ?? 3} onReflow={reflowCallback} className={textClassName} />
      )}
      {showExpand && (
        <button className="w-fit font-medium text-indigo-600 hover:underline" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? 'Read less' : 'Read more'}
        </button>
      )}
    </div>
  );
};
