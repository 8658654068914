import { useAppSelector } from 'src/core/store';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { BaseRouteProps } from './types';
import { createElement } from 'react';

export const AuthLayout = <P,>({ component, title, props, responsiveView = true }: BaseRouteProps<P>) => {
  const auth = useAppSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  if (auth.isAuthenticated) {
    const next = searchParams.get('next');
    return <Navigate to={next ? next : '/'} />;
  }
  return (
    <>
      <Helmet titleTemplate="%s | SentiSum" title={title} defaultTitle="SentiSum">
        {responsiveView && <meta name="viewport" content="width=device-width, initial-scale=1" />}
      </Helmet>
      {createElement(component, props)}
    </>
  );
};
