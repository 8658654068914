import { getDataApiClientInstance } from './utils/clientAdapters';
import { FormatFiltersV4Params, FormatFiltersV4State, formatFiltersV4, formatFiltersV5 } from './Tranformers';
import { ISummaryDataResponse, OverTimeData } from './apiTypes/Summary';
import { IDrillDownResponse } from 'src/types/DrillDown';
import { Dimension, Metric } from 'src/types/User';
import { IGetSummaryGraphDataResponse } from 'Slices/types/summary';

export const getSummaryOverviewGraphData = async (
  params: FormatFiltersV4Params,
  state?: FormatFiltersV4State,
  breakdownRequired?: boolean
): Promise<IGetSummaryGraphDataResponse> => {
  try {
    const filterbody = formatFiltersV5({ ...params, addTypeMetric: true }, state);
    const updatedFilterBody = {
      ...filterbody,
      conditions: {
        ...filterbody.conditions,
        breakdownRequired: breakdownRequired,
      },
    };
    const summaryOverviewKey = `v4/data/insights/total_count`;
    const response = await getDataApiClientInstance(summaryOverviewKey + JSON.stringify(updatedFilterBody)).post<OverTimeData>(
      summaryOverviewKey,
      updatedFilterBody
    );
    const source_object = updatedFilterBody.metrics.reduce((acc, metric) => {
      acc[metric.name] = response.data;
      return acc;
    }, {});
    return { data: source_object };
  } catch (error) {
    throw error?.response?.data?.message ? new Error(error?.response?.data?.message) : error;
  }
};

export const getDrillDownCounts = async (
  dimension: Dimension,
  metric: Metric,
  chartDimensionObject: Dimension,
  selectedTopic: string,
  state?: FormatFiltersV4State
) => {
  try {
    const filterbody = formatFiltersV4({ dimension, metric }, state);
    const updatedFilterBody = {
      ...filterbody,
      conditions: {
        ...filterbody.conditions,
        filter_dimension: {
          dimension: chartDimensionObject,
          filter_value: selectedTopic,
        },
      },
    };
    const summaryOverviewKey = 'v4/data/insights/drilldown_counts';
    return (await getDataApiClientInstance(summaryOverviewKey).post<IDrillDownResponse>(summaryOverviewKey, updatedFilterBody)).data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getSummaryDrivers = async (state?: FormatFiltersV4State) => {
  try {
    const summaryDriversKey = 'v4/data/insights/summary/drivers';
    const response = await getDataApiClientInstance(summaryDriversKey).post<ISummaryDataResponse>(summaryDriversKey, formatFiltersV4({}, state));
    return { data: response.data };
  } catch (error) {
    throw error?.response?.data?.message ? new Error(error?.response?.data?.message) : error;
  }
};
