import React, { HTMLAttributes, ReactHTML, createElement, forwardRef } from 'react';
import { useCss } from 'react-use';
import AvatarColurs from './AvatarColurs.json';
import { cn } from 'Utils/TailwindUtils';

interface AvatarProps<T extends HTMLElement> extends HTMLAttributes<T> {
  type?: keyof ReactHTML;
  name: string;
}

const AvatarComponent = <T extends HTMLElement>({ type = 'div', name, className, ...rest }: AvatarProps<T>, ref: React.Ref<T>) => {
  const nameValue = name?.charAt(0).toLocaleLowerCase();
  const color = AvatarColurs[nameValue?.charCodeAt(0) - 97];
  const colursClass = useCss({
    backgroundColor: color?.background,
    color: color?.text,
  });
  return createElement(
    type,
    {
      ...rest,
      ref,
      className: cn(
        'h-10 w-10 rounded-full border-2 border-white grid shrink-0 place-content-center text-sm font-bold uppercase drop-shadow-md',
        colursClass,
        className
      ),
    },
    nameValue
  );
};

export const Avatar = forwardRef(AvatarComponent);
