import { useWhiteLabel } from 'src/context/WhiteLabelContext';
import { useMutation } from '@tanstack/react-query';
import { getSSOProvider } from 'src/api/User';

export const useSsoProvider = () => {
  const { defaultSsoProvider } = useWhiteLabel();
  const ssoProviderQuery = useMutation({
    mutationKey: ['getSSOProvider'],
    mutationFn: getSSOProvider,
  });
  const getSsoProvider = async (email?: string) => {
    if (!email) return defaultSsoProvider?.value ?? 'default';
    const emailDomain = email.split('@')[1].split('.')[0];
    let ssoProvider = defaultSsoProvider?.value;
    if (!defaultSsoProvider) ssoProvider = await ssoProviderQuery.mutateAsync(email);
    else if (defaultSsoProvider.blacklistedDomains?.includes(emailDomain)) ssoProvider = 'default';
    return ssoProvider;
  };
  return { getSsoProvider, ssoProviderQuery };
};
