import { useAppDispatch, useAppSelector } from 'src/core/store';
import { MetricsListLoader } from '../AllMetrics/MetricsListLoader';
import { memo, useEffect, useState } from 'react';
import { DndContext, DragEndEvent, DragOverlay, DragStartEvent, PointerSensor, UniqueIdentifier, useSensor, useSensors } from '@dnd-kit/core';
import { useDroppable } from '@dnd-kit/core';
import { EditMetricCard } from './EditMetricCard';
import { configuationFromMetricSuggestion, updateMetricCards } from 'Slices/editMetricCard';
import { ConfigMetricCard } from './ConfigMetricCard';
import { getMetricSuggestions } from './utils';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';
import { MetricCardGridData } from './MetricCardGridData';

export const metricCardBgColors = ['bg-[#F1F3FD]', 'bg-[#F5F1FD]', 'bg-[#EDF8FD]', 'bg-[#F3FFE8]'];

export const MetricCardGrid = memo(({ overItemId, draggedItemId }: { overItemId: UniqueIdentifier; draggedItemId?: UniqueIdentifier }) => {
  const dispatch = useAppDispatch();
  const { setNodeRef } = useDroppable({
    id: 'edit-metric-card-container',
  });
  const { suggestedMetricCards, fetchMetricsStatus, metricCards, selecedMetricCard, fetchAvailbleDateRangeBoundsStatus } = useAppSelector(
    (state) => state.editMetricCard
  );

  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 1 } }));
  const handleDragStart = (event: DragStartEvent) => setActiveId(event.active.id);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) {
      setActiveId(null);
      return;
    }
    const oldIndex = metricCards.findIndex((metric) => metric.id === active.id);
    const newIndex = metricCards.findIndex((metric) => metric.id === over.id);
    dispatch(updateMetricCards(arrayMove(metricCards, oldIndex, newIndex)));
    setActiveId(null);
  };

  const reportId = useAppSelector((state) => state.editReport.id);

  useEffect(() => {
    if (reportId || !suggestedMetricCards.length || metricCards.length) return;
    let bgColorIndex = 0;
    const metricSuggestions = getMetricSuggestions().map((suggestion) => {
      const bgColor = metricCardBgColors[bgColorIndex];
      bgColorIndex = (bgColorIndex + 1) % metricCardBgColors.length;
      return configuationFromMetricSuggestion(suggestion, bgColor);
    });
    dispatch(updateMetricCards(metricSuggestions));
  }, [reportId, suggestedMetricCards]);

  if (fetchMetricsStatus === 'idle' || fetchMetricsStatus === 'pending' || fetchAvailbleDateRangeBoundsStatus !== 'fulfilled')
    return <MetricsListLoader className="grid grid-cols-2 gap-4" height={117} />;
  if (selecedMetricCard) return <EditMetricCard />;
  return (
    <>
      {!overItemId ? (
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors}>
          <SortableContext items={metricCards}>
            <MetricCardGridData
              metricCards={metricCards}
              overItemId={overItemId}
              draggedItemId={draggedItemId}
              setNodeRef={setNodeRef}
              activeId={activeId}
            />
          </SortableContext>
          {activeId &&
            createPortal(
              <DragOverlay>
                <ConfigMetricCard metric={metricCards.find((report) => report.id === activeId)} isDraging />
              </DragOverlay>,
              document.body
            )}
        </DndContext>
      ) : (
        <MetricCardGridData
          metricCards={metricCards}
          overItemId={overItemId}
          draggedItemId={draggedItemId}
          setNodeRef={setNodeRef}
          activeId={activeId}
        />
      )}
    </>
  );
});
