import React, { memo, useCallback, useState } from 'react';
import LinesEllipsis, { CommonReactLinesEllipsisProps } from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { cn } from 'Utils/TailwindUtils';
const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

interface TableHeaderProps extends React.ThHTMLAttributes<HTMLTableCellElement> {
  children: string;
}

export const TableHeader: React.FC<TableHeaderProps> = memo(
  ({ className, children, ...rest }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const reflowCallback = useCallback<CommonReactLinesEllipsisProps['onReflow']>(({ clamped }) => setShowTooltip(clamped), []);
    return (
      <th scope="col" className={cn('px-6 py-3', className)} {...rest}>
        <ResponsiveEllipsis
          text={children}
          maxLine={2}
          data-tooltip-content={showTooltip ? children : ''}
          data-tooltip-id="white-tooltip"
          onReflow={reflowCallback}
        />
      </th>
    );
  },
  (prevProps, nextProps) => prevProps.children === nextProps.children
);
