import React, { useCallback, useEffect } from 'react';
import { toast, useToasterStore } from 'react-hot-toast';
import { FiRefreshCw } from 'react-icons/fi';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { Transition } from '@headlessui/react';
import { Button } from 'src/components/Button';

const refreshNotification = () => {
  const refreshPage = () => {
    trackFilterEvent(TRACK_EVENT.VERSION_UPDATE_CONFIRMED);
    window.location.reload();
  };
  return toast.custom(
    (t) => (
      <Transition
        appear
        show={t.visible}
        className="pointer-events-auto flex gap-4 rounded-2xl bg-white p-4 pr-6 shadow-lg "
        enter="transform transition ease-out duration-1000 sm:duration-700"
        enterFrom="translate-x-96"
        enterTo="-translate-x-2"
      >
        <span className="flex-shrink-0 pt-1.5">
          <FiRefreshCw />
        </span>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-1">
            <div className="text-base font-semibold text-blue-1050">Kindly refresh</div>
            <div className="text-sm text-blue-970">The new version of the app is now available</div>
          </div>
          <Button label="Refresh" variant="primary" className="w-fit rounded-3xl" onClick={refreshPage} />
        </div>
      </Transition>
    ),
    { duration: Infinity, id: 'refreshNotification' }
  );
};

export const useRefreshNotification = () => {
  const { toasts } = useToasterStore();
  const displayNotification = useCallback(() => {
    if (document.visibilityState !== 'visible' || toasts.find((toast) => toast.id === 'refreshNotification')) return;
    refreshNotification();
    trackFilterEvent(TRACK_EVENT.VERSION_UPDATE);
  }, [toasts]);
  useEffect(() => {
    document.addEventListener('plugin_web_update_notice', displayNotification);
    return () => document.removeEventListener('plugin_web_update_notice', displayNotification);
  }, [displayNotification]);
};
