import React, { FC } from 'react';
import { VerticalBarGraphCard } from './VerticalBarGraphCard';
import { Label, LabelList, Legend } from 'recharts';
import { IChartComparator } from 'src/types/Filter';
import { METRICS_VALUE_META } from 'Utils/enums/ChartMetadata';
import { CustomTick } from '../../GraphComponents/CustomTick';
import { CustomLegend, currentBar, customYAxis, previousBar } from 'src/components/Charts/BarCharts/GraphComponents';
import { VerticalBarCustomLabel } from '../../GraphComponents/CustomLabel';

export interface VerticalBarGraphSectionProps {
  selectedMetricType: string;
  label?: string;
  type?: string;
  data: any;
  dateRange?: any;
  onClick?: (path: string) => void;
  chartComparator: IChartComparator;
  domain?: any;
  hideLabels?: boolean;
}

export const VerticalBarGraphSection: FC<VerticalBarGraphSectionProps> = (props) => {
  const { dateRange, label, type, data, selectedMetricType, hideLabels, onClick, domain, ...rest } = props;
  const currentCustomLabel = data.length <= 10 && !hideLabels && (
    <LabelList content={(props) => <VerticalBarCustomLabel data={data} dataKey="data" {...props} />} />
  );
  const barData = {
    barSize: 28,
    radius: [6, 6, 0, 0],
  };
  const yAxisLabel = <Label value={label} angle={-90} position="insideLeft" dy={40} />;
  const yAxisData = {
    domain: [domain?.minDomain ?? 'auto', domain?.maxDomain],
    tick: <CustomTick metricMetadata={METRICS_VALUE_META[selectedMetricType]} isVerticalBarGraph />,
  };
  return (
    <VerticalBarGraphCard data={data} type={type} selectedMetricType={selectedMetricType} onClick={onClick} {...rest}>
      {customYAxis({ yAxisData, yAxisLabel })}
      {!!dateRange && (
        <Legend
          content={<CustomLegend dateRange={dateRange.dateRange} previousDateRange={dateRange.previousDateRange} />}
          layout="horizontal"
          verticalAlign="top"
          align="right"
        />
      )}
      {previousBar({ onClick, barData })}
      {currentBar({ currentCustomLabel, onClick, barData })}
    </VerticalBarGraphCard>
  );
};
