import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { SOMETHING_WRONG } from '../utils/constants/common';
import { verifyEmailApi } from 'src/api/Onboard';
import { IApiCallStatus } from 'src/types/Globals';
import { registerApi } from 'src/api/User';

export const register = createAsyncThunk<void, { name: string; email: string; password: string; marketing: boolean }>(
  'onboard/register',
  ({ name, email, password, marketing }) => registerApi(name, email, password, marketing)
);

export const verifyEmail = createAsyncThunk<void, { email: string; code: string }>('onboard/verifyEmail', ({ email, code }) =>
  verifyEmailApi(email, code)
);

interface OnboardState {
  currentStep: string;
  user?: any;
  registerInitiated?: boolean;
  registerFailed?: boolean;
  registerFailureMessage?: string;
  verifyEmailStatus: IApiCallStatus;
  verifyEmailFailureMessage?: string;
}

const onboardInitialState: OnboardState = {
  currentStep: 'signup',
  verifyEmailStatus: 'idle',
};

const OnboardingSlice = createSlice({
  name: 'onboard',
  initialState: {
    ...onboardInitialState,
  },
  reducers: {
    reset: (state) => {
      state.currentStep = 'signup';
      state.user = null;
      state.registerInitiated = null;
      state.registerFailed = null;
      state.registerFailureMessage = null;
    },
    resetRegisterStatus: (state) => {
      state.registerInitiated = null;
      state.registerFailed = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state, action) => {
      const { name, email, password } = action.meta.arg;
      state.user = {
        ...state.user,
        fullName: name,
        email: email,
        password: password,
      };
      state.registerInitiated = true;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.registerInitiated = false;
      state.registerFailed = false;
      state.registerFailureMessage = null;
      state.currentStep = 'verifyEmail';
    });
    builder.addCase(register.rejected, (state, action) => {
      state.registerInitiated = false;
      state.registerFailed = true;
      state.registerFailureMessage = action?.error?.message || SOMETHING_WRONG;
    });
    builder.addCase(verifyEmail.pending, (state) => {
      state.verifyEmailStatus = 'pending';
      state.verifyEmailFailureMessage = null;
    });
    builder.addCase(verifyEmail.fulfilled, (state) => {
      state.verifyEmailStatus = 'fulfilled';
      state.verifyEmailFailureMessage = null;
      state.currentStep = 'getStarted';
    });
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.verifyEmailStatus = 'rejected';
      state.verifyEmailFailureMessage = action?.error?.message || SOMETHING_WRONG;
    });
  },
});

const persistConfig = {
  key: 'onboard',
  storage,
  whitelist: [],
};

export const { reset, resetRegisterStatus } = OnboardingSlice.actions;
export default persistReducer(persistConfig, OnboardingSlice.reducer);
