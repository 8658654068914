import { useEditorContext } from '../EditorContext';
import { MenuContent } from './MenuContent';
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';

export const LinkHoverMenu = () => {
  const { editorId } = useEditorContext();
  const [open, setOpen] = useState(false);
  return (
    <Tooltip
      id={editorId}
      delayHide={500}
      delayShow={500}
      render={(target) => (open ? MenuContent(target.activeAnchor) : undefined)}
      afterShow={() => setOpen(true)}
      afterHide={() => setOpen(false)}
      clickable
      opacity={1}
      arrowColor="transparent"
      disableStyleInjection
      place="bottom"
      className="z-50 !rounded-xl !bg-white !p-0"
    />
  );
};
