import React from 'react';
import { Box, Grid, Text } from 'grommet';
import { DateRangeLabel } from 'src/components/Label';
import type { IChartComparator } from 'src/types/Filter';
import type { CommonFilterState } from 'Slices/types/common';
import type { Topic } from 'src/api/apiTypes/Summary';
import type { Dimension, IEsAlias, Metric } from 'src/types/User';
import { SummaryTable } from 'src/ui/containers/SummaryTable';
import { ADMIN_INDEX_SOURCE_TYPES } from 'Utils/enums/SourceModelTypes';

interface ISummaryTableScreenShotProps {
  label: string;
  rowCount?: number;
  filters: Pick<CommonFilterState['filters'], 'dateRange' | 'previousDateRange'>;
  chartMetricData: Topic[];
  chartMetricObject: Metric;
  chartComparator: IChartComparator;
  sourceType: ADMIN_INDEX_SOURCE_TYPES;
  chartDimensionObject: Dimension;
}

export const SummaryTableScreenShot: React.FC<ISummaryTableScreenShotProps> = (props) => {
  const { label, filters, chartMetricData, chartMetricObject, chartComparator, sourceType, chartDimensionObject, rowCount } = props;
  const { dateRange, previousDateRange } = filters;
  return (
    <Box gap="medium">
      <Box direction="row" align="center" justify="between" pad={{ horizontal: 'small' }} gap="large">
        <Box gap="small" align="center" flex="shrink">
          <span className="text-base font-semibold capitalize">{label}</span>
        </Box>
        <Box flex="grow" width={{ max: 'fit-content' }}>
          <Grid columns={{ count: 2, size: 'flex' }} gap={{ row: 'small' }}>
            <Text size="xsmall" color="text-field-dark-2">
              Current:
            </Text>
            <DateRangeLabel start={dateRange.start} end={dateRange.end} color="text-field-dark-2" />
            <Text size="xsmall" color="text-field-dark-2">
              Comparing to:
            </Text>
            <DateRangeLabel {...previousDateRange} color="text-field-dark-2" />
          </Grid>
        </Box>
      </Box>
      <SummaryTable
        drivers={chartMetricData.slice(0, rowCount)}
        chartMetricObject={chartMetricObject}
        chartComparator={chartComparator}
        sourceType={sourceType}
        status="fulfilled"
        chartDimensionObject={chartDimensionObject}
        className="table-auto"
        lazyLoadDisabled={true}
      />
    </Box>
  );
};
