import * as Select from '@radix-ui/react-select';
import { ForwardedRef, createElement, forwardRef } from 'react';
import { TextInputGray } from '../Input';
import { useMatchedOptions, useSearchText } from '../Dropdown/Hooks';
import { cn } from 'Utils/TailwindUtils';
import { IconType } from 'react-icons';

interface RadixSingleSelectProps extends Select.SelectContentProps {
  options: { value: string; label: string; icon?: IconType }[];
  searchPlaceholder?: string;
  optionClassName?: string;
}

interface RadixSingleSelectWithNoSearchProps extends RadixSingleSelectProps {
  enableSearch?: false;
  menuOpen?: boolean;
  disableSorting?: boolean;
}

interface RadixSingleSelectWithSearchProps extends RadixSingleSelectProps {
  enableSearch: true;
  menuOpen?: boolean;
  disableSorting?: boolean;
}

export type RadixSingleSelectMenuProps = RadixSingleSelectWithNoSearchProps | RadixSingleSelectWithSearchProps;

const RadixSingleSelectComponent = (
  { options: values, className, enableSearch, searchPlaceholder, disableSorting, optionClassName, menuOpen, ...rest }: RadixSingleSelectMenuProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const [searchText, setSearchText] = useSearchText(menuOpen);
  const matchedOptions = useMatchedOptions(values, searchText, undefined, undefined, disableSorting);

  return (
    <Select.Content ref={ref} className={cn('radix-dropdown-content min-w-60', className)} position="popper" {...rest}>
      <div className="flex flex-col gap-2 py-2">
        {enableSearch && (
          <div className="px-4">
            <TextInputGray value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder={searchPlaceholder} autoFocus />
          </div>
        )}
        <div className="flex max-h-60 flex-col overflow-auto px-2">
          {matchedOptions.map(({ value, label, icon, disabled }) => (
            <Select.Item key={`select-item-${value}`} value={value} asChild disabled={disabled}>
              <button
                className={cn('flex rounded-md px-4 py-2 text-left text-sm text-blue-1100 hover:bg-sky-1000 disabled:text-blue-450', optionClassName)}
                disabled={disabled}
              >
                {icon && <span className="pr-2 pt-0.5">{createElement(icon, { size: '15px' })}</span>}
                {label}
              </button>
            </Select.Item>
          ))}
        </div>
      </div>
    </Select.Content>
  );
};

export const RadixSingleSelectMenu = forwardRef(RadixSingleSelectComponent);
