import { HTTP_ERROR_MESSAGES, NETWORK_ERROR } from '../../core/utils/constants/common';

export const validateErrorResponse = (error?: { message?: string; response?: { data?: { message?: string } } }) => {
  if (error?.message === HTTP_ERROR_MESSAGES.NETWORK_ERROR) {
    throw new Error(NETWORK_ERROR);
  }
  if (error?.message !== HTTP_ERROR_MESSAGES.ERR_CANCELED) {
    throw new Error(error?.response?.data?.message);
  }
};
