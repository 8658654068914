import React, { FC } from 'react';
import { Box, Text } from 'grommet';
import { DateRangeLabel } from 'src/components/Label';
import type { IChartComparator } from 'src/types/Filter';
import type { CommonFilterState } from 'Slices/types/common';
import type { Topic } from 'src/api/apiTypes/Summary';
import type { Metric } from 'src/types/User';
import { ReportType } from 'src/types/Report';
import { HorizontalBarGraphContainer } from '../BarGraph/HorizontalBarGraphContainer';

interface IHorizontalBarGraphScreenShotProps {
  label: string;
  rowCount?: number;
  filters: Pick<CommonFilterState['filters'], 'dateRange' | 'previousDateRange'>;
  chartMetricData: Topic[];
  chartMetricObject: Metric;
  chartComparator: IChartComparator;
  type: ReportType;
}

export const HorizontalBarGraphScreenShot: FC<IHorizontalBarGraphScreenShotProps> = (props) => {
  const { label, filters, chartMetricData, chartMetricObject, chartComparator, type, rowCount } = props;
  const { dateRange, previousDateRange } = filters;

  return (
    <div className="w-[40rem]">
      <Box direction="row" align="center" justify="between" pad={{ horizontal: 'small' }} gap="large">
        <Box gap="small" align="center" flex="shrink">
          <span className="text-base font-semibold capitalize">{label}</span>
        </Box>
        <div className="max-w-max">
          <div className="flex gap-6">
            <Text size="xsmall" color="text-field-dark-2">
              Current:
            </Text>
            <span className="flex items-center justify-center gap-1.5">
              {(type === 'H' || type === 'L') && <span className="size-2 rounded-full bg-indigo-600"></span>}
              <DateRangeLabel start={dateRange.start} end={dateRange.end} color="text-field-dark-2" />
            </span>
          </div>
          <div className="flex gap-6">
            <Text size="xsmall" color="text-field-dark-2">
              Comparing to:
            </Text>
            <span className="flex items-center justify-center gap-1.5">
              {(type === 'H' || type === 'L') && <span className="size-2 rounded-full bg-indigo-100"></span>}
              <DateRangeLabel {...previousDateRange} color="text-field-dark-2" />
            </span>
          </div>
        </div>
      </Box>
      <HorizontalBarGraphContainer
        drivers={chartMetricData.slice(0, rowCount)}
        chartComparator={chartComparator}
        chartMetricObject={chartMetricObject}
        tableVisualization={type}
      />
    </div>
  );
};
