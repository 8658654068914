import React, { FC, ForwardedRef, Fragment, PropsWithChildren } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { Transition } from '@headlessui/react';
import { cn } from 'Utils/TailwindUtils';

export type NestedContentProps = DialogPrimitive.DialogContentProps & { isOpen: boolean; addPortal?: boolean; overlayClassName?: string };

const PortalWrapper: FC<PropsWithChildren<{ addPortal: boolean }>> = ({ addPortal, children }) =>
  addPortal ? <DialogPrimitive.Portal>{children}</DialogPrimitive.Portal> : <>{children}</>;

const NestedContentComponent = (
  { children, className, isOpen, addPortal = true, overlayClassName, ...props }: NestedContentProps,
  ref: ForwardedRef<HTMLDivElement>
) => (
  <PortalWrapper addPortal={addPortal}>
    <Transition.Root show={isOpen}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <DialogPrimitive.Overlay forceMount className={cn('fixed inset-0 z-20 bg-black/50', overlayClassName)} />
      </Transition.Child>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <DialogPrimitive.Content
          forceMount
          className={cn('fixed z-20 bg-white', { 'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2': addPortal }, className)}
          {...props}
          ref={ref}
        >
          {children}
        </DialogPrimitive.Content>
      </Transition.Child>
    </Transition.Root>
  </PortalWrapper>
);

export const NestedDialogContent = React.forwardRef(NestedContentComponent);

export * from '@radix-ui/react-dialog';
