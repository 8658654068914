import { Blank, IconProps } from 'grommet-icons';
import React from 'react';
import { ViewUnlockImage } from '../images';

const ViewLock: React.FC<IconProps & JSX.IntrinsicElements['svg']> = (props) => {
  return (
    <Blank {...props}>
      <ViewUnlockImage />
    </Blank>
  );
};

export default ViewLock;
