import React, { createElement } from 'react';
import type { BreadCrumb as BreadCrumbProps } from './types';
import { cn } from 'Utils/TailwindUtils';
import { NavLink } from 'react-router-dom';

interface BreadCrumbExtendedProps extends BreadCrumbProps {
  iconSize?: number;
}

export const BreadCrumb: React.FC<BreadCrumbExtendedProps> = ({ className, label, icon, iconSize, to: href, ...rest }) => {
  return (
    <div className={cn('flex items-center gap-3 text-sm', className)} {...rest}>
      {icon && <span className="mt-1 shrink-0"> {createElement(icon, { size: iconSize })}</span>}
      {href ? (
        <NavLink to={href} className="line-clamp-1 hover:underline">
          {label}
        </NavLink>
      ) : (
        <span className="line-clamp-1">{label}</span>
      )}
    </div>
  );
};
