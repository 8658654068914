import { FC, useEffect, useMemo } from 'react';
import * as NestedDialog from 'src/components/Dialog/NestedDialog';
import { useAppSelector } from 'src/core/store';
import { DiyTagsModal } from 'src/ui/containers/DiyTagsModal';
import { useDiyTagModalStateContext } from './SearchDialog';

interface CreateDiyTagButtonProps {
  searchTags: string[];
}

export const CreateDiyTagButton: FC<CreateDiyTagButtonProps> = ({ searchTags }) => {
  const { setShowDiyTagModal, showDiyTagModal } = useDiyTagModalStateContext();
  const diyTagValue = useMemo(
    () => ({
      name: '',
      includeKeywords: searchTags,
      excludeKeywords: [],
    }),
    [searchTags]
  );
  return (
    <NestedDialog.Root open={showDiyTagModal} onOpenChange={setShowDiyTagModal}>
      <NestedDialog.Trigger className="w-full rounded-b-xl border-t px-4 py-3 text-left text-xs text-gray-850 hover:bg-gray-650 hover:text-blue-1100">
        + Create this as a DIY Tag
      </NestedDialog.Trigger>
      <DiyTagsModal isOpen={showDiyTagModal} type="create" intialValue={diyTagValue} setOpen={setShowDiyTagModal} />
    </NestedDialog.Root>
  );
};
