import React from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import { useEditorContext } from '../../EditorContext';
import { HiCheck } from 'react-icons/hi';

interface ColorCheckboxProps {
  colour: string;
  checked: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ColorCheckbox: React.FC<ColorCheckboxProps> = ({ colour, checked, setOpen }) => {
  const { editor } = useEditorContext();
  const onCheckedChange = () => {
    editor?.chain().focus().setColor(colour).run();
    setOpen(false);
  };
  return (
    <div className="px-2 drop-shadow-xl">
      <Checkbox.Root
        checked={checked}
        onCheckedChange={onCheckedChange}
        className="flex h-5 w-5 items-center justify-center rounded-full ring-white radix-state-checked:ring-2"
        style={{ backgroundColor: colour }}
      >
        <Checkbox.Indicator className="contents text-base text-white">
          <HiCheck />
        </Checkbox.Indicator>
      </Checkbox.Root>
    </div>
  );
};
