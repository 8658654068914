import React, { FC, PropsWithChildren } from 'react';
import { BarChart, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import { IChartComparator } from 'src/types/Filter';
import { TickValues } from '../../GraphComponents/TickValues';
import { IChartData } from '../HorizontalBarGraph/HorizontalBarGraphCard';
import { VerticalBarGraphTooltipContainer, customXAxis } from 'src/components/Charts/BarCharts/GraphComponents';

export interface VerticalBarGraphCardProps {
  data: IChartData[];
  chartComparator: IChartComparator;
  selectedMetricType: string;
  type?: string;
  onClick?: (name: string) => void;
}

export const VerticalBarGraphCard: FC<PropsWithChildren<VerticalBarGraphCardProps>> = ({
  data,
  type,
  children,
  chartComparator,
  selectedMetricType,
  onClick,
}) => {
  const xAxisData = {
    interval: type === 'overTime' ? ('preserveStartEnd' as const) : data.length <= 5 ? 0 : (Math.floor(data.length / 5) as number),
    ...(type !== 'overTime' && {
      tick: (props) => {
        const { x, y, payload } = props;
        return <TickValues x={x} y={y} value={payload.value} onClick={onClick} />;
      },
    }),
  };
  return (
    <ResponsiveContainer width="100%" className="vertical-bar-graph">
      <BarChart data={data} barGap={-8} reverseStackOrder>
        <CartesianGrid stroke="#D0D5DD" strokeWidth={0.3} vertical={false} />
        {customXAxis({ xAxisData })}
        <Tooltip
          content={(props) => (
            <VerticalBarGraphTooltipContainer
              active={props.active}
              payload={props.payload}
              chartComparator={chartComparator}
              selectedMetricType={selectedMetricType}
            />
          )}
          cursor={{ fill: 'transparent' }}
        />
        {children}
      </BarChart>
    </ResponsiveContainer>
  );
};
