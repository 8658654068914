import React, { createElement, forwardRef } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { FormTextInput } from './FormTextInput';

interface FormPasswordInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactNode;
}

export const FormPasswordInput = forwardRef<HTMLInputElement, FormPasswordInputProps>((props, ref) => {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <FormTextInput {...props} type={showPassword ? 'text' : 'password'} ref={ref}>
      <button className="mx-3 flex items-center text-lg" onClick={() => setShowPassword(!showPassword)} type="button">
        {createElement(showPassword ? FiEye : FiEyeOff)}
      </button>
    </FormTextInput>
  );
});
