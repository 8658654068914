import { useEffect, useState, useMemo } from 'react';
import { useAppSelector } from 'src/core/store';

import { toCSV } from 'react-csv/lib/core';
import moment from 'moment';
import { SourceModelTypesEnum } from 'Enums/SourceModelTypes';
import { LANG_TYPES, TEXT_TYPES } from 'Constants/languages';
import { CHART_COMPARATOR_TYPES_ENUM } from 'Enums/ChartComparatorTypes';
import { getMetricValueAndPercentChange } from 'Utils/chartsV2/graphFunctions';
import { SUMMARY_DRIVER_TYPES, DRIVERS_TYPE_TEXTS } from 'Enums/SummaryDriverTypes';
import { EXPORT_DATA_TYPE } from '../DataExportEnums';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { SURVEY_TYPES } from 'Enums/SourceModelTypes';
import { IconConfirm } from 'src/components/Dialog';
import { capitalize } from 'lodash';
import { FiDownload } from 'react-icons/fi';
import { casex } from 'casex';
import { saveAs } from 'file-saver';

const setSummaryMetricHeaderV2 = ({ data, chartComparator }) => {
  const summaryHeader = ['Summary', 'Tickets', 'Value'];
  summaryHeader.push(`${CHART_COMPARATOR_TYPES_ENUM?.[chartComparator]?.[LANG_TYPES.EN]?.[TEXT_TYPES.ALIAS]} Change`);
  data.push(summaryHeader);
};

const setSummaryDriversMetricHeaderV3 = ({ data, chartMetric, chartComparator, chartDimensionObject }) => {
  const summaryHeader = ['Summary', `${chartDimensionObject?.display_name}`];
  summaryHeader.push(capitalize(`${SourceModelTypesEnum?.[chartMetric]?.[LANG_TYPES.EN]?.[TEXT_TYPES.ALIAS]}`));

  summaryHeader.push(
    capitalize(
      `${CHART_COMPARATOR_TYPES_ENUM?.[chartComparator]?.[LANG_TYPES.EN]?.[TEXT_TYPES.ALIAS]} ${
        SourceModelTypesEnum?.[chartMetric]?.[LANG_TYPES.EN]?.[TEXT_TYPES.ALIAS]
      } Change`
    )
  );
  data.push(summaryHeader);
};
const setDriversMetricDataInCsvV3 = ({ data, chartMetric, chartComparator, driversType, chartMetricData }) => {
  if (chartMetricData && Array.isArray(chartMetricData)) {
    chartMetricData.forEach((driverDataMetric) => {
      const csvDriversValue = [];
      csvDriversValue.push(
        DRIVERS_TYPE_TEXTS?.[driversType]?.[chartMetric]?.captionName
          ? DRIVERS_TYPE_TEXTS?.[driversType]?.[chartMetric]?.captionName
          : DRIVERS_TYPE_TEXTS?.[driversType]?.captionName
      );
      csvDriversValue.push(casex({ text: driverDataMetric?.name, pattern: 'Ca Se' }));
      const metricValueAndChange = getMetricValueAndPercentChange(driverDataMetric.current_interval.metrics, chartMetric, chartComparator);
      csvDriversValue.push(metricValueAndChange.value);

      csvDriversValue.push(metricValueAndChange.change);
      data.push(csvDriversValue);
    });
  }
};

const formCsvDataV3 = ({
  data,
  chartDimensionObject,
  chartMetric,
  chartComparator,
  summaryGraphDataV2,
  summaryDriversData,
  selectedSourceClientData,
}) => {
  const isSurveyType = SURVEY_TYPES.includes(selectedSourceClientData?.es_alias?.type);
  setSummaryMetricHeaderV2({ data, chartComparator });
  Object.keys(summaryGraphDataV2).forEach((metric) => {
    const selectedSummaryGraphData = summaryGraphDataV2[metric];
    const summaryMetricData = [];
    summaryMetricData.push(SourceModelTypesEnum?.[metric]?.[LANG_TYPES.EN]?.[TEXT_TYPES.ALIAS].replace(/\w+/g, capitalize));
    summaryMetricData.push(`${chartDimensionObject?.display_name}`);
    const metricValueChange = getMetricValueAndPercentChange(selectedSummaryGraphData?.current_interval?.metrics, metric, chartComparator);
    summaryMetricData.push(metricValueChange.value);
    summaryMetricData.push(metricValueChange.change);
    data.push(summaryMetricData);
  });
  data.push([]);
  setSummaryDriversMetricHeaderV3({
    data,
    chartMetric,
    chartComparator,
    chartDimensionObject,
  });
  if (!isSurveyType)
    setDriversMetricDataInCsvV3({
      data,
      chartMetric,
      chartComparator,
      driversType: SUMMARY_DRIVER_TYPES.DRIVERS,
      chartMetricData: summaryDriversData?.['drivers'],
    });

  const important_drivers = summaryDriversData?.['card_drivers']?.find((data) => data.change === chartComparator);
  const top_decreases = [...important_drivers?.[SUMMARY_DRIVER_TYPES.TOP_DECREASES]];
  const top_increases = [...important_drivers?.[SUMMARY_DRIVER_TYPES.TOP_INCREASES]];
  summaryDriversData?.['drivers']?.forEach((driver) => {
    important_drivers?.[SUMMARY_DRIVER_TYPES.TOP_DECREASES]?.find((value, index) => {
      if (value === driver.name) {
        top_decreases.splice(index, 1, driver);
        return true;
      }
      return false;
    });
    important_drivers?.[SUMMARY_DRIVER_TYPES.TOP_INCREASES]?.find((value, index) => {
      if (value === driver.name) {
        top_increases.splice(index, 1, driver);
        return true;
      }
      return false;
    });
  });
  if (isSurveyType) {
    const biggestChangesDrivers = summaryDriversData?.biggestChanges?.find(({ change }) => change === chartComparator).driverName;
    const biggestChangesData = summaryDriversData?.drivers.reduce((acc, driver) => {
      if (biggestChangesDrivers.includes(driver.name)) acc.push(driver);
      return acc;
    }, []);
    setDriversMetricDataInCsvV3({
      data,
      chartMetric,
      chartComparator,
      driversType: SUMMARY_DRIVER_TYPES.TOP_REASONS,
      chartMetricData: biggestChangesData,
    });
  }
  setDriversMetricDataInCsvV3({
    data,
    chartMetric,
    chartComparator,
    driversType: SUMMARY_DRIVER_TYPES.TOP_DECREASES,
    chartMetricData: top_decreases,
  });
  setDriversMetricDataInCsvV3({
    data,
    chartMetric,
    chartComparator,
    driversType: SUMMARY_DRIVER_TYPES.TOP_INCREASES,
    chartMetricData: top_increases,
  });
};
export const SummaryDataExport = ({ doSummaryExport, updateSummaryExportStatus }) => {
  const data = [];

  const { summaryGraphDataV2, summaryDriverGraphDataV2, fetchSummaryGraphDataV2Status, fetchSummaryDriversDataV2Status } = useAppSelector(
    (state) => state.summary
  );
  const { selectedSourceClientData, chartComparator, chartDimensionObject, chartMetric } = useAppSelector((state) => state.filter);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const summaryDriversData = useMemo(() => {
    return summaryDriverGraphDataV2;
  }, [summaryDriverGraphDataV2, chartComparator]);

  const datetime = moment();

  let fileName = `${selectedSourceClientData?.display_name}_summary_export_${datetime.format('YYYY_M_D_H_m_s')}.csv`;

  const closeDialog = (status) => {
    setShowConfirmationDialog(status);
    if (!status) {
      updateSummaryExportStatus();
    }
  };

  const startToDownload = () => {
    const csvString = toCSV(data, null, `,`, `"`);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, fileName);
    trackFilterEvent(TRACK_EVENT.DATA_EXPORT, { exportDataType: EXPORT_DATA_TYPE.SUMMARY_DATA });
    setShowConfirmationDialog(false);
    updateSummaryExportStatus();
  };

  useEffect(() => {
    if (doSummaryExport) {
      setShowConfirmationDialog(true);
    } else {
      setShowConfirmationDialog(false);
      updateSummaryExportStatus();
    }
  }, [doSummaryExport]);

  if (summaryDriversData && summaryGraphDataV2 && fetchSummaryGraphDataV2Status === 'fulfilled' && fetchSummaryDriversDataV2Status === 'fulfilled') {
    formCsvDataV3({
      data,
      chartComparator,
      chartDimensionObject,
      chartMetric,
      summaryGraphDataV2,
      summaryDriversData,
      selectedSourceClientData,
    });
  }

  return (
    <IconConfirm
      visible={showConfirmationDialog}
      setVisible={closeDialog}
      onOk={startToDownload}
      icon={FiDownload}
      title="Export Summary Data"
      text={'Do you want to export summary data?'}
      okText="Yes"
      cancelText="No"
      okExtra={{ variant: 'primary' }}
    />
  );
};
