import React, { useLayoutEffect } from 'react';
import { useAppDispatch } from 'src/core/store';
import styled from 'styled-components';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { asyncErrorConfirm } from 'src/components/Dialog';
import { FiTrash2 } from 'react-icons/fi';
import { IFilterTheme } from 'src/types/Filter';
import { TransparentClose } from 'src/components/Button';
import { FILTER_ACTION_TYPES, FILTER_TYPES } from 'src/types/enums';
import { cloneDeep } from 'lodash';
import { useFilterContext } from './DataSourceFilters';

const StyledDataSourceLabel = styled.div`
  color: ${(props) => props['data-text-color']};
  background: ${(props) => props['data-bg-color']};
  &:hover div {
    --tw-gradient-from: ${(props) => props['data-bg-color']};
  }
`;

interface DataSourceFilterLabelProps {
  dataSource: string;
  theme: IFilterTheme;
}

export const DataSourceFilterLabel: React.FC<DataSourceFilterLabelProps> = ({ dataSource, theme }) => {
  const dispatch = useAppDispatch();
  const { datasourceFilters: dataSourceFilters, updateDataSourceFilters } = useFilterContext();
  const closeButtonRef = React.useRef<HTMLButtonElement>(null);
  const deleteFilter = async () => {
    if (
      !(await asyncErrorConfirm('Are you sure, you want to remove ALL the filters under this source?', {
        title: 'Removing all filters',
        icon: FiTrash2,
        cancelText: 'No, keep it',
        okText: 'Yes, Remove',
      }))
    )
      return;
    const bookmarkdata = cloneDeep(dataSourceFilters);
    delete bookmarkdata[dataSource];
    trackFilterEvent(TRACK_EVENT.FILTER_ACTION, {
      Data_Source: dataSource,
      Type: FILTER_TYPES.DATA_SOURCE,
      Action_Type: FILTER_ACTION_TYPES.REMOVE,
    });
    dispatch(updateDataSourceFilters(bookmarkdata));
  };
  return (
    <StyledDataSourceLabel className="group/dataSource relative h-max rounded-md p-2" data-bg-color={theme?.background} data-text-color={theme?.text}>
      <div className="text-xs capitalize">{dataSource}</div>
      <TransparentClose
        onClick={deleteFilter}
        className="top-0 w-4 max-w-full rounded-md to-transparent opacity-0 group-hover/dataSource:opacity-100"
        data-tip="Remove All the filters under this source"
        ref={closeButtonRef}
      />
    </StyledDataSourceLabel>
  );
};
