import { FC, useState } from 'react';

export const confirmable =
  <T,>(Component: FC<BaseConfirmProps<T>>) =>
  ({ dispose, reject, resolve, ...other }: ConfirmableProps<T>) => {
    const [show, setShow] = useState(true);

    const dismiss = () => {
      setShow(false);
      dispose();
    };

    const cancel = (value: boolean) => {
      setShow(false);
      reject(value);
    };

    const proceed = (value: boolean) => {
      setShow(false);
      resolve(value);
    };

    return <Component cancel={cancel} dismiss={dismiss} proceed={proceed} show={show} {...other} />;
  };
