import React, { forwardRef } from 'react';
import { HTMLButtonProps } from 'src/types/Globals';
import { cn } from 'Utils/TailwindUtils';

export const FilterButton = forwardRef<HTMLButtonElement, HTMLButtonProps>(({ className, children, ...props }, ref) => (
  <button
    className={cn(
      'flex items-center justify-between gap-1 rounded-2xl border border-white bg-gray-650 p-3 text-xs font-semibold',
      'truncate hover:bg-gray-1000 disabled:opacity-50 radix-state-open:border-blue-1100',
      className
    )}
    {...props}
    ref={ref}
  >
    {children}
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 1H2L4 3.5L6 1Z" fill="#4F4F4F" stroke="#4F4F4F" />
    </svg>
  </button>
));
