import React, { createElement, FC, useCallback } from 'react';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { cn } from 'Utils/TailwindUtils';
import type { IconType } from 'react-icons/lib';
import type { ToggleSingleGroupProps } from './types';

export const IconToggle: FC<ToggleSingleGroupProps<IconType>> = ({ className, options, optionClassName, allowNull, onValueChange, ...props }) => {
  const changeHandler = useCallback((value: string) => ((!value && allowNull) || value) && onValueChange?.(value), [allowNull, onValueChange]);
  return (
    <ToggleGroup.Root type="single" className={cn('flex text-base', className)} onValueChange={changeHandler} {...props}>
      {options.map(({ label, className, value: optionValue, ...rest }) => (
        <ToggleGroup.Item
          className={cn('flex items-center', optionClassName, className)}
          aria-label={optionValue}
          value={optionValue}
          key={optionValue}
          {...rest}
        >
          {createElement(label)}
        </ToggleGroup.Item>
      ))}
    </ToggleGroup.Root>
  );
};
