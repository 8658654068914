import React from 'react';
import { useEditorContext } from '../../EditorContext';
import { MdFormatBold, MdFormatItalic, MdFormatListBulleted, MdFormatListNumbered } from 'react-icons/md';
import { MenuOption } from './MenuOption';

export const ToggleOptions = () => {
  const { editor } = useEditorContext();
  const toggleOptions = [
    { icon: MdFormatBold, command: () => editor?.chain().focus().toggleBold().run(), name: 'bold' },
    { icon: MdFormatItalic, command: () => editor?.chain().focus().toggleItalic().run(), name: 'italic' },
    { icon: MdFormatListBulleted, command: () => editor?.chain().focus().toggleBulletList().run(), name: 'bulletList' },
    { icon: MdFormatListNumbered, command: () => editor?.chain().focus().toggleOrderedList().run(), name: 'orderedList' },
  ];
  return (
    <>
      {toggleOptions.map((option) => (
        <MenuOption key={option.name} {...option} />
      ))}
    </>
  );
};
