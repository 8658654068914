import { cn } from 'Utils/TailwindUtils';
import { VariantProps, cva } from 'class-variance-authority';
import React, { HTMLAttributes } from 'react';

export const errorCVA = cva([], {
  variants: {
    size: {
      small: 'text-sm',
      large: 'text-lg',
    },
    type: {
      error: 'text-red-1000',
      warning: 'text-warning-600',
    },
  },
  defaultVariants: {
    size: 'large',
    type: 'error',
  },
});

interface FormErrorProps extends HTMLAttributes<HTMLParagraphElement>, VariantProps<typeof errorCVA> {
  message?: string;
}

const FormError: React.FC<FormErrorProps> = ({ className, message, children, type, size, ...rest }) =>
  message || children ? (
    <p className={cn(errorCVA({ size, type }), className)} {...rest} role="alert">
      {message || children}
    </p>
  ) : null;

export default FormError;
