import React, { useCallback } from 'react';
import { HiOutlinePencil } from 'react-icons/hi';
import { updateCardName } from 'Slices/editMetricCard';
import { EditableTextInput } from 'src/components/Input';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { z } from 'zod';

const ReportNameSchema = z.string().max(100, { message: 'Metric card name should be less than 100 characters' });

export const EditMetricCardName = () => {
  const dispatch = useAppDispatch();
  const selecedMetricCard = useAppSelector((state) => state.editMetricCard.selecedMetricCard);
  const renameCard = useCallback((value: string) => dispatch(updateCardName(value)), []);
  return (
    <div className="pl-4 pt-3 text-left">
      <EditableTextInput
        className="flex items-baseline gap-2 text-base"
        value={selecedMetricCard.name}
        onChange={renameCard}
        schema={ReportNameSchema}
      >
        <span className="m-2 font-medium">{selecedMetricCard.name}</span>
        <HiOutlinePencil className="text-slate-550 hover:text-blue-1100" size={14} />
      </EditableTextInput>
    </div>
  );
};
