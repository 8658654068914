import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { BarSkeleton } from 'src/components/SkeletonPlaceHolder';
import { HTMLDivProps } from 'src/types/Globals';

export const SummaryGraphCardLoader: React.FC<HTMLDivProps> = (props) => {
  return (
    <div {...props}>
      <div className="mb-20">
        <BarSkeleton width="20%" />
      </div>
      <Skeleton height={160} />
    </div>
  );
};
