import moment from 'moment';
import { CommonFilterState } from 'Slices/types/common';
import { DateRange } from 'src/types/Globals';

export const getPreviousDefaultDateRange = (dateRange: DateRange) => {
  const dateDiff = moment(dateRange.end).diff(dateRange.start, 'days');
  if (dateDiff > 7) {
    return {
      start: moment(dateRange.start).subtract(dateDiff, 'days').toDate(),
      end: moment(dateRange.end).subtract(dateDiff, 'days').toDate(),
    };
  } else {
    return {
      start: moment(dateRange.start).subtract(7, 'days').toDate(),
      end: moment(dateRange.end).subtract(7, 'days').toDate(),
    };
  }
};

const getPreviousDateIntervals = (currentDateRange: DateRange) => {
  const { start, end } = getPreviousDefaultDateRange(currentDateRange);
  return currentDateRange
    ? [
        {
          label: 'Default',
          startDate: start,
          endDate: end,
          key: 'default',
        },
        {
          label: 'Previous Day',
          startDate: moment(currentDateRange.start).subtract(1, 'days').toDate(),
          endDate: moment(currentDateRange.end).subtract(1, 'days').toDate(),
          key: 'day',
        },
        {
          label: 'Previous Week',
          startDate: moment(currentDateRange.start).subtract(1, 'weeks').toDate(),
          endDate: moment(currentDateRange.end).subtract(1, 'weeks').toDate(),
          key: 'week',
        },
        {
          label: 'Previous Month',
          startDate: moment(currentDateRange.start).subtract(1, 'months').toDate(),
          endDate: moment(currentDateRange.end).subtract(1, 'months').toDate(),
          key: 'month',
        },
      ]
    : [];
};

type PreviousFilterData = Pick<CommonFilterState['filters'], 'dateRange' | 'previousDateRange' | 'previous_interval'>;

export const setPreveiousDateRangeOnDateRangeChange = (data: PreviousFilterData) => {
  const { dateRange, previousDateRange, previous_interval } = data;
  const previousDateIntervals = getPreviousDateIntervals(dateRange);
  const currentPreviousDateInterval = previousDateIntervals.find((interval) => interval.key === previous_interval);
  if (currentPreviousDateInterval) return { start: currentPreviousDateInterval.startDate, end: currentPreviousDateInterval.endDate };
  else return previousDateRange;
};

export default getPreviousDateIntervals;
