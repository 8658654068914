import { CloseButton } from 'Static/images';
import { cn } from 'Utils/TailwindUtils';
import React, { FC } from 'react';
import { HiOutlineCheck, HiOutlineExclamationTriangle } from 'react-icons/hi2';
import { Button } from 'src/components/Button';
import * as Dialog from 'src/components/Dialog/NestedDialog';
const inviteStatusMessage = new Map([
  [
    'error',
    {
      title: 'Something went wrong',
      icon: HiOutlineExclamationTriangle,
      iconClassName: 'border-red-50 bg-red-100 text-red-800',
      description: "It's not you, it's us. Something on our side went wrong, kindly try again.",
      okText: 'Try again',
    },
  ],
  [
    'success',
    {
      title: 'Invitation Sent',
      icon: HiOutlineCheck,
      iconClassName: 'border-green-50 bg-green-100 text-green-800',
      description: 'Your teammates are invited to use SentiSum.',
      okText: 'Done',
    },
  ],
]);

export const InviteStatus: FC<{ status: string }> = ({ status }) => {
  if (!inviteStatusMessage.has(status)) return null;
  const { title, icon: Icon, iconClassName, description, okText } = inviteStatusMessage.get(status);
  return (
    <>
      <div className="flex">
        <span className={cn('grid h-14 w-14 place-items-center rounded-full border-8 p-1', iconClassName)}>
          <Icon className="text-xl" />
        </span>
        <span className="ml-auto">
          <Dialog.Close>
            <CloseButton />
          </Dialog.Close>
        </span>
      </div>
      <div className="mb-7 mt-5 flex flex-col gap-1.5">
        <p className="text-lg font-semibold leading-7 text-gray-900">{title}</p>
        <p className="text-sm font-normal leading-5 text-gray-500">{description}</p>
      </div>
      <Dialog.Close asChild>
        <Button variant="primary" label={okText} size="large" className="rounded-lg" />
      </Dialog.Close>
    </>
  );
};
