import { FC, PropsWithChildren } from 'react';
import { ExportAsPngContextProvider } from './ExportAsPngContext';
import { SentryErrorContext } from './SentryError';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { WhiteLabelProvider } from './WhiteLabelContext';
import { ToastContainer } from './ToastContainer';
import { ReactTooltipContainer, WhiteTextTooltip } from 'src/components/Tooltip';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'src/core/store';
import { Grommet } from 'grommet';
import theme from 'src/ui/theme';

const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: 1000 * 60 * 5 } } });

const GlobalContext: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Grommet theme={theme} themeMode="light" className="root">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HelmetProvider>
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <SentryErrorContext>
                  <WhiteLabelProvider>
                    <ExportAsPngContextProvider>{children}</ExportAsPngContextProvider>
                  </WhiteLabelProvider>
                </SentryErrorContext>
              </QueryClientProvider>
            </BrowserRouter>
            <ReactTooltipContainer id="tooltip" />
            <WhiteTextTooltip id="white-tooltip" />
            <ToastContainer />
          </HelmetProvider>
        </PersistGate>
      </Provider>
    </Grommet>
  );
};

export default GlobalContext;
