import { DigInUsage } from 'src/types/DigIn';
import { getDataApiClientInstance, getAdminClientInstance } from './utils/clientAdapters';

export const digInSummaryViewed = async () => {
  const key = 'client/useSummary';
  return await getAdminClientInstance(key).post(key);
};

export const getDigInUsage = async () => {
  const key = 'client/summaryUsage';
  return (await getAdminClientInstance(key).get<{ data: DigInUsage }>(key)).data.data;
};

export const submitDigInFeedback = async (id: string, feedbackText: string, rating: number) => {
  const key = 'v4/summaryFeedback';
  return await getDataApiClientInstance(key).post(key, { id, feedbackText, rating });
};
