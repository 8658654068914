import { useQuery } from '@tanstack/react-query';
import React, { PropsWithChildren, createContext, useMemo } from 'react';
import { getWhiteLabelConfig, getWhiteLabelSubdomains } from 'src/api/WhiteLabel';
import { PageSpinner } from 'src/components/Spinner';
import type { WhiteLabelConfig } from 'src/types/WhiteLabel';
import defaultConfig from './defaultConfig.json';

type WhiteLabelContextType = ({ isWhiteLabel: true; cdnFolder: string } | { isWhiteLabel: false }) & WhiteLabelConfig;

const WhiteLabelContext = createContext<WhiteLabelContextType | undefined>(undefined);

export const WhiteLabelProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const subDomainName = useMemo(() => window.location.hostname.split('.')[0], []);
  const cdnFolder = useMemo(() => `${process.env.REACT_APP_CDN_ENDPOINT}${subDomainName}/`, []);
  const subdomaisQuery = useQuery({
    queryKey: ['subdomains'],
    queryFn: getWhiteLabelSubdomains,
  });
  const isWhiteLabel = useMemo(() => subdomaisQuery.data?.includes(subDomainName), [subdomaisQuery.data]);
  const configQuery = useQuery({
    queryKey: ['white-label-config', isWhiteLabel],
    queryFn: () => getWhiteLabelConfig(subDomainName),
    enabled: isWhiteLabel ? true : false,
  });
  return (
    <WhiteLabelContext.Provider value={{ isWhiteLabel, cdnFolder, ...(configQuery?.data ?? defaultConfig) }}>
      {subdomaisQuery.isLoading || (isWhiteLabel && !configQuery.data) ? <PageSpinner /> : children}
    </WhiteLabelContext.Provider>
  );
};

export const useWhiteLabel = () => {
  const context = React.useContext(WhiteLabelContext);
  if (context === null) throw new Error('useWhiteLabel must be used within a WhiteLabelProvider');
  return context;
};
