import React, { createElement, useCallback } from 'react';
import { confirmable, createConfirmation } from './BaseAyncConfirm';
import * as Dialog from 'src/components/Dialog/NestedDialog';
import type { IconType } from 'react-icons/lib';
import { cn } from 'Utils/TailwindUtils';
import { FiTrash2 } from 'react-icons/fi';
import { Button, ButtonProps } from '../Button';

interface IIconConfirmProps {
  show: boolean;
  proceed: (proceed: boolean) => void;
  confirmation: string;
  options: {
    className?: string;
    okText?: string;
    cancelText?: string;
    title: string;
    icon1: IconType;
    icon2: IconType;
    okExtra?: Omit<ButtonProps, 'ref'>;
    cancelExtra?: Omit<ButtonProps, 'ref'>;
    icon1ClassName?: string;
    icon2ClassName?: string;
  };
}

export const AsyncDualIconConfirm: React.FC<IIconConfirmProps> = ({ show, proceed, confirmation, options }) => {
  const { title, cancelText, okText, icon1, icon2, okExtra, cancelExtra, icon1ClassName, icon2ClassName, className } = options;
  const hideDialogCallback = useCallback(() => proceed(false), []);
  return (
    <Dialog.Root open={show} onOpenChange={hideDialogCallback}>
      <Dialog.NestedDialogContent isOpen={show} className={cn('w-96 rounded-xl p-2', className)}>
        <div className="flex flex-col gap-3 p-3">
          <div className={cn('flex h-14 w-14 items-center justify-center rounded-full border-8', icon1ClassName)}>
            {createElement(icon1, { size: 18 })}
          </div>
          <div className={cn('absolute left-16 flex h-14 w-14 items-center justify-center rounded-full border-8', icon2ClassName)}>
            {createElement(icon2, { size: 18 })}
          </div>
          <p className="mt-2 text-lg">{title}</p>
          <p className="text-sm font-normal text-gray-500">{confirmation}</p>
          <div className="mt-5 flex gap-2">
            <Button variant="secondary" label={cancelText} className="flex-1" onClick={hideDialogCallback} {...cancelExtra} />
            <Button variant="danger" label={okText} className="flex-1" onClick={() => proceed(true)} {...okExtra} />
          </div>
        </div>
      </Dialog.NestedDialogContent>
    </Dialog.Root>
  );
};

export function asyncDualIconConfirm(confirmation: string, options: IIconConfirmProps['options']) {
  return createConfirmation(confirmable(AsyncDualIconConfirm))({ confirmation, options });
}

export function asynDualIconDeleteConfirm(
  confirmation: string,
  options: Omit<IIconConfirmProps['options'], 'icon1ClassName' | 'icon2ClassName' | 'icon2'>
) {
  const newOptions = {
    ...options,
    icon1ClassName: 'border-indigo-25 bg-indigo-50 text-indigo-600',
    icon2ClassName: 'border-red-110 bg-red-250 text-red-900',
    icon2: FiTrash2,
  };
  return createConfirmation(confirmable(AsyncDualIconConfirm))({ confirmation, options: newOptions });
}
