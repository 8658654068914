import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import qs, { ParsedUrlQueryInput } from 'querystring';

const NEXT_PATH_REGEX_VALIDATOR = /^\/.+/;

interface NavigateWithNextPageParamProps {
  pathname: string;
}

export const NavigateWithNextPageParam: FC<NavigateWithNextPageParamProps> = ({ pathname }) => {
  const query: ParsedUrlQueryInput = {};
  const currentPath = window.location.pathname + window.location.search;
  if (currentPath && NEXT_PATH_REGEX_VALIDATOR.test(currentPath)) query.next = currentPath;
  const search = Object.keys(query).length > 0 ? qs.stringify(query) : '';
  return <Navigate to={{ pathname, search }} replace />;
};
