import React from 'react';
import { FiCalendar } from 'react-icons/fi';

export const NoDataError = () => {
  return (
    <div className="flex h-full w-full items-center justify-center text-sm">
      <div className="w-72 text-center font-normal not-italic text-gray-600">
        <img src="/images/error/NoDataError.svg" alt="Failed to generate Data" loading="lazy" width={159} height={140} />
        <p className="mb-3 mt-2">Looks like you haven't received many customer comments for this time period.</p>
        <div className="flex items-center justify-center gap-2">
          <FiCalendar className="text-xl" />
          Try selecting a wider date range
        </div>
      </div>
    </div>
  );
};
