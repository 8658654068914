import { cn } from 'Utils/TailwindUtils';
import { FC, SVGProps } from 'react';

interface CustomTextProps extends SVGProps<SVGTextElement> {
  value: string;
  className?: string;
  textAnchor?: string;
}

export const CustomText: FC<CustomTextProps> = ({ x, y, className, color, textAnchor, value }) => {
  return (
    <text x={x} y={y} fill={color} textAnchor={textAnchor} className={cn('text-sm', className)}>
      {value}
    </text>
  );
};
