import React, { ButtonHTMLAttributes, useCallback } from 'react';
import { cn } from 'Utils/TailwindUtils';
import * as Select from 'src/components/Select';
import { useAppDispatch } from 'src/core/store';
import { IFilterRelation } from 'src/types/Bookmark';
import { FilterRelations } from 'src/types/enums/Filter';
import { useFilterContext } from './DataSourceFilters';

const relationOptions = Object.values(FilterRelations).map((relation) => ({ label: relation, value: relation }));

export const PreviousFilterRelationSelect: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, ...rest }) => {
  const dispatch = useAppDispatch();
  const { previousFilterRelation, updatePreviousFilterRelation } = useFilterContext();
  const handleValueChange = useCallback((value: IFilterRelation) => dispatch(updatePreviousFilterRelation(value)), [dispatch]);
  return (
    <div className="relative">
      <Select.Root value={previousFilterRelation} onValueChange={handleValueChange}>
        <Select.Trigger className={cn('p-2 text-xs font-bold uppercase text-blue-850', className)} {...rest}>
          {previousFilterRelation}
        </Select.Trigger>
        <Select.RadixSingleSelectMenu options={relationOptions} className="min-w-8" optionClassName="uppercase" />
      </Select.Root>
    </div>
  );
};
