import { FC, useEffect } from 'react';
import { Button } from 'src/components/Button';
import * as Dialog from 'src/components/Dialog/NestedDialog';
import { FormTagsInput, FormTextInput } from 'src/components/Input';
import { DiyTagData } from 'src/types/Filter';
import { ISetState } from 'src/types/Globals';
import { DiyTagActionTypes } from './types';
import { useIsMutating } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

interface DiyTagsFormProps {
  value: DiyTagData;
  setValue: ISetState<DiyTagData>;
  type: DiyTagActionTypes;
  submitCallback: (value: DiyTagData) => void;
}

export const DIYTagFormSchema = z.object({
  name: z.string().refine((value) => value.trim().length > 0, { message: 'Please enter name.' }),
  includeKeywords: z.string().array().nonempty({ message: 'Please add at least one keyword.' }),
  excludeKeywords: z.array(z.string()).optional(),
});

export type DIYTagForm = z.infer<typeof DIYTagFormSchema>;

export const DiyTagsForm: FC<DiyTagsFormProps> = ({ value, setValue, type, submitCallback }) => {
  const inProgress = useIsMutating({ mutationKey: ['diyTagsForm'] }) > 0;
  const onSubmit = (value) => {
    setValue(value);
    submitCallback(value);
  };
  const methods = useForm<DIYTagForm>({
    resolver: zodResolver(DIYTagFormSchema),
    mode: 'onBlur',
    defaultValues: value,
  });
  const { register, handleSubmit, getValues, trigger, formState } = methods;
  const handleClick = () => {
    trigger();
    if (formState.isValid) onSubmit(getValues());
  };
  useEffect(() => {
    if (formState.isDirty) trigger();
  }, [formState.isValid]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(() => {})} className="mt-4 flex flex-col gap-4 px-1" id="diy-tag-form">
        <FormTextInput
          label="Name of your DIY tag"
          {...register('name')}
          placeholder="Enter name"
          autoComplete="off"
          defaultValue={value.name ?? null}
        />
        <FormTagsInput
          {...register('includeKeywords')}
          label="Look for tickets including at least one of these words/ phrases"
          help="You can separate keywords using comma"
          pasteSplit={(data) => data.split(/[,]+/)}
        />
        <FormTagsInput
          {...register('excludeKeywords')}
          label="Exclude tickets that has at least one of these words/ phrases"
          help="You can separate keywords using comma"
          pasteSplit={(data) => data.split(/[,]+/)}
        />
      </form>
      <div className="flex justify-end gap-2">
        <Dialog.Close asChild>
          <Button label="Cancel" variant="secondary" />
        </Dialog.Close>
        <Button
          label={type === 'create' ? 'Create' : 'Update'}
          variant="primary"
          type="submit"
          inProgress={inProgress}
          onClick={handleClick}
          data-testid="diy-tag-form-submit"
        />
      </div>
    </FormProvider>
  );
};
