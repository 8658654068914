import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { updateDataSourceFilters, updateFilterRestrictDimension, updateMetadataFilter, updateSearchString } from 'Slices/filter';
import { updateSelectedView } from 'Slices/filterbookmarks';
import { store } from 'src/core/store';
import { IFilterView } from 'src/types/Views';

const dispatch = store.dispatch;

export const trackView = (view: IFilterView, action: string) =>
  trackFilterEvent(TRACK_EVENT.FILTER_BOOKMARK_VIEW, { Id: view.id, Name: view.name, Is_Bookmarked: view.is_bookmarked, Action_Type: action });

export const clearView = () => {
  dispatch(updateDataSourceFilters({}));
  dispatch(updateMetadataFilter([]));
  dispatch(updateSearchString(''));
  dispatch(updateSelectedView());
};
