import { IUserSurveyApiData } from './apiTypes/User';
import { getAdminClientInstance } from './utils/clientAdapters';

export const submitUserSurveyApi = async (data: IUserSurveyApiData, form: 'signup' | 'survey'): Promise<any> => {
  const key = `user/survey?form=${form}`;
  try {
    const response = await getAdminClientInstance(key).post(key, data);
    return response.data.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
