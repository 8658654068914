import { createElement, forwardRef, useRef } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { IconOption } from '../../types';
import { cn } from 'Utils/TailwindUtils';
import { FiChevronRight } from 'react-icons/fi';
import { TextInputGray } from 'src/components/Input';
import { useMatchedOptions, useSearchText } from '../../Hooks';
export interface RadixIconDropdownMenuProps extends DropdownMenuPrimitive.DropdownMenuContentProps {
  options: IconOption[];
  disableSorting?: boolean;
}

const Content = ({
  subOptions,
  searchText,
  onSubMenuClick,
  disableSorting,
}: {
  subOptions: { value: number; label: string }[];
  searchText: string;
  onSubMenuClick: (value: number) => void;
  disableSorting?: boolean;
}) => {
  const matchedOptions = useMatchedOptions(subOptions, searchText, undefined, undefined, disableSorting);
  return (
    <>
      {matchedOptions.length === 0 && <div className="flex justify-center pb-2 pt-4 text-xs text-gray-470">No items found</div>}
      {matchedOptions.map((option, idx) => (
        <DropdownMenuPrimitive.Item
          key={idx}
          onClick={() => onSubMenuClick(option.value)}
          className="flex items-center gap-3 rounded-md p-2 text-left text-sm text-blue-1100 hover:bg-sky-1000"
          role="button"
        >
          <span className="w-full truncate">{option.label}</span>
        </DropdownMenuPrimitive.Item>
      ))}
    </>
  );
};

export const RadixIconDropdownMenu = forwardRef<HTMLDivElement, RadixIconDropdownMenuProps>(
  ({ options, className, disableSorting, ...rest }, ref) => {
    const [searchText, setSearchText] = useSearchText(true);
    const inputRef = useRef<HTMLInputElement>(null);
    return (
      <DropdownMenuPrimitive.Content
        align="start"
        ref={ref}
        className={cn('radix-dropdown-content flex min-w-[10rem] flex-col p-2', className)}
        {...rest}
      >
        {options.map(
          (
            { label, icon, onClick, subOptions, alignOffset, subMenuPlaceholder, onSubMenuClick, iconProps, iconClassName, className, ...rest },
            index
          ) =>
            subOptions ? (
              <DropdownMenuPrimitive.Sub key={index}>
                <DropdownMenuPrimitive.SubTrigger
                  className={cn(
                    'flex w-full items-center justify-between rounded-md p-2 text-left text-sm text-blue-1100 hover:bg-sky-1000',
                    className
                  )}
                  role="button"
                >
                  <div className="flex items-center gap-3">
                    {createElement(icon, { size: 18, className: iconClassName, ...iconProps })}
                    {label}
                  </div>
                  <span className="flex">
                    <FiChevronRight />
                  </span>
                </DropdownMenuPrimitive.SubTrigger>
                <DropdownMenuPrimitive.SubContent
                  className="z-[100] max-h-96 min-h-48 w-80 overflow-auto rounded-lg border-[0.5px] bg-white p-3 shadow-lg"
                  sideOffset={11}
                  alignOffset={alignOffset}
                >
                  <div onKeyDown={(e) => e.stopPropagation()}>
                    <TextInputGray
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder={subMenuPlaceholder}
                      autoFocus={true}
                      ref={inputRef}
                    />
                  </div>
                  <Content subOptions={subOptions} searchText={searchText} onSubMenuClick={onSubMenuClick} disableSorting={disableSorting} />
                </DropdownMenuPrimitive.SubContent>
              </DropdownMenuPrimitive.Sub>
            ) : (
              <DropdownMenuPrimitive.Item
                key={index}
                onClick={onClick}
                data-testid={rest.dataTestId}
                {...rest}
                className={cn('flex items-center gap-3 rounded-md p-2 text-left text-sm text-blue-1100 hover:bg-sky-1000', className)}
                role="button"
              >
                {createElement(icon, { size: 18, className: iconClassName, ...iconProps })}
                <span>{label}</span>
              </DropdownMenuPrimitive.Item>
            )
        )}
      </DropdownMenuPrimitive.Content>
    );
  }
);
