import { INTERVAL_BREAKDOWN_TYPES } from 'Enums/chartIntervalBreakdownTypes';
import moment from 'moment';
import { SOURCE_TYPE_API_VALUES } from 'Enums/SourceModelTypes';
import { OverTimeData } from 'src/api/apiTypes/Summary';
import { IChartComparator } from 'src/types/Filter';
import { IValueOverTime } from 'src/components/Charts/LineCharts/types';
import { IDateRange } from 'src/api/apiTypes/Filters';

export const MOMENT_X_AXIS_DATE_FORMAT = {
  [INTERVAL_BREAKDOWN_TYPES.HOUR]: 'hA',
  [INTERVAL_BREAKDOWN_TYPES.DAY]: 'DD MMM',
  [INTERVAL_BREAKDOWN_TYPES.WEEK]: 'DD MMM',
  [INTERVAL_BREAKDOWN_TYPES.MONTH]: 'MMM YYYY',
};
export const MOMENT_CHART_TOOLTIP_DATE_FORMAT = {
  [INTERVAL_BREAKDOWN_TYPES.HOUR]: 'hA, ddd Do MMM YYYY',
  [INTERVAL_BREAKDOWN_TYPES.DAY]: 'ddd Do MMM, YYYY',
  [INTERVAL_BREAKDOWN_TYPES.WEEK]: 'ddd Do MMM, YYYY',
  [INTERVAL_BREAKDOWN_TYPES.MONTH]: 'MMM YYYY',
};

export const getChartDateLabel = (range: Partial<IDateRange>, intervalBreakdown, DATE_FORMAT = MOMENT_X_AXIS_DATE_FORMAT) => {
  let { start, end } = range ?? {};
  if (intervalBreakdown === INTERVAL_BREAKDOWN_TYPES.WEEK)
    if (moment(start).month() === moment(end).month() && moment(start).year() === moment(end).year())
      if (moment(start).date() === moment(end).date()) return `${moment(start).format(DATE_FORMAT[intervalBreakdown])}`;
      else return `${moment(start).format(DATE_FORMAT[intervalBreakdown].split(' M')[0])} - ${moment(end).format(DATE_FORMAT[intervalBreakdown])}`;
    else return `${moment(start).format(DATE_FORMAT[intervalBreakdown])} - ${moment(end).format(DATE_FORMAT[intervalBreakdown])}`;
  if (Object.values(INTERVAL_BREAKDOWN_TYPES).includes(intervalBreakdown)) return moment(start).format(DATE_FORMAT[intervalBreakdown]);
  return '-';
};

const getValueAndPercentChange = (metricObject, selectedComparator) => {
  const metric = { value: 0, change: 0 };
  metric.value = metricObject?.value;
  if (metricObject?.changes) {
    metricObject.changes.find((percentChangeObject) => {
      if (percentChangeObject.comparison === selectedComparator) {
        metric.change = percentChangeObject.value;
        return true;
      }
      return false;
    });
  }
  return metric;
};
export const getMetricValueAndPercentChange = (metricObjectArray, selectedMetric, selectedComparator) => {
  const metricApiName = SOURCE_TYPE_API_VALUES[selectedMetric];
  if (Array.isArray(metricObjectArray)) {
    const metricObject = metricObjectArray.find(({ metric_type, metric }) => metric_type === metricApiName || metric === metricApiName);
    return getValueAndPercentChange(metricObject, selectedComparator);
  } else return getValueAndPercentChange(metricObjectArray, selectedComparator);
};

export const formChartValueOverTime = (chartMetricData: OverTimeData, chartMetric: string, chartComparator: IChartComparator) => {
  const valueOverTime: IValueOverTime = { dataSize: null, data: [] };
  if (
    chartComparator &&
    chartMetricData &&
    chartMetricData?.current_interval?.breakdown?.data_over_time?.length &&
    chartMetricData?.previous_interval?.breakdown?.data_over_time?.length
  ) {
    valueOverTime.dataSize = {
      currentDataSize: chartMetricData?.current_interval?.breakdown?.data_over_time?.length,
      previousDataSize: chartMetricData?.previous_interval?.breakdown?.data_over_time?.length,
    };
    const totalDataPoints = Math.max(valueOverTime.dataSize.currentDataSize, valueOverTime.dataSize.previousDataSize);
    const apiBreakdownIntervalType = chartMetricData?.current_interval?.breakdown?.type;
    for (let dataPoint = 0; dataPoint < totalDataPoints; dataPoint++) {
      const currentGraphData = chartMetricData?.current_interval?.breakdown?.data_over_time[dataPoint];
      const range =
        chartMetricData?.current_interval?.breakdown?.data_over_time[dataPoint] ??
        chartMetricData?.previous_interval?.breakdown?.data_over_time[dataPoint];
      const metrics = getMetricValueAndPercentChange(currentGraphData?.metrics, chartMetric, chartComparator);
      const previousGraphData = chartMetricData?.previous_interval?.breakdown?.data_over_time[dataPoint];
      const previousRangeMetrics = getMetricValueAndPercentChange(previousGraphData?.metrics, chartMetric, chartComparator);
      const dataPointMetric = {
        date: getChartDateLabel(range?.range, apiBreakdownIntervalType),
        data: metrics.value,
        previousData: previousRangeMetrics.value,
        dataMeta: {
          metric: metrics,
          interval: apiBreakdownIntervalType,
          range: currentGraphData?.range,
          incomplete: currentGraphData?.incomplete,
          isAnomaly: currentGraphData?.is_anomaly,
        },
        previousDataMeta: {
          metric: previousRangeMetrics,
          interval: apiBreakdownIntervalType,
          range: previousGraphData?.range,
          incomplete: previousGraphData?.incomplete,
        },
      };
      valueOverTime.data.push(dataPointMetric);
    }
  }
  if (valueOverTime.data.every(({ data, previousData }) => data === undefined && previousData === undefined)) valueOverTime.isDataNull = true;
  return valueOverTime;
};
