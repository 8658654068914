import React, { ButtonHTMLAttributes, useCallback } from 'react';
import { cn } from 'Utils/TailwindUtils';
import * as Select from 'src/components/Select';
import { useAppDispatch } from 'src/core/store';
import { IFilterRelation } from 'src/types/Bookmark';
import { FilterRelations, FILTER_ACTION_TYPES } from 'src/types/enums/Filter';
import { useFilterGroupContext } from '../DataSourceFilterContainer';
import { cloneDeep } from 'lodash';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { useFilterContext } from '../DataSourceFilters';

const relationOptions = Object.values(FilterRelations).map((relation) => ({ label: relation, value: relation }));

export const GroupFilterRelationSelect: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, ...rest }) => {
  const dispatch = useAppDispatch();
  const { filterGroup, filterGroupIndex, dataSource } = useFilterGroupContext();
  const groupFilterRelation = filterGroup.conditionalFilterRelation;
  const { datasourceFilters: dataSourceFilters, updateDataSourceFilters } = useFilterContext();
  const handleValueChange = useCallback(
    (value: IFilterRelation) => {
      const updatedDataSourceFilters = cloneDeep(dataSourceFilters);
      updatedDataSourceFilters[dataSource][filterGroupIndex].conditionalFilterRelation = value;
      dispatch(updateDataSourceFilters(updatedDataSourceFilters));
      trackFilterEvent(TRACK_EVENT.FILTER_ACTION, {
        action: FILTER_ACTION_TYPES.GROUP_CONDITION,
      });
    },
    [dispatch, dataSourceFilters, dataSource, filterGroupIndex]
  );
  return (
    <div className="relative">
      <Select.Root value={groupFilterRelation} onValueChange={handleValueChange}>
        <Select.Trigger className={cn('mx-0.5 bg-gray-650 p-2 text-xs font-bold text-blue-850', className)} {...rest}>
          {groupFilterRelation}
        </Select.Trigger>
        <Select.RadixSingleSelectMenu options={relationOptions} className="min-w-8" />
      </Select.Root>
    </div>
  );
};
