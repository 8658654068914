import * as Checkbox from '@radix-ui/react-checkbox';
import { HiCheck, HiMinusSm } from 'react-icons/hi';
import { PropsWithChildren, ReactNode, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { cn } from 'Utils/TailwindUtils';

interface CheckBoxType {
  label?: ReactNode;
  onChange: Checkbox.CheckboxProps['onCheckedChange'];
  checked: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  name?: string;
  className?: string;
}

export const CheckBox: React.FC<PropsWithChildren<CheckBoxType>> = (props) => {
  const { label, onChange, checked, name, className, indeterminate, disabled } = props;
  const id = useState[uuid()];
  return (
    <label className="flex cursor-pointer select-none items-center gap-3" htmlFor={name ? name : id}>
      <Checkbox.Root
        className={cn(
          'flex h-4 w-4 items-center justify-center rounded border border-grey-300 text-black radix-state-checked:border-indigo-600 radix-state-checked:bg-indigo-50 radix-state-checked:text-indigo-600 radix-state-indeterminate:border-black',
          className
        )}
        checked={indeterminate ? 'indeterminate' : checked}
        onCheckedChange={onChange}
        disabled={disabled}
        id={name ? name : id}
      >
        <Checkbox.Indicator className="contents font-bold">
          {indeterminate && <HiMinusSm />}
          {checked && <HiCheck />}
        </Checkbox.Indicator>
      </Checkbox.Root>
      {label}
    </label>
  );
};
