import type { TeamInviteDomain, TeamMember } from 'src/types/Team';
import { getAdminClientInstance } from './utils/clientAdapters';
import type { AdminApiWrapper } from './apiTypes/Common';

export const getTeamMembers = async () => {
  try {
    const key = 'team';
    return (await getAdminClientInstance(key).get<AdminApiWrapper<TeamMember[]>>(key)).data.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const deleteTeamMember = async (email: string) => {
  try {
    return await getAdminClientInstance('users/delete').delete('users', { data: { email } });
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const updateTeamMember = async (data: Partial<TeamMember>) => {
  try {
    const key = 'users/' + data.uuid;
    return await getAdminClientInstance(key).put(key, data);
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const inviteTeamMembers = async (emails: string[]) => {
  try {
    const key = 'users/invite';
    return await getAdminClientInstance(key).post(key, emails);
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const getTeamInviteDomains = async () => {
  try {
    const key = 'sso/configuration';
    return (await getAdminClientInstance(key).get<AdminApiWrapper<TeamInviteDomain[]>>(key)).data.data;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};

export const updateTeamInviteDomains = async (data: TeamInviteDomain[]) => {
  try {
    const key = 'sso/configuration';
    return await getAdminClientInstance(key).post(key, data);
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
