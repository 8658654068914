import { CircleInformation } from 'grommet-icons';
import { cn } from 'Utils/TailwindUtils';
import { WhiteTextTooltip } from 'src/components/Tooltip';

export const DataExportCard = ({ icon, name, tooltipText, className, ...props }) => {
  return (
    <button
      {...props}
      className="relative m-3 flex h-24 w-56 items-center justify-center gap-3 rounded-md border border-gray-1100 bg-white text-gray-800 disabled:bg-gray-100"
    >
      {tooltipText && (
        <>
          <div className={cn('absolute right-0 top-0 pr-2')} data-tooltip-id="data-export-tooltip" data-tooltip-content={tooltipText}>
            <CircleInformation size="small" />
          </div>
          <WhiteTextTooltip id="data-export-tooltip" className="z-50 w-80" />
        </>
      )}
      {icon}

      <p className="text-sm">{name}</p>
    </button>
  );
};
