import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import * as Select from 'src/components/Select';
import { updateSelectedDateField } from 'Slices/filter';
import { DropDownArrowIcon } from 'Static/images';

interface DateFieldSelectProps {
  dateFieldOptions: { value: string; label: string; description: string }[];
}
export const DateFieldSelect: React.FC<DateFieldSelectProps> = ({ dateFieldOptions }) => {
  const dispatch = useAppDispatch();
  const selectedDateField = useAppSelector((state) => state.filter.filters.selectedDateField);
  const updateDateFieldCallback = useCallback((value: string) => dispatch(updateSelectedDateField(value)), [dispatch]);
  return (
    <Select.Root value={selectedDateField} onValueChange={updateDateFieldCallback}>
      <Select.Trigger className="filter-button">
        {dateFieldOptions.find(({ value }) => value === selectedDateField)?.label}
        <DropDownArrowIcon />
      </Select.Trigger>
      <Select.RadixDescriptionSelectMenu options={dateFieldOptions} className="z-50" />
    </Select.Root>
  );
};
