import { Transition } from '@headlessui/react';
import React from 'react';
import { ToastBar, Toaster } from 'react-hot-toast';
import { HiCheckCircle } from 'react-icons/hi2';

const toastOptions = { duration: 5000, success: { icon: <HiCheckCircle className="text-xl text-green-700" /> } };

export const ToastContainer = () => {
  return (
    <Toaster position="top-right" containerClassName="text-sm" toastOptions={toastOptions}>
      {(t) => (
        <Transition
          appear
          show={t.visible}
          enter="transform transition ease-out duration-1000 sm:duration-700"
          enterFrom="translate-x-96"
          enterTo="-translate-x-2"
        >
          <ToastBar toast={t} />
        </Transition>
      )}
    </Toaster>
  );
};
