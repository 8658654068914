import { getHubspotToken } from 'src/api/Hubspot';

export const enableHubspotTracking = async (identificationEmail: string) => {
  const token = await getHubspotToken();
  window.hsConversationsSettings = { identificationEmail: identificationEmail, identificationToken: token };
  if (window.HubSpotConversations) window.HubSpotConversations.widget.load();
  else setTimeout(() => window.HubSpotConversations && enableHubspotTracking(identificationEmail), 4000);
};

export const disableHubspotTracking = () => {
  window.HubSpotConversations?.widget.remove();
};
