import { Auth } from '@aws-amplify/auth';
import { persistor } from '../store';
import { trackEvent, TRACK_EVENT, resetTrackers } from '../trackEvent';
import { PREVIOUS_DEPENDS_ON_KEY } from 'src/api/utils/clientAdapters';
import { disableHubspotTracking } from '../hubspotTracking';

export const logout = async () => {
  disableHubspotTracking();
  trackEvent(TRACK_EVENT.LOGOUT);
  resetTrackers();
  persistor.purge();
  localStorage.removeItem(PREVIOUS_DEPENDS_ON_KEY);
  Auth.signOut();
  window.location.replace('/login');
};
