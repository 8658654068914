import React, { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { cn } from 'Utils/TailwindUtils';
import { FormField } from '../Form';
import { TextInputWhite } from './TextInputWhite';

interface FormTextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactNode;
  children?: React.ReactNode;
}

export const FormTextInput = forwardRef<HTMLInputElement, FormTextInputProps>(({ label, name, className, children, ...props }, ref) => {
  const formContext = useFormContext();
  const error = formContext.formState.errors[name ?? ''];
  return (
    <FormField label={label} name={name ?? ''}>
      <div className={cn('flex justify-between rounded-lg border', { 'border-red-900': error }, className)}>
        <TextInputWhite ref={ref} name={name} className="rounded-lg border-0" aria-invalid={!!error} {...props} />
        {children}
      </div>
    </FormField>
  );
});
