import { Helmet } from 'react-helmet-async';
import { BaseRouteProps } from './types';
import { createElement } from 'react';

export const Layout = <P,>({ component, title, props, responsiveView = true }: BaseRouteProps<P>) => {
  return (
    <>
      <Helmet titleTemplate="%s | SentiSum" title={title} defaultTitle="SentiSum">
        {responsiveView && <meta name="viewport" content="width=device-width, initial-scale=1" />}
      </Helmet>
      {createElement(component, props)}
    </>
  );
};
