import { getAdminClientInstance } from './utils/clientAdapters';

export const getHubspotToken = async () => {
  try {
    const key = 'user/hubspot/token';
    return (await getAdminClientInstance(key).get<{ data: { token: string } }>(key)).data.data.token;
  } catch (error) {
    throw new Error(error?.response?.data?.message);
  }
};
