import React, { useContext } from 'react';
import { useFilterGroupContext } from '../DataSourceFilterContainer';
import styled from 'styled-components';
import { IndivisualFilter } from './IndivisualFilter';
import { IFilterData } from 'src/types/Bookmark';
import { AddFilterToGroup } from './AddFilterToGroup';
import { GroupFilterRelationSelect } from './GroupFilterRelationSelect';

interface IndivisualFilterContext {
  filter: IFilterData;
  filterIndex: number;
}

const IndivisualFilterContext = React.createContext<IndivisualFilterContext | null>(null);

const StyledFilterGroup = styled.div`
  &:has(.group-relation:hover) .group-relation {
    background-color: var(--tw-indigo-50);
    color: var(--tw-indigo-600);
  }
`;

export const FilterGroup = () => {
  const { filterGroup } = useFilterGroupContext();
  return (
    <StyledFilterGroup className="flex flex-wrap gap-y-1 text-blue-1100">
      {filterGroup.conditionalFilters.map((filter, filterIndex) => (
        <div className="flex items-center" key={filterIndex}>
          {filterIndex > 0 && <GroupFilterRelationSelect className="group-relation" />}
          <IndivisualFilterContext.Provider value={{ filter, filterIndex }}>
            <IndivisualFilter />
          </IndivisualFilterContext.Provider>
        </div>
      ))}
      {filterGroup.conditionalFilters.at(-1)?.values?.length > 0 && <AddFilterToGroup />}
    </StyledFilterGroup>
  );
};

export const useIndivisualFilterContext = () => useContext(IndivisualFilterContext);
