import { DatabaseAdd } from 'Static/images';
import { SidebarNavLink } from './SidebarNavLink';

export const IntegrationLink = () => {
  return (
    <SidebarNavLink
      to="/settings/integrations"
      icon={DatabaseAdd}
      title="Integrations"
      description="Add more data sources"
      data-tour-element="integration-navigation-sidebar"
    />
  );
};
