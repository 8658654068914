import React from 'react';
import { cn } from 'Utils/TailwindUtils';
import { useIndivisualFilterContext } from '../FilterGroup';
import { FilterOperations } from 'src/types/enums/Filter';

interface FilterValuesLabelProps {
  values: string[];
}

export const FilterValuesLabel: React.FC<FilterValuesLabelProps> = ({ values }) => {
  const operation = useIndivisualFilterContext().filter.operation;
  return (
    <>
      {values.length ? (
        <span className={cn({ capitalize: [FilterOperations.is, FilterOperations.isNot].includes(operation) })}>
          {`${values.slice(0, 3).join(', ')}`}
        </span>
      ) : (
        <span className="text-indigo-600">
          {[FilterOperations.is, FilterOperations.isNot].includes(operation) ? 'Select Value ...' : 'Enter Value ...'}
        </span>
      )}
      {values.length > 3 && <span> or {values.length - 3} more</span>}
    </>
  );
};
