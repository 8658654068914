import React, { FC } from 'react';
import * as Select from '@radix-ui/react-select';
import { RadixSingleSelectMenu, RadixSingleSelectMenuProps } from './RadixSingleSelectMenu';

export const RadixSingleSelect: FC<RadixSingleSelectMenuProps & Pick<Select.SelectProps, 'value' | 'onValueChange'> & { disabled?: boolean }> = (
  props
) => {
  const { children, value, onValueChange, disabled, ...rest } = props;
  return (
    <Select.Root value={value} onValueChange={onValueChange}>
      <Select.Trigger disabled={disabled} asChild>
        {children}
      </Select.Trigger>
      <RadixSingleSelectMenu {...rest} />
    </Select.Root>
  );
};
