import { useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/core/store';

import {
  startToDownloadDataExportFromServerV3,
  toggleShowExportPopup,
  resetDataExportDownloadingStatus,
  resetDataExportReduxState,
} from 'Slices/dataExport';
import { EXPORT_DATA_TYPE } from './DataExportEnums';
import { SURVEY_TYPES } from 'Enums/SourceModelTypes';
import { toast } from 'react-hot-toast';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { SENTISUM_TAG_TYPES } from 'Constants/common';
import { IconConfirm } from 'src/components/Dialog';
import { FiDownload } from 'react-icons/fi';
import format from 'string-template';
import { getUserTimezone } from 'Utils/dateOperations';

const ALL_THEME = 'All Themes';

const CONFIRMATION_DESCRIPTION = 'Export {type}';

const EXPORT_DATA_TYPE_TITLE_PROPS = {
  [EXPORT_DATA_TYPE.DISCOVER_SENTISUM_TAG_ANALYTICS]: {
    label: 'SentiSum Tag Analytics',
  },
  [EXPORT_DATA_TYPE.CONVERSATION_DATA]: {
    label: 'Conversation Data',
  },
};
const EXPORT_DATA_TYPE_TEXT_PROPS = {
  [EXPORT_DATA_TYPE.DISCOVER_SENTISUM_TAG_ANALYTICS]: {
    label: "You're exporting <strong>SentiSum Tag</strong>",
  },
  [EXPORT_DATA_TYPE.CONVERSATION_DATA]: {
    label: "You're exporting <strong>Conversation Data </strong>",
  },
};

export const StartToDownloadDataExportComponent = ({}) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const dispatch = useAppDispatch();
  const {
    selectedSourceClientData,
    filters,
    chartComparator,
    chartMetric,
    chartIntervalBreakdown,
    searchString,
    selectedTheme,
    selectedTopic,
    selectedAspect,
    chartDimension,
    sentimentFilter,
    chartDimensionObject,
  } = useAppSelector((state) => state.filter);

  const { themes } = useAppSelector((state) => state.filter.filterdata);
  const { conversationsMetadataFieldList } = useAppSelector((state) => state.timeseries);
  const { dataExportType, startDownload, dataExportDownloadingStatus, showExportPopup } = useAppSelector((state) => state.dataexport);
  const { timezone } = getUserTimezone();
  const [downloadCompleted, setDownloadComplted] = useState(undefined);
  const themeDimensionMapping = themes?.find((theme) => theme.name === selectedTheme)?.dimension_mapping;
  useEffect(() => {
    if (!!startDownload && dataExportDownloadingStatus !== 'pending') {
      setShowConfirmationDialog(true);
    }
  }, [startDownload]);
  useEffect(() => {
    if (!!downloadCompleted) {
      if (dataExportDownloadingStatus === 'fulfilled') {
        dispatch(resetDataExportDownloadingStatus());
        toast.success(`The data has been exported successfully.`);
      } else if (dataExportDownloadingStatus === 'rejected') {
        dispatch(resetDataExportDownloadingStatus());
        toast.error(`The data export has failed. Please try again.`);
      }
    }
  }, [downloadCompleted]);

  useEffect(() => {
    setTimeout(() => {
      if (dataExportDownloadingStatus === 'fulfilled' || dataExportDownloadingStatus === 'rejected') {
        setDownloadComplted(new String('downloaded'));
      }
    }, 100);
  }, [dataExportDownloadingStatus]);

  const closeDialog = (status) => {
    setShowConfirmationDialog(status);
    if (!status) {
      dispatch(resetDataExportReduxState());
    }
  };
  const confirmedStartToDownload = () => {
    if (dataExportDownloadingStatus !== 'pending') {
      let themeTopics = [];
      if (selectedTheme && themeDimensionMapping) {
        themeTopics = Object.values(themeDimensionMapping)[0] ?? [];
      }
      const mixpanelProps = {
        Export_Data_Type: dataExportType,
      };
      const exportProps = {
        type: dataExportType,
      };
      if (dataExportType === EXPORT_DATA_TYPE.CONVERSATION_DATA) {
        exportProps.fields = conversationsMetadataFieldList
          .filter(({ checked }) => {
            return checked;
          })
          .map(({ path, field_name }) => {
            return { path, field_name };
          });
        mixpanelProps.Export_Field_List = exportProps.fields;
      }

      if (showExportPopup) {
        dispatch(toggleShowExportPopup(false));
      }
      setDownloadComplted(undefined);
      trackFilterEvent(TRACK_EVENT.DATA_EXPORT, mixpanelProps);
      closeDialog(false);
      const request_data = {
        filters,
        metrics: [chartMetric],
        dimension: chartDimension,
        chartComparator: chartComparator,
        chartIntervalBreakdown: chartIntervalBreakdown,
        search: searchString,
        theme: themeTopics,
        timezone,
        exportProps,
      };
      if (dataExportType === EXPORT_DATA_TYPE.CONVERSATION_DATA) {
        request_data.aspect = selectedAspect;
        if (chartDimension === SENTISUM_TAG_TYPES.SENTISUM_TAG) {
          request_data.topic = selectedTopic;
          if (
            ((selectedSourceClientData?.es_alias?.enable_sentiments &&
              [SENTISUM_TAG_TYPES.SENTISUM_TAG, SENTISUM_TAG_TYPES.DIY_TAG].includes(chartDimension)) ||
              (SURVEY_TYPES.includes(selectedSourceClientData?.es_alias?.type) &&
                [SENTISUM_TAG_TYPES.SENTISUM_TAG, SENTISUM_TAG_TYPES.DIY_TAG].includes(chartDimension))) &&
            sentimentFilter > 0
          ) {
            request_data.sentiment = sentimentFilter;
          }
        } else if (chartDimension === SENTISUM_TAG_TYPES.DIY_TAG) {
          request_data.diy_topic = selectedTopic;
          request_data.dimension_values = selectedTopic;
        } else {
          request_data.dimension_values = selectedTopic;
        }
      }
      dispatch(
        startToDownloadDataExportFromServerV3({
          ...request_data,

          selectedSourceClientData,
        })
      );
    }
  };
  const getConfirmationDialogTitle = () => {
    let confirmationText = format(CONFIRMATION_DESCRIPTION, {
      type: EXPORT_DATA_TYPE_TITLE_PROPS?.[dataExportType]?.['label'],
    });
    if (dataExportType === EXPORT_DATA_TYPE.DISCOVER_SENTISUM_TAG_ANALYTICS) {
      confirmationText = format(CONFIRMATION_DESCRIPTION, {
        type: `${chartDimensionObject?.display_name} Analytics`,
      });
    }
    return confirmationText;
  };
  const getConfirmationDialogText = () => {
    let confirmationText = EXPORT_DATA_TYPE_TEXT_PROPS?.[dataExportType]?.['label'];
    if (dataExportType === EXPORT_DATA_TYPE.DISCOVER_SENTISUM_TAG_ANALYTICS) {
      confirmationText = `You're exporting <strong>${chartDimensionObject?.display_name} Analytics</strong>`;
    }
    return <div dangerouslySetInnerHTML={{ __html: confirmationText }} />;
  };
  return (
    <IconConfirm
      visible={showConfirmationDialog}
      setVisible={closeDialog}
      onOk={confirmedStartToDownload}
      icon={FiDownload}
      title={getConfirmationDialogTitle()}
      text={getConfirmationDialogText()}
      className="w-[30rem]"
      okText="Download CSV"
      cancelText="Cancel"
      okExtra={{ variant: 'primary' }}
    />
  );
};
