import React from 'react';
import { ImageDescriptionCard } from 'src/components/Card';

export const NoSourceCard = () => {
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <ImageDescriptionCard
        imagePath="/images/dashboard.svg"
        imageDimensions={{ width: 251, height: 125 }}
        imageAlt="no-dashboard"
        description="We're curating your insights in the better way possible… You'll be able to explore the insights very soon here."
        title="You're almost there and your dashboard is ready"
        className="h-[30rem] w-[35rem] gap-16"
      />
    </div>
  );
};
