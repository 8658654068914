import { TextExtendedProps } from 'grommet';
import React, { useMemo } from 'react';
import { getDateRangeLabel } from 'Utils/dateOperations';
import { Text } from '../Text';

interface IDateRangeLabelProps extends TextExtendedProps {
  start: Date;
  end: Date;
  prefix?: string;
}

const DateRangeLabel: React.FC<IDateRangeLabelProps> = ({ start, end, prefix, ...rest }) => {
  const dateString = useMemo(() => getDateRangeLabel(start, end), [start, end]);
  return <Text textProps={rest} text={`${prefix || ''}${dateString}`} />;
};

export default DateRangeLabel;
