import { FC, SVGProps } from 'react';

export const UpGreenSVG: FC<SVGProps<SVGSVGElement>> = ({ color, x, y }) => {
  return (
    <svg x={x} y={y} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.1" cx="7.25342" cy="7.00024" r="7" fill={color} />
      <path
        d="M9.86315 6.99994L7.25262 4.3894L4.64209 6.99994"
        stroke={color}
        stroke-width="1.17971"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M7.25244 9.61047V4.3894" stroke={color} stroke-width="1.17971" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
