import { getDateRangeLabel } from 'Utils/dateOperations';
import moment from 'moment';
import { FC } from 'react';
import { IDateRange } from 'src/api/apiTypes/Filters';

interface CustomLegendProps {
  dateRange: IDateRange;
  previousDateRange: IDateRange;
}

export const CustomLegend: FC<CustomLegendProps> = ({ dateRange, previousDateRange }) => {
  return (
    <div className="float-right flex gap-3 text-xs text-gray-500">
      <span className="flex items-center justify-center gap-1.5">
        <span className="size-2 rounded-full bg-indigo-600"></span>
        {getDateRangeLabel(moment(dateRange.start).toDate(), moment(dateRange.end).toDate())}
      </span>
      <span className="flex items-center justify-center gap-1.5">
        <span className="size-2 rounded-full bg-indigo-100"></span>
        {getDateRangeLabel(moment(previousDateRange.start).toDate(), moment(previousDateRange.end).toDate())}
      </span>
    </div>
  );
};
