import { cva, VariantProps } from 'class-variance-authority';
import { ForwardedRef, forwardRef, ReactNode } from 'react';
import { HTMLButtonProps } from 'src/types/Globals';
import { cn } from 'Utils/TailwindUtils';
import { Spinner } from '../Spinner';

export const buttonCVA = cva(['border', 'rounded-lg', 'transition duration-500 ease-in', 'disabled:cursor-not-allowed disabled:opacity-50'], {
  variants: {
    variant: {
      danger: 'bg-red-100 text-red-900 border-transparent hover:ring-2 hover:ring-red-900',
      secondary: 'bg-white border-gray-430 text-blue-250 hover:ring-2 hover:ring-black',
      primary: 'bg-grey-900 text-white border-transparent hover:bg-indigo-600',
      tertiary: 'bg-white text-grey-900 border-grey-900 hover:ring-2 hover:ring-indigo-600 hover:border-indigo-600 hover:text-indigo-600',
      light: 'bg-indigo-50 border-0 text-indigo-600 hover:bg-indigo-600 font-semibold hover:text-white duration-200',
      dark: 'bg-indigo-600 border-0 text-white hover:bg-indigo-50 font-semibold hover:text-indigo-600 duration-200',
      neutral: 'bg-gray-50 hover:bg-gray-100',
    },
    size: {
      small: 'px-4 py-2 text-sm gap-3',
      large: 'w-full py-3 rounded-full font-semibold text-base gap-6',
    },
  },
  defaultVariants: {
    size: 'small',
  },
});

export interface CommonButtonProps extends VariantProps<typeof buttonCVA> {
  label?: ReactNode;
  icon?: ReactNode;
}
export interface ButtonProps extends HTMLButtonProps, CommonButtonProps {
  inProgress?: boolean;
}

export const Button = forwardRef(
  ({ variant, inProgress, className, size, icon, label, type, ...rest }: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <button
        className={cn(buttonCVA({ variant, size }), { 'flex items-center justify-center': inProgress || icon }, className)}
        disabled={inProgress}
        ref={ref}
        type={type ?? 'button'}
        {...rest}
      >
        {inProgress ? <Spinner className="text-white" size={size === 'large' ? 'small' : undefined} /> : icon}
        {label}
      </button>
    );
  }
);
