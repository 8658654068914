import React from 'react';
import { DropDownArrowIcon } from 'src/static/images';
import { cn } from 'Utils/TailwindUtils';

interface IDropdownLabelProps {
  label: string;
  className?: string;
}
const DropdownLabel: React.FC<IDropdownLabelProps> = ({ label, className }) => {
  return (
    <div className={cn('flex items-center gap-0.5 text-xs capitalize', className)}>
      {label}
      <DropDownArrowIcon />
    </div>
  );
};

export { DropdownLabel };
