import React from 'react';
import { CustomDriverGraphTooltip } from 'src/ui/containers/SummaryTable/TableBody/CustomDriverGraphTooltip';

export const SummaryBarGraphTooltip = ({ active, payload, selectedMetricType, chartComparator }) => {
  if (!active || !payload || !payload.length) return null;
  return (
    <CustomDriverGraphTooltip
      driver={payload[0].payload.driverData}
      isBarChart={true}
      selectedMetricType={selectedMetricType}
      chartComparator={chartComparator}
    />
  );
};
