import { Suspense, lazy, useEffect } from 'react';
import { fetchSavedBookmarks, updateSelectedView, viewValidationStatus } from 'Slices/filterbookmarks';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { ViewAndFilterContainer } from './ViewAndFilterContainer';

interface FilterContainerProps {
  yScroll: number;
}

export const FilterTopBar: React.FC<FilterContainerProps> = ({ yScroll }) => {
  const dispatch = useAppDispatch();
  const { fetchAvailableFiltersStatus, filterdata } = useAppSelector((state) => state.filter);
  const { fetchAvailableBookmarkViewsStatus, selectedView, bookmarks } = useAppSelector((state) => state.filterbookmarks);
  const selectedSource = useAppSelector((state) => state.filter.filters?.source);
  const setSelectedView = () => {
    const availableView = bookmarks?.all?.find((bookmark) => bookmark.id === selectedView?.id);
    if (fetchAvailableBookmarkViewsStatus === 'fulfilled') dispatch(updateSelectedView(availableView ? availableView : null));
    if (['rejected', 'fulfilled'].includes(fetchAvailableBookmarkViewsStatus)) dispatch(viewValidationStatus(true));
  };
  useEffect(setSelectedView, [fetchAvailableBookmarkViewsStatus]);
  useEffect(() => {
    selectedSource && dispatch(fetchSavedBookmarks(selectedSource));
  }, [selectedSource]);
  useEffect(() => {
    ['fulfilled', 'rejected'].includes(fetchAvailableFiltersStatus) && !filterdata?.filters?.length && dispatch(viewValidationStatus(true));
  }, [filterdata?.filters]);
  if (fetchAvailableFiltersStatus === 'fulfilled' && fetchAvailableBookmarkViewsStatus === 'fulfilled' && filterdata?.filters?.length > 0 && !yScroll)
    return (
      <>
        <hr />
        <Suspense fallback={null}>
          <ViewAndFilterContainer />
        </Suspense>
      </>
    );
  return <></>;
};
