import { Box, Text } from 'grommet';
import { DataExportCard } from './DataExportCard';
import { LineChart, List } from 'grommet-icons';
import { useAppDispatch } from 'src/core/store';

import { setExportDataModelType } from 'Slices/dataExport';
import { EXPORT_DATA_TYPE } from './DataExportEnums';
import { startToDownloadExportData } from 'Slices/dataExport';
import { useAppSelector } from 'src/core/store';

export const DataExportTypeSelectorComponent = ({}) => {
  const { totalConversationCount, conversationsMetadataFieldList } = useAppSelector((state) => state.timeseries);
  const dispatch = useAppDispatch();
  const selectExportType = (type) => {
    dispatch(setExportDataModelType({ type }));
  };
  const isConversationExportDisabled = () => {
    return totalConversationCount > 10000 || totalConversationCount <= 0;
  };
  let chartDimensionObject = useAppSelector((state) => state.filter?.chartDimensionObject);
  const getTopicAnalyticsName = () => {
    if (chartDimensionObject && chartDimensionObject?.display_name) {
      return `${chartDimensionObject?.display_name} Analytics`;
    }

    return 'SentiSum Tag Analytics';
  };
  return (
    <Box pad={'small'} justify={'center'} align={'center'} gap={'small'} width={{ minWidth: '520px', maxWidth: '75%', width: 'auto' }}>
      <Box direction={'row'} margin={{ bottom: 'xsmall' }} align={'start'} width={'100%'}>
        <Text size={'small'}>Download Data in CSV</Text>
      </Box>
      <Box direction={'row'} style={{ flexWrap: 'wrap' }} align={'center'}>
        <DataExportCard
          icon={<LineChart size="28px" color="var(--tw-indigo-600)" />}
          name={getTopicAnalyticsName()}
          onClick={() => {
            selectExportType(EXPORT_DATA_TYPE.DISCOVER_SENTISUM_TAG_ANALYTICS);
            dispatch(startToDownloadExportData());
          }}
        />
        <DataExportCard
          icon={<List size="28px" color="var(--tw-indigo-600)" />}
          name={'Conversation Data'}
          disabled={isConversationExportDisabled()}
          tooltipText={
            isConversationExportDisabled()
              ? `The conversation export min limit is 1 and the max limit is ${Number(
                  10000
                ).toLocaleString()}. Please filter the conversations with this limit.`
              : false
          }
          onClick={() => selectExportType(EXPORT_DATA_TYPE.CONVERSATION_DATA)}
        />
      </Box>
    </Box>
  );
};
