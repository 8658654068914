import moment from 'moment';
import { IDateRangeInterval } from './types';
export const renderStaticRangeLabel = (dateInterval: ReturnType<typeof StaticRanges>[0]) => {
  return (
    <span className={dateInterval.key} onClick={(e) => dateInterval.changeSelection?.(dateInterval?.key) ?? e.stopPropagation()}>
      {dateInterval.customLabel}
    </span>
  );
};

export const StaticRanges = (
  dateIntervals: IDateRangeInterval[],
  selection: (value?: string) => string,
  dateRangeBoundsEnd: Date,
  extraRangeLabel?: JSX.Element
) => {
  const ranges = dateIntervals.map((interval) => ({
    key: interval.key,
    range: () => ({
      days: moment(dateRangeBoundsEnd).diff(interval.startDate, 'days'),
      key: 'selection',
      endDate: interval.endDate,
      startDate: interval.startDate,
      definedRange: true,
    }),
    isSelected: (range) => {
      return selection() === interval.key ? true : false;
    },
    hasCustomRendering: true,
    changeSelection: selection,
    customLabel: <>{interval.label}</>,
  }));
  if (extraRangeLabel)
    ranges.unshift({
      key: 'custom',
      isSelected: (range) => false,
      hasCustomRendering: true,
      range: () => null,
      changeSelection: undefined,
      customLabel: extraRangeLabel,
    });
  return ranges;
};
