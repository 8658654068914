const isStringOrNumber = (value) => {
  const dataType = typeof value;
  if (
    (dataType === 'number' || (dataType === 'string' && value && value.toLowerCase() !== 'null' && value.toLowerCase() !== 'undefined')) &&
    !!value.toString()
  ) {
    return true;
  }
  return false;
};

const isNumber = (value) => {
  if (typeof value === 'number') {
    return true;
  }
  return false;
};

const isString = (value) => {
  if (typeof value === 'string') {
    return true;
  }
  return false;
};

const isObjectType = (value) => {
  const dataType = typeof value;
  if (dataType === 'object' && value !== null) {
    return true;
  }
  return false;
};

const flattenObjectWithParent = (key, value, join_text = ' ') => {
  let expandmetadata = {};
  let valueData = '-';
  if (isStringOrNumber(value)) {
    valueData = value;
    expandmetadata[key] = valueData;
  } else if (Array.isArray(value)) {
    const array_string = value.filter(isStringOrNumber).join(', ');
    if (array_string) {
      valueData = array_string;
    }
    expandmetadata[key] = valueData;
  } else if (isObjectType(value)) {
    Object.keys(value).forEach((object_key) => {
      expandmetadata = {
        ...expandmetadata,
        ...flattenObjectWithParent(`${key}${join_text}${object_key}`, value[object_key]),
      };
    });
  } else expandmetadata[key] = JSON.stringify(value);
  return expandmetadata;
};

const getObjectFirstElementKey = (object) => {
  if (object && typeof object === 'object') {
    return Object.keys(object).find((key) => {
      try {
        return JSON.parse(key.trim());
      } catch (error) {
        return key.trim();
      }
    });
  }
  return undefined;
};

const isObjectsEquivalent = ({ object1: a = {}, object2: b = {}, allowExtraProperty = false }) => {
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (!allowExtraProperty && aProps.length != bProps.length) {
    return false;
  }

  const properties = aProps.length < bProps.length ? aProps : bProps;
  for (let i = 0; i < properties.length; i++) {
    let propName = properties[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

const isObjectsDeepEquivalent = ({ object1: a = {}, object2: b = {}, metadata = { allowExtraProperty: false } }) => {
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (!metadata.allowExtraProperty && aProps.length != bProps.length) {
    return false;
  }

  let properties = aProps;
  let object = a;
  if (aProps.length > bProps.length) {
    properties = bProps;
    minSizeObject = b;
  }
  for (let i = 0; i < properties.length; i++) {
    let propName = properties[i];
    let propValue = object[propName];
    if (!compareValue(a[propName], b[propName], metadata)) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

const compareValue = (a, b, metadata) => {
  let isConditionChecked = true;
  if (a && b) {
    if (isNumber(a) && isNumber(b)) {
      if (a !== b) {
        return false;
      }
    } else if (isString(a) && isString(b)) {
      if (a !== b) {
        return false;
      }
    } else if (Array.isArray(a) && Array.isArray(b)) {
      if (!isSameArrayDeep(a, b)) {
        return false;
      }
    } else if (isObjectType(a) && isObjectType(b)) {
      if (!isObjectsDeepEquivalent({ object1: a, object2: b, metadata })) {
        return false;
      }
    } else {
      isConditionChecked = false;
    }
  } else {
    isConditionChecked = false;
  }
  if (!isConditionChecked && a !== b) {
    return false;
  }
  return true;
};

const isSameArrayDeep = (firstArray, secondArray, metadata) => {
  let a = [...firstArray];
  let b = [...secondArray];

  let result = false;
  const isEqualSize = firstArray.length === secondArray.length;
  if (isEqualSize) {
    result = a.every((aValue) => {
      let bMatchedIndex = -1;
      // return secondArray.includes(value);
      const matched = b.find((bValue, index) => {
        if (compareValue(aValue, bValue, metadata)) {
          bMatchedIndex = index;
          return true;
        }
        return false;
      });
      if (bMatchedIndex < 0) {
        return false;
      }
      b.splice(bMatchedIndex, 1);
      return true;
    });
  }
  return result;
};

const setObjectDefaultProps = (defaultProps, props) => {
  if (isObjectType(defaultProps) && isObjectType(props)) {
    const propsObject = {};
    const allPropsKey = new Set([...Object.keys(defaultProps), ...Object.keys(props)]);
    allPropsKey.forEach((key) => {
      if (key in defaultProps && key in props) {
        const defaultValue = defaultProps[key];
        const propValue = props[key];
        if (isObjectType(defaultValue) && isObjectType(propValue)) {
          propsObject[key] = setObjectDefaultProps(defaultValue, propValue);
        } else {
          propsObject[key] = propValue;
        }
      } else if (key in defaultProps) {
        propsObject[key] = defaultProps[key];
      } else {
        propsObject[key] = props[key];
      }
    });
    return propsObject;
  }
  return props;
};

export default {
  flattenObjectWithParent,
  isString,
  isNumber,
  isStringOrNumber,
  isObjectType,
  getObjectFirstElementKey,
  isObjectsEquivalent,
  isObjectsDeepEquivalent,
  isSameArrayDeep,
  setObjectDefaultProps,
  compareValue,
};
