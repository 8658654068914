import { ResponsiveContainer, AreaChart } from 'recharts';
import { useMemo } from 'react';
import { PropsWithChildren, FC } from 'react';
import { DrawMajorLine } from './DrawMajorLine';
import { BaseGraphProps } from './types';

export const BaseLineGraph: FC<PropsWithChildren<BaseGraphProps>> = (props) => {
  const { size = 'small', valueOverTime, height, width, children, chartProps, lines, isChartTypeInline } = props;
  const containerHeight = useMemo(() => (height ? height : size === 'large' ? 300 : undefined), [height, size]);
  if (typeof width === 'number' && typeof height === 'number')
    return (
      <AreaChart
        role={isChartTypeInline ? 'button' : undefined}
        data={valueOverTime.data}
        {...chartProps}
        width={width}
        height={height}
        className="line-graph"
      >
        {children}
        {DrawMajorLine({ valueOverTime, lines })}
        <defs>
          <linearGradient id="colorData" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="var(--tw-indigo-50)" stopOpacity={0.7} />
            <stop offset="65%" stopColor="var(--tw-indigo-50)" stopOpacity={0.3} />
            <stop offset="97%" stopColor="var(--tw-indigo-50)" stopOpacity={0} />
          </linearGradient>
        </defs>
      </AreaChart>
    );
  return (
    <ResponsiveContainer width={width} height={containerHeight} className="line-graph">
      <AreaChart role={isChartTypeInline ? 'button' : undefined} data={valueOverTime.data} {...chartProps}>
        {children}
        {DrawMajorLine({ valueOverTime, lines })}
        <defs>
          <linearGradient id="colorData" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="var(--tw-indigo-50)" stopOpacity={0.7} />
            <stop offset="65%" stopColor="var(--tw-indigo-50)" stopOpacity={0.3} />
            <stop offset="97%" stopColor="var(--tw-indigo-50)" stopOpacity={0} />
          </linearGradient>
        </defs>
      </AreaChart>
    </ResponsiveContainer>
  );
};
