import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ADMIN_INDEX_SOURCE_TYPES, SOURCE_TYPES } from 'Enums/SourceModelTypes';
import { SURVEY_TYPES, SUPPORT_TYPES } from 'Enums/SourceModelTypes';
import { ICustomTopic, ISelectedClientSourceData, IUser } from 'src/types/User';
import { ISourceOptions } from '../../../src/types/Source';
import { getDateRangeBounds, getFiltersData } from '../../../src/api/Filters';
import {
  IActiveConversation,
  IDateFilterData,
  IFilterState,
  IGetAvailableFiltersResponse,
  IGetDateRangeBoundsRequest,
  IGetDateRangeBoundsResponse,
} from './types/filter';
import { IFilterView, IViewFilterData } from '../../../src/types/Views';
import { IDataSourceFilters, IFilterRelation } from '../../../src/types/Bookmark';
import { IChartComparator, IChartIntervalBreakDown } from '../../../src/types/Filter';
import { DIMENSION_TYPES, SENTISUM_TAG_DIMENSION_OBJECT } from 'Enums/chartDimensionsType';
import moment from 'moment';
import { getPreviousDefaultDateRange, setPreveiousDateRangeOnDateRangeChange } from 'Constants/previousDateRangeIntervals';
import getDateRangeIntervals, { setDateRangeOnDateFieldChange } from 'Constants/DateRangeIntervals';
import filterColors from 'Constants/filterColors.json';
import { RootState } from '../store';
import { transformFilters, transformViewToFilters, hasInValidFilters, getFilterRequestData } from 'src/api/Tranformers';
import { cloneDeep, isEqual } from 'lodash';
import { PERCENT_TICKET_METRIC_OBJECT, SENTIMENT_METRIC_OBJECT, VOLUME_METRIC_OBJECT } from 'Utils/enums/ChartMetricObjects';
import { CommonFilterState } from './types/common';
import { setBreakDownOnDateRangeChange } from 'Utils/constants/BreakDownOptions';
import { fetchDimensions } from './analytics';
import { setDefaultSourceAction } from './actions/launchdarkly';

export const getAvailableFilters = createAsyncThunk<IGetAvailableFiltersResponse, void, { state: RootState }>(
  'getAvailableFilters',
  (_, { getState }) => {
    const { filterdata, sourceType } = getFilterRequestData(getState().filter);
    return getFiltersData(filterdata, sourceType);
  }
);

export const updateDateRangeBounds = createAsyncThunk<IGetDateRangeBoundsResponse, IGetDateRangeBoundsRequest>(
  'updateDateRangeBounds',
  ({ source }) => getDateRangeBounds(source)
);

export const getSourceOptions = (user: IUser) => {
  let aliases: ISourceOptions[] = [];
  user.clients?.forEach((client) =>
    client.es_aliases?.forEach((alias) =>
      aliases.push({
        name: alias.name,
        types: [alias.type],
        displayName: user.clients.length > 1 ? `${client.display_name}: ${alias.display_name}` : alias.display_name,
      })
    )
  );
  return aliases;
};

export const setDefaultDateRange = (
  state: Pick<CommonFilterState, 'filters' | 'dateRangeBounds' | 'selectedSourceClientData' | 'chartIntervalBreakdown'>,
  defaultDateRangeInterval: string
) => {
  const isSurveyType = SURVEY_TYPES.includes(state.selectedSourceClientData?.es_alias?.type);
  const dateRangeBounds = state.dateRangeBounds;
  const dateRanges = getDateRangeIntervals(dateRangeBounds, dateRangeBounds[0].date_field, []);
  const dateRangeKey = defaultDateRangeInterval || (isSurveyType ? 'last28days' : 'last7days');
  const selectedDateRange = dateRanges.find(({ key }) => key === dateRangeKey);
  state.filters.selectedDateField = dateRangeBounds[0].date_field;
  state.filters.dateRange = { start: selectedDateRange?.startDate, end: selectedDateRange?.endDate };
  state.filters.interval = selectedDateRange?.key;
  state.filters.previousDateRange = getPreviousDefaultDateRange(state.filters.dateRange);
  state.filters.previous_interval = 'default';
  state.chartIntervalBreakdown = setBreakDownOnDateRangeChange(state.filters.dateRange);
};

const initialStateAPIStatus = {
  fetchAvailableFiltersStatus: 'idle',
};

const initialState: IFilterState = {
  fetchAvailableFiltersStatus: 'idle',
  fetchAvailbleDateRangeBoundsStatus: 'idle',
  bookmarkFilters: {
    previousFilterRelation: 'and',
    datasourceFilters: {},
  },
  filterdata: { filters: [], topics: [], themes: [] },
  searchString: '',
  chartDimension: DIMENSION_TYPES.SENTISUM_TAG,
  chartDimensionObject: SENTISUM_TAG_DIMENSION_OBJECT,
  chartMetric: SOURCE_TYPES.SUPPORT_TYPE,
  chartMetricObject: VOLUME_METRIC_OBJECT,
  chartComparator: 'percent',
  chartIntervalBreakdown: 'day',
  sentimentFilter: 0,
  activeConversation: 'latest',
  showSavedFilters: true,
};

const FiltersSlice = createSlice({
  name: 'filter',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetFilterReduxState: (state, action: PayloadAction<Partial<CommonFilterState>>) =>
      Object.assign(state, { ...initialState, ...(action?.payload ?? {}) }),
    resetFilterReduxStateAPIStatus: (state) => Object.assign(state, { ...initialStateAPIStatus }),
    updateFilters: (state, action: PayloadAction<ISourceOptions>) => {
      let filters = { ...action.payload };
      if (filters.name !== state.filters?.source)
        state.filters = {
          source: filters.name,
          metadata: [],
        };
    },
    updateFilterRestrictDimension: (state, action: PayloadAction<boolean>) => {
      state.filters.isRestrictDimension = action.payload;
    },
    updateFilterDateRange: (state, action: PayloadAction<IDateFilterData>) => {
      state.filters.dateRange = { start: action.payload.startDate, end: action.payload.endDate };
      state.filters.interval = action.payload.interval;
      state.filters.previous_interval = 'default';
      if (state.filters.dateRange) {
        state.filters.previousDateRange = setPreveiousDateRangeOnDateRangeChange(state.filters);
        state.chartIntervalBreakdown = setBreakDownOnDateRangeChange(state.filters.dateRange, state.chartIntervalBreakdown);
      }
    },
    updateMetadataFilter: (state, action: PayloadAction<IFilterState['filters']['metadata']>) => {
      if (state?.filters && action.payload) state.filters.metadata = action.payload;
    },
    updateDataSourceFilters: (state, action: PayloadAction<IDataSourceFilters>) => {
      state.bookmarkFilters.datasourceFilters = action.payload;
      if (!state.filters.metadata) state.filters.metadata = [];
      if (state.filters.metadata?.length === 0) state.filters.isRestrictDimension = false;
      const newMedataFilters = transformFilters(cloneDeep(state.bookmarkFilters));
      if (!hasInValidFilters(state.bookmarkFilters) && !isEqual(state.filters.metadata, newMedataFilters)) state.filters.metadata = newMedataFilters;
    },
    updateChartDimensionType: (state, action: PayloadAction<string>) => {
      let dimensionsObjectList = state?.selectedSourceClientData?.es_alias?.dimensions;
      const selected_dimension_object = dimensionsObjectList?.find((dimension_details) => dimension_details.name === action.payload);
      state.filters.isRestrictDimension = false;
      if (selected_dimension_object) {
        state.chartDimension = action.payload;
        state.chartDimensionObject = selected_dimension_object;
      } else {
        state.chartDimension = DIMENSION_TYPES.SENTISUM_TAG;
        state.chartDimensionObject = SENTISUM_TAG_DIMENSION_OBJECT;
      }
    },
    updateChartMetricSelector: (state, action: PayloadAction<SOURCE_TYPES>) => {
      state.chartMetric = action.payload;
      const chartMetricObject = state?.selectedSourceClientData?.es_alias?.metrics;
      const selected_metric_object = chartMetricObject?.find((metric_details) => metric_details.name === action.payload);
      if (selected_metric_object && SUPPORT_TYPES.includes(state?.selectedSourceClientData?.es_alias?.type))
        state.chartMetricObject = selected_metric_object;
      else {
        if (SURVEY_TYPES.includes(state?.selectedSourceClientData?.es_alias?.type)) {
          state.chartMetric = SOURCE_TYPES.SURVEY_TYPE;
          state.chartMetricObject = SENTIMENT_METRIC_OBJECT;
        } else {
          state.chartMetric = SOURCE_TYPES.SUPPORT_TYPE;
          state.chartMetricObject = VOLUME_METRIC_OBJECT;
        }
      }
    },
    updateChartComparatorSelector: (state, action: PayloadAction<IChartComparator>) => {
      state.chartComparator = action.payload;
    },
    updateChartIntervalBreakdownSelector: (state, action: PayloadAction<IChartIntervalBreakDown>) => {
      state.chartIntervalBreakdown = action.payload;
    },
    updateChartPreviousComparisonIntervalSelector: (state, action: PayloadAction<IDateFilterData>) => {
      const { startDate, endDate, interval } = action.payload;
      state.filters.previousDateRange = { start: startDate, end: endDate };
      state.filters.previous_interval = interval;
    },
    updateSelectedSourceClientData: (state, action: PayloadAction<ISelectedClientSourceData>) => {
      const userClientSourceData = { ...action.payload };
      const updatedESaliasValues = { ...userClientSourceData.es_alias };
      if (updatedESaliasValues?.dimensions?.length < 1) updatedESaliasValues.dimensions = [SENTISUM_TAG_DIMENSION_OBJECT];
      if (updatedESaliasValues?.metrics?.length < 1)
        if (SURVEY_TYPES.includes(updatedESaliasValues.type)) updatedESaliasValues.metrics = [SENTIMENT_METRIC_OBJECT];
        else updatedESaliasValues.metrics = [VOLUME_METRIC_OBJECT, PERCENT_TICKET_METRIC_OBJECT];
      userClientSourceData.es_alias = updatedESaliasValues;
      state.selectedSourceClientData = userClientSourceData;
    },
    updateSentimentFilterData: (state, action) => {
      state.sentimentFilter = action.payload;
    },
    updateDateRangeBoundsSync: (state, action) => {
      state.dateRangeBounds = action.payload;
    },
    updateSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload ?? '';
    },
    updateAspectTopicTheme: (state, action: PayloadAction<{ themes?: string; topics?: string; aspects?: string }>) => {
      state.selectedTheme = action.payload.themes;
      state.selectedTopic = action.payload.topics;
      state.selectedAspect = action.payload.aspects;
    },
    resetViewFilters: (state) => {
      state.fetchAvailableFiltersStatus = 'idle';
      state.searchString = '';
      state.bookmarkFilters = {
        previousFilterRelation: 'and',
        datasourceFilters: {},
      };
      state.filters.metadata = [];
    },
    updateFiltersFromView: (state, action: PayloadAction<IFilterView>) => {
      state.filters.metadata = action.payload.filters.dataSourceFilters;
      state.searchString = action.payload.filters.search;
      state.bookmarkFilters = transformViewToFilters(action.payload.filters);
    },
    updateSelectedDateField: (state, action: PayloadAction<string>) => {
      state.filters.selectedDateField = action.payload;
      state.filters.dateRange = setDateRangeOnDateFieldChange(state.dateRangeBounds, state.filters);
      state.filters.previousDateRange = setPreveiousDateRangeOnDateRangeChange(state.filters);
    },
    updatePreviousFilterRelation: (state, action: PayloadAction<IFilterRelation>) => {
      state.bookmarkFilters.previousFilterRelation = action.payload;
      const newMedataFilters = transformFilters(cloneDeep(state.bookmarkFilters));
      if (!isEqual(state.filters.metadata, newMedataFilters)) state.filters.metadata = newMedataFilters;
    },
    updateInboxFilter: (state, action: PayloadAction<IActiveConversation>) => {
      state.activeConversation = action.payload;
    },
    addOrUpdateDiyTopic: (state, action: PayloadAction<ICustomTopic>) => {
      const diyTopics = state.selectedSourceClientData?.es_alias?.custom_topics;
      if (diyTopics.some((topic) => topic.id === action.payload.id))
        state.selectedSourceClientData.es_alias.custom_topics = diyTopics.map((topic) => (topic.id === action.payload.id ? action.payload : topic));
      else state?.selectedSourceClientData?.es_alias?.custom_topics.push(action.payload);
    },
    deleteDiyTopic: (state, action: PayloadAction<number>) => {
      state.selectedSourceClientData.es_alias.custom_topics = state.selectedSourceClientData.es_alias.custom_topics.filter(
        (topic) => topic.id !== action.payload
      );
    },
    updateFilterContainerHeight: (state, action: PayloadAction<number>) => {
      state.filterContainerHeight = action.payload;
    },
    setPreviousSource: (state, action: PayloadAction<string>) => {
      state.previousSource = action.payload;
    },
    setDefaultFilter: (state, action: PayloadAction<IViewFilterData>) => {
      state.filters.metadata = action.payload.dataSourceFilters;
      state.searchString = action.payload.search;
      state.showSavedFilters = false;
      state.bookmarkFilters = transformViewToFilters(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvailableFilters.pending, (state) => {
        state.fetchAvailableFiltersStatus = 'pending';
        state.previousSource = state.filters.source;
      })
      .addCase(getAvailableFilters.fulfilled, (state, action) => {
        const { filters, topics, themes } = action.payload;
        state.filterdata.filters = filters.map((filter, index) => ({ ...filter, theme: filterColors[index % filterColors.length] }));
        state.filterdata.topics = topics.map((topic) => ({ value: topic, count: 0 }));
        state.filterdata.themes = themes;
        state.fetchAvailableFiltersStatus = 'fulfilled';
        state.typeMetric = action.payload.typeMetric;
      })
      .addCase(getAvailableFilters.rejected, (state) => {
        state.filterdata = { filters: [], topics: [], themes: [] };
        state.fetchAvailableFiltersStatus = 'rejected';
      })
      .addCase(updateDateRangeBounds.pending, (state) => {
        state.fetchAvailbleDateRangeBoundsStatus = 'pending';
        state.fetchAvailbleDateRangeBoundsError = null;
      })
      .addCase(updateDateRangeBounds.fulfilled, (state, action) => {
        const dateRange = action.payload.filterdata.map(({ dateRange, dateField }) => ({ ...dateField, ...dateRange }));
        state.dateRangeBounds = dateRange;
        const isSouceChanged = !state.previousSource || state.previousSource !== state.filters.source;
        if (isSouceChanged || !state.filters.dateRange || !state.filters.dateRange.start)
          setDefaultDateRange(state, action.meta.arg.defaultDateRangeInterval);
        else {
          state.filters.dateRange = {
            start: moment(state.filters.dateRange.start).toDate(),
            end: moment(state.filters.dateRange.end).toDate(),
          };
          const { start, end } = state.filters.previousDateRange;
          state.filters.previousDateRange = { start: moment(start).toDate(), end: moment(end).toDate() };
        }
      })
      .addCase(updateDateRangeBounds.rejected, (state, action) => {
        state.fetchAvailbleDateRangeBoundsStatus = 'rejected';
        state.fetchAvailbleDateRangeBoundsError = action?.error?.message;
      })
      .addCase(setDefaultSourceAction, (state, action) => {
        const { user, defaultSource } = action.payload;
        const availablesources = getSourceOptions(user);
        const WRONG_SOURCE_IN_FILTERS = availablesources.filter((data) => data.name === state.filters?.source).length === 0;
        if (availablesources.length && (!state.filters?.source || WRONG_SOURCE_IN_FILTERS)) {
          const source =
            availablesources.find(({ name, types }) => (defaultSource ? name === defaultSource : types.includes(ADMIN_INDEX_SOURCE_TYPES.volume)))
              ?.name ?? availablesources[0].name;
          state.filters = { source: source, metadata: [] };
        }
      })
      .addCase(fetchDimensions.fulfilled, (state, action) => {
        if (state.selectedTopic && !action.payload.topics.some((topic) => topic.name === state.selectedTopic)) state.selectedTopic = null;
      });
  },
});

const persistConfig = {
  key: 'filter',
  storage,
  whitelist: [
    'filters',
    'noOfDays',
    'showSavedFilters',
    'bookmarkFilters',
    'filterdata',
    'searchString',
    'typeMetric',
    'chartIntervalBreakdown',
    'previousSource',
  ],
};

export const {
  resetFilterReduxState,
  resetFilterReduxStateAPIStatus,
  updateFilters,
  updateFilterDateRange,
  updateDateRangeBoundsSync,
  updateMetadataFilter,
  updateSearchString,
  updateAspectTopicTheme,
  updateFilterRestrictDimension,
  updateDataSourceFilters,
  resetViewFilters,
  updateChartMetricSelector,
  updateChartComparatorSelector,
  updateSelectedSourceClientData,
  updateChartIntervalBreakdownSelector,
  updateChartPreviousComparisonIntervalSelector,
  updateChartDimensionType,
  updateSentimentFilterData,
  updateFiltersFromView,
  updateSelectedDateField,
  updatePreviousFilterRelation,
  updateInboxFilter,
  addOrUpdateDiyTopic,
  deleteDiyTopic,
  updateFilterContainerHeight,
  setPreviousSource,
  setDefaultFilter,
} = FiltersSlice.actions;
export default persistReducer(persistConfig, FiltersSlice.reducer);
