import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import reducer from './slices';
import * as Sentry from '@sentry/react';
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state: RootState) => {
    return {
      ...state,
      auth: {
        ...state.auth,
        tokens: 'REDACTED',
        user: 'FILTERED',
      },
      timeseries: {
        ...state.timeseries,
        convdata: 'REDACTED',
      },
      onboard: {
        ...state.onboard,
        user: { ...(state.onboard.user || {}), password: 'FILTERED' },
      },
      filter: {
        ...state.filter,
        filterdata: 'FILTERED',
        selectedSourceClientData: { ...state.filter?.selectedSourceClientData, es_alias: 'FILTERED' },
      },
      analytics: { ...state.analytics, topics: 'FILTERED', topicThemeMapping: 'FILTERED' },
      filterbookmarks: { ...state.filterbookmarks, bookmarks: 'FILTERED' },
      dashboard: { ...state.dashboard, reportData: 'FILTERED' },
      editReport: {
        ...state.editGraphReport,
        reportOverview: 'FILTERED',
        reportDrivers: 'FILTERED',
        filterdata: 'FILTERED',
        selectedSourceClientData: { ...state.filter?.selectedSourceClientData, es_alias: 'FILTERED' },
      },
    };
  },
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: ['filter', 'editReport', 'dashboard'],
      },
    }),
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [sentryReduxEnhancer],
});

const persistor = persistStore(store);

export { store, persistor };

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
