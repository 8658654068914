import { useState } from 'react';
import { useFloating, offset, autoPlacement, autoUpdate } from '@floating-ui/react-dom';
import { LinkDialog } from './LinkDialog';
import { HiOutlinePencil } from 'react-icons/hi';

interface UpdateLinkProps {
  targetElement: HTMLElement;
}

export const UpdateLink: React.FC<UpdateLinkProps> = ({ targetElement }) => {
  const [open, setOpen] = useState(false);
  const { refs, floatingStyles } = useFloating({
    middleware: [offset(10), autoPlacement({ allowedPlacements: ['bottom-end', 'top-end', 'bottom', 'top'] })],
    whileElementsMounted: autoUpdate,
  });
  return (
    <div>
      <button
        className="flex items-center justify-center rounded-md p-2 text-base hover:bg-grey-100 hover:text-grey-900 radix-state-open:bg-grey-100 radix-state-open:text-grey-900"
        onClick={() => setOpen(!open)}
        ref={refs.setReference}
        data-state={open ? 'open' : 'closed'}
      >
        <HiOutlinePencil />
      </button>
      {open && <LinkDialog style={floatingStyles} ref={refs.setFloating} setOpen={setOpen} targetElement={targetElement} />}
    </div>
  );
};
