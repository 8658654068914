const formatNumber = (number, decimalPoint = false) => {
  let value = 0;
  if (decimalPoint) {
    if (number < 1 && number > -1) {
      value = Number(number.toFixed(2));
    } else if ((number < 10 && number >= 1) || (number <= -1 && number > -10)) {
      value = Number(number.toFixed(1));
    } else {
      value = Math.round(number);
    }
  } else {
    value = Math.round(number);
  }
  return value;
};

if (!Number.convertToLocaleString) {
  Number.convertToLocaleString = function (value) {
    if (typeof value === 'number') {
      return value.toLocaleString();
    } else if (typeof value === 'string') {
      const number = Number.parseFloat(value);
      if (!Number.isNaN(number)) {
        return number.toLocaleString();
      }
    }
    return '';
  };
}

export default {
  formatNumber,
};
