import { getDriverData } from 'Utils';
import React, { FC } from 'react';
import { Label, LabelList } from 'recharts';
import { METRICS_VALUE_META } from 'Utils/enums/ChartMetadata';
import { getBarGraphData } from 'Utils/chartsV2/barGraphFunctions';
import { Topic } from 'src/api/apiTypes/Summary';
import { currentBar, customXAxis, previousBar } from 'src/components/Charts/BarCharts/GraphComponents';
import { CustomTick } from 'src/components/Charts/BarCharts/GraphComponents/CustomTick';
import { IndividualHBGProps } from './TopIncreaseDecreaseHBG';
import { HorizontalBarGraphCard } from 'src/components/Charts/BarCharts/BarGraphCards/HorizontalBarGraph/HorizontalBarGraphCard';
import { HorizontalBarCustomLabel } from 'src/components/Charts/BarCharts/GraphComponents/CustomLabel';

const barData = { barSize: 20, radius: [8, 8, 8, 8] };

export const HighestLowestHBG: FC<IndividualHBGProps> = (props) => {
  const { drivers, chartMetricObject, hideLabels, label, onClick, chartComparator } = props;
  const tranformDriverDataProps = (c: ReturnType<typeof getDriverData>) => ({ dataLabel: c.valueText });
  const transformDataValueProps = (topic: Topic) => ({
    data: topic.current_interval.metrics[0].value,
    previousData: topic.previous_interval.metrics[0].value,
  });
  const { data, minDomain, maxDomain } = getBarGraphData(
    drivers,
    chartComparator,
    chartMetricObject,
    tranformDriverDataProps,
    transformDataValueProps
  );
  const currentCustomLabel = !hideLabels && <LabelList content={<HorizontalBarCustomLabel data={data} dataKey="data" />} />;
  const xAxisLabel = <Label value={label} dy={-25} />;
  const xAxisData = {
    orientation: 'top' as const,
    axisLine: { stroke: '#E4E7EC' },
    type: 'number' as const,
    domain: [minDomain ?? 'auto', maxDomain],
    tick: <CustomTick metricMetadata={METRICS_VALUE_META[chartMetricObject.name]} {...props} />,
  };
  return (
    <HorizontalBarGraphCard data={data} selectedMetricType={chartMetricObject.name} chartComparator={chartComparator} onClick={onClick}>
      {customXAxis({ xAxisData, xAxisLabel })}
      {previousBar({ onClick, barData })}
      {currentBar({ currentCustomLabel, onClick, barData })}
    </HorizontalBarGraphCard>
  );
};
