import React, { createElement, PropsWithChildren, useCallback } from 'react';
import { CloseButton } from 'src/static/images';
import { cn } from 'Utils/TailwindUtils';
import { Button, ButtonProps } from '../Button';
import * as Dialog from 'src/components/Dialog/NestedDialog';
import { cva, VariantProps } from 'class-variance-authority';

const iconCVA = cva(['flex h-14 w-14 items-center justify-center rounded-full border-8'], {
  variants: {
    variant: {
      primary: 'border-indigo-25 bg-indigo-50 text-indigo-600',
      danger: 'border-red-110 bg-red-250 text-red-900',
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
});

export interface IconConfirmProps extends VariantProps<typeof iconCVA> {
  isProcessing?: boolean;
  onOk?: () => void;
  okText?: string;
  onCancel?: () => void;
  cancelText?: string;
  title: string;
  text?: JSX.Element | string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  okExtra?: Omit<ButtonProps, 'ref'>;
  cancelExtra?: Omit<ButtonProps, 'ref'>;
  iconClassName?: string;
  className?: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const IconConfirm: React.FC<PropsWithChildren<IconConfirmProps>> = ({ children, className, ...props }) => {
  const { isProcessing, visible, setVisible, onOk, okText, onCancel, cancelText, title, text, icon, iconClassName, variant, ...rest } = props;
  const toggleVisibleCallback = useCallback(() => {
    if (!isProcessing) {
      setVisible(!visible);
      onCancel?.();
    }
  }, [isProcessing, setVisible, visible]);
  return (
    <Dialog.Root open={visible} onOpenChange={toggleVisibleCallback}>
      <Dialog.NestedDialogContent isOpen={visible} className={cn('w-96 rounded-xl p-6')}>
        <div className="flex flex-col gap-6">
          <div className="flex items-center gap-3 border-b pb-3">
            <div className={cn(iconCVA({ variant: variant ?? 'primary' }), iconClassName)}>{createElement(icon, { width: 18, height: 18 })}</div>
            <p className="text-lg font-semibold text-grey-800">{title}</p>
            <Dialog.Close className="ml-auto self-start">
              <CloseButton />
            </Dialog.Close>
          </div>
          <p className="text-sm font-normal text-grey-500">{text}</p>
          {children}
          <div className="flex justify-end gap-2">
            <Button variant="secondary" label={cancelText ?? 'Cancel'} onClick={toggleVisibleCallback} {...rest.cancelExtra} />
            <Button variant="danger" label={okText ?? 'ok'} onClick={onOk} {...rest.okExtra} />
          </div>
        </div>
      </Dialog.NestedDialogContent>
    </Dialog.Root>
  );
};
