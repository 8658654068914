import { trackFilterEvent, TRACK_EVENT } from 'src/core/trackEvent';
import saveAs from 'file-saver';
import html2canvas from 'html2canvas';
import type { ExportAsPngData } from './types';
import { toast } from 'react-hot-toast';

export const canCopyImagesToClipboard = !!navigator?.clipboard?.write;

export const exportAsPng = async (type: ExportAsPngData['type'], fileName: string) => {
  const canvas = await html2canvas(document.getElementById('screenshot-element'), {
    useCORS: true,
    backgroundColor: 'white',
    onclone: (document) => document.getElementById('screenshot-element').classList.remove('opacity-0'),
    windowWidth: 1920,
    windowHeight: 1080,
  });
  switch (type) {
    case 'download':
      saveAs(canvas.toDataURL(), `${fileName}.png`);
      break;
    case 'copy':
      if (!canCopyImagesToClipboard) throw new Error('Cannot copy images to clipboard');
      const blob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve, 'image/png'));
      if (!blob) throw new Error('Cannot copy images to clipboard');
      if (document.hasFocus()) {
        await navigator.clipboard.write([new ClipboardItem({ [blob.type]: blob })]);
        toast.success('Image copied to clipboard');
      } else toast.error('Please focus on the page to copy the image to clipboard');
      break;
  }
  trackFilterEvent(TRACK_EVENT.PNG_DOWNLOAD, { fileName, type });
};
