import { CustomArea } from './DrawLine/CustomArea';
import { CustomDotProps, IValueOverTime, MajorLineProps } from './types';

export const getDataSize = (valueOverTimeSeries: IValueOverTime) => {
  return [valueOverTimeSeries.dataSize?.currentDataSize, valueOverTimeSeries.dataSize?.previousDataSize];
};
export const AnomalyDot = (props: CustomDotProps) => {
  const { cx, cy, radius = '7', payload, dataKey } = props;
  if (payload.dataMeta.isAnomaly && dataKey === 'data')
    return (
      <>
        <circle opacity="0.2" cx={cx} cy={cy} r={radius} fill="#D92D20" />
        <circle cx={cx} cy={cy} r={(radius as number) * 0.57} fill="#D92D20" />
      </>
    );
  else return null;
};
export const DrawMajorLine = ({ valueOverTime, lines }: MajorLineProps) => {
  const lineElements: JSX.Element[] = [];
  if (valueOverTime.isDataNull) return lineElements;
  if (valueOverTime.dataSize?.currentDataSize !== 0 && !lines?.hideData) lineElements.push(CustomArea({ valueOverTime, lines, lineKey: 'data' }));
  if (valueOverTime.dataSize?.previousDataSize !== 0 && !lines?.hidePreviousData)
    lineElements.push(CustomArea({ valueOverTime, lines, lineKey: 'previousData' }));
  return lineElements;
};
