import React, { Suspense, createElement } from 'react';
import { Spinner } from 'src/components/Spinner';
import { Helmet } from 'react-helmet-async';
import { BaseRouteProps } from './types';

const RouteLoader = () => (
  <div className="grid h-full w-full grow place-items-center">
    <Spinner size="medium" />
  </div>
);

export const InternalRoute = <P,>({ component, props, title }: BaseRouteProps<P>) => {
  return (
    <>
      <Helmet titleTemplate="%s | SentiSum" title={title} defaultTitle="SentiSum" />
      <Suspense fallback={<RouteLoader />}>{createElement(component, props)}</Suspense>
    </>
  );
};
